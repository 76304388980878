<template>
  <div class="w-full bg-white h-64 border rounded-lg relative">
    <div
      class="absolute h-full w-8 bg-gray-200 animate-pulse rounded-l-lg"
    ></div>
    <div class="absolute top-2 right-2">
      <IconPack type="EllipsisHorizontalCircle" class="w-7 h-7 text-gray-200" />
    </div>
    <div class="w-full h-full pl-16 py-5 flex flex-col gap-6">
      <div class="w-2/3 h-6 bg-gray-200 animate-pulse rounded-full"></div>
      <div class="flex items-center gap-4">
        <div class="w-36 h-8 border bg-gray-50 rounded-md"></div>
        <div class="w-10 h-10 bg-gray-200 animate-pulse rounded-full"></div>
        <div class="w-1/2 h-4 bg-gray-200 rounded-full"></div>
      </div>
      <div class="flex items-center gap-4">
        <div class="w-40 h-4 bg-gray-200 rounded-full"></div>
        <div class="w-10 h-10 bg-gray-200 animate-pulse rounded-full"></div>
        <div class="w-10 h-10 bg-gray-200 animate-pulse rounded-full"></div>
      </div>
      <hr class="-ml-16" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocCardSkeleton'
};
</script>

<style lang="scss" scoped></style>
