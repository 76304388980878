import Login from '@/views/Global/LoginPage.vue';
import Register from '@/views/Global/RegisterPage.vue';
import ForgotPassword from '@/views/Global/ForgotPasswordPage.vue';
import ResetPassword from '@/views/Global/ResetPasswordPage.vue';
import OpenApp from '@/views/Global/OpenApp.vue';

export default [
  {
    meta: { requiresAuth: false },
    path: '/login',
    name: 'Login',
    component: Login,
    title: 'Login'
  },
  {
    meta: { requiresAuth: false },
    path: '/register',
    name: 'Register',
    component: Register,
    title: 'Register'
  },
  {
    meta: { requiresAuth: false },
    path: '/register/:affiliateUid',
    name: 'Register Affiliate',
    component: Register,
    title: 'Register'
  },
  {
    meta: { requiresAuth: false },
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    title: 'Forgot Password'
  },
  {
    meta: { requiresAuth: false },
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    title: 'Reset Password'
  },

  {
    meta: { requiresAuth: false },
    path: '/open-app',
    name: 'Open App',
    component: OpenApp,
    title: 'OpenApp'
  }
];
