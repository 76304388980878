<template>
  <AppModal
    style="backdrop-filter: blur(3px)"
    name="ImageCarousel"
    v-model="showImageCarousel"
  >
    <div class="w-full h-screen flex relative">
      <transition name="scale-quick" mode="out-in" appear>
        <div
          v-if="images && index >= 0 && images[index]?.url && !loading"
          class="m-auto w-full h-full py-20 relative"
          :class="{ 'max-w-7xl': isLandscape, 'max-w-5xl': !isLandscape }"
        >
          <p
            v-if="imageCount > 1"
            class="w-full -mt-10 mb-2 left-0 m-auto text-white text-center text-base font-medium py-2"
          >
            <span class="bg-black bg-opacity-50 py-2 px-6 rounded-full"
              >{{ index + 1 }} / {{ imageCount }}</span
            >
          </p>
          <div class="w-full h-full gap-4 flex justify-between items-center">
            <!-- Left Arrow -->
            <IconPack
              :class="{ invisible: index === 0, visible: index > 0 }"
              @click="index > 0 ? index-- : index, slideImage(-1)"
              type="ArrowLeft"
              class="cursor-pointer fixed left-[15%] top-1/2 bg-white h-9 w-9 text-primary-500 hover:bg-primary-500 z-10 hover:text-white transition-all duration-200 rounded-full p-2"
            />
            <!-- Image -->
            <div
              ref="image"
              class="w-fit mx-auto h-full flex justify-center relative"
            >
              <div
                class="my-auto relative"
                :class="{
                  'w-full max-w-lg': !isLandscape,
                  'max-w-4xl': isLandscape
                }"
              >
                <img
                  :src="images[index]?.url"
                  class="max-h-[80vh] object-contain select-none rounded-lg"
                  alt="..."
                />
                <!-- actions -->
                <div class="absolute flex flex-col gap-4 top-2 -right-14">
                  <!-- close -->
                  <div
                    v-tippy="{ content: 'Close', placement: 'right' }"
                    @click="hideModal"
                    class="w-10 h-10 hover:shadow-sm hover:shadow-white hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full flex items-center justify-center"
                  >
                    <IconPack type="XMark" class="w-5 h-5 text-primary-500" />
                  </div>
                  <!-- delete -->
                  <button
                    v-tippy="{ content: 'Delete Image', placement: 'right' }"
                    v-if="deletable"
                    @click="deleteDocument"
                    class="bg-white group hover:bg-red-400 w-fit transition-all duration-300 hover:-translate-y-0.5 rounded-full p-2"
                  >
                    <IconPack
                      type="Trash"
                      class="w-6 h-6 text-primary-500 group-hover:text-white transition-all duration-300"
                    />
                  </button>
                  <!-- edit label -->
                  <button
                    v-if="!readonly"
                    v-tippy="{ content: 'Edit Label', placement: 'right' }"
                    @click="editLabel"
                    class="bg-white group hover:bg-primary-200 w-fit transition-all duration-300 hover:-translate-y-0.5 rounded-full p-2"
                  >
                    <IconPack
                      type="PencilSquare"
                      class="w-6 h-6 text-primary-500 group-hover:text-white transition-all duration-300"
                    />
                  </button>
                </div>
              </div>
              <div
                class="absolute bottom-0 left-0 flex justify-center w-full"
                v-if="images[index]?.title"
              >
                <p
                  class="w-fit text-primary-500 bg-white bg-opacity-80 px-4 rounded-md text-center text-base font-medium py-2"
                >
                  <span class="">{{ activeImage.title }}</span>
                </p>
              </div>
            </div>
            <!-- Right Arrow -->
            <IconPack
              solid
              :class="{
                invisible: index === imageCount - 1,
                visible: index < imageCount - 1
              }"
              @click="index < imageCount ? index++ : index, slideImage(1)"
              type="ArrowRight"
              class="cursor-pointer fixed right-[15%] top-1/2 bg-white h-9 w-9 text-primary-500 hover:bg-primary-500 z-10 hover:text-white transition-all duration-200 rounded-full p-2"
            />
          </div>
        </div>
        <div v-else class="m-auto">
          <AppSpinner class="w-10 text-white" />
        </div>
      </transition>
    </div>
    <AppModal name="EditLabelModal" v-model="showEditLabel">
      <div class="w-full h-screen flex" style="backdrop-filter: blur(3px)">
        <div class="w-full max-w-lg bg-white rounded-md m-auto p-4">
          <TextInput v-model="label" multiline label="Label" />
          <div class="flex justify-end gap-4 mt-4">
            <div>
              <AppButton @click="updateLabel('cancel')" color="gray"
                >Cancel</AppButton
              >
            </div>
            <div>
              <AppButton @click="updateLabel('save')" color="primary"
                >Save</AppButton
              >
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  </AppModal>
</template>

<script>
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
export default {
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => []
    },
    openAt: {
      type: Number,
      default: 0
    },
    deletable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'delete-image', 'confirm', 'cancel'],
  data() {
    return {
      showImageCarousel: false,
      showEditLabel: false,
      label: '',
      index: this.openAt,
      loading: false
    };
  },
  computed: {
    imageCount() {
      return this.images.length;
    },
    isLandscape() {
      let url = this.images[this.index]?.url;
      if (url) {
        let img = new Image();
        img.src = url;
        return img.width > img.height;
      }
      return false;
    },
    activeImage() {
      return this.images[this.index];
    }
  },
  methods: {
    showModal() {
      this.showImageCarousel = true;
    },
    hideModal() {
      this.showImageCarousel = false;
    },
    slideImage(direction) {
      if (direction === 1) {
        this.$refs.image?.classList.remove('animate-slide-out-right');
        this.$refs.image?.classList.remove('animate-slide-in-right');
        this.$refs.image?.classList.add('animate-slide-out-left');
        setTimeout(() => {
          this.$refs.image?.classList.remove('animate-slide-out-left');
          this.$refs.image?.classList.add('animate-slide-in-left');
        }, 100);
        this.$refs.image?.classList.remove('animate-slide-in-left');
      }

      if (direction === -1) {
        this.$refs.image?.classList.add('animate-slide-out-right');
        setTimeout(() => {
          this.$refs.image?.classList.remove('animate-slide-out-right');
          this.$refs.image?.classList.add('animate-slide-in-right');
        }, 100);
        this.$refs.image?.classList.remove('animate-slide-in-right');
      }
    },
    handleKeyDown(event) {
      if (event.key === 'ArrowLeft') {
        if (this.index > 0) {
          this.index--;
          this.slideImage(-1);
        }
      }
      if (event.key === 'ArrowRight') {
        if (this.index < this.imageCount - 1) {
          this.index++;
          this.slideImage(1);
        }
      }
      if (event.key === 'Escape') {
        this.hideModal();
      }
    },

    deleteDocument() {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        title: 'Delete Image',
        icon: 'Trash',
        message: 'Are you sure you want to delete this image?',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      });

      reveal();

      onConfirm(() => {
        this.loading = true;
        this.$store
          .dispatch('Documents/deleteStaticDocument', this.activeImage.id)
          .then(() => {
            this.$emit('delete-image', this.activeImage);
            this.$toast.success('Document deleted successfully');
          })
          .finally(() => {
            this.loading = false;

            if (this.imageCount === 0) {
              this.hideModal();
            }
          });
      });
    },
    editLabel() {
      this.label = this.activeImage.title;
      this.showEditLabel = true;
    },
    updateLabel(action) {
      if (action === 'save') {
        this.$store
          .dispatch('Documents/upadteStaticDocument', {
            id: this.activeImage.id,
            attributes: {
              title: this.label
            }
          })
          .then(() => {
            this.activeImage.title = this.label;
            this.$emit('refresh', 'Label updated successfully');
          });
      }
      this.showEditLabel = false;
    }
  },
  watch: {
    modelValue() {
      this.showImageCarousel = this.modelValue;
    },
    showImageCarousel() {
      this.$emit('update:modelValue', this.showImageCarousel);
    }
  }
};
</script>

<style scoped>
.animate-slide-out-left {
  animation: slide-out-left 0.1s ease-in-out forwards;
}

.animate-slide-in-left {
  animation: slide-in-left 0.1s ease-in-out forwards;
}

.animate-slide-out-right {
  animation: slide-out-right 0.1s ease-in-out forwards;
  animation-delay: 0.3;
}

.animate-slide-in-right {
  animation: slide-in-right 0.1s ease-in-out forwards;
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-30%);
    opacity: 0;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(30%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(30%);
    opacity: 0;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(-30%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
