// import router 
 import router from '../../router';

export default {
  AUTH_SUCCESS(state, payload) {
    state.status = 'success';
    state.token = payload.jwt;
    state.refreshToken = payload.refresh;
    state.user = payload.user;

    localStorage.setItem('refreshToken', payload.refresh);
    localStorage.setItem('token', payload.jwt);
    localStorage.setItem('user', JSON.stringify(payload.user));
  },
  REFRESH_TOKEN(state, payload) {
    state.token = payload.jwt;
    state.refreshToken = payload.refresh;

    localStorage.setItem('refreshToken', payload.refresh);
    localStorage.setItem('token', payload.jwt);
  },
  LOG_OUT(state) {
    state.status = 'logout';
    state.token = null;
    state.refreshToken = null;
    state.user = null;

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');

    router.push({ name: 'Login' });


  },
  SET_USER(state, payload) {
    state.user = payload;
    localStorage.setItem('user', JSON.stringify(payload));
  },
};
