<template>
  <div class="flex flex-col gap-4 w-full max-w-5xl">
    <AddClientView fullPage />
  </div>
</template>

<script>
import AddClientView from '@/components/Contacts/AddClientView.vue';

export default {
  name: 'ClientList',
  components: {
    AddClientView
  }
};
</script>
