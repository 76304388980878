export default {
  SET_LOCATION(state, location) {
    state.location = location;
  },
  ADD_OR_UPDATE_LOCATION(state, location) {
    if (!state.locations) state.locations = [];
    const index = state.locations.findIndex((loc) => loc?.id === location?.id);
    if (index === -1) {
      state.locations.push(location);
    } else {
      state.locations[index] = location;
    }
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  }
};
