<template>
  <transition name="slide-up" mode="out-in" appear>
    <div class="w-full flex flex-col gap-4 max-w-screen-xl">
      <div type="page" class="w-full flex justify-between items-center gap-2">
        <AppButton
          type="secondary"
          class="!w-32"
          icon="arrow-left"
          @click="$router.go(-1)"
        >
          <IconPack type="ArrowLeft" class="w-4 h-4 mr-2" />
          Back
        </AppButton>
        <div class="flex items-center gap-2 w-full justify-center">
          <p class="text-2xl font-bold">Manage Templates</p>
          <AppChip
            size="xs"
            class="bg-gradient-to-tl from-primary-100 to-primary-400"
          >
            <p class="text-xs font-medium">beta</p>
          </AppChip>
        </div>
        <AppButton
          class="!w-32 !h-fit"
          @click="$router.push('/documents/templates/create')"
        >
          Create
        </AppButton>
      </div>
      <div class="w-full border-t mb-10"></div>
      <transition name="slide-up" mode="out-in" appear>
        <div
          v-if="!companyDocs?.length"
          class="w-full flex items-center justify-center"
        >
          No Custom Templates
        </div>
        <div v-else class="grid grid-cols-2 m-auto w-full gap-8">
          <DocumentTypeCard
            class="h-fit w-fit"
            v-for="documentType in companyDocs"
            :key="documentType.id"
            :documentType="documentType"
          />
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import DocumentTypeCard from '../../../components/Documents/DocumentTypeCard.vue';
export default {
  components: {
    DocumentTypeCard
  },
  mounted() {
    this.$store.dispatch('Documents/getDocumentTemplates');
  },
  computed: {
    documentTypes() {
      return this.$store.getters['Documents/types'];
    },
    companyDocs() {
      return this.documentTypes?.filter(
        (doc) => doc.attributes.type === 'Company'
      );
    },
    globalDocs() {
      return this.documentTypes?.filter(
        (doc) => doc.attributes.type === 'Global'
      );
    }
  }
};
</script>
