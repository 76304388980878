<template>
  <transition name="push-left" mode="out-in" appear>
    <div
      v-if="show"
      class="fixed w-[520px] !z-[60] bg-gray-50 top-0 right-0 h-screen border-l"
    >
      <div
        class="w-full h-full overflow-y-scroll relative"
        ref="commentsScroll"
      >
        <!-- header -->
        <div
          @wheel="preventWheelPropagation"
          class="flex flex-col gap-5 items-center fixed w-[520px] z-50 shadow-sm bg-gray-50 p-5"
        >
          <div class="flex w-full gap-10 items-center">
            <IconPack
              type="ArrowLeft"
              class="text-gray-500 shrink-0 cursor-pointer w-6 h-6"
              @click="show = false"
            />
            <div class="w-full">
              <h3 class="text-lg font-semibold w-full">Job Comments</h3>
              <p class="text-sm text-gray-400">
                {{ location.attributes.address_line }}
              </p>
            </div>
            <AppSwitch
              class="w-fit whitespace-nowrap"
              right-label="This Job Only"
              v-model="currentJobOnly"
            />
          </div>
        </div>
        <!-- footer -->
        <div
          @wheel="preventWheelPropagation"
          class="flex z-50 bg-white fixed w-[520px] shadow-2xl items-center justify-between p-2 bottom-0"
        >
          <div class="flex flex-col w-full">
            <!-- reply to -->
            <ReplyCard
              v-if="replyTo"
              :replyTo="replyTo"
              @remove-reply-to="removeReplyTo"
              active
            />

            <transition name="scale-quick" mode="out-in" appear>
              <!-- mentions -->
              <div
                v-if="mentions.length > 0"
                class="flex flex-col gap-4 px-4 py-2"
              >
                <p class="text-xs font-bold">Mentions:</p>
                <div class="flex gap-6 flex-wrap">
                  <div
                    class="relative"
                    v-for="mention in mentions"
                    :key="mention.id"
                  >
                    <transition name="scale-quick" mode="out-in" appear>
                      <ContactCard
                        icononly
                        :contact="mention.attributes"
                        class="scale-125"
                      />
                    </transition>
                    <div class="absolute -top-2.5 -right-2.5">
                      <IconPack
                        type="XMark"
                        class="w-5 h-5 text-white bg-red-500 rounded-full p-0.5 cursor-pointer"
                        @click="
                          mentions = mentions.filter((m) => m.id !== mention.id)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="scale-quick" mode="out-in" appear>
              <!-- attatchments -->
              <div
                v-if="attachments.type"
                class="flex flex-col w-full gap-2 px-4 py-2"
              >
                <p class="text-xs font-bold">Attachments:</p>
                <!-- template documents -->
                <div v-if="attachments.type == 'template_document'">
                  <TemplateDocCard
                    removable
                    condensed
                    @remove="removeTemplateDoc"
                    :id="attachments.attachment.id"
                    class="w-full"
                    :document="{
                      ...attachments.attachment.attributes,
                      id: attachments.attachment.id
                    }"
                  />
                </div>
                <!-- images -->
                <div
                  v-if="attachments.type == 'image'"
                  class="flex gap-4 flex-wrap"
                >
                  <div
                    v-for="(image, i) in attachments.attachment"
                    :key="`image-${i}`"
                    class="relative"
                  >
                    <transition name="scale-quick" mode="out-in" appear>
                      <img
                        :src="`data:image/png;base64,${image.data}`"
                        alt="`image-${i}`"
                        class="w-24 h-24 object-cover rounded-lg border"
                      />
                    </transition>
                    <div class="absolute -top-1.5 -right-1.5">
                      <IconPack
                        type="XMark"
                        class="w-5 h-5 text-white bg-red-500 rounded-full p-0.5 cursor-pointer"
                        @click="removeAttachment(i)"
                      />
                    </div>
                  </div>
                </div>
                <!-- pdf -->
                <div
                  v-if="attachments.type == 'pdf'"
                  class="flex gap-4 flex-wrap"
                >
                  <div
                    v-for="(pdf, i) in attachments.attachment"
                    :key="`pdf-${i}`"
                    class="relative flex flex-col items-center text-center gap-1 w-24"
                  >
                    <div
                      class="flex flex-col items-center w-full h-24 border rounded-lg p-5"
                    >
                      <IconPack type="Document" class="" />
                      <p class="text-sm">PDF</p>
                    </div>
                    <p class="text-xs">{{ pdf.name }}</p>
                    <div class="absolute -top-1.5 -right-1.5">
                      <IconPack
                        type="XMark"
                        class="w-5 h-5 text-white bg-red-500 rounded-full p-0.5 cursor-pointer"
                        @click="removeAttachment(i)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <div class="flex items-center space-x-2 ">
              <div
                @click="showAttachmentOptions"
                class="rounded-full transition-all duration-300 hover:bg-gray-100 w-11 h-11 flex items-center justify-center"
              >
                <IconPack
                  type="PaperClip"
                  class="text-gray-500 ml-1.5 mr-1 cursor-pointer -translate-x-0.5 w-6 h-6"
                />
              </div>
              <TextInput
                ref="messageInput"
                multiline
                style="resize: none !important"
                v-model="message"
                class="w-72"
                placeholder="Type a message"
              />
              <div
                class=""
                v-tippy="{
                  content:
                    company?.attributes?.subscription_status == 'payment_failed'
                      ? 'Please update your payment method.'
                      : ''
                }"
              >
                <AppButton
                  @click="sendComment"
                  :disabled="
                    fetching ||
                    company?.attributes?.subscription_status == 'payment_failed'
                  "
                  :pulse="fetching"
                  class="rounded-full w-full h-full"
                  style="padding: 8px"
                >
                  <IconPack
                    type="PaperAirplane"
                    solid
                    class="text-white cursor-pointer w-5 h-5"
                  />
                </AppButton>
              </div>
            </div>
          </div>
        </div>

        <!-- comments -->
        <div class="mt-20 mb-40 p-4 w-full">
          <div
            class="w-full flex flex-col gap-2"
            v-for="(group, i) in groupedComments"
            :key="i"
          >
            <div class="w-full my-5 flex sticky top-28 z-30 justify-center">
              <p
                class="text-xs shadow bg-gray-100 py-1.5 px-4 rounded-full w-fit text-center text-gray-400"
              >
                {{
                  moment(group[0].attributes.createdAt).format('Do MMM YYYY')
                }}
              </p>
            </div>
            <div v-for="comment in group" :key="comment.id" class="relative">
              <!-- <transition name="scale-quick" mode="out-in" appear> -->
              <CommentCard
                @replyToComment="replyToComment"
                :comment="comment"
              />
              <div
                :id="comment.id"
                class="absolute top-0 left-0 rounded-xl opacity-0 w-full transition-all duration-300 h-full z-0"
              ></div>
              <!-- </transition> -->
            </div>
          </div>
        </div>

        <!-- attachment options -->
        <transition name="scale-quick" mode="out-in" appear>
          <div
            v-if="showAttachment"
            ref="showAttachment"
            class="fixed flex cursor-pointer flex-col gap-2 rounded-lg border bg-white shadow-lg translate-x-2 bottom-20 z-30"
          >
            <p
              v-if="job.documents.length > 0"
              @click="showTemplateDocuments"
              class="w-full hover:bg-gray-100 transition-all flex items-center gap-3 duration-300 p-4"
            >
              <IconPack type="DocumentText" class="w-5 h-5" />
              Template Document
            </p>
            <p
              @click="showUpload('image')"
              class="w-full hover:bg-gray-100 transition-all flex items-center gap-3 duration-300 p-4"
            >
              <IconPack type="Photo" class="w-5 h-5" />
              Image(s)
            </p>
            <p
              @click="showUpload('pdf')"
              class="w-full hover:bg-gray-100 transition-all flex items-center gap-3 duration-300 p-4"
            >
              <IconPack type="Document" class="w-5 h-5" />
              PDF
            </p>
          </div>
        </transition>

        <!-- template documents -->
        <PopUp
          v-if="showTemplateDocs"
          @close="showTemplateDocs = false"
          class="w-[505px] max-h-[70%] overflow-y-scroll fixed flex flex-col gap-2 bottom-20 rounded-lg border bg-white shadow-lg translate-x-2 z-30"
        >
          <template #title> Template Document </template>
          <template #subtitle>
            This document will be linked to your comment.
          </template>
          <TemplateDocs @select="selectTemplate" :job="job" />
        </PopUp>

        <!-- team members -->
        <transition name="scale-quick" mode="out-in" appear>
          <div
            v-if="showTeamMembers"
            ref="showTeamMembers"
            class="w-[505px] max-h-80 overflow-y-scroll fixed flex flex-col gap-2 bottom-20 rounded-lg border bg-white shadow-lg translate-x-2 z-30"
          >
            <ContactCard
              @click="addMention(member)"
              clickable
              condensed
              v-for="member in memberSearch"
              :key="member.id"
              :contact="member.attributes"
              class="w-full border-none shadow-none"
            />
          </div>
        </transition>

        <!-- pdf or image -->
        <PopUp
          v-if="showFileUpload"
          @close="showFileUpload = false"
          class="bg-white max-h-[70%] overflow-y-scroll rounded-lg fixed bottom-20 w-[505px] translate-x-2 z-30"
        >
          <template #title>
            Upload {{ uploadType === 'image' ? 'Image' : 'PDF' }}
          </template>
          <template #subtitle>
            Upload {{ uploadType === 'image' ? 'an image' : 'a PDF' }} to your
            comment.
          </template>
          <FileUploadModal
            @create-documents="uploadFiles"
            ref="fileUpload"
            :type="uploadType"
            class="border-none shadow"
            grid
          />
        </PopUp>
      </div>
    </div>
  </transition>
</template>

<script>
import CommentCard from './CommentCard.vue';
import AppSwitch from '@/components/EW/AppSwitch.vue';
import ContactCard from '@/components/Contacts/ContactCard.vue';
import TemplateDocs from './TemplateDocs.vue';
import TemplateDocCard from '@/components/Documents/TemplateDocCard.vue';
import FileUploadModal from '@/components/Modals/FileUploadModal.vue';
import PopUp from '@/components/Modals/PopUp.vue';
import ReplyCard from './ReplyCard.vue';

export default {
  name: 'JobComments',
  components: {
    CommentCard,
    AppSwitch,
    ContactCard,
    TemplateDocs,
    TemplateDocCard,
    FileUploadModal,
    PopUp,
    ReplyCard
  },
  emits: ['update:modelValue', 'update:comments'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    location: {
      type: Object,
      default: null
    },
    client: {
      type: Object,
      default: null
    },
    job: {
      type: Object,
      default: null
    }
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (
        this.$refs.showAttachment != event.target &&
        !this.$refs.showAttachment?.contains(event.target)
      ) {
        if (this.showAttachment) this.showAttachment = false;
      }

      // if (this.$refs.showTeamMembers != event.target && !this.$refs.showTeamMembers?.contains(event.target)) {
      //     if (this.showTeamMembers) this.showTeamMembers = false;
      // }
    });

    setTimeout(() => {
      if (this.$route.query.comment) {
        // scroll to comment
        const comment = this.comments.find(
          (comment) => comment.attributes.uid === this.$route.query.comment
        );
        if (comment) {
          const commentEl = document.getElementById(comment.id);
          if (commentEl) {
            // scroll to element and an addition 20px up
            commentEl.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });

            this.highlightComment(comment.id);
          }
        }
      }
    }, 600);
  },
  beforeUnmount() {
    document.removeEventListener('click', () => {
      console.log('removed');
    });
  },
  data() {
    return {
      currentJobOnly: false,
      showAttachment: false,
      showTeamMembers: false,
      showTemplateDocs: false,
      message: '',
      mentions: [],
      attachments: {
        type: '',
        attachment: null
      },
      showFileUpload: false,
      uploadType: '',
      replyTo: null,
      replyToColor: ''
    };
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    fetching() {
      return this.$store.getters['JobComments/fetching'];
    },
    members() {
      return this.company.attributes.users.data?.filter(
        (user) => user.id !== this.user.id
      );
    },
    memberSearch() {
      if (this.message.includes('@')) {
        const lastAtPos = this.message.lastIndexOf('@');
        const searchTerm = this.message.slice(lastAtPos + 1).toLowerCase();

        return this.members.filter((member) => {
          const name =
            `${member.attributes.first_name} ${member.attributes.last_name}`.toLowerCase();
          const isMentioned = this.mentions.some(
            (mention) => mention.id === member.id
          );
          return name.includes(searchTerm) && !isMentioned;
        });
      }
      return [];
    },

    comments() {
      const comments = this.$store.getters['JobComments/jobComments'];
      if (this.currentJobOnly) {
        return comments.filter(
          (comment) => comment.attributes.job.data.id === this.job.id
        );
      }
      return comments;
    },
    groupedComments() {
      return this.comments.reduce((acc, comment) => {
        const date = new Date(comment.attributes.createdAt).toDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(comment);
        return acc;
      }, {});
    },
    replyToUser() {
      return this.replyTo.attributes.creator.data.id === this.user.id
        ? 'You'
        : this.replyTo.attributes.creator.data.attributes.first_name;
    },
    replyToAttachmentCount() {
      return this.replyTo.attributes.job_comment_attachments.data.length;
    },
    replyToAttachmentType() {
      return this.replyTo.attributes.job_comment_attachments.data[0].attributes
        .file_type;
    }
  },
  methods: {
    preventScrollPropagation() {
      this.$refs.commentsScroll.addEventListener('wheel', this.handleScroll, {
        passive: false
      });
    },
    highlightComment(id) {
      const comment = document.getElementById(id);
      if (comment) {
        setTimeout(() => {
          comment.style.backgroundColor = '#000';
          comment.style.opacity = '0.1';
        }, 500);
        setTimeout(() => {
          comment.style.backgroundColor = 'transparent';
          comment.style.opacity = '0';
        }, 1500);
      }
    },
    handleScroll(event) {
      const el = this.$refs.commentsScroll;
      const scrollTop = el.scrollTop;
      const scrollHeight = el.scrollHeight;
      const height = el.clientHeight;
      const wheelDelta = event.deltaY;
      const isAtTop = wheelDelta < 0 && scrollTop <= 0;
      const isAtBottom = wheelDelta > 0 && scrollTop + height >= scrollHeight;

      if (
        (isAtTop || isAtBottom) &&
        !this.showTeamMembers &&
        !this.showTemplateDocs &&
        !this.showFileUpload
      ) {
        event.preventDefault();
      }
    },
    preventWheelPropagation(event) {
      event.preventDefault();
    },
    showAttachmentOptions() {
      if (this.showAttachment) {
        this.showAttachment = false;
      } else {
        setTimeout(() => {
          this.showAttachment = true;
        }, 10);
      }
    },
    addMention(member) {
      const lastAtPos = this.message.lastIndexOf('@');

      const beforeAt = this.message.substring(0, lastAtPos);

      let endIndex = this.message.indexOf(' ', lastAtPos);
      if (endIndex === -1) endIndex = this.message.length;
      const afterAt = this.message.substring(endIndex);

      const mentionName = `${member.attributes.first_name}`;

      const newMessage = `${beforeAt} ${mentionName} ${afterAt}`;

      if (!this.mentions.some((mention) => mention.id === member.id)) {
        this.mentions.push(member);
      }

      this.message = newMessage.trim() + ' ';

      this.showTeamMembers = false;
      this.$refs.messageInput.focus();
    },

    showTemplateDocuments() {
      this.showAttachment = false;
      this.showTemplateDocs = true;
    },

    selectTemplate(doc) {
      this.attachments = {
        type: 'template_document',
        attachment: doc
      };
      this.showTemplateDocs = false;
    },

    removeTemplateDoc() {
      this.attachments = {
        type: '',
        attachment: null
      };
    },

    showUpload(type) {
      this.showAttachment = false;
      this.showFileUpload = true;
      this.uploadType = type;
    },

    uploadFiles(files) {
      this.showFileUpload = false;
      this.attachments = {
        type: this.uploadType,
        attachment: files
      };
    },

    removeAttachment(index) {
      this.attachments.attachment.splice(index, 1);
      if (this.attachments.attachment.length === 0) {
        this.attachments = {
          type: '',
          attachment: null
        };
      }
    },

    sendComment() {
      if (this.message.trim() === '' && !this.attachments.attachment) return;

      const attachments =
        this.attachments.type === 'template_document'
          ? {
              document: this.attachments.attachment.id
            }
          : this.attachments.type === 'image'
          ? [...this.attachments.attachment.map((image) => image.data)]
          : this.attachments.type === 'pdf'
          ? {
              base64: `${this.attachments.attachment[0].data}`,
              name: this.attachments.attachment[0].name
            }
          : null;

      const data = {
        jobId: this.job.id,
        comment: this.message,
        attachments: attachments,
        mentions: this.mentions,
        replyToId: this.replyTo?.id
      };

      this.$store.dispatch('JobComments/createComment', data).then(async () => {
        this.message = '';
        this.mentions = [];
        this.attachments = {
          type: '',
          attachment: null
        };
        this.replyTo = null;

        await this.$store
          .dispatch('JobComments/getJobComments', {
            location: this.location.id,
            client: this.client.id
          })
          .then((res) => {
            // scroll to bottom
            this.$refs.commentsScroll.scrollTop =
              this.$refs.commentsScroll.scrollHeight;
            this.$emit(
              'updateTotal',
              res.data.data.filter((comment) => !comment.attributes.is_deleted)
                .length
            );
          });
      });
    },

    replyToComment(comment) {
      this.replyTo = comment;
      this.$refs.messageInput.focus();
    },

    setBackgroundColor(color) {
      this.replyToColor = color;
    },

    removeReplyTo() {
      this.replyTo = null;
      this.replyToColor = '';
    }
  },
  watch: {
    modelValue(value) {
      if (value) {
        setTimeout(() => {
          this.$refs.commentsScroll.addEventListener(
            'wheel',
            this.handleScroll,
            { passive: false }
          );
        }, 100);
      } else {
        this.$refs.commentsScroll.removeEventListener(
          'wheel',
          this.handleScroll,
          { passive: false }
        );
      }
    },
    message(value) {
      if (value.includes('@')) {
        this.showTeamMembers = true;
      } else {
        this.showTeamMembers = false;
      }
    }
  }
};
</script>
