<template>
  <AppModal
    style="backdrop-filter: blur(3px)"
    name="plantModal"
    v-model="openModal"
  >
    <div class="w-full h-screen px-2 flex relative">
      <div
        @click="hideModal"
        class="w-full opacity-0 h-full z-10 absolute top-0 left-0"
      ></div>
      <AppCard class="m-auto bg-gray-50 w-full z-20 max-w-2xl relative">
        <div class="absolute hidden top-0 -right-14 sm:flex flex-col gap-4">
          <div
            @click="hideModal"
            class="w-10 h-10 hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full flex items-center justify-center"
          >
            <IconPack type="XMark" class="w-5 h-5 text-primary-500" />
          </div>
        </div>
        <div class="flex flex-col gap-8">
          <AppTitle class="-mb-8" type="large">Selected Plants</AppTitle>
          <div class="flex flex-wrap gap-2 items-center">
            <div
              :class="{ 'hidden bg-gray-100': i > 5 }"
              v-for="(plant, i) in selectedPlants"
              :key="plant.id"
            >
              <p v-if="i < 5" class="bg-gray-100 px-2 py-0.5 rounded-full">
                {{ plant?.attributes?.name }}
              </p>
            </div>
            <p
              v-if="selectedPlants.length > 5"
              class="bg-gray-100 px-2 py-0.5 rounded-full"
            >
              +{{ selectedPlants.length - 5 }} more
            </p>
          </div>
          <SearchableDropdown
            v-if="areaOptions && areaOptions.length > 0"
            searchable
            ref="area"
            :options="[...areaOptions]"
            placeholder="Select Plant Area"
            class="z-50 w-full"
            v-model="plantArea"
          >
            <template #iconLeft>
              <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
            </template>
          </SearchableDropdown>

          <div class="flex gap-2 items-center">
            <div>
              <AppButton color="gray" @click="hideModal()"> Cancel </AppButton>
            </div>
            <div>
              <AppButton :pulse="pulse" @click="save"> Save </AppButton>
            </div>
          </div>
        </div>
      </AppCard>
    </div>
  </AppModal>
</template>

<script>
// import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
// import { createConfirmDialog } from 'vuejs-confirm-dialog';

export default {
  data() {
    return {
      openModal: this.modelValue,
      selectedPlants: [],
      plantArea: ''
    };
  },
  computed: {
    areas() {
      return this.$store.getters['Plants/areas'];
    },
    areaOptions() {
      return this.areas?.map((area) => {
        return area.attributes.name;
      });
    },
    pulse() {
      return this.$store.getters['Plants/fetching'];
    }
  },
  mounted() {},
  methods: {
    hideModal() {
      this.openModal = false;
    },
    show(selectedPlants) {
      this.plantArea = '';
      this.selectedPlants = selectedPlants;
      this.openModal = true;
    },
    save() {
      this.selectedPlants.forEach(async (plant) => {
        if (
          this.plantArea &&
          Array.isArray(this.plantArea) &&
          this.plantArea.length > 0
        ) {
          const area = this.areas.find((area) => {
            if (area.attributes.name === this.plantArea[0]) {
              return area;
            }
          });

          plant.attributes.area.data = {
            ...area
          };

          await setTimeout(async () => {
            await this.$store
              .dispatch('Plants/updatePlant', {
                id: plant.id,
                data: {
                  area: {
                    connect: [area.id]
                  }
                }
              })
              .then(() => {
                this.$toast.success(
                  `${plant.attributes.name} updated successfully`
                );
              })
              .catch(() => {
                this.$toast.error(`Failed to update ${plant.attributes.name}`);
              });
          }, 300);
        } else if (this.plantArea === '' || this.plantArea?.length === 0) {
          await setTimeout(async () => {
            await this.$store
              .dispatch('Plants/updatePlant', {
                id: plant?.id,
                data: {
                  area: {
                    disconnect: [plant?.attributes.area?.data?.id]
                  }
                }
              })
              .then(() => {
                this.$toast.success(
                  `${plant.attributes.name} updated successfully`
                );
                plant.attributes.area.data = null;
              })
              .catch(() => {
                this.$toast.error(`Failed to update ${plant.attributes.name}`);
              });
          }, 300);
        }
      });
    }
  }
};
</script>
