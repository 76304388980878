<template>
  <AppCard @click="handleClick" :clickable="clickable">
    <div class="flex gap-5 flex-col lg:flex-row items-center transition-all duration-300 justify-start w-full">
      <label v-if="selectable" class="relative flex items-center rounded-full cursor-pointer">
        <input @click.stop="() => {
          $emit('select', { ...plant, selected: !selected });
        }
          " type="checkbox" :checked="selected"
          class="peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all checked:border-primary-500 checked:bg-white" />
        <span
          class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
          <IconPack type="CheckCircle" solid class="w-6 h-6 text-primary-400" />
        </span>
      </label>

      <div class="flex flex-row items-center gap-2 lg:justify-between" :class="small ? 'w-fit' : 'w-full lg:w-48'">
        <transition name="scale-quick" mode="out-in" appear>
          <div class="font-bold text-sm flex flex-row items-center gap-1" :class="{
            'text-green-500': plant.attributes.online,
            'text-red-500': !plant.attributes.online
          }">
            <div class="rounded-full w-2 h-2 animate-pulse" :class="{
              'bg-green-500': plant.attributes.online,
              'bg-red-500': !plant.attributes.online
            }"></div>
            <p>{{ plant.attributes.online ? 'Online' : 'Offline' }}</p>
          </div>
        </transition>

        <AppChip size="xs" class="text-xs transition-all duration-300" :class="{
          'bg-green-500': plant.attributes.soc > 50,
          'bg-secondary-500': plant.attributes.soc <= 50,
          'bg-red-500': plant.attributes.soc <= 25
        }" v-tippy="{
          content:
            'Last updated ' + moment(plant.attributes.updatedAt).fromNow(),
          placement: 'bottom'
        }">
          <IconPack v-if="plant.attributes.soc > 60" solid type="Battery100" class="w-4 h-4 mr-2" />
          <IconPack v-else-if="plant.attributes.soc > 30" solid type="Battery50" class="w-4 h-4 mr-2" />
          <IconPack v-else type="Battery0" class="w-4 h-4 mr-2" />
          {{ plant.attributes.soc }}%
        </AppChip>

        <!-- v-tippy="{ content: invitedToConnect && !hasConnectApp ? `Invitation sent ${inviteDate ? moment(inviteDate).fromNow() : ''}` : '', hideOnClick: false, delay: 200 }" -->
        <div v-if="!small && (readyToInvite || hasConnectApp || invitedToConnect)"
          class="relative flex w-6 h-6 group items-center">
          <IconPack :type="hasConnectApp
            ? 'DevicePhoneMobile'
            : invitedToConnect
              ? 'Clock'
              : readyToInvite
                ? 'PaperAirplane'
                : 'NoSymbol'
            " :class="{
              'text-primary-500': hasConnectApp,
              'text-secondary-500 group-hover:opacity-0 transition-all duration-300 delay-100':
                invitedToConnect && !hasConnectApp,
              'text-gray-400 -rotate-45 cursor-pointer':
                !hasConnectApp && !invitedToConnect && readyToInvite,
              'text-gray-400':
                !hasConnectApp && !invitedToConnect && !readyToInvite
            }" @click.stop="inviteToConnect" class="w-6 h-6 focus:outline-none shrink-0"
            :solid="invitedToConnect && !hasConnectApp" v-tippy="{
              content: hasConnectApp
                ? 'Has connect app'
                : invitedToConnect && !hasConnectApp
                  ? ''
                  : readyToInvite
                    ? 'Invite to Connect'
                    : plant.attributes.reload_not_ready_reason
                      ? plant.attributes.reload_not_ready_reason
                      : 'Not ready for invite',
              placement: 'bottom',
              delay: 200
            }" />
          <SearchableDropdown v-if="invitedToConnect && !hasConnectApp" @selected="handleSelection" ref="actions"
            noborder action @click.stop
            class="rounded-md absolute transition-all duration-200 delay-100 opacity-0 group-hover:opacity-100"
            :options="[
              { name: 'Resend Invite', icon: 'ArrowUturnLeft' },
              { name: 'Copy Invite Link', icon: 'ClipboardDocumentList' }
            ]">
            <template #iconLeft>
              <IconPack type="EllipsisHorizontalCircle"
                class="w-6 h-6 text-gray-500 cursor-pointer transition-all delay-100 opacity-0 group-hover:opacity-100 duration-300" />
            </template>
          </SearchableDropdown>
        </div>

        <div v-if="!readyToInvite && !hasConnectApp && !invitedToConnect"
          class="relative flex w-6 h-6 group items-center">
          <IconPack v-if="!small" type="NoSymbol" :class="{
            'text-gray-400':
              !hasConnectApp && !invitedToConnect && !readyToInvite
          }"
            class="w-6 h-6 group-hover:opacity-0 transition-all duration-300 delay-100 group-hover:rotate-90 absolute focus:outline-none shrink-0"
            :solid="invitedToConnect && !hasConnectApp" />
          <IconPack v-if="!small" type="ArrowPath" @click.stop="refresh" :class="{
            'text-gray-400':
              !hasConnectApp && !invitedToConnect && !readyToInvite
          }"
            class="w-6 h-6 opacity-0 group-hover:opacity-100 transition-all duration-300 delay-100 cursor-pointer group-hover:rotate-180 absolute focus:outline-none shrink-0"
            :solid="invitedToConnect && !hasConnectApp" v-tippy="{
              content: !plant.attributes.ready_for_reload
                ? plant.attributes.reload_not_ready_reason
                  ? plant.attributes.reload_not_ready_reason
                  : 'Not ready for invite'
                : '',
              placement: 'bottom',
              delay: 200
            }" />
        </div>

        <div class="flex lg:hidden justify-end text-end w-full items-center gap-2 uppercase text-xs font-bold">
          <transition name="scale-quick" mode="out-in" appear>
            <ContactCard v-tippy="{
              content: `Click to view jobs for ${client.first_name} ${client.last_name}`
            }" @click="filterClient" v-if="client" icononly clickable :contact="client" />
          </transition>
          <div v-tippy="{
            content: `${subscriptionType
              ? `<div class='tip-container'><p>Type: <span class='tip-chip'>${subscriptionType
                .toLowerCase()
                .replace('_', ' ')
                .replace('reload', 'Reload')
                .replace('only', ' Only')
                .replace('maintenance', ' Maintenance')
                .replace('gen', 'Gen')}</span></p>`
              : ''
              }${subscriptionType
                ? `<p>Since: <span class='tip-chip'> 
                  ${moment(
                  plant.attributes.subscription?.data?.attributes.createdAt
                ).format('D MMM YYYY')}</span></p></div>`
                : ''
              }`,
            allowHTML: true
          }" class="flex gap-1 items-center" :class="{
            'text-emerald-500': subscriptionStatus == 'active',
            'text-red-400': subscriptionStatus == 'cancelled',
            'text-secondary-500': subscriptionStatus == 'pending',
            'text-red-500': subscriptionStatus == 'payment_failed',
            'text-gray-500': !plant.attributes.subscription?.data
          }">
            <div class="w-2 h-2 rounded-full" :class="{
              'bg-primary-50': subscriptionType == 'RELOAD_MAINTENANCE',
              'bg-secondary-300': subscriptionType == 'RELOAD_ONLY'
            }"></div>
            <IconPack type="CreditCard" class="w-4 h-4" />
            <p v-if="!subscriptionStatus">No Subscription</p>
            <p v-else-if="subscriptionStatus == 'active'">Active</p>
            <p v-else-if="subscriptionStatus == 'pending'">Pending</p>
            <p v-else-if="subscriptionStatus == 'cancelled'">Cancelled</p>
            <p v-else-if="subscriptionStatus == 'payment_failed'">
              Payment Failed
            </p>
          </div>
        </div>
      </div>

      <div class="flex-1 flex w-full overflow-x-hidden items-center sm:items-start flex-col">
        <transition name="scale-quick" mode="out-in" appear>
          <div class="w-fit text-sm whitespace-nowrap">
            {{ plant.attributes.name }}
          </div>
        </transition>
      </div>

      <div class="w-24 flex items-center justify-center">
        <p v-if="plant.attributes.next_inspection"
          class="bg-gray-100 text-gray-500 rounded-lg py-0.5 px-3 w-fit whitespace-nowrap text-xs">
          {{ moment(plant.attributes.next_inspection).format('D MMM YY') }}
        </p>
      </div>

      <transition name="scale-quick" mode="out-in" appear>
        <ContactCard v-tippy="{
          content: `Click to view jobs for ${client.first_name} ${client.last_name}`
        }" @click="filterClient" v-if="client" class="mx-auto hidden lg:block" icononly clickable :contact="client" />
        <div v-else>
          <div class="w-7 h-7 rounded-full"></div>
        </div>
      </transition>

      <div v-if="!small"
        class="flex w-full gap-4 lg:w-2/5 justify-end lg:justify-between uppercase text-right text-xs font-bold whitespace-nowrap items-center">
        <!-- Subscription Status ----------------------------------------------------------------------------------------------------------------- -->

        <div v-tippy="{
          content: `${subscriptionType
            ? `<div class='tip-container'><p>Type: <span class='tip-chip'>${subscriptionType
              .toLowerCase()
              .replace(/_/g, ' ')
              .replace(/reload/g, 'Reload')
              .replace(/only/g, ' Only')
              .replace(/maintenance/g, ' Maintenance')
              .replace(/gen/g, 'Gen')
              .replace(/annual/g, 'Annual')}</span></p>`
            : ''
            } 
            ${subscriptionType
              ? `<p>Since: <span class='tip-chip'> ${moment(
                plant.attributes.subscription?.data?.attributes.createdAt
              ).format('D MMM YYYY')}</span></p></div>`
              : ''
            }`,
          allowHTML: true
        }" class="hidden lg:flex gap-1 items-center w-full" :class="{
          'text-emerald-500': subscriptionStatus == 'active',
          'text-red-400': subscriptionStatus == 'cancelled',
          'text-secondary-500': subscriptionStatus == 'pending',
          'text-red-500': subscriptionStatus == 'payment_failed',
          'text-gray-500': !plant.attributes.subscription?.data
        }">
          <div class="w-2 h-2 rounded-full" :class="{
            'bg-primary-50': subscriptionType == 'RELOAD_MAINTENANCE',
            'bg-secondary-300': subscriptionType == 'RELOAD_ONLY'
          }"></div>
          <IconPack type="CreditCard" class="w-4 h-4" />
          <p v-if="!subscriptionStatus">No Subscription</p>
          <p v-else-if="subscriptionStatus == 'active'">Active</p>
          <p v-else-if="subscriptionStatus == 'pending'">Pending</p>
          <p v-else-if="subscriptionStatus == 'cancelled'">Cancelled</p>
          <p v-else-if="subscriptionStatus == 'payment_failed'">
            Payment Failed
          </p>
        </div>

        <!-- Reload Status ----------------------------------------------------------------------------------------------------------------------- -->

        <div class="uppercase lg:w-full text-center justify-center text-xs font-bold flex gap-3">
          <div v-if="!reloadEnabled">No Reload</div>
          <div class="text-green-500 flex flex-row gap-1 items-center"
            v-else-if="plant.attributes.status == 'optimise'">
            <IconPack type="Banknotes" class="w-4 h-4" />
            Optimise
          </div>
          <div class="text-blue-400 flex flex-row gap-1 items-center" v-else-if="plant.attributes.status == 'charge'">
            <IconPack solid type="Battery100" class="w-4 h-4" />
            Charging
          </div>
          <div class="text-secondary-600 flex flex-row gap-1 items-center"
            v-else-if="plant.attributes.status == 'loadshedding'">
            <IconPack type="Bolt" class="w-4 h-4" />
            Loadshedding
          </div>
        </div>

        <!-- System Size -------------------------------------------------------------------------------------------------------------------- -->

        <transition name="scale-quick" mode="out-in" appear>
          <div class="font-bold text-xs text-gray-500 text-center whitespace-nowrap lg:w-1/2">
            <p v-if="plant.attributes.system_capacity > 0">
              {{ Math.floor(plant.attributes.system_capacity) }} kW
            </p>
          </div>
        </transition>

        <!-- Solarman Button ---------------------------------------------------------------------------------------------------------------- -->

        <a :href="`https://pro.solarmanpv.com/station?&id=${plant.attributes.external_id}`" target="_blank" v-if="
          plant.attributes.integration.data?.attributes.uid ==
          'solarman-business'
        " v-tippy="{
          content: 'Open plant Solarman dashboard',
          placement: 'bottom'
        }">
          <AppButton class="w-max" size="xs" customColor="#4299E1">Solarman
            <IconPack type="ArrowTopRightOnSquare" class="w-4 h-4 ml-2" />
          </AppButton>
        </a>

        <!-- Sunsynk Button ----------------------------------------------------------------------------------------------------------------- -->

        <a :href="`https://sunsynk.net/plants/overview/${plant.attributes.external_id}/2`" target="_blank"
          v-if="plant.attributes.integration.data?.attributes.uid == 'sunsynk'" v-tippy="{
            content: 'Open plant Sunsynk dashboard',
            placement: 'bottom'
          }">
          <AppButton size="xs" customColor="rgb(240, 183, 74)" class="w-max">Sunsynk
            <IconPack type="ArrowTopRightOnSquare" class="w-4 h-4 ml-2" />
          </AppButton>
        </a>

        <!-- GoodWe Button ------------------------------------------------------------------------------------------------------------------------------ -->

        <a :href="`https://www.semsportal.com/powerstation/PowerStatusSnMin/${plant.attributes.external_id}`"
          target="_blank" v-if="plant.attributes.integration.data?.attributes.uid == 'goodwe'" v-tippy="{
            content: 'Open plant GoodWe dashboard',
            placement: 'bottom'
          }">
          <AppButton size="xs" customColor="rgb(198, 10, 25)" class="w-max">GoodWe
            <IconPack type="ArrowTopRightOnSquare" class="w-4 h-4 ml-2" />
          </AppButton>
        </a>

      </div>
    </div>
  </AppCard>
</template>
<script>
import ContactCard from '@/components/Contacts/ContactCard.vue';
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';

export default {
  components: {
    ContactCard
  },
  emits: ['select', 'refresh', 'showDetails'],
  props: {
    small: {
      type: Boolean,
      default: false
    },
    plant: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.clientId && !this.client) {
      this.$store.dispatch('Contacts/getContactById', this.clientId);
    }
  },
  computed: {
    hasConnectApp() {
      return this.plant.attributes.invites?.data.some(
        (invite) => invite.attributes.status === 'completed'
      );
    },
    invitedToConnect() {
      return this.plant.attributes.invites?.data.some(
        (invite) => invite.attributes.status === 'pending'
      );
    },
    readyToInvite() {
      const ready = this.plant.attributes.ready_for_reload;
      if (ready === null || ready === undefined) return false;
      return ready;
    },
    clientId() {
      return this.plant.attributes.invites?.data[0]?.attributes
        .comply_contact_uid;
    },
    client() {
      return this.$store.getters['Contacts/contactById'](this.clientId);
    },
    subscriptionStatus() {
      return this.plant.attributes.subscription?.data?.attributes.status;
    },
    reloadEnabled() {
      return this.plant.attributes.reload_enabled;
    },
    inviteDate() {
      return this.plant.attributes.invites?.data[0]?.attributes.createdAt;
    },
    subscriptionType() {
      const code =
        this.plant.attributes.subscription?.data?.attributes.plan_code;
      return code;
    }
  },
  methods: {
    inviteToConnect() {
      if (this.hasConnectApp) {
        this.$toast.success('Connect app already installed');
        return;
      }

      if (!this.readyToInvite) {
        this.$toast.error(
          'Plant not ready for invite, calcualtions will be inaccurate!',
          {
            duration: 8000,
            position: 'bottom'
          }
        );
      }

      this.$modal.show('ConnectInviteModal', this.plant);
    },
    filterClient() {
      this.$router.push({ path: '/jobs', query: { client: this.client.uid } });
    },
    handleSelection(selection) {
      if (selection.name === 'Resend Invite') {
        this.inviteToConnect();
      } else if (selection.name === 'Copy Invite Link') {
        const invites = this.plant.attributes.invites?.data;
        this.$copyText(
          `https://connect.ew.energy/register?invite=${invites[invites.length - 1]?.attributes.uid
          }`
        );
        this.$toast.success('Invite link copied to clipboard');
      }
    },
    refresh() {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        icon: 'ExclamationTriangle',
        title: 'Send Invite',
        subtitle: `${this.plant.attributes.reload_not_ready_reason
          ? 'Not Ready for Invite'
          : 'Not Ready for Invite'
          }`,
        message: `${this.plant.attributes.reload_not_ready_reason} \n This will cause inaccurate calculation?`,
        color: 'red'
      });

      reveal();

      onConfirm(() => {
        // this.$emit('refresh', this.plant);
        this.inviteToConnect();
      });
    },
    handleClick() {
      if (this.clickable) this.$emit('showDetails', this.plant);
    }
  }
};
</script>

<style>
.tip-chip {
  @apply bg-white bg-opacity-10 text-center px-2.5 py-0.5 w-fit rounded-full justify-center;
}

.tip-container {
  @apply flex flex-col gap-2 p-1;
}
</style>
