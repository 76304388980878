import store from '..';

export default {
  getProducts({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(`${import.meta.env.VITE_API_URL}/products`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token'],
          },
          params: {
            populate: 'categories, product_specifications, product_specifications.product_specification_type',
            sort: 'name',
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit('SET_PRODUCTS', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) commit('SET_PRODUCTS', {});
          console.log(error);
          reject(error);
        });
    });
  },

  getProductCategories({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(`${import.meta.env.VITE_API_URL}/product-categories`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token'],
          },
          params: {
            sort: 'name',
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit('SET_PRODUCT_CATEGORIES', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) commit('SET_PRODUCT_CATEGORIES', {});
          console.log(error);
          reject(error);
        });
    });
  }
};
