import ManageCompany from '@/views/App/ManageCompany.vue';
import SubscriptionPlans from '@/views/App/SubscriptionPlans.vue';
import CancelSubscription from '@/views/App/CancelSubscription.vue';

export default [
  {
    meta: { layout: 'dashboard', requiresAuth: true, requiresCompany: true },
    path: '/subscription',
    name: 'Manage Subscription',
    component: SubscriptionPlans,
    title: 'ManageSubscription'
  },
  {
    meta: { layout: 'dashboard', requiresAuth: true,requiresCompany: true },
    path: '/subscription/cancel',
    name: 'Cancel Subscription',
    component: CancelSubscription,
    title: 'CancelSubscription'
  },

  {
    meta: { layout: 'dashboard', requiresAuth: true,requiresCompany: true },
    path: '/company',
    name: 'Manage Company',
    component: ManageCompany,
    title: 'ManageCompany',
    children: [
      {
        meta: { layout: 'dashboard', requiresAuth: true,requiresCompany: true },
        path: 'subscribe/:plan_code',
        name: 'Company Subscription',
        component: ManageCompany,
        title: 'CompanySubscription'
      }
    ]
  }
];
