<template>
  <div class="w-full flex flex-col gap-8 max-w-5xl items-center">
    <div class="w-full flex justify-between">
      <div
        v-if="!this.id"
        @click="showClientView ? showLocation() : $router.push('/jobs')"
        class="flex cursor-pointer select-none gap-2 items-center hover:-translate-y-[1px] transition-transform duration-300"
      >
        <IconPack
          type="ArrowLeft"
          class="cursor-pointer h-6 w-6 text-primary-500"
        />
        <p>
          {{
            showLocationView ? 'Jobs' : showClientView ? 'Location' : 'Cancel'
          }}
        </p>
      </div>
      <div
        v-else
        @click="handleBack"
        class="flex cursor-pointer select-none gap-2 items-center hover:-translate-y-[1px] transition-transform duration-300"
      >
        <IconPack
          type="ArrowLeft"
          class="cursor-pointer h-6 w-6 text-primary-500"
        />
        <p>Back</p>
      </div>
      <div class="w-fit py-2 px-4 rounded-full">
        <p class="font-bold text-lg">{{ this.id ? 'Edit Job' : 'Add Job' }}</p>
      </div>
    </div>
    <!-- Title and description -->
    <AppCard v-if="showSummaryView" class="w-full">
      <div class="flex gap-2 items-center">
        <IconPack type="Briefcase" class="w-6 h-6 text-primary-500" />
        <h1 class="text-lg text-primary-600 font-extrabold">Job Details</h1>
      </div>
      <div class="flex flex-col gap-2 mt-4">
        <TextInput
          v-model="title"
          class="w-full"
          label="Title"
          placeholder="Job Title"
        />
        <TextInput
          v-model="description"
          multiline
          class="w-full"
          label="Description"
          placeholder="Job Description"
        />
      </div>
    </AppCard>

    <!-- Location -->
    <AddLocationView
      ref="locationView"
      v-if="showLocationView || showSummaryView"
      :summary="showSummaryView"
      v-model="location"
      @save="saveLocation"
      @edit="showLocation"
    />
    <!-- Client -->
    <AddClientView
      skipable
      ref="clientView"
      :active="selectClient"
      v-if="showClientView || showSummaryView"
      v-model="client"
      @select="saveClient"
      @skip="skipContact"
      @swap="swapContact"
    />
    <!-- Date & Time -->
    <div
      v-if="showSummaryView"
      class="w-full flex gap-5 flex-col justify-center items-center"
    >
      <AppCard class="w-full">
        <template #title> Job Date & Time </template>
        <DateRangePicker
          ref="datepicker"
          v-model="dateRange"
          :dateRange="dateRange"
          auto-apply
          single-date-picker
          time-picker
          :time-picker24-hour="false"
          :ranges="false"
          controlContainerClass="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm border-dashed text-gray-500"
        >
          <template #input>
            <div
              @click="addTime"
              class="flex gap-2 cursor-pointer select-none items-center"
            >
              <IconPack type="Calendar" class="w-5 h-5 text-gray-500" />
              <p class="text-gray-500">Date & Time</p>
              <div
                class="flex items-center gap-2"
                v-if="
                  moment(dateRange.startDate).format('Do MMM YYYY') !==
                  'Invalid date'
                "
              >
                <span class="text-gray-400">|</span>
                <p
                  class="text-gray-400 text-sm font-semibold flex items-center gap-3"
                >
                  <span class="bg-gray-100 py-1 px-2 rounded">{{
                    moment(dateRange.startDate).format('Do MMM YYYY')
                  }}</span>
                  <span class="bg-gray-100 py-1 px-2 rounded">{{
                    moment(dateRange.startDate).format('h:mm A')
                  }}</span>
                  <span
                    class="flex items-center gap-1"
                    v-if="
                      moment(dateRange.startDate).format('Do MMM YYYY') !==
                      moment(dateRange.endDate).format('Do MMM YYYY')
                    "
                  >
                    <span>-</span>
                    <span class="bg-gray-100 py-1 px-2 rounded">{{
                      moment(dateRange.endDate).format('Do MMM YYYY')
                    }}</span>
                  </span>
                </p>
              </div>
            </div>
          </template>
          <template #footer>
            <!-- Clear Date Selection -->
            <template
              v-if="dateRange.startDate !== null && dateRange.endDate !== null"
            >
              <div
                @click="clearDates"
                class="p-1 border-t mt-1 flex justify-center"
              >
                <p
                  class="hover:bg-gray-100 w-full h-full p-2 text-center text-sm select-none cursor-pointer rounded"
                >
                  Clear Selection
                </p>
              </div>
            </template>
          </template>
        </DateRangePicker>
      </AppCard>
      <AppCard class="w-full">
        <div class="flex w-full items-center gap-10">
          <!-- Own Reference Number -->
          <div>
            <TextInput
              v-model="reference_number"
              class="w-full"
              label="Own Reference Number"
              placeholder="REV01"
            />
          </div>
          <!-- Job Type -->
          <SearchableDropdown
            label="Job Type"
            v-model="jobType"
            dashed
            :options="
              jobTypes?.map((type) => {
                return { color: type.color, name: type.name };
              })
            "
            placeholder="Select a job type"
          >
            <template #iconRight>
              <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
            </template>
          </SearchableDropdown>
        </div>
      </AppCard>
      <div class="w-full flex justify-center items-center max-w-xs">
        <AppButton
          :pulse="pulse"
          @click="this.id ? updateJob() : createJob()"
          class="mt-5"
          type="primary"
          >{{ this.id ? 'Save Changes' : 'Create Job' }}
        </AppButton>
      </div>
    </div>
    <ErrorModal ref="errorModal" />
  </div>
</template>

<script>
import AddLocationView from '@/components/Location/AddLocationView.vue';
import AddClientView from '@/components/Contacts/AddClientView.vue';
import DateRangePicker from 'vue3-daterange-picker';
import moment from 'moment';
import ErrorModal from '@/components/Modals/ErrorModal.vue';

export default {
  name: 'AddJobs',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  components: {
    AddLocationView,
    AddClientView,
    DateRangePicker,
    ErrorModal
  },
  mounted() {
    if (this.id) {
      this.$store
        .dispatch('Jobs/getJobById', this.id)
        .then((res) => {
          this.title = res.data.data[0].attributes.title;
          this.description = res.data.data[0].attributes.description;
          this.location =
            res.data.data[0].attributes.location?.data?.attributes;
          this.client = res.data.data[0].attributes.client?.data?.attributes;
          this.dateRange.startDate = moment(
            res.data.data[0].attributes.start_date
          ).toDate();
          this.dateRange.endDate = moment(
            res.data.data[0].attributes.start_date
          ).toDate();
          this.reference_number = res.data.data[0].attributes.reference_number;
          this.jobType = this.jobTypes?.find(
            (type) =>
              type.name.toLowerCase() ===
              res.data.data[0].attributes.job_type.data.attributes.name.toLowerCase()
          );
          this.jobType = {
            color: this.jobType.color,
            name: this.jobType?.name
          };
        })
        .catch((err) => {
          console.log(err);
        });
      this.showLocationView = false;
      this.showClientView = false;
      this.showSummaryView = true;
    }
  },

  computed: {
    job() {
      return this.$store.getters['Jobs/jobById'];
    },
    fetching() {
      return this.$store.getters['Jobs/fetching'];
    }
  },

  data() {
    return {
      jobType: null,
      showLocationView: true,
      showClientView: false,
      showSummaryView: false,
      location: null,
      client: null,
      dateRange: {
        startDate: moment().startOf('day').set({ hour: 8, minute: 0 }).toDate(),
        endDate: moment().endOf('day').toDate()
      },
      selectClient: false,
      reference_number: '',
      title: '',
      description: '',
      pulse: false
    };
  },

  methods: {
    saveLocation() {
      this.$store
        .dispatch('Location/createLocation', this.location)
        .then((res) => {
          this.$toast.success('Location saved successfully');
          this.$refs.locationView.map_image =
            res.data.data.attributes.map_image;
          this.showLocationView = false;
          if (this.client) {
            this.showSummaryView = true;
            this.selectClient = false;
          } else {
            this.showClientView = true;
            this.selectClient = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error('Failed to save location', {
            position: 'bottom',
            onClick: () => {
              this.$refs.errorModal.show(err);
            }
          });
        });
    },
    saveClient() {
      this.showClientView = false;
      this.showSummaryView = true;
    },
    showLocation() {
      this.showSummaryView = false;
      this.showClientView = false;
      this.showLocationView = true;
    },
    skipContact() {
      console.log('skip');
      this.client = null;
      this.showClientView = false;
      this.showSummaryView = true;
    },
    swapContact() {
      this.showClientView = true;
      this.showSummaryView = false;
    },
    clearDates() {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
    },
    addTime() {
      setTimeout(() => {
        const calendarTime = document.querySelector('.calendar-time');
        const calendarContainer = document.querySelector('.drp-calendar');

        if (calendarContainer) {
          calendarContainer.classList.add('gap-3');
          calendarContainer.style.display = 'flex';
          calendarContainer.style.flexDirection = 'column-reverse';
        }

        if (calendarTime) {
          //append a Time title
          const timeTitle = document.createElement('p');
          timeTitle.classList.add('text-gray-500', 'text-sm', 'font-semibold');
          timeTitle.innerText = 'Time: ';
          calendarTime.prepend(timeTitle);
          calendarTime.classList.add('items-center', 'gap-1', 'bg-white');
        }
      }, 10);
    },
    handleBack() {
      if (this.showSummaryView) {
        this.$router.back();
      } else {
        this.showSummaryView = true;
        this.showClientView = false;
        this.showLocationView = false;
        this.$refs.clientView.selectContact(this.client);
      }
    },
    async createJob() {
      let clientID = '';
      this.pulse = true;
      if (this.client) {
        await this.$store
          .dispatch('Contacts/getContactById', this.client.uid)
          .then((res) => {
            clientID = res.id;
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error('Failed to get client', {
              position: 'bottom',
              onClick: () => {
                this.$refs.errorModal.show(err);
              }
            });
          });
      }

      let jobType = this.jobTypes?.find((type) =>
        Array.isArray(this.jobType)
          ? type.name === this.jobType[0]?.name
          : type.name === this.jobType?.name
      );
      const locationId = this.$refs.locationView.stateLocation?.id;

      if (!locationId) {
        this.$toast.error('Please select a location');
        return;
      }

      const job = {
        uid: '',
        start_date: this.dateRange.startDate.toISOString(),
        reference_number: this.reference_number,
        client: clientID ? clientID : null,
        location: locationId,
        job_type: jobType?.id,
        title: this.title,
        description: this.description
      };

      console.log('job', job);

      this.$store
        .dispatch('Jobs/createJob', job)
        .then(() => {
          this.$toast.success('Job created successfully');
          this.$router.push('/jobs');
        })
        .catch((err) => {
          this.$toast.error('Failed to create job', {
            position: 'bottom',
            onClick: () => {
              this.$refs.errorModal.show(err);
            }
          });
        });
    },

    async updateJob() {
      let clientID = '';
      this.pulse = true;

      if (this.client) {
        await this.$store
          .dispatch('Contacts/getContactById', this.client.uid)
          .then((res) => {
            clientID = res.id;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      let jobType = this.jobTypes?.find((type) =>
        Array.isArray(this.jobType)
          ? type.name === this.jobType[0]?.name
          : type.name === this.jobType?.name
      );

      const job = {
        id: this.job ? this.job.id : '',
        attributes: {
          uid: '',
          start_date: this.dateRange.startDate.toISOString(),
          reference_number: this.reference_number,
          client: clientID ? clientID : null,
          location: this.$refs.locationView.stateLocation.id,
          job_type: jobType?.id,
          title: this.title,
          description: this.description
        }
      };
      console.log('job', job);

      this.$store
        .dispatch('Jobs/updateJob', job)
        .then(() => {
          this.$toast.success('Job updated successfully');
          this.$router.push('/jobs');
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error('Failed to update job', {
            position: 'bottom',
            onClick: () => {
              this.$refs.errorModal.show(err);
            }
          });
        });
    }
  }
};
</script>

<style>
.hourselect,
.minuteselect,
.ampmselect {
  background-color: #f89838b9 !important;
  color: white;
  border-radius: 5px !important;
  border: none !important;
  font-weight: 600 !important;
  margin-right: -3px !important;
  cursor: pointer;
}

.minuteselect {
  margin-left: -3px !important;
}
</style>
