<template>
  <!-- <router-link :to="'/documents/templates/' + documentType.attributes.uid"> -->
  <AppCard
    @click="edit()"
    :pulse="loading"
    clickable
    class="w-full !rounded-3xl"
  >
    <div class="flex items-center gap-4">
      <div
        class="w-full bg-gray-100 px-3 py-0.5 rounded-md text-center flex justify-center items-center gap-2 font-bold text-sm"
      >
        <p class="text-primary-500">{{ documentType.attributes.name }}</p>
        <transition name="scale-quick" mode="out-in" appear>
          <AppChip
            size="xs"
            v-if="documentType.attributes.archived"
            class="bg-secondary-400 absolute -top-2 -left-2 text-xs rounded-full px-1 py-0.5"
          >
            Archived
          </AppChip>
        </transition>
      </div>
      <AppActions
        @archive="archive"
        @unarchive="archive"
        @clone="clone"
        @delete="deleteItem"
        :uid="documentType.id.toString()"
        @click.stop
        :options="options"
      />
    </div>
    <div class="w-full flex justify-around text-sm mt-4 font-bold gap-2">
      <p class="">Sections: {{ sectionCount }}</p>
      <p class="">Questions: {{ questionCount }}</p>
    </div>
  </AppCard>
  <!-- </router-link> -->
</template>
<script>
import AppActions from '../EW/AppActions.vue';

export default {
  props: {
    documentType: {}
  },
  components: {
    AppActions
  },
  data() {
    return {
      loading: false,
      options: [
        // if the document is archived, show the option to unarchive
        // if the document is not archived, show the option to archive
        {
          name: this.documentType.attributes.archived ? 'Unarchive' : 'Archive',
          icon: 'ArchiveBox'
        }
      ]
    };
  },
  computed: {
    sectionCount() {
      if (!this.documentType?.attributes?.structure) return 0;
      return Object.keys(this.documentType.attributes.structure)?.length;
    },
    questionCount() {
      if (!this.documentType?.attributes?.structure) return 0;

      let count = 0;

      Object.keys(this.documentType.attributes.structure).forEach((key) => {
        count += this.documentType.attributes.structure[key].questions?.length;
      });

      return count;
    }
  },
  methods: {
    async archive() {
      this.loading = true;
      await this.$store.dispatch('Documents/toggleArchiveDocumentType', {
        id: this.documentType.id,
        archived: !this.documentType.attributes.archived
      });
      this.loading = false;
    },
    async clone() {
      console.log('Cloning');
      this.loading = true;
      await this.$store.dispatch('Documents/cloneDocumentType', {
        id: this.documentType.id,
        name: `${this.documentType.attributes.name} - Copy`
      });
      this.loading = false;
    },
    async deleteItem() {
      this.loading = true;
      await this.$store.dispatch(
        'Documents/deleteDocumentType',
        this.documentType.id
      );
      this.loading = false;
    },
    edit() {
      this.$router.push(
        `/documents/templates/${this.documentType.attributes.uid}`
      );
    }
  }
};
</script>

<!-- <template #subtitle>
  {{ documentType.attributes.subtitle }}
</template> -->
<!-- <template #chip>
  <span class="capitalize bg-gray-100 rounded-full px-3 py-1 " :class="{
    'bg-gray-200 text-primary-500': documentType.attributes.type === 'Global',
    'bg-primary-500 text-white': documentType.attributes.type === 'Company'
    
  }">{{ documentType.attributes.type }}</span>
</template> -->

<!-- <div class="flex justify-between p-4">
  <span
    class="bg-primary px-2 py-0.5 flex justify-center rounded-full text-white text-xs gap-1"
  >
    <span class="font-bold">Sections:</span>
    {{ sectionCount }}
  </span>

  <span class="font-bold">Questions:</span>
  {{ questionCount }}
</div> -->
