<template>
  <div class="w-full">
    <AppTitle type="page">Manage Templates</AppTitle>

    <div class="flex flex-wrap h-full w-full">
      <DocumentTypeCard
        class="m-1"
        v-for="documentType in documentTypes"
        :key="documentType.id"
        :documentType="documentType"
      />
    </div>
  </div>
</template>
<script>
import DocumentTypeCard from '../../../components/Documents/DocumentTypeCard.vue';
export default {
  components: {
    DocumentTypeCard
  },
  mounted() {
    this.$store.dispatch('Documents/getDocumentTemplates');
  },
  computed: {
    documentTypes() {
      return this.$store.getters['Documents/types'];
    }
  }
};
</script>
