<template>
  <transition name="slide-up" mode="out-in" appear>
    <div class="w-full px-6 max-w-6xl">
      <AppTitle type="page" class="text-center">Recent Activity</AppTitle>
      <div class="w-full flex justify-end">
        
        <AppButton
          v-if="isAdmin && company.attributes.plan_code === 'ULTIMATE'"
          @click="$router.push({ name: 'Document Templates' })"
          class="!w-fit relative"
        >
          View Template Document
          <AppChip
            size="xs"
            class="bg-gradient-to-tl font-medium absolute -top-3.5 -right-6 from-primary-100 to-primary-400"
          >
            <p class="text-xs">beta</p>
          </AppChip>
        </AppButton>
      </div>
      <div class="w-full mt-4 mb-8">
        <p class="text-gray-400">Search for documents by name, assigned to</p>
        <div class="flex items-center gap-2">
          <TextInput
            v-model="filter"
            class="w-full"
            placeholder="Search documents ..."
          >
            <template #icon>
              <IconPack type="MagnifyingGlass" class="w-5 h-5 text-gray-500" />
            </template>
          </TextInput>
          <div
            @click="resetFilters"
            v-if="shouldApplyFilters"
            class="flex gap-1 h-fit items-center rounded bg-white p-2 border border-gray-300 shadow-sm select-none text-gray-500 hover:bg-gray-100 transition-all duration-300 cursor-pointer"
          >
            <IconPack type="XMark" class="w-5 h-5 text-gray-500" />
            <p>Reset</p>
          </div>
          <div
            class="flex gap-1 my-auto items-center rounded bg-white p-2 border border-gray-300 shadow-sm text-gray-500"
          >
            <p class="text-gray-500">Total:</p>
            <transition name="scale-quick" mode="out-in" appear>
              <p v-if="!fetching" class="text-primary-500 font-bold">
                {{ totalDocuments }}
              </p>
              <div
                v-else
                class="w-6 h-4 text-transparent rounded bg-gray-200 animate-pulse"
              >
                {{ totalDocuments }}
              </div>
            </transition>
          </div>
        </div>
        <div class="flex w-full gap-3 flex-wrap">
          <SearchableDropdown
            searchable
            ref="assignedTo"
            v-model="assignedToFilter"
            :options="credentials"
            placeholder="Assigned To"
          >
            <template #iconLeft>
              <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
            </template>
          </SearchableDropdown>
          <SearchableDropdown
            ref="status"
            v-model="statusFilter"
            :options="[
              { name: 'Locked', color: 'primary-500' },
              { name: 'Approved', color: 'green-500' },
              { name: 'Pending', color: 'secondary-500' },
              { name: 'Open', color: 'gray-400' }
            ]"
            placeholder="Document Status"
          >
            <template #iconLeft>
              <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
            </template>
          </SearchableDropdown>
          <SearchableDropdown
            searchable
            ref="types"
            v-model="typeFilter"
            :options="documentTypes"
            placeholder="Document Type"
          >
            <template #iconLeft>
              <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
            </template>
          </SearchableDropdown>
        </div>
      </div>
      <transition name="slide-up" mode="out-in" appear>
        <div v-if="fetching" class="flex flex-col gap-4">
          <DocCardSkeleton v-for="i in 4" :key="i" />
        </div>
        <div v-else-if="documents.length" class="flex w-full flex-col gap-5">
          <div v-for="document in filteredDocuments" :key="document.id">
            <div class="w-full flex justify-end items-center gap-2 p-1">
              <IconPack type="Clock" class="text-gray-400 w-5 h-5" />
              <p class="text-gray-400">
                {{
                  document.attributes.last_value_update
                    ? moment(document.attributes.last_value_update).fromNow()
                    : moment(document.attributes.updatedAt).fromNow()
                }}
              </p>
            </div>
            <TemplateDocCard
              class="bg-white !w-full"
              :document="document.attributes"
              :id="document.id"
            />
          </div>
          <AppPagination
            v-if="pageCount > 1 && !fetching"
            :pageCount="pageCount"
            :currentPage="parseInt(currentPage)"
            @previous="loadPreviousPage"
            @next="loadNextPage"
            @goto="gotoPage"
          />
        </div>
        <div v-else>
          <p class="text-center text-gray-400">No documents found</p>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import TemplateDocCard from '../../../components/Documents/TemplateDocCard.vue';
import AppPagination from '../../../components/EW/AppPagination.vue';
import DocCardSkeleton from '../../../components/Documents/Templates/DocCardSkeleton.vue';

export default {
  name: 'AppDocuments',
  components: {
    TemplateDocCard,
    AppPagination,
    DocCardSkeleton
  },
  data() {
    return {
      perPage: 50,
      pageCount: 0,
      totalDocuments: 0,
      fetching: false
    };
  },
  computed: {
    documents() {
      return this.$store.getters['Documents/documents'];
    },
    documentTypes() {
      return this.$store.getters['Documents/types']
        ?.filter((type) => !type.attributes.archived)
        .map((type) => {
          return { name: type.attributes.name, id: type.id };
        });
    },
    credentials() {
      return this.$store.getters['Credentials/credentials']?.map((cred) => {
        return {
          email: cred.attributes.email,
          name: `${cred.attributes.first_name} ${cred.attributes.last_name}`,
          description: cred.attributes.registration_number
        };
      });
    },
    filteredDocuments() {
      let filteredList = this.documents;
      if (this.shouldApplyFilters)
        filteredList = this.applyFilters(filteredList);

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return filteredList.sort((a, b) => {
        // if a and b both have last_value_update then sort otherwise don't sort
        if (a.attributes.last_value_update && b.attributes.last_value_update) {
          return (
            new Date(b.attributes.last_value_update) -
            new Date(a.attributes.last_value_update)
          );
        }
      });
    },
    shouldApplyFilters() {
      return (
        this.filter !== '' ||
        this.typeFilter !== '' ||
        this.statusFilter !== '' ||
        this.assignedToFilter !== ''
      );
    },
    filter: {
      get() {
        const name = this.$route.query?.query?.toString() || '';
        const cred = this.credentials.find((cred) => cred.name === name);
        return cred ? cred : name;
      },
      set(value) {
        debounce(() => {
          const query =
            value !== ''
              ? { ...this.$route.query, page: 1, query: value }
              : { ...this.$route.query, query: undefined };
          this.$router.replace({ query: query });
        }, 300);
      }
    },
    typeFilter: {
      get() {
        const name = this.$route.query?.type?.toString() || '';
        const type = this.documentTypes?.find((type) => type.name === name);
        return type ? type : name;
      },
      set(value) {
        const query =
          value.length > 0
            ? { ...this.$route.query, page: 1, type: value[0]?.name }
            : { ...this.$route.query, type: undefined };
        this.$router.replace({ query: query });
      }
    },
    statusFilter: {
      get() {
        const status = this.$route.query?.status
          ? this.$route.query.status
          : '';

        if (status === 'Locked') {
          return { name: 'Locked', color: 'primary-500' };
        } else if (status === 'Approved') {
          return { name: 'Approved', color: 'green-500' };
        } else if (status === 'Pending') {
          return { name: 'Pending', color: 'secondary-500' };
        } else if (status === 'Open') {
          return { name: 'Open', color: 'gray-400' };
        } else {
          return '';
        }
      },
      set(value) {
        const query =
          value.length > 0
            ? { ...this.$route.query, page: 1, status: value[0]?.name }
            : { ...this.$route.query, status: undefined };
        this.$router.replace({ query: query });
      }
    },
    assignedToFilter: {
      get() {
        const name = this.$route.query?.assignedTo?.toString() || '';
        const cred = this.credentials.find((cred) => cred.name === name);
        return cred ? cred : name;
      },
      set(value) {
        const query =
          value.length > 0
            ? { ...this.$route.query, page: 1, assignedTo: value[0]?.name }
            : { ...this.$route.query, assignedTo: undefined };
        this.$router.replace({ query: query });
      }
    },

    currentPage: {
      get() {
        const value =
          parseInt(this.$route.query?.page?.toString()) ||
          localStorage.getItem('currentDocumentPage') ||
          1;
        return value == 'NaN' ? 1 : value;
      },
      set(value) {
        if (value == 'NaN') value = 1;

        this.$router
          .replace({ query: { ...this.$route.query, page: value } })
          .then(() => {
            let oldValue = parseInt(
              localStorage.getItem('currentDocumentPage')
            );

            if (oldValue === value && !this.shouldApplyFilters) {
              this.loadDocuments();
            } else if (!this.shouldApplyFilters) {
              this.loadDocuments(true);
            } else {
              // this.filtered = false;
              this.loadDocuments();
            }

            localStorage.setItem('currentDocumentPage', value.toString());
          });
      }
    }
  },
  mounted() {
    // get doc types
    this.$store.dispatch('Documents/getDocumentTemplates');
    this.$store.dispatch('Credentials/getCredentials');
    this.loadDocuments();
  },
  methods: {
    loadDocuments() {
      const start = (this.currentPage - 1) * this.perPage;
      const limit = this.perPage;
      const query = this.getQuery();
      let sort = this.getSort();

      debounce(async () => {
        this.fetching = true;
        await this.$store
          .dispatch('Documents/getDocuments', {
            'pagination[start]': start,
            'pagination[limit]': limit,
            sort: sort,
            filters: { ...query }
          })
          .then((res) => {
            this.totalDocuments = res.data.meta.pagination.total;
            this.pageCount = Math.ceil(this.totalDocuments / this.perPage);
          });
        this.fetching = false;
      }, 500);
    },
    applyFilters(filteredList) {
      if (this.assignedToFilter) {
        filteredList = filteredList.filter((doc) => {
          return (
            doc.attributes.approvals_required.data.some(
              (approval) =>
                approval.attributes.email == this.assignedToFilter.email
            ) ||
            doc.attributes.user_approvals.data.some(
              (approval) =>
                approval.attributes.email == this.assignedToFilter.email
            )
          );
        });
      }

      if (this.statusFilter) {
        switch (this.statusFilter.name) {
          case 'Locked':
            filteredList = filteredList.filter((doc) => {
              return doc.attributes.is_locked;
            });
            break;
          case 'Approved':
            filteredList = filteredList.filter((doc) => {
              return doc.attributes.is_approved && !doc.attributes.is_locked;
            });
            break;
          case 'Pending':
            // pending is when approvals_required has 2 or more items, and at least one of them is not in user_approvals and ther is at least one user_approval
            filteredList = filteredList.filter((doc) => {
              return (
                doc.attributes.approvals_required.data.length > 1 &&
                doc.attributes.approvals_required.data.some((approval) => {
                  return !doc.attributes.user_approvals.data.some(
                    (userApproval) =>
                      userApproval.attributes.email == approval.attributes.email
                  );
                }) &&
                doc.attributes.user_approvals.data.length > 0
              );
            });
            break;
          case 'Open':
            // open is when there are no user_approvals
            filteredList = filteredList.filter((doc) => {
              return doc.attributes.user_approvals.data.length === 0;
            });
            break;

          default:
            break;
        }
      }

      if (this.typeFilter) {
        filteredList = filteredList.filter((doc) => {
          return doc.attributes.document_type.data.id == this.typeFilter.id;
        });
      }

      if (this.filter) {
        const words = this.filter.split(' ');
        filteredList = filteredList.filter((doc) => {
          return words.every((word) => {
            return (
              doc.attributes.name.toLowerCase().includes(word.toLowerCase()) ||
              doc.attributes.document_type.data.attributes.name
                .toLowerCase()
                .includes(word.toLowerCase()) ||
              doc.attributes.approvals_required.data.some((approval) =>
                approval.attributes.first_name
                  .toLowerCase()
                  .includes(word.toLowerCase())
              ) ||
              doc.attributes.approvals_required.data.some((approval) =>
                approval.attributes.last_name
                  .toLowerCase()
                  .includes(word.toLowerCase())
              ) ||
              doc.attributes.user_approvals.data.some((approval) =>
                approval.attributes.first_name
                  .toLowerCase()
                  .includes(word.toLowerCase())
              ) ||
              doc.attributes.user_approvals.data.some((approval) =>
                approval.attributes.last_name
                  .toLowerCase()
                  .includes(word.toLowerCase())
              )
            );
          });
        });
      }

      return filteredList;
    },
    loadPreviousPage() {
      if (this.currentPage > 1) {
        this.loading = true;
        this.currentPage--;
      }
    },

    loadNextPage() {
      if (this.currentPage < this.pageCount) {
        this.loading = true;
        this.currentPage++;
      }
    },

    gotoPage(page) {
      if (page === '...') {
        return;
      }
      this.loading = true;
      this.currentPage = page;
    },
    getQuery() {
      let query = {
        $and: []
      };

      if (this.filter) {
        const words = this.filter.split(' ').map((word) => word.toLowerCase());
        query.$and.push({
          $or: [
            { name: { $containsi: words } },
            {
              document_type: {
                name: {
                  $containsi: words
                }
              }
            },
            {
              approvals_required: {
                first_name: {
                  $containsi: words
                }
              }
            },
            {
              approvals_required: {
                last_name: {
                  $containsi: words
                }
              }
            },
            {
              user_approvals: {
                first_name: {
                  $containsi: words
                }
              }
            },
            {
              user_approvals: {
                last_name: {
                  $containsi: words
                }
              }
            }
          ]
        });
      }

      if (this.typeFilter) {
        query.$and.push({
          document_type: {
            id: {
              $eq: this.typeFilter.id
            }
          }
        });
      }

      if (this.statusFilter) {
        switch (this.statusFilter.name) {
          case 'Locked':
            query.$and.push({
              is_locked: { $eq: true }
            });
            break;
          case 'Approved':
            query.$and.push({
              $and: [
                { is_approved: { $eq: true } },
                { is_locked: { $eq: false } }
              ]
            });
            break;
        }
      }

      if (this.assignedToFilter) {
        query.$and.push({
          $or: [
            {
              approvals_required: {
                email: {
                  $eq: this.assignedToFilter.email
                }
              }
            },
            {
              user_approvals: {
                email: {
                  $eq: this.assignedToFilter.email
                }
              }
            }
          ]
        });
      }

      query.$and.push({
        is_deleted: { $eq: false }
      });
      return query;
    },
    getSort() {
      return 'updatedAt:desc';
    },
    resetFilters() {
      this.$router.replace({ query: {} });
    }
  },
  watch: {
    filter: 'loadDocuments',
    typeFilter: 'loadDocuments',
    statusFilter: 'loadDocuments',
    assignedToFilter: 'loadDocuments'
  }
};
let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(func, delay);
};
</script>
