<template>
  <div class="w-full flex flex-col gap-2">
    <div class="w-full flex justify-between gap-4">
      <div>
        <AppButton type="secondary" icon="arrow-left" @click="$router.go(-1)">
          <IconPack type="ArrowLeft" class="w-4 h-4 mr-2" />
          Back
        </AppButton>
      </div>
      <AppTitle type="large" class="!w-fit mb-4">{{
        documentTypeName
      }}</AppTitle>
      <div class="opacity-0">
        <AppButton
          class="!opacity-0"
          type="secondary"
          icon="arrow-left"
          disabled
          @click="$router.go(-1)"
        >
          <IconPack type="ArrowLeft" class="w-4 h-4 mr-2" />
          Back
        </AppButton>
      </div>
    </div>
    <div class="w-full flex flex-col gap-4">
      <div v-for="item in documentStructure" :key="item.key">
        <AppCard
          titleType="medium"
          class="w-full max-w-screen-lg mx-auto"
          collapsable
          clickable
        >
          <!-- {{ getSectionValues(item.key) }} -->
          <template #title>
            <p class="text-primary-500">{{ item.title }}</p>
          </template>
          <div v-for="question in item.questions" :key="question.key">
            <!-- {{ question }} -->
            <!-- {{ getQuestionValues(question) }} -->
            <div v-if="question.multi_input">
              <AppTitle type="base">{{ question.title }}</AppTitle>
              <div class="flex flex-wrap items-start gap-0">
                <div
                  class="-mt-3"
                  :class="{ 'max-w-md': question.type === 'image' }"
                  v-for="(questionValue, i) in getQuestionValues(question)
                    ?.attributes?.value.data"
                  :key="question.key + '-' + i"
                >
                  <EditQuestionCard
                    @open-image-carousel="
                      () => {
                        $refs.carousel.index = i;
                        images = getQuestionValues(
                          question
                        )?.attributes?.value?.data?.map((value) => ({
                          url: value,
                          label: question.title
                        }));
                        showImageCarousel = true;
                      }
                    "
                    :answer="getQuestionValues(question)"
                    :question="{
                      ...question,
                      title: question.multi_input_label[i]
                    }"
                    :disabled="!structure"
                  />
                </div>
              </div>
            </div>

            <EditQuestionCard
              @open-image-carousel="
                (showImageCarousel = true),
                  (images = [{ url: $event.url, label: question.title }]),
                  ($refs.carousel.index = 0)
              "
              v-else
              :answer="getQuestionValues(question)"
              :question="question"
              :disabled="!structure"
            />
          </div>
        </AppCard>
      </div>
      <div
        v-if="!structure"
        class="w-fit flex gap-4 max-w-screen-lg mx-auto mt-4"
      >
        <!-- <AppButton type="primary" class="w-full px-8"> Print to PDF </AppButton>
        <AppButton
          @click="$router.push({ name: 'A4 Document', params: { uid: uid } })"
          type="primary"
          class="w-full whitespace-nowrap px-8"
        >
          Preview PDF
        </AppButton> -->
      </div>
    </div>
  </div>
  <ImageCarouselModal
    readonly
    ref="carousel"
    v-model="showImageCarousel"
    :images="images"
  />
</template>

<script>
import EditQuestionCard from '../../../components/Documents/Templates/EditQuestionCard.vue';
import ImageCarouselModal from '../../../components/Modals/ImageCarouselModal.vue';

export default {
  name: 'DocumentDetails',
  components: {
    EditQuestionCard,
    ImageCarouselModal
  },
  props: {
    uid: {
      type: String,
      required: true
    }
  },
  async mounted() {
    if (this.uid) {
      await this.$store.dispatch('Documents/getDocumentById', this.uid);
      await this.$store.dispatch('Products/getProducts');
      await this.$store.dispatch('Products/getProductCategories');
      await this.$store.dispatch('Credentials/getCredentials');
    }
  },
  data() {
    return {
      showImageCarousel: false,
      images: []
    };
  },
  computed: {
    structure() {
      return this.$route.query.structure
        ? JSON.parse(this.$route.query.structure)
        : null;
    },
    document() {
      return this.$store.getters['Documents/currentDocument'];
    },
    documentType() {
      return this.document?.attributes?.document_type?.data;
    },
    documentTypeName() {
      return this.structure
        ? 'Document Structure'
        : this.documentType?.attributes?.name;
    },
    documentStructure() {
      return this.structure || this.documentType?.attributes?.structure;
    },
    documentValues() {
      return this.document?.attributes?.document_values?.data;
    },
    products() {
      return this.$store.getters['Products/products'];
    },
    credentials() {
      return this.$store.getters['Credentials/credentials'];
    }
  },
  methods: {
    getComponentName(type) {
      switch (type) {
        case 'select':
          return 'select';
        case 'text':
          return 'TextInput';
        case 'date':
          return 'input';
        case 'credential':
          return 'input';
        default:
          return null;
      }
    },
    getSectionValues(section) {
      if (this.structure) return [];
      //find all value entries in documentValues where the 'section' attribute is the same is the passed in section value
      return this.documentValues?.filter(
        (value) => value.attributes.section === section
      );
    },
    getQuestionValues(question) {
      if (this.structure) return [];
      //find all value entries in documentValues where the 'question' attribute is the same is the passed in question value
      const value = this.documentValues?.filter(
        (value) => value.attributes.key === question.key
      );

      if (question.type === 'product') {
        if (this.products) {
          const product = this.products.find(
            (product) => product.id === value[0]?.attributes.value.data
          );

          if (!product) return value[0]?.attributes.value.data;
          return [
            {
              id: product.id,
              name: product.attributes.name,
              main_image: product.attributes.main_image,
              brand_image: product.attributes.brand_image
            }
          ];
        }
      }
      if (question.type === 'credential') {
        if (this.credentials) {
          const credential = this.credentials.find(
            (credential) => credential.id === value[0]?.attributes.value.data
          );
          if (!credential) return value[0]?.attributes.value.data;
          return [
            {
              id: credential.id,
              name:
                credential.attributes.first_name +
                ' ' +
                credential.attributes.last_name,
              description:
                credential.attributes.registration_number +
                ' - ' +
                credential.attributes.registration_type
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')
            }
          ];
        }
      }

      return value[0];
    }
  }
};
</script>
