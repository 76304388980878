<script setup>
const emit = defineEmits(['confirm', 'cancel']);
</script>

<template>
  <div
    style="backdrop-filter: blur(3px)"
    class="bg-black fixed z-50 flex top-0 bottom-0 right-0 left-0 bg-opacity-40"
  >
    <div class="flex items-center justify-center w-full h-screen p-4">
      <transition name="scale-quick" mode="out-in" appear>
        <AppCard :icon="icon" class="max-w-md">
          <template #title>
            <p class="text-center text-xl text-primary-500">{{ title }}</p>
          </template>
          <div
            class="w-full text-center text-gray-0 font-semibold mt-5 max-w-lg m-auto"
          >
            {{ subtitle }}
          </div>
          <div
            class="w-full text-center text-gray-500 mb-7 mt-2 max-w-lg m-auto"
          >
            {{ message }}
          </div>

          <div
            v-if="jobWarning > 0"
            class="w-full flex items-center gap-1 justify-center text-center bg-red-100 rounded-full p-1 mb-7 -mt-4 max-w-lg m-auto"
          >
            <IconPack type="ExclamationTriangle" class="w-5 h-5 text-red-400" />
            <p class="text-red-400 font-medium text-sm">
              There {{ jobWarning == 1 ? 'is' : 'are' }} <span class="bg-white px-1.5 mx-0.5 py-0.5 rounded-full">{{ jobWarning }}</span> job{{
                jobWarning > 1 ? 's' : ''
              }}
              associated with this client.
            </p>
          </div>

          <div class="flex flex-row gap-4">
            <AppButton @click="emit('cancel')" color="gray">{{
              cancelText
            }}</AppButton>
            <AppButton :color="color" @click="emit('confirm')">{{
              confirmText
            }}</AppButton>
          </div>
        </AppCard>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      default: 'Confirm'
    },
    subtitle: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: 'Are you sure?'
    },
    icon: {
      type: String,
      default: 'XMark'
    },
    color: {
      type: String,
      default: 'red'
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    jobWarning: {
      type: Number,
      default: 0
    }
  }
};
</script>
