import Invite from '@/views/App/Invite.vue';
import GetApp from '@/views/App/GetApp.vue';

export default [{
    meta: { requiresAuth: false,  layout: 'dashboard' },
    path: '/invite/get-the-app',
    name: 'Get the App',
    component: GetApp,
    title: 'Get the App',
  },
  {
    meta: { requiresAuth: false },
    path: '/invite/:join_code',
    name: 'Invite',
    component: Invite,
    title: 'Invite',
  },  
];
