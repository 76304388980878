import DocumentTemplates from '@/views/Admin/Documents/DocumentTemplates.vue';
import EditDocumentTemplate from '@/views/Admin/Documents/EditDocumentTemplate.vue';

export default [
  {
    meta: { layout: 'dashboard', requiresAuth: true },
    path: '/documents/templates',
    name: 'Document Templates',
    component: DocumentTemplates,
    title: 'Document Templates',
  },
  {
    meta: { layout: '', requiresAuth: true },
    path: '/documents/templates/:uid',
    name: 'Edit Document Template',
    component: EditDocumentTemplate,
    title: 'Edit Document Template',
  },
];
