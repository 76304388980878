import Dashboard from '@/views/Admin/Dashboard.vue';

export default [
  {
    meta: { layout: 'dashboard', requiresAuth: true },
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard',
  },
];
