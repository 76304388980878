<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 315 923"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(1,0,0,1,-789.969,-227.714)">
      <path
        d="M941.318,598.052L1104.81,873.531L1104.81,227.714L941.318,598.052Z"
        style="fill: rgb(248, 152, 56); fill-rule: nonzero"
      />
      <path
        d="M953.464,779.979L789.969,504.5L789.969,1150.32L953.464,779.979Z"
        style="fill: rgb(248, 152, 56); fill-rule: nonzero"
      />
      <path
        d="M789.969,504.5L945.318,590.052L1104.81,873.531L953.464,789.979L789.969,510.5Z"
        style="fill: rgb(252, 181, 21); fill-rule: nonzero"
      />
    </g>
  </svg>
</template>
