<template>
    <transition name="scale-quick" mode="out-in" appear>
        <div ref="showTemplateDocs">

            <!-- <div class="flex flex-col gap-2 items-center sticky top-0 z-20 bg-white p-4 shadow-sm">
                <div class="flex w-full justify-between ">
                    <IconPack @click="closeTemplateDocs" type="XMark"
                        class="w-7 h-7 text-white rounded-full bg-primary-500 p-0.5" />
                    <h1 class="text-lg font-semibold ">Template Document</h1>
                    <div class="w-6 h-6"></div>
                </div>
                <p class="text-sm text-gray-400">This document will be linked to your comment.</p>
            </div> -->
            <TextInput v-model="search" class="w-full" placeholder="Search for a document">
                <template #icon>
                    <IconPack type="MagnifyingGlass" class="w-5 h-5" />
                </template>
            </TextInput>
            <div class="flex flex-col gap-4 py-2 px-6">
                <TemplateDocCard clickable @click="$emit('select', document)" class="w-full" condensed
                    v-for="document in filteredDocs" :id="document.id" :key="document.id" :document="document.attributes" />
            </div>
        </div>
    </transition>
</template>

<script>
import TemplateDocCard from '@/components/Documents/TemplateDocCard.vue';

export default {
    name: 'TemplateDocs',
    components: {
        TemplateDocCard
    },
    props: {
        job: {
            type: Object,
            default: null
        }
    },
    emits: ['update:showTemplateDocs', 'select'],
    data() {
        return {
            search: '',
        }
    },
    computed: {
        filteredDocs() {
            return this.job.documents.filter(doc => doc.attributes.name.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    methods: {
        closeTemplateDocs() {
            this.$emit('update:showTemplateDocs', false);
        },
    },
}
</script>

<style lang="scss" scoped></style>