<template>
    <AppCard v-if="documents.length > 0" class=" mx-auto w-full">
        <div class="flex flex-col gap-0 5">
            <p class="font-bold text-xl text-primary">{{ category?.attributes?.title }}</p>
            <p class="text-primary-400">{{ category?.attributes?.subtitle }}</p>
            <p class="text-sm text-gray-400 my-2">{{ category?.attributes?.description }}</p>
        </div>
        <div class="flex flex-col gap-2 mt-4">
            <AppCard @click="selectDocument(doc)" clickable class="w-full mx-auto" v-for="doc in documents" :key="doc?.id">
                <div class="flex justify-between items-center">
                    <div class="flex flex-col gap-2">
                        <p class="text-primary-600 font-bold">{{ doc.attributes.name }}</p>
                        <p class="text-gray-400 font-light text-sm">{{ doc.attributes.subtitle }}</p>
                    </div>
                    <IconPack type="ChevronRight" class="w-6 h-6 text-gray-400" />
                </div>
            </AppCard>
        </div>
    </AppCard>
</template>

<script>
export default {
    props: {
        category: {
            type: Object,
            required: true,
        },
        documents: {
            type: Array,
            required: true,
        },
    },
    emits: ["select"],
    methods: {
        selectDocument(doc) {
            this.$emit("select", doc);
        },
    },
}
</script>