<template>
  <AppCard class="w-fit">
    <div class="flex items-center bg-purple-500 bg-opacity-0 justify-between">
      <div class="flex flex-col gap-2">
        <div class="rounded-full flex items-center">
          <ContactCard
            class="scale-[1.28]"
            icononly
            :contact="selectedCredential.attributes"
          />
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900">
              {{ modelValue?.attributes.first_name }}
              {{ modelValue?.attributes.last_name }}
            </p>
            <span class="text-sm text-gray-500">
              {{ modelValue?.attributes.registration_number }}
            </span>
          </div>
          <div class="bg-yellow-500 bg-opacity-0"></div>
        </div>
        <p
          class="py-1 text-center text-sm px-4 text-white font-bold rounded-full"
          :class="`bg-${getColor()}`"
        >
          {{ registrationType }}
        </p>
      </div>
    </div>
  </AppCard>
</template>

<script>
import ContactCard from '@/components/Contacts/ContactCard.vue';
export default {
  name: 'CredentialCard',
  components: {
    ContactCard
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedCredential: this.modelValue
    };
  },
  computed: {
    registrationType() {
      return this.modelValue?.attributes.registration_type
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  },
  mounted() {},
  methods: {
    getColor() {
      switch (this.modelValue?.attributes.registration_type) {
        case 'single_phase_tester':
          return 'yellow-500';
        case 'installation_electrician':
          return 'blue-500';
        case 'master_installation_electrician':
          return 'emerald-500';
        case 'electrician':
          return 'red-400';
        case 'engineer':
          return 'purple-500';
        case 'N/A':
          return 'gray-500';
      }
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.selectedCredential = this.modelValue;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
