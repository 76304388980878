<template>
  <div class="w-full max-w-screen-md flex flex-col gap-4">
    <div class="w-full flex justify-between items-center">
      <div class="flex items-center gap-2">
        <IconPack type="Inbox" class="w-6 h-6" /> Show read
        <AppSwitch v-model="showRead" />
      </div>
      <p
        @click="markAllRead"
        v-if="unreadItems?.length > 1"
        class="text-secondary-500 font-semibold text-sm cursor-pointer"
      >
        Mark All Read
      </p>
    </div>
    <p class="text-gray-400 text-sm ml-2">{{ unreadItems.length > 0 ? unreadItems.length : 'no' }} unread messages</p>
    <div class="flex flex-col gap-4" v-if="showRead">
      <div class="flex flex-col gap-4" v-if="inboxItems?.length > 0">
        <div v-for="item in inboxItems" :key="item.id">
          <transition name="scale-quick" mode="out-in" appear>
            <InboxItemsCard :item="item" />
          </transition>
        </div>
      </div>
      <div v-else>
        <AppCard>
          <p class="text-lg">No messages</p>
        </AppCard>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-col gap-4" v-if="unreadItems?.length > 0">
        <div v-for="item in unreadItems" :key="item.id">
          <transition name="scale-quick" mode="out-in" appear>
            <InboxItemsCard :item="item" />
          </transition>
        </div>
      </div>
      <div v-else>
        <AppCard>
          <p class="text-lg">No messages</p>
        </AppCard>
      </div>
    </div>
  </div>
</template>

<script>
import AppSwitch from '../../components/EW/AppSwitch.vue';
import InboxItemsCard from '@/components/InboxItems/InboxItemCard.vue';


export default {
  name: 'InboxItems',
  components: {
    AppSwitch,
    InboxItemsCard
  },
  data() {
    return {
      showRead: false
    };
  },
  mounted() {
    this.$store.dispatch('InboxItems/getInboxItems', { id: this.user.id });
  },
  computed: {
    inboxItems() {
      return this.$store.getters['InboxItems/inboxItems'];
    },
    unreadItems() {
      return this.inboxItems?.filter((item) => !item?.attributes?.is_read);
    }
  },
  methods: {
    markAllRead() {
      this.$store.dispatch('InboxItems/markAllRead', { id: this.user.id });
    }
  }
};
</script>
