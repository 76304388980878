<template>
  <div>
    <SearchableDropdown
      :disabled="disabled"
      v-model="selectedCredential"
      searchable
      :options="options"
      placeholder="Select registered credentials"
    >
      <template #iconRight>
        <IconPack type="ChevronDown" class="w-5 h-5" />
      </template>
    </SearchableDropdown>
  </div>
</template>

<script>
export default {
  name: 'SelectCredential',
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCredential: this.modelValue
    };
  },
  mounted() {
    if (this.credentials.length === 0) {
      this.$store.dispatch('Credentials/getCredentials');
    }
  },
  computed: {
    credentials() {
      return this.$store.getters['Credentials/credentials'];
    },
    options() {
      const optionList = this.credentials.map((credential) => {
        return {
          name:
            credential.attributes.first_name +
            ' ' +
            credential.attributes.last_name,
          description:
            credential.attributes.registration_number +
            ' - ' +
            this.getTypeName(credential.attributes.registration_type),
          id: credential.id
        };
      });

      return optionList;
    }
  },
  methods: {
    getTypeColor(type) {
      switch (type) {
        case 'single_phase_tester':
          return 'yellow-500';
        case 'installation_electrician':
          return 'blue-500';
        case 'master_installation_electrician':
          return 'emerald-500';
        case 'electrician':
          return 'red-400';
        case 'engineer':
          return 'primary-400';
        case 'N/A':
          return 'gray-500';
      }
    },
    getTypeName(type) {
      switch (type) {
        case 'single_phase_tester':
          return 'Single Phase Tester';
        case 'installation_electrician':
          return 'Installation Electrician';
        case 'master_installation_electrician':
          return 'Master Installation Electrician';
        case 'electrician':
          return 'Electrician';
        case 'engineer':
          return 'Engineer';
        case 'N/A':
          return 'N/A';
      }
    }
  },
  watch: {
    selectedCredential: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal);
      },
      deep: true
    }
  }
};
</script>
