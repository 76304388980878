<template>
  <div ref="clickTarget" class="relative inline-block text-left !z-[60]">
    <div>
      <button
        id="options-menu"
        type="button"
        class="flex items-center justify-center w-full rounded-md px-4 py-2 shadow-sm border border-gray-300 text-md font-bold text-gray-00 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
        @click="menubarDropdownActive = !menubarDropdownActive"
      >
        <AppSpinner v-if="!userProfile" class="w-3 h-5 text-primary-500" />
        <span v-else>
          <div class="flex items-center">
            <IconPack type="User" class="w-5 h-5 mr-1" />
            <span class="overflow-ellipsis overflow-hidden">{{
              userProfile.first_name
            }}</span>
          </div>
        </span>
      </button>
    </div>
    <transition name="scale-quick" mode="out-in" appear>
      <div
        v-if="menubarDropdownActive"
        class="z-60 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      >
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div v-if="userProfile" class="bg-gray-50 text-center p-2 text-sm">
            <div class="font-bold">
              {{ userProfile.first_name + ' ' + userProfile.last_name }}
            </div>
            <div class="break-words">{{ userProfile.email }}</div>
          </div>

          <a
            href="#"
            class="block px-4 py-2 text-md text-gray-700 rounded-md hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
          >
            <span class="flex flex-col">
              <span @click="logout()"> Logout </span>
            </span>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

export default {
  setup() {
    const clickTarget = ref(null);
    return { clickTarget };
  },
  data() {
    return {
      menubarDropdownActive: false
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters['Authentication/user'];
    }
  },
  mounted() {
    onClickOutside(
      this.clickTarget,
      function () {
        this.menubarDropdownActive = false;
      }.bind(this)
    );
  },
  methods: {
    logout() {
      this.$store.commit('Authentication/LOG_OUT');
      this.$router.push('/login');
    }
  }
};
</script>
