<template>
  <AppCard
    @removeClicked="removeQuestion"
    @cloneClicked="cloneQuestion"
    removable
    draggable
    cloneable
    collapsable
    :icon="icon"
    class="p-2"
  >
    <template #title>{{ question.title }}</template>
    <div class="p-4 space-y-4">
      <div class="grid grid-cols-1 w-full">
        <TextInput
          class="mx-4"
          :label="
            localQuestion.type == 'formula'
              ? 'Formula Name'
              : localQuestion.type == 'heading'
              ? 'Heading'
              : 'Question Text'
          "
          placeholder="Enter question title"
          v-model="localQuestion.title"
          v-if="!showKeys"
        />

        <div
          v-else
          @click="copyToClipboard(sectionKey + '.' + localQuestion.key)"
        >
          <TextInput
            class="mx-4 cursor-pointer"
            @input="localQuestion.keyDirty = true"
            label="Question PDF Mapping"
            :modelValue="sectionKey + '.' + localQuestion.key"
            disabled
          /><TextInput
            class="mx-4"
            :label="
              localQuestion.type == 'formula'
                ? 'Formula Name'
                : localQuestion.type == 'heading'
                ? 'Heading'
                : 'Question Text'
            "
            placeholder="Enter question title"
            v-model="localQuestion.title"
            v-if="!showKeys"
          />
        </div>
      </div>

      <TextInput
        v-if="question.type !== 'formula'"
        class="mx-4"
        label="Description"
        multiline
        placeholder="Enter question description"
        v-model="localQuestion.description"
      />

      <div class="m-4" v-if="question.type === 'formula'">
        <div class="font-bold h-5 text-primary-600">Formula operator</div>
        <Multiselect
          placeholder="Select a type"
          v-model="localQuestion.formula_operator"
          :options="formulas"
          label="name"
          valueProp="slug"
        />

        <div class="font-bold h-5 text-primary-600 my-4">Formula inputs</div>

        <AddCard
          class="my-4"
          v-model:active="showAddFormulaInput"
          @saveClicked="addFormulaInput()"
        >
          <template #title>Add Input</template>
          <template #content>
            <div class="my-2">
              <Multiselect
                placeholder="Select a type"
                v-model="addFormulaInputData.type"
                :options="[
                  { name: 'Question', slug: 'question' },
                  { name: 'Constant', slug: 'constant' }
                ]"
                label="name"
                valueProp="slug"
              />
            </div>
            <div class="my-2" v-if="addFormulaInputData.type === 'question'">
              <Multiselect
                placeholder="Select formula question input"
                v-model="addFormulaInputData.selector"
                :options="questions.filter((i) => i.key !== question.key)"
                label="title"
                valueProp="key"
                @change="
                  (value) => {
                    addFormulaInputData.title = questions.find(
                      (i) => i.key === value
                    )?.title;
                  }
                "
              />

              <TextInput
                class="my-4"
                label="Selector"
                v-model="addFormulaInputData.selector"
              />
            </div>
            <TextInput
              v-if="addFormulaInputData.type === 'constant'"
              class="my-4"
              label="Constant Value"
              v-model="addFormulaInputData.value"
            />
          </template>
        </AddCard>

        <AppCard
          @removeClicked="localQuestion.formula_inputs.splice(index, 1)"
          removable
          class="px-3 py-4 my-2"
          v-for="(formulaInput, index) in localQuestion.formula_inputs"
          :key="index"
        >
          <template #title>
            {{
              formulaInput.title || 'Constant Value: ' + formulaInput.value
            }}</template
          >
        </AppCard>
      </div>

      <TextInput
        v-if="question.type === 'text'"
        class="w-1/2 mx-4"
        label="Placeholder"
        v-model="localQuestion.placeholder"
      />

      <TextInput
        class="mx-4 w-max"
        label="Unit of Measurement"
        v-if="question.type === 'number'"
        v-model="localQuestion.unit"
      />

      <div class="grid grid-cols-2 w-max">
        <TextInput
          type="number"
          v-if="question.type === 'number'"
          label="Minimum Value"
          class="mx-4 w-max"
          v-model="localQuestion.validation.min"
        />

        <TextInput
          type="number"
          v-if="question.type === 'number'"
          label="Maximum Value"
          class="mx-4 w-max"
          v-model="localQuestion.validation.max"
        />
      </div>

      <div v-if="question.type === 'select'">
        <AppTitle class="ml-4">Select Options</AppTitle>

        <div class="flex flex-wrap w-full">
          <div
            class="w-1/2"
            v-for="(option, index) in question.options"
            :key="index"
          >
            <div class="flex items-center mx-4">
              <IconPack
                @click="showImagePicker(index)"
                type="Photograph"
                class="cursor-pointer h-6 w-6 text-white rounded-md p-1 mr-2 bg-primary-500"
                :class="{ 'bg-green-600': option.image }"
              />
              <TextInput
                class="w-full"
                v-if="!showKeys"
                :updateKey="updateKey(option)"
                v-model="option.title"
                :placeholder="'Option ' + (index + 1)"
              >
                <template #iconRight>
                  <IconPack
                    @click="removeSelectOption(option)"
                    type="XCircle"
                    solid
                    class="text-red-400 transition-colors duration-200 cursor-pointer hover:text-red-500 h-4 w-4"
                  />
                </template>
              </TextInput>

              <div
                class="w-full"
                v-else
                @click="
                  copyToClipboard(
                    sectionKey + '.' + localQuestion.key + ':' + option.key
                  )
                "
              >
                <TextInput
                  disabled
                  class=""
                  :modelValue="
                    sectionKey + '.' + localQuestion.key + ':' + option.key
                  "
                >
                  <template #iconRight>
                    <IconPack
                      @click="removeSelectOption(option)"
                      type="X"
                      class="text-gray-400 transition-colors duration-200 cursor-pointer hover:text-red-500 h-4 w-4"
                    />
                  </template>
                </TextInput>
              </div>
            </div>
          </div>
          <div class="w-1/2 px-4">
            <AppButton @click="addSelectOption" class="my-2">
              Add Option
            </AppButton>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap items-center">
        <div class="m-2">
          <AppSwitch leftLabel="Allow N/A" v-model="localQuestion.show_na" />
        </div>
        <div class="m-2" v-if="question.type === 'select'">
          <AppSwitch leftLabel="Allow 'Other'" v-model="localQuestion.other" />
        </div>
        <div class="m-2">
          <AppSwitch
            leftLabel="Non Sensitive"
            v-model="localQuestion.non_sensitive"
          />
        </div>
        <div class="m-2">
          <AppSwitch
            leftLabel="Multi-line Text Input"
            v-if="question.type === 'text'"
            v-model="localQuestion.multi_line"
          />
        </div>
        <div
          v-if="!['formula', 'heading'].includes(localQuestion.type)"
          class="m-2"
        >
          <AppSwitch leftLabel="Required" v-model="localQuestion.required" />
        </div>
        <div
          v-if="!['formula', 'heading'].includes(localQuestion.type)"
          class="m-2"
        >
          <AppSwitch
            leftLabel="Allow Templating"
            v-model="localQuestion.allow_templating"
          />
        </div>
        <div class="m-4 w-full" v-if="question.type === 'product'">
          <div class="font-bold h-5 text-primary-600">Product category</div>
          <Multiselect
            placeholder="Select a category"
            v-model="localQuestion.product_category"
            :options="productCategories"
            label="name"
            valueProp="id"
          />
        </div>
        <div
          v-if="
            !['credential', 'select', 'formula', 'heading'].includes(
              localQuestion.type
            )
          "
          class="m-2"
        >
          <AppSwitch
            leftLabel="Allow Multiple Answers"
            v-model="localQuestion.multi_input"
          />
        </div>
      </div>

      <div v-if="localQuestion.multi_input" class="grid grid-cols-2 w-max">
        <TextInput
          type="number"
          label="Minimum Answer Count"
          class="mx-4 w-max"
          :max="Number(localQuestion.validation.max_answers)"
          v-model="localQuestion.validation.min_answers"
        />

        <TextInput
          type="number"
          label="Maximum Answer Count"
          class="mx-4 w-max"
          :min="Number(localQuestion.validation.min_answers)"
          v-model="localQuestion.validation.max_answers"
        />
      </div>

      <div
        v-if="localQuestion.multi_input && localQuestion.validation.max_answers"
      >
        <div v-for="i in Number(localQuestion.validation.max_answers)" :key="i">
          <TextInput
            class="mx-4 w-1/2 my-4"
            :label="'Multi Answer Label: ' + i"
            v-model="localQuestion.multi_input_label[i - 1]"
            v-if="!showKeys"
          />

          <div
            v-else
            @click="
              copyToClipboard(
                sectionKey + '.' + localQuestion.key + '.' + (i - 1)
              )
            "
          >
            <TextInput
              disabled
              class="mx-4 w-1/2 my-4"
              :label="'Multi Answer PDF Mapping: ' + i"
              :modelValue="sectionKey + '.' + localQuestion.key + '.' + (i - 1)"
            />
          </div>
        </div>
      </div>
    </div>

    <AppModal name="ImagePickerModal" v-model="showImageModal">
      <template v-slot="{ params }">
        <div
          v-if="params?.optionIndex !== undefined"
          class="w-full h-screen flex"
        >
          <AppCard class="m-auto max-w-2xl p-8 relative">
            <IconPack
              @click="showImageModal = false"
              type="X"
              class="cursor-pointer bg-red-500 h-5 w-5 text-white rounded-full p-1 top-2 right-2 absolute"
            />
            <template #title>
              {{ localQuestion.options[params.optionIndex].title }}
            </template>
            Paste a valid image URL to use as the card image.
            <TextInput
              class="w-full"
              v-model="localQuestion.options[params.optionIndex].image"
              placeholder="Image URL"
            />
            <div class="flex justify-end mt-4">
              <AppButton @click="showImageModal = false">Save</AppButton>
            </div>
          </AppCard>
        </div>
      </template>
    </AppModal>
  </AppCard>
</template>

<script>
import AppSwitch from '../../EW/AppSwitch.vue';
import AppButton from '../../EW/AppButton.vue';
import AddCard from '../../EW/AddCard.vue';
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';

export default {
  components: { AppSwitch, AppButton, AddCard },
  props: {
    questions: {
      type: Array
    },
    question: {},
    showKeys: {
      type: Boolean,
      default: false
    },
    sectionKey: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localQuestion: null,
      showImageModal: false,
      productCategories: [],
      addFormulaInputData: {},
      showAddFormulaInput: false,
      formulas: [
        {
          name: 'Add',
          slug: 'add'
        },
        {
          name: 'Subtract',
          slug: 'subtract'
        },
        {
          name: 'Multiply',
          slug: 'multiply'
        },
        {
          name: 'Divide',
          slug: 'divide'
        }
      ]
    };
  },
  mounted() {
    this.localQuestion = JSON.parse(JSON.stringify(this.question));

    if (!this.localQuestion.multi_input_label)
      this.localQuestion.multi_input_label = [];

    if (this.localQuestion.type === 'product') this.getProductCategories();
  },
  watch: {
    localQuestion: {
      handler() {
        this.$emit('updateQuestion', this.localQuestion);
      },
      deep: true
    }
  },
  computed: {
    icon() {
      switch (this.question.type) {
        case 'select':
          return 'Check';
        case 'text':
          return 'DocumentText';
        case 'image':
          return 'Photo';
        case 'number':
          return 'Calculator';
        case 'location':
          return 'Map';
        case 'date':
          return 'Calendar';
        case 'product':
          return 'ArchiveBox';
        case 'credential':
          return 'Identification';
        case 'signature':
          return 'Pencil';
        case 'formula':
          return 'Beaker';
        case 'heading':
          return 'ChatBubbleLeft';
        default:
          return 'QuestionMarkCircle';
      }
    }
  },
  methods: {
    addFormulaInput() {
      if (!this.localQuestion.formula_inputs)
        this.localQuestion.formula_inputs = [];
      this.localQuestion.formula_inputs.push(this.addFormulaInputData);
      this.addFormulaInputData = {};
      this.showAddFormulaInput = false;
    },
    getProductCategories() {
      this.$store.$api
        .get(
          import.meta.env.VITE_API_URL + '/product-categories',

          {
            headers: {
              Authorization:
                'Bearer ' + this.$store.getters['Authentication/token']
            }
          }
        )
        .then((res) => {
          this.productCategories = res.data.data.map((category) => {
            return {
              id: category.id,
              name: category.attributes.name
            };
          });
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error('Failed to load product categories.');
        });
    },
    showImagePicker(index) {
      console.log('showimagepicker ' + index);
      this.$modal.show('ImagePickerModal', { optionIndex: index });
    },
    async copyToClipboard(textToCopy) {
      this.$copyText(textToCopy);
      this.$toast.success('Copied to clipboard');
    },
    removeSelectOption(option) {
      this.localQuestion.options = this.localQuestion.options.filter(
        (o) => o.key !== option.key
      );
    },
    addSelectOption() {
      this.localQuestion.options
        ? this.localQuestion.options.push({
            key: '',
            title: ''
          })
        : (this.localQuestion.options = [
            {
              key: '',
              title: ''
            }
          ]);
    },
    removeQuestion() {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        icon: 'ExclamationCircle',
        title: 'Remove Question?',
        message: 'Are you sure you want to remove this question?',
        color: 'red'
      });

      reveal();

      onConfirm(() => {
        this.$emit('removeQuestion', this.question.key);
      });
    },
    cloneQuestion() {
      this.$emit('cloneQuestion', this.question.key);
    },
    updateKey(entity) {
      let key = entity.title
        .toLowerCase()
        .replaceAll(' ', '_')
        .replace(/[\W]+/g, '');

      if (this.questions.find((q) => q.key === key)) {
        key += '_' + Math.random().toString(36).substr(2, 9);
      }

      entity.key = key;
    }
  }
};
</script>
