import ManageAccount from '@/views/App/ManageAccount.vue';

export default [
  {
    meta: { layout: 'dashboard',  requiresAuth: true },
    path: '/account/delete',
    name: 'Manage Account',
    component: ManageAccount,
    title: 'Manage Account',
  
  },
];
