<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="flex h-full justify-center items-start px-2 mb-20 scrollbar-hide">
    <transition name="scale-quick" mode="out-in" appear>
      <AppSpinner key="spinner" v-if="!company" class="h-20 text-primary-500" />
      <AppCard key="notFound" v-else-if="company?.error"
        >Invite not found 🤔</AppCard
      >
      <div
        key="invite"
        v-else
        class="flex flex-col w-full max-w-md px-4 py-8 sm:px-6 md:px-8 lg:px-10"
      >
        <EWMainLogo class="justify-self-start self-start mb-10 m-auto w-48" />

        <AppCard
          v-if="company?.logo"
          class="flex justify-center items-center p-6 mb-4"
        >
          <img :src="company?.logo" class="m-auto w-48" />
        </AppCard>

        <p class="mb-6">
          <strong>{{ company?.name }}</strong> is inviting you to join their
          organisation on Energy Warehouse. Please set up your profile to accept
          the invitation.
        </p>

        <div class="border-t"></div>
        <transition name="scale-quick" mode="out-in" appear>
          <div
            v-if="!existingAccount"
            key="register"
            class="m-auto w-full mt-4"
          >
            <AppTitle class="self-start text-xl font-bold sm:text-2xl"
              >Join {{ company?.name }}</AppTitle
            >
            Register a new Energy Wherehouse account below.

            <div class="mt-8">
              <div class="flex flex-col mb-2">
                <TextInput
                  v-model="first_name"
                  placeholder="First name"
                ></TextInput>
              </div>
              <div class="flex flex-col mb-2">
                <TextInput
                  v-model="last_name"
                  placeholder="Last name"
                ></TextInput>
              </div>
              <div class="flex flex-col mb-2">
                <TextInput v-model="email" placeholder="Email address"
                  ><template #icon
                    ><IconPack type="Envelope" class="w-5 h-5" /></template
                ></TextInput>
              </div>

              <div class="flex flex-col mb-6">
                <TextInput
                  v-model="password"
                  type="password"
                  placeholder="New password"
                  ><template #icon
                    ><IconPack type="LockOpen" class="w-5 h-5" /></template
                ></TextInput>
              </div>

              <AppSwitch
                class="text-sm my-4"
                leftLabel="Already have an account?"
                v-model="existingAccount"
              />

              <div class="flex w-full">
                <AppButton
                  color="primary"
                  class="justify-center"
                  :pulse="loading"
                  @click="join()"
                  >Join</AppButton
                >
              </div>
            </div>
          </div>
          <div v-else key="join" class="m-auto w-full mt-4 mb-8">
            <AppTitle class="self-start text-xl font-bold sm:text-2xl"
              >Join {{ company?.name }}</AppTitle
            >
            If you already have an Energy Warehouse account,
            <strong>ensure you have the app installed, </strong> then continue
            to the app below.

            <a :href="`energywarehouse://invite/${joinCode}`"
              ><AppButton class="mt-2">Continue in App</AppButton></a
            >

            <div class="mt-8">
              <AppSwitch
                class="text-sm my-4"
                leftLabel="Already have an account?"
                v-model="existingAccount"
              />
            </div>
          </div>
        </transition></div
    ></transition>
  </div>
</template>
<script>
import AppSwitch from '@/components/EW/AppSwitch.vue';
export default {
  components: {
    AppSwitch
  },
  data() {
    return {
      existingAccount: false,
      company: null,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      loading: false
    };
  },
  mounted() {
    this.getInvite();
  },
  computed: {
    joinCode() {
      return this.$route.params.join_code;
    }
  },
  methods: {
    getInvite() {
      this.$store.$api
        .get(
          import.meta.env.VITE_API_URL + `/companies/invite/${this.joinCode}`
        )
        .then((res) => {
          this.company = res.data;
        })
        .catch((error) => {
          this.company = { error: true };
          this.toastApiErrors(error);
        });
    },
    join() {
      this.loading = true;
      this.$store
        .dispatch('Authentication/register', {
          pending_join_requests: {
            connect: [this.company.id]
          },
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password,
          username: this.email,
          uid: Math.random().toString()
        })
        .then(() => {
          this.$router.push('/invite/get-the-app');
          this.loading = false;
        })
        .catch((error) => {
          this.toastApiErrors(error);
          this.loading = false;
        });
    }
  }
};
</script>
