<template>
  <span
    class="inline-flex items-center font-bold rounded-full shadow-md text-center w-max text-white"
    :class="{
      'px-3 py-1': size == 'xs',
      'px-3 py-2': size == 'base',

      'pointer-cursor hover:shadow-lg hover:-translate-y-0.5 transform transition duration-300':
        clickable
    }"
  >
    <div v-if="hasIcon" class="w-4 mr-2">
      <slot name="icon" />
    </div>
    <slot />
  </span>
</template>
<script>
export default {
  props: {
    size: { type: String, default: 'base' },
    clickable: { type: Boolean, default: false }
  },
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    }
  }
};
</script>
