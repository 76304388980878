<template>
  <AppModal
    class="pdfModal"
    style="backdrop-filter: blur(3px); z-50"
    name="pdfModal"
    v-model="openModal"
  >
    <div class="w-full h-screen flex relative">
      <div
        @click="hideModal"
        class="w-full opacity-0 h-full z-10 absolute top-0 left-0"
      ></div>
      <div class="w-full px-32 py-5 relative">
        <div class="w-full z-20 h-full rounded-xl relative">
          <iframe :src="url" class="w-full h-full rounded-xl"> </iframe>
          <div class="absolute top-0 -right-14 flex flex-col gap-4">
            <div
              v-tippy="{ content: 'Close', placement: 'right' }"
              @click="hideModal"
              class="w-10 h-10 hover:shadow-sm hover:shadow-white hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full flex items-center justify-center"
            >
              <IconPack type="XMark" class="w-5 h-5 text-primary-500" />
            </div>
            <button
              v-tippy="{ content: 'Delete Pdf', placement: 'right' }"
              v-if="deletable"
              @click="deleteDocument()"
              class="bg-white group hover:bg-red-400 w-fit transition-all duration-300 hover:-translate-y-0.5 rounded-full p-2"
            >
              <IconPack
                type="Trash"
                class="w-6 h-6 text-primary-500 group-hover:text-white transition-all duration-300"
              />
            </button>
            <!-- edit label -->
            <button
              v-tippy="{ content: 'Edit Label', placement: 'right' }"
              v-if="editable"
              @click="editLabel"
              class="bg-white group hover:bg-primary-200 w-fit transition-all duration-300 hover:-translate-y-0.5 rounded-full p-2"
            >
              <IconPack
                type="PencilSquare"
                class="w-6 h-6 text-primary-500 group-hover:text-white transition-all duration-300"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    deletable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'delete', 'edit-label'],
  data() {
    return {
      openModal: this.modelValue,
      showError: false,
      modifiedURL: ''
    };
  },
  methods: {
    hideModal() {
      this.openModal = false;
      this.$emit('update:modelValue', this.openModal);
    },
    deleteDocument() {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        title: 'Delete Document',
        message: 'Are you sure you want to delete this document?',
        confirmText: 'Delete'
      });

      reveal();

      onConfirm(() => {
        this.$emit('delete');
        this.$emit('update:modelValue', false);
      });
    },
    editLabel() {
      this.$emit('edit-label');
    }
  },
  watch: {
    modelValue() {
      this.openModal = this.modelValue;
    }
  }
};
</script>
