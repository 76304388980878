<template>
  <div class="flex justify-center items-center flex-col pb-20">
    <div class="flex flex-col w-full max-w-md px-6 py-8 md:px-8 lg:px-10">
      <EWMainLogo class="justify-self-start self-start mb-10 m-auto w-48" />

      <div
        v-if="affiliateUid && !affiliate"
        class="flex w-full justify-center items-center font-bold mb-4"
      >
        Loading referal link...
      </div>

      <div
        v-else-if="affiliateUid && affiliate"
        class="flex w-full justify-center items-center font-bold text-green-600 mb-4"
      >
        {{ affiliate.attributes.name }} referal link active
        <IconPack type="CheckCircle" class="w-6" />
      </div>

      <div class="m-auto w-full">
        <AppTitle class="text-center text-xl font-bold sm:text-2xl mb-4"
          >Register an account</AppTitle
        >

        <p v-if="!affiliateUid">
          Please register within the app. Don't have the app yet?
          <strong>Download it now.</strong>
        </p>

        <AppStores v-if="!affiliateUid" class="w-full my-8" />

        <div v-if="affiliateUid">
          <div class="flex flex-col mb-2">
            <TextInput
              v-model="first_name"
              placeholder="First name"
            ></TextInput>
          </div>
          <div class="flex flex-col mb-2">
            <TextInput v-model="last_name" placeholder="Last name"></TextInput>
          </div>

          <div class="flex flex-col mb-2">
            <TextInput v-model="email" placeholder="Email address"
              ><template #icon
                ><IconPack type="Envelope" class="w-5 h-5" /></template
            ></TextInput>
          </div>

          <div class="flex flex-col mb-2">
            <TextInput v-model="contact_number" placeholder="Contact number"
              ><template #icon
                ><IconPack type="Phone" class="w-5 h-5" /></template
            ></TextInput>
          </div>

          <div class="flex flex-col mb-6">
            <TextInput v-model="password" type="password" placeholder="Password"
              ><template #icon
                ><IconPack type="LockOpen" class="w-5 h-5" /></template
            ></TextInput>
            <TextInput
              v-model="password2"
              type="password"
              placeholder="Password confirmation"
              ><template #icon
                ><IconPack type="LockOpen" class="w-5 h-5" /></template
            ></TextInput>
          </div>

          <div class="flex w-full">
            <AppButton
              color="primary"
              class="justify-center"
              :pulse="loading"
              @click="register()"
              >Register</AppButton
            >
          </div>
        </div>
      </div>
    </div>
    <router-link
      to="/login"
      class="text-gray-500 font-medium hover:text-gray-700 transition-colors duration-300"
      ><div class="flex flex-row items-center">
        <IconPack type="ArrowLeft" class="w-5 h-5 mr-2" /> Already have an
        account?
      </div></router-link
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      first_name: '',
      last_name: '',
      contact_number: '',
      email: '',
      password: '',
      password2: '',
      loading: false,
      affiliate: null
    };
  },
  computed: {
    affiliateUid() {
      return (
        this.$route.params.affiliateUid || localStorage.getItem('affiliateUid')
      );
    }
  },
  mounted() {
    if (
      this.$route.params.affiliateUid ||
      localStorage.getItem('affiliateUid')
    ) {
      if (this.$route.params.affiliateUid)
        localStorage.setItem('affiliateUid', this.$route.params.affiliateUid);
      this.loadReferal();

      setTimeout(() => {
        window.location.href =
          'energywarehouse://register/' + this.$route.params.affiliateUid;
      }, 1000);
    }
  },
  methods: {
    loadReferal() {
      this.$store.$api
        .get(import.meta.env.VITE_API_URL + '/affiliates', {
          params: { 'filters[uid]': this.affiliateUid }
        })
        .then((res) => {
          if (res.data?.data?.length <= 0) return;
          this.affiliate = res.data.data[0];
        });
    },
    register() {
      this.loading = true;

      if (!this.contact_number) {
        this.$toast.error('Please enter your contact number');
        this.loading = false;
        return;
      }

      if (this.password !== this.password2) {
        this.$toast.error('Passwords do not match');
        this.loading = false;
        return;
      }

      this.$store
        .dispatch('Authentication/register', {
          first_name: this.first_name?.trim(),
          last_name: this.last_name?.trim(),
          email: this.email?.trim(),
          contact_number: this.contact_number?.trim(),
          username: this.email?.trim(),
          password: this.password?.trim(),
          uid: '',
          affiliate_uid: this.affiliateUid
        })
        .then((res) => {
          console.log(res);
          // this.$toast.success(
          //   'Please check your email for a confirmation link'
          // );

          this.$router.push('/invite/get-the-app');
          this.loading = false;
        })
        .catch((error) => {
          this.toastApiErrors(error);
          this.loading = false;
        });
    }
  }
};
</script>
