import store from '..';

export default {
  getCredentials({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {

      store.$api
        .get(`${import.meta.env.VITE_API_URL}/credentials`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token'],
          },
          params: {
            sort: 'first_name',
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log('getCredentials', response.data);
            commit('SET_CREDENTIALS', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) commit('SET_CREDENTIALS', {});
          console.log(error);
          reject(error);
        });
    });
  }
};
