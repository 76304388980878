<script setup>
import { getCurrentInstance } from 'vue';
</script>
<template>
  <transition name="scale-quick" mode="out-in" appear>
    <div
      v-if="!condensed"
      class="flex flex-col w-full rounded-lg border border-gray-300 relative"
    >
      <transition name="fade" mode="out-in" appear>
        <div
          v-if="loading"
          style="backdrop-filter: blur(0.8px)"
          class="w-full h-full absolute bg-black z-10 rounded-lg bg-opacity-10 flex justify-center items-center"
        >
          <AppSpinner class="w-8 h-8 text-primary-600" />
        </div>
      </transition>

      <div
        class="flex items-center justify-center absolute top-0 -left-0 shadow-sm rounded-l-md w-8 h-full"
        :class="{
          'bg-primary-500': status == 'Locked',
          'bg-green-500': status == 'Approved',
          'bg-secondary-500': status == 'Awaiting Approval',
          'bg-gray-400': status == 'In Progress'
        }"
      >
        <h2 class="-rotate-90 text-sm whitespace-nowrap font-normal text-white">
          {{
            //max 20 characters of title[0]
            title[0]?.length > 20
              ? `${title[0].split(' ')[0]} ${title[0].split(' ')[1]} ${
                  title[0].split(' ')[2]
                }`
              : title[0]
          }}
        </h2>
      </div>

      <SearchableDropdown
        @selected="handleSelection"
        ref="actions"
        noborder
        action
        @click.stop
        class="rounded-md absolute m-2 top-0 right-0 z-10"
        :options="options"
      >
        <template #iconLeft>
          <IconPack
            type="EllipsisHorizontalCircle"
            class="w-8 h-8 group-hover:text-primary-500 bg-white hover:bg-gray-100 rounded-md p-1 transition-all duration-300 text-gray-500 cursor-pointer"
          />
        </template>
      </SearchableDropdown>

      <div class="py-4 ml-10 px-5 flex flex-col gap-4">
        <div class="w-full flex justify-between">
          <p class="text-lg font-bold text-primary-500">
            {{ title.join(' - ') }}
          </p>
          <!-- <IconPack
            v-if="document.is_template"
            type="Star"
            class="w-6 h-6 text-secondary-500"
          /> -->
        </div>
        <div class="flex flex-col gap-4">
          <!-- Last Updated -->
          <div class="flex gap-2 flex-wrap items-center">
            <p
              class="text-gray-400 text-sm whitespace-nowrap bg-gray-100 w-fit h-fit py-1 px-2 border border-gray-300 rounded font-semibold"
            >
              Last Updated:
            </p>
            <div class="flex w-fit items-center">
              <div>
                <ContactCard condensed :contact="updator" class="border-none" />
              </div>
              <div class="flex gap-2 items-center justify-end w-full">
                <p class="text-gray-800 font-bold">{{ time }}</p>
                <p class="text-gray-700">{{ date }}</p>
              </div>
            </div>
          </div>
          <!-- Approvals -->
          <div class="flex gap-4 justify-between w-full items-center">
            <div class="flex gap-2 items-center">
              <p class="text-primary-500 font-semibold">Approvals:</p>
              <div
                v-if="approvalsRequired?.length > 0"
                class="flex justify-center gap-2 items-center"
              >
                <div v-for="reqUser in approvalsRequired" :key="reqUser.id">
                  <ContactCard
                    class="cursor-pointer"
                    v-tippy="{
                      content: tooltip(reqUser.id),
                      placement: 'top',
                      delay: 200
                    }"
                    @click="
                      !isLocked
                        ? reqUser.id === user.id
                          ? toggleApproval(user)
                          : requestDocumentApprovalToggle(reqUser)
                        : ''
                    "
                    icononly
                    condensed
                    :color="
                      status == 'Awaiting Approval'
                        ? documentApprovals.some((u) => u.id === reqUser.id)
                          ? '#4ade80'
                          : '#F89838'
                        : ''
                    "
                    :contact="reqUser.attributes"
                  />
                </div>
              </div>
              <div v-else class="px-8 -ml-6">
                <IconPack type="UserCircle" class="w-6 h-6 text-gray-400" />
              </div>
            </div>
            <!-- Status -->
            <div class="w-1/4 scale-[.8] flex justify-end">
              <div
                :class="{
                  'bg-primary-500': status == 'Locked',
                  'bg-green-500': status == 'Approved',
                  'bg-secondary-500': status == 'Awaiting Approval',
                  'bg-gray-400': status == 'In Progress'
                }"
                class="flex gap-2 px-4 py-2 text-white rounded-full w-fit items-center flex-nowrap"
              >
                <IconPack solid :type="statusIcon" class="w-6 h-6" />
                <p class="whitespace-nowrap">{{ status }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="w-xfull flex gap-4 justify-end p-2">
        <div
          v-if="status == 'Approved' && isAdmin"
          v-tippy="{
            content:
              company?.attributes?.subscription_status == 'payment_failed'
                ? 'Please update your payment method.'
                : ''
          }"
        >
          <!-- Lock Button -->
          <transition name="scale-quick" mode="out-in" appear>
            <AppButton
              @click="lockDocument"
              :pulse="locking"
              class="group border-none shadow-none"
              :disabled="
                company?.attributes?.subscription_status == 'payment_failed'
              "
            >
              <template #icon>
                <IconPack type="LockClosed" class="w-5 h-5 text-white" />
              </template>
              <p class="text-white font-medium">Lock</p>
            </AppButton>
          </transition>
        </div>
        <div
          v-if="
            canToggleApproval && !isApproved && getAction(user.id) == 'approve'
          "
          v-tippy="{
            content:
              company?.attributes?.subscription_status == 'payment_failed'
                ? 'Please update your payment method.'
                : ''
          }"
        >
          <!-- approve button -->
          <AppButton
            @click="toggleApproval(user)"
            color="green"
            class="group border-none shadow-none"
            :disabled="
              company?.attributes?.subscription_status == 'payment_failed'
            "
          >
            <template #icon>
              <IconPack solid type="CheckCircle" class="w-5 h-5" />
            </template>
            <p class="font-medium">Approve</p>
          </AppButton>
        </div>
        <div class="flex items-center gap-2">
          <!-- approval button -->
          <div
            v-tippy="{
              content:
                company?.attributes?.subscription_status == 'payment_failed'
                  ? 'Please update your payment method.'
                  : ''
            }"
          >
            <AppButton
              :disabled="
                company?.attributes?.subscription_status == 'payment_failed'
              "
              @click="toggleApproval(user)"
              :id="getCurrentInstance().uid"
              v-if="canToggleApproval && getAction(user.id) == 'revoke'"
              color="white"
              class="group border-none shadow-none bg-white hover:bg-red-400 transition-all duration-100 delay-100 cursor-pointer"
            >
              <template #icon>
                <IconPack
                  type="CheckCircle"
                  class="w-5 h-5 group-hover:opacity-0 text-primary-500 transition-all duration-200 delay-100"
                />
                <IconPack
                  type="XCircle"
                  solid
                  class="w-5 h-5 opacity-0 absolute top-1/2 -translate-y-1/2 group-hover:opacity-100 text-white transition-all duration-200 delay-100"
                />
              </template>
              <p
                class="text-primary-500 group-hover:opacity-0 group-hover:text-white transition-all duration-200 delay-75 font-medium"
              >
                Approved
              </p>
              <p
                class="text-primary-500 absolute ml-3 opacity-0 group-hover:opacity-100 group-hover:text-white group-hover:font-bold transition-all duration-200 delay-75 font-medium"
              >
                Revoke
              </p>
            </AppButton>
          </div>
          {{ isDeleted }}
          <!-- Pdf Button -->
          <div
            v-tippy="{
              content:
                company?.attributes?.subscription_status == 'payment_failed'
                  ? 'Please update your payment method.'
                  : ''
            }"
          >
            <AppButton
              @click="generatePDF(false)"
              color="white"
              class="group border-none shadow-none cursor-pointer"
              :disabled="
                company?.attributes?.subscription_status == 'payment_failed'
              "
            >
              <template #icon>
                <IconPack type="Document" class="w-5 h-5 text-primary-500" />
              </template>
              <p class="text-primary-500 font-bold">PDF</p>
            </AppButton>
          </div>
        </div>
      </div>

      <AppModal
        style="backdrop-filter: blur(3px)"
        name="generatingPDF"
        v-model="generating"
      >
        <div class="w-full h-screen flex justify-center items-center">
          <div
            class="py-5 px-10 flex flex-col gap-3 bg-white w-fit m-auto rounded-lg border"
          >
            <div class="flex gap-2 items-center">
              <AppSpinner class="w-6 h-6 text-primary-500" />
              <p class="text-primary-500 text-lg font-bold">Generating PDF</p>
            </div>
            <p class="text-gray-400 text-sm font-normal">
              Please wait while we generate your PDF
            </p>
          </div>
        </div>
      </AppModal>

      <PDFModal v-model="openPDF" :url="pdfURL"></PDFModal>

      <ErrorModal ref="errorModal" />
    </div>
    <div v-else>
      <div
        class="select-none border rounded-md bg-white"
        :class="{
          'hover:-translate-y-0.5 hover:shadow-lg transition-all duration-300 cursor-pointer':
            clickable
        }"
      >
        <div
          class="flex rounded-t-md p-3"
          :class="{
            'bg-primary-500 text-white': status == 'Locked',
            'bg-green-500 text-white': status == 'Approved',
            'bg-secondary-500 text-white': status == 'Awaiting Approval',
            'bg-gray-400 text-white': status == 'In Progress',
            'justify-center': !removable,
            'justify-between': removable
          }"
        >
          <div v-if="removable" class="w-6 h-6"></div>
          <p class="text-sm font-bold">{{ title[1] }}</p>
          <IconPack
            v-if="removable"
            type="XMark"
            @click="$emit('remove', document.id)"
            class="w-6 h-6 rounded-full bg-gray-50 text-primary-500 p-1 hover:bg-red-400 hover:text-white transition-all duration-300"
          />
        </div>
        <div
          class="flex p-3 items-center justify-between text-primary-500 gap-2"
        >
          <p class="text-sm">{{ title.join(' - ') }}</p>
          <IconPack type="Star" class="w-6 h-6 text-secondary-500" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ContactCard from '@/components/Contacts/ContactCard.vue';
import PDFModal from '@/components/Modals/PDFModal.vue';
import moment from 'moment';
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import ErrorModal from '@/components/Modals/ErrorModal.vue';
import { document } from 'postcss';

export default {
  components: {
    ContactCard,
    PDFModal
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    condensed: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    removable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:document', 'refresh', 'remove'],
  data() {
    return {
      generating: false,
      locking: false,
      openPDF: false,
      pdfURL: '',
      latitude: 0,
      longitude: 0,
      loading: false
    };
  },
  computed: {
    title() {
      return this.document.name?.split('-');
    },
    isDeleted() {
      return this.document.is_deleted;
    },

    options() {
      const options = [];
      if (this.company?.attributes?.subscription_status != 'payment_failed') {
        if (this.isLocked) {
          options.push({
            name: 'Clone',
            icon: 'DocumentDuplicate'
          });
          options.push({
            name: 'Regenerate',
            icon: 'ArrowPath'
          });
        } else {
          options.push({
            name: 'Clone',
            icon: 'DocumentDuplicate'
          });
          options.push({
            name: 'Delete',
            icon: 'Trash'
          });
        }
        options.push({
          name: 'View',
          icon: 'DocumentText'
        });
      }

      return options;
    },
    status() {
      return this.document.is_locked
        ? 'Locked'
        : this.document.is_approved && !document.is_locked
        ? 'Approved'
        : this.document.user_approvals?.data?.length <=
            this.document.approvals_required?.data?.length &&
          this.document.user_approvals?.data?.length > 0
        ? 'Awaiting Approval'
        : 'In Progress';
    },
    statusIcon() {
      return this.status == 'Locked'
        ? 'LockClosed'
        : this.status == 'Approved'
        ? 'CheckCircle'
        : this.status == 'Awaiting Approval'
        ? 'Clock'
        : 'Clock';
    },
    updator() {
      if (this.document.updator?.data) {
        return this.document.updator?.data.attributes;
      } else if (this.document.creator?.data) {
        return this.document.creator?.data.attributes;
      }

      return {
        first_name: 'System',
        last_name: 'Generated'
      };
    },
    time() {
      if (this.document.updatedAt) {
        return moment(this.document.updatedAt).format('HH:mm');
      } else if (this.document.createdAt) {
        return moment(this.document.createdAt).format('HH:mm');
      }

      return 'n/a';
    },
    date() {
      if (this.document.updatedAt) {
        return moment(this.document.updatedAt).format('Do MMM');
      } else if (this.document.createdAt) {
        return moment(this.document.createdAt).format('Do MMM');
      }

      return 'n/a';
    },
    approvalsRequired() {
      if (this.document.approvals_required?.data?.length > 0) {
        return this.document.approvals_required?.data;
      } else {
        return this.documentApprovals;
      }
    },

    documentApprovals() {
      return this.document.user_approvals?.data;
    },

    isLocked() {
      return this.document.is_locked;
    },

    isApproved() {
      return this.document.is_approved && !this.document.is_locked;
    },

    canToggleApproval() {
      if (this.isLocked) return false;
      if (this.approvalsRequired?.length === 0) return true;
      if (this.documentApprovals?.find((user) => user.id === this.user.id))
        return true;
      if (this.approvalsRequired?.find((user) => user.id === this.user.id))
        return true;

      return false;
    }
  },
  methods: {
    tooltip(userId) {
      return this.isLocked
        ? ''
        : this.documentApprovals.some((u) => u.id === userId)
        ? userId === this.user.id
          ? 'Revoke your approval'
          : 'Request ' + this.getAction(userId)
        : userId !== this.user.id
        ? 'Request approval'
        : 'Approve';
    },

    async generatePDF(regenerate = false) {
      // check if document is locked
      if (!regenerate && this.document.is_locked) {
        this.pdfURL =
          import.meta.env.VITE_ASSETS_URL +
          this.document.locked_document_url +
          '?timestamp=' +
          new Date().getTime();
        this.openPDF = true;
        return;
      }

      this.generating = true;
      this.$store
        .dispatch('Documents/generatePDF', this.id)
        .then((res) => {
          this.generating = false;
          this.pdfURL =
            res?.data.pdf_url + '?timestamp=' + new Date().getTime();
          this.openPDF = true;
        })
        .catch((error) => {
          this.generating = false;
          this.$toast.error('Failed to generate PDF', {
            position: 'bottom',
            onClick: () => {
              this.$refs.errorModal.show(error);
            }
          });
        });
    },

    async lockDocument() {
      if (this.document.is_locked) {
        return;
      }

      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        title: 'Confirm Document Lock',
        message:
          'Confirm that you wish to lock this document. This will prevent nay further changes to it.',
        icon: 'LockClosed',
        color: 'primary'
      });

      reveal();

      onConfirm(() => {
        this.locking = true;
        this.$store
          .dispatch('Documents/lockDocument', this.document.id)
          .then(() => {
            this.generating = true;
            this.generatePDF(true);
            this.$store
              .dispatch('Jobs/getJobById', this.document.jobUid)
              .then(() => {
                this.locking = false;
                this.$toast.success('Document locked');
              });
          })
          .catch((error) => {
            this.$toast.error('Failed to lock document', {
              position: 'bottom',
              onClick: () => {
                this.$refs.errorModal.show(error);
              }
            });
            this.locking = false;
          });
      });
    },

    getAction(userId) {
      return this.documentApprovals.some((u) => u.id === userId)
        ? 'revoke'
        : 'approve';
    },

    handleSelection(option) {
      switch (option.name) {
        case 'Regenerate':
          this.generatePDF(true);
          break;
        case 'Clone': {
          const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
            title: 'Clone Document',
            message: 'Confirm that you wish to clone this document?',
            icon: 'DocumentDuplicate',
            color: 'primary'
          });

          reveal();

          onConfirm(() => {
            this.loading = true;
            const documentName = this.document.name + ' - Copy';
            this.$store
              .dispatch('Documents/cloneDocument', {
                documentId: this.document.id,
                jobId: this.document.jobId,
                documentName: documentName
              })
              .then(() => {
                this.$emit('refresh', 'Document cloned');
              })
              .catch((error) => {
                this.$toast.error('Failed to clone document', {
                  position: 'bottom',
                  onClick: () => {
                    this.$refs.errorModal.show(error);
                  }
                });
              })
              .finally(() => {
                this.loading = false;
              });
          });

          break;
        }

        case 'Delete': {
          const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
            title: 'Delete Document',
            message: 'Confirm that you wish to delete this document?',
            icon: 'Trash',
            color: 'red'
          });

          reveal();

          onConfirm(() => {
            this.loading = true;
            this.$store
              .dispatch('Documents/deleteDocument', this.document.id)
              .then(() => {
                this.$emit('refresh', 'Document deleted');
              })
              .catch((error) => {
                this.$toast.error('Failed to delete document', {
                  position: 'bottom',
                  onClick: () => {
                    this.$refs.errorModal.show(error);
                  }
                });
              })
              .finally(() => {
                this.loading = false;
              });
          });

          break;
        }

        case 'View':
          this.$router.push({
            name: 'DocumentDetails',
            params: { uid: this.document.uid }
          });
          break;

        default:
          break;
      }
    },

    requestDocumentApprovalToggle(user) {
      const action = this.getAction(user.id);

      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        title:
          action === 'approve' ? 'Request Approval' : 'Revoke Approval Request',
        message:
          action === 'approve'
            ? `Confirm that you wish to request approval from ${user.attributes.first_name} ${user.attributes.last_name} for this document?`
            : `Confirm that you wish to request ${user.attributes.first_name} ${user.attributes.last_name} to revoke their approval of this document.`,
        icon: action === 'approve' ? 'ExclamationCircle' : 'XCircle',
        color: action === 'approve' ? 'primary' : 'red'
      });

      reveal();

      onConfirm(() => {
        this.loading = true;
        this.$store
          .dispatch('Documents/requestDocumentApprovalToggle', {
            documentId: this.document.id,
            approvalUserId: user.id,
            action: action
          })
          .then((response) => {
            response().then(() => {
              this.$store
                .dispatch('Jobs/getJobById', this.document.jobUid)
                .then(() => {
                  this.loading = false;
                  this.$toast.show(
                    `Request sent to ${user.attributes.first_name} ${user.attributes.last_name}`,
                    {
                      position: 'bottom'
                    }
                  );
                });
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error('Failed to send request', {
              position: 'bottom',
              onClick: () => {
                this.$refs.errorModal.show(error);
              }
            });
          });
      });
    },

    async toggleApproval(user) {
      const action = this.getAction(user.id);

      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        title: action === 'approve' ? 'Approve Document' : 'Revoke Approval',
        message:
          action === 'approve'
            ? 'Confirm that you hereby consent to have reviewed the document, agree with its contents and wish to add your credentials to it?'
            : 'Confirm that you wish to remove your approval of this document.',
        icon: action === 'approve' ? 'ExclamationCircle' : 'XCircle',
        color: action === 'approve' ? 'green' : 'red'
      });

      let location = {
        latitude: 0,
        longitude: 0
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
        return;
      }
      reveal();

      onConfirm(() => {
        const approved = action === 'approve';
        this.loading = true;

        this.$store
          .dispatch('Documents/toggleApproval', {
            documentId: this.document.id,
            userId: user.id,
            approved: approved,
            latitude: location.latitude,
            longitude: location.longitude
          })
          .then((res) => {
            res().then(() => {
              this.$store
                .dispatch('Jobs/getJobById', this.document.jobUid)
                .then(() => {
                  this.loading = false;
                  this.$toast.success(
                    `Approval ${approved ? 'granted' : 'revoked'}`
                  );
                });
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error('Failed to toggle approval', {
              position: 'bottom',
              onClick: () => {
                this.$refs.errorModal.show(error);
              }
            });
          });
      });
    }
  }
};
</script>
