<template>
  <AppCard
    class="overflow-clip"
    v-if="location"
    :open="open"
    @toggle-collapse="toggleCollapse"
    :clickable="clickable"
  >
    <template v-if="collapsable" #title>
      <div class="flex gap-2 items-center">
        <IconPack type="MapPin" class="w-6 h-6 text-primary-500" />
        <p class="text-primary-500 font-medium text-lg">Location</p>
      </div>
      <p v-if="collapsed" class="text-sm ml-7 mt-1 text-gray-400">
        {{ location.address_line }}, {{ location.city }}
      </p>
    </template>
    <div
      class="flex rounded-md"
      :class="{
        'flex-col gap-5 p-3': collapsable,
        'flex-row flex-wrap gap-10': !collapsable
      }"
    >
      <div
        class="flex h-32 items-center flex-shrink-0 rounded border shadow-sm overflow-hidden"
        :class="{
          'w-full max-w-sm': collapsable,
          'w-full max-w-xs hover:shadow  transition-all duration-300 ease-in-out group':
            !collapsable
        }"
      >
        <img
          v-if="hasMapImage"
          :src="location.map_image"
          alt="map"
          class="w-full scale-150 group-hover:scale-[1.6] tranistion-all duration-500"
        />
        <div
          v-else
          class="w-full h-full flex items-center justify-center bg-gray-100"
        >
          <IconPack type="Map" class="w-10 h-10 text-gray-300" />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div>
          <h2 class="text-primary-600 text-left text-lg font-bold">
            {{ location.address_line }}
          </h2>
          <p class="text-gray-500 my-1">
            <span v-if="open">{{ location.suburb }}, </span>
            <span> {{ open ? location.city : title }}</span>
          </p>
        </div>
        <div
          v-if="hasReferenceNumber"
          class="py-1 px-2 w-fit bg-gray-100 border rounded"
        >
          <p class="text-xs font-bold text-gray-500">
            REF:
            <slot name="reference_number" />
          </p>
        </div>
        <div
          v-if="hasStand || hasBuildingName"
          class="text-sm flex flex-col gap-1"
        >
          <p class="text-gray-400" v-if="hasBuildingName">
            Building: {{ location.building_name }}
          </p>
          <p class="text-gray-400" v-if="hasStand">
            Stand: {{ location.stand_number }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="collapsable && !hideActions"
      class="flex w-full justify-end absolute bottom-4 right-4 items-end h-10 gap-3"
    >
      <div>
        <AppButton @click="applyFilter" color="secondary">
          <template #icon>
            <IconPack type="Briefcase" class="w-5 h-5 text-white" />
          </template>
          <p class="text-white font-medium">Jobs</p>
        </AppButton>
      </div>
    </div>
  </AppCard>
  <AppCard v-else class="h-64">
    <div class="flex flex-col gap-2">
      <div class="flex gap-2 items-center">
        <IconPack type="MapPin" class="w-6 h-6 text-primary-500" />
        <p class="text-primary-500 font-medium text-lg">Location</p>
      </div>
      <p class="text-sm ml-7 mt-1 text-gray-400">No location selected</p>
    </div>
  </AppCard>
</template>

<script>
export default {
  props: {
    location: { type: Object, required: false },
    collapsable: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
    title: { type: String, default: '' },
    clickable: { type: Boolean, default: false },
    hideActions: { type: Boolean, default: false }
  },
  computed: {
    hasReferenceNumber() {
      return !!this.$slots.reference_number;
    },
    hasStand() {
      return !!this.location.stand_number;
    },
    hasMapImage() {
      return !!this.location.map_image;
    },
    hasBuildingName() {
      return !!this.location.building_name;
    }
  },
  data() {
    return {
      collapsed: !this.open
    };
  },
  methods: {
    toggleCollapse(collapsed) {
      this.collapsed = collapsed;
    },
    applyFilter() {
      this.$router.push({
        path: '/jobs',
        query: { location: this.location.uid }
      });
    }
  }
};
</script>
