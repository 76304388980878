import { createStore } from 'vuex';
import App from './App';
import Authentication from './Authentication';
import Documents from './Documents';
import Company from './Company';
import Subscriptions from './Subscriptions';
import Jobs from './Jobs';
import Contacts from './Contacts';
import Location from './Location';
import Plants from './Plants';
import JobComments from './JobComments';
import InboxItems from './InboxItems';
import Credentials from './Credentials';
import Products from './Products';

export default createStore({
  modules: {
    App,
    Authentication,
    Documents,
    Company,
    Subscriptions,
    Jobs,
    Contacts,
    Location,
    Plants,
    JobComments,
    InboxItems,
    Credentials,
    Products,
  },
});
