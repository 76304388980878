import store from '..';
import qs from 'qs';

// Utility function to map job data
const mapJobData = (job) => {
  return {
    id: job.id,
    uid: job.attributes.uid,
    reference_number: job.attributes.reference_number,
    createdAt: job.attributes.createdAt,
    updatedAt: job.attributes.updatedAt,
    location: job.attributes.location?.data,
    client: job.attributes.client?.data,
    creator: job.attributes.creator?.data,
    updator: job.attributes.updator?.data,
    status: job.attributes.status?.data,
    current_status: job.attributes.current_status?.data,
    documents: job.attributes.documents?.data,
    static_documents: job.attributes.static_documents?.data,
    start_date: job.attributes.start_date,
    job_type: job.attributes.job_type?.data,
    title: job.attributes.title,
    description: job.attributes.description
  };
}

export default {
  async getJobs({ commit, rootGetters }, { start, limit = 10, filters, showCompleted = true }) {
    try {
      commit('SET_FETCHING', true);

      if (!showCompleted) {
        filters = {
          filters: {
            $and: [
              filters?.filters,
              {
                current_status: {
                  $or: [
                    {
                      status: {
                        $ne: 'completed'
                      }
                    },
                    {
                      status: {
                        $null: true
                      }
                    }
                  ]
                }
              },
              {
                is_deleted: {
                  $ne: true
                }
              }
            ]
          }
        }
      } else {
        filters = {
          filters: {
            $and: [
              filters?.filters,
              {
                is_deleted: {
                  $ne: true
                }
              }
            ]
          }
        }
      }



      const response = await store.$api.get(`${import.meta.env.VITE_API_URL}/jobs/?${qs.stringify(filters)}`, {
        headers: {
          Authorization: 'Bearer ' + rootGetters['Authentication/token'],
        },
        params: {
          'pagination[start]': start,
          'pagination[limit]': limit,
          'sort[0]': 'start_date:desc',
          'sort[1]': 'createdAt:desc',
          'populate': 'is_deleted,client,location,creator,updator,status,current_status,documents,documents.user_approvals,documents.approvals_required,documents.creator,documents.updator,documents.is_approved,documents.is_locked,static_documents,static_documents.creator,start_date,job_type',
        },
      });

      if (response.status === 200) {
        commit('SET_FETCHING', false);
        console.log('response', response?.data)
        const jobList = response?.data?.data.map(mapJobData);

        commit('SET_JOBS', jobList);
        commit('SET_TOTAL', response?.data.meta.pagination.total)

        return response;
      }
    } catch (error) {
      if (error.response?.status === 401) commit('SET_JOBS', {});
      console.error(error);
      throw error;
    }
  },

  async getJobById({ commit, rootGetters }, id) {
    try {
      const response = await store.$api.get(`${import.meta.env.VITE_API_URL}/jobs/?filters[uid][$eq]=${id}&populate=client,location,creator,updator,status,current_status,documents,documents.user_approvals,documents.approvals_required,documents.creator,documents.updator,documents.is_approved,documents.is_locked,static_documents,static_documents.creator,job_type`, {
        headers: {
          Authorization: 'Bearer ' + rootGetters['Authentication/token'],
        },
      });

      if (response.status === 200) {
        const job = response.data.data.map(mapJobData);
        console.log(job[0]);
        commit('SET_JOB_BY_ID', job[0]);
        return response;
      }
    } catch (error) {
      if (error.response?.status === 401) commit('SET_JOB_BY_ID', {});
      console.error(error);
      throw error;
    }
  },


  async getJobTypes({ commit, rootGetters }) {
    try {
      const response = await store.$api.get(`${import.meta.env.VITE_API_URL}/job-types`, {
        headers: {
          Authorization: 'Bearer ' + rootGetters['Authentication/token'],
        },
      });

      if (response.status === 200) {
        const jobTypes = response?.data.data.map((jobType) => ({
          id: jobType.id,
          uid: jobType.attributes.uid,
          name: jobType.attributes.name,
          type: jobType.attributes.type,
          color: jobType.attributes.color,
          is_deleted: jobType.attributes.is_deleted,
          createdAt: jobType.attributes.createdAt,
          updatedAt: jobType.attributes.updatedAt,
        }));
        const jobTypesFiltered = jobTypes.filter((jobType) => !jobType.is_deleted);
        commit('SET_JOB_TYPES', jobTypesFiltered);
        return response;
      }
    } catch (error) {
      if (error.response?.status === 401) commit('SET_JOB_TYPES', {});
      console.error(error);
      throw error;
    }
  },

  async changeJobStatus({ rootGetters }, { id, status }) {
    try {
      const response = await store.$api.post(`${import.meta.env.VITE_API_URL}/job-status-changes`, {
        data: {
          uid: '',
          job: id,
          status: status,
          user: rootGetters['Authentication/user'].id,
        },
      }, {
        headers: {
          Authorization: 'Bearer ' + rootGetters['Authentication/token'],
        },
      });

      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createJob({ commit, rootGetters }, job) {
    try {
      const response = await store.$api.post(`${import.meta.env.VITE_API_URL}/jobs`, {
        data: {
          ...job,
        },
      }, {
        headers: {
          Authorization: 'Bearer ' + rootGetters['Authentication/token'],
        },
      });

      if (response.status === 200) {
        commit('UPDATE_JOB', response.data.data);
        return response;
      }
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteJob({ commit, rootGetters }, id) {
    commit('SET_FETCHING', true);
    return store.$api.delete(`${import.meta.env.VITE_API_URL}/jobs/${id}`, {
      headers: {
        Authorization: 'Bearer ' + rootGetters['Authentication/token'],
      },
    }).then((response) => {
      if (response.status === 200) {
        commit('DELETE_JOB', id);
        commit('SET_FETCHING', false);
        return response;
      }
    }).catch((error) => {
      console.error(error);
      throw error;
    });
  },

  updateJob({ commit, rootGetters }, job) {
    commit('SET_FETCHING', true);
    return store.$api.put(`${import.meta.env.VITE_API_URL}/jobs/${job.id}`, {
      data: {
        ...job.attributes,
      },
    }, {
      headers: {
        Authorization: 'Bearer ' + rootGetters['Authentication/token'],
      },
    }).then((response) => {
      if (response.status === 200) {
        commit('UPDATE_JOB', response.data.data);
        commit('SET_JOB_BY_ID', response.data.data);
        commit('SET_FETCHING', false);
        return response;
      }
    }
    ).catch((error) => {
      console.error(error);
      throw error;
    });
  },

  clearJob({ commit }) {
    console.log('clearJob');
    commit('SET_JOB_BY_ID', {});
  }


};
