import store from '..';

export default {
    async getInboxItems({ commit, rootGetters }, { id, lastUpdate }) {
        if (lastUpdate) {
            try {
                const response = await store.$api.get(`${import.meta.env.VITE_API_URL}/user-inbox-items`, {
                    headers: {
                        Authorization: `Bearer ${rootGetters['Authentication/token']}`,
                    },
                    params: {
                        populate: 'from_user,job_comment',
                        'filters[user][id][$eq]': id,
                        'filters[updatedAt][$gte]': lastUpdate,
                        'filters[is_read][$eq]': false,
                        'filters[job_comment][is_deleted][$eq]': false,
                        'sort[createdAt]': 'desc',
                    }
                });

                if (response.status === 200) {
                    if (response.data.data.length > 0) {
                        commit('UPDATE_INBOX', response.data.data)
                    }
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                const response = await store.$api.get(`${import.meta.env.VITE_API_URL}/user-inbox-items`, {
                    headers: {
                        Authorization: `Bearer ${rootGetters['Authentication/token']}`,
                    },
                    params: {
                        populate: 'from_user,job_comment',
                        'filters[user][id][$eq]': id,
                        'filters[job_comment][is_deleted][$eq]': false,
                        'sort[createdAt]': 'desc',
                    }
                });

                if (response.status === 200) {
                    commit('SET_INBOX_ITEMS', response.data.data)
                    // console.log(response);
                }
            }
            catch (error) {
                console.error(error);
            }
        }
    },
    async markAsRead({ commit, rootGetters }, { id, read }) {
        try {
            const response = await store.$api.put(`${import.meta.env.VITE_API_URL}/user-inbox-items/${id}`, {
                data: {
                    is_read: read
                }
            }, {
                headers: {
                    Authorization: `Bearer ${rootGetters['Authentication/token']}`,
                },
                params: {
                    populate: 'from_user',
                }
            });

            if (response.status === 200) {
                // console.log(response);
                commit('ADD_OR_UPDATE_INBOX_ITEM', response.data.data)
            }
        }
        catch (error) {
            console.error(error);
        }
    },
    async markAllRead({ commit, rootGetters }, { id }) {
        try {
            const response = await store.$api.post(`${import.meta.env.VITE_API_URL}/user-inbox-items/mark-read`, {
                data: {
                    is_read: true
                }
            }, {
                headers: {
                    Authorization: `Bearer ${rootGetters['Authentication/token']}`,
                },
                params: {
                    populate: 'from_user',
                    'filters[user][id][$eq]': id,
                }
            });

            if (response.status === 200) {
                // console.log(response);
                commit('MARK_ALL_READ')
            }
        }
        catch (error) {
            console.error(error);
        }
    }

};
