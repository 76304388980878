<template>
  <svg
    viewBox="0 0 1934 923"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
  >
    <g transform="matrix(1,0,0,1,-493.431,-227.714)">
      <path
        d="M941.318,598.052L1104.81,873.531L1104.81,227.714L941.318,598.052Z"
        style="fill: rgb(248, 152, 56); fill-rule: nonzero"
      />
      <path
        d="M953.464,779.979L789.969,504.5L789.969,1150.32L953.464,779.979Z"
        style="fill: rgb(248, 152, 56); fill-rule: nonzero"
      />
      <path
        d="M789.969,504.5L941.318,598.052L1104.81,873.531L953.464,779.979L789.969,504.5Z"
        style="fill: rgb(252, 181, 21); fill-rule: nonzero"
      />
      <g transform="matrix(1,0,0,1,464.212,845.49)">
        <g>
          <path
            d="M29.219,0L29.219,-309.615L276.828,-309.615L276.828,-235.417L128.641,-235.417L128.641,-194.219L253.917,-194.219L253.917,-123.172L128.641,-123.172L128.641,-75.25L282.922,-75.25L282.922,0L29.219,0Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1126.51,845.49)">
        <g>
          <path
            d="M29.219,0L29.219,-309.615L276.828,-309.615L276.828,-235.417L128.641,-235.417L128.641,-194.219L253.917,-194.219L253.917,-123.172L128.641,-123.172L128.641,-75.25L282.922,-75.25L282.922,0L29.219,0Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1426.45,845.49)">
        <g>
          <path
            d="M127.38,-176.562L172.568,-176.562C188.406,-176.562 199.333,-178.698 205.359,-182.974C211.385,-187.25 214.396,-194.708 214.396,-205.359C214.396,-216.292 211.385,-223.891 205.359,-228.167C199.333,-232.437 187.563,-234.578 170.047,-234.578L127.38,-234.578L127.38,-176.562ZM29.219,0L29.219,-309.615L177.406,-309.615C223.786,-309.615 256.891,-302.682 276.719,-288.807C296.547,-274.932 306.464,-252.161 306.464,-220.495C306.464,-201.578 302.401,-185.88 294.271,-173.411C286.146,-160.937 273.604,-151.057 256.646,-143.771C273.885,-140.13 286.427,-132.948 294.271,-122.229C302.12,-111.51 306.323,-95.776 306.885,-75.042L308.568,-31.948C308.568,-31.672 308.635,-31.318 308.776,-30.901C309.198,-18.568 312.141,-12.12 317.604,-11.562L317.604,0L217.339,0C215.521,-7.427 214.151,-13.906 213.24,-19.443C212.333,-24.979 211.875,-29.286 211.875,-32.37L211.875,-63.271C211.875,-79.245 208.724,-90.49 202.417,-97.005C196.109,-103.521 185.182,-106.781 169.625,-106.781L127.38,-106.781L127.38,0L29.219,0Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1754.98,845.49)">
        <g>
          <path
            d="M259.589,0L254.755,-26.693C243.547,-14.781 230.021,-5.922 214.188,-0.104C198.354,5.708 179.927,8.62 158.906,8.62C114.625,8.62 79.734,-5.922 54.229,-34.995C28.724,-64.073 15.974,-103.906 15.974,-154.495C15.974,-206.203 30.198,-246.312 58.646,-274.828C87.089,-303.344 126.958,-317.604 178.245,-317.604C218.604,-317.604 251.146,-307.583 275.88,-287.547C300.615,-267.505 315.292,-239.271 319.917,-202.839L225.536,-202.839C221.755,-215.167 215.625,-224.557 207.146,-231.005C198.667,-237.448 188.193,-240.672 175.724,-240.672C155.823,-240.672 140.938,-233.036 131.057,-217.76C121.177,-202.49 116.24,-179.714 116.24,-149.448C116.24,-121.422 121.214,-100.474 131.161,-86.599C141.109,-72.729 156.245,-65.792 176.563,-65.792C191.135,-65.792 203.505,-69.187 213.661,-75.984C223.823,-82.781 230.094,-91.927 232.474,-103.417L187.703,-103.417L187.703,-169.625L323.698,-169.625L323.698,0L259.589,0Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,2105.79,845.49)">
        <g>
          <path
            d="M110.141,0L110.141,-114.557L-3.781,-309.615L106.359,-309.615L159.75,-204.099L213.557,-309.615L320.755,-309.615L209.563,-114.557L209.563,0L110.141,0Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,917.769,986.112)">
        <g>
          <path
            d="M1,-90.521L20.271,-90.521L35.75,-26.687L48.854,-90.521L68.729,-90.521L81.833,-26.687L97.313,-90.521L116.458,-90.521L90.521,0L72.771,0L58.792,-69.375L44.688,0L26.938,0L1,-90.521Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1035.36,986.112)">
        <g>
          <path
            d="M32.229,-90.521L53.75,-90.521L86.354,0L66.854,0L60.688,-18.625L25.563,-18.625L19.646,0L0,0L32.229,-90.521ZM55.646,-33.875L43.063,-72.396L30.458,-33.875L55.646,-33.875Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1121.72,986.112)">
        <g>
          <path
            d="M9.313,-90.521L53.25,-90.521C63.151,-90.521 70.469,-88.422 75.208,-84.229C79.958,-80.031 82.333,-73.568 82.333,-64.833C82.333,-59.292 81.177,-54.672 78.875,-50.979C76.568,-47.297 73.234,-44.781 68.875,-43.437C72.818,-42.089 75.604,-39.964 77.229,-37.062C78.865,-34.172 79.813,-29.708 80.063,-23.667L80.438,-12.958L80.438,-12.583C80.531,-7.219 81.714,-3.948 83.979,-2.771L83.979,0L63.583,0C62.318,-2.344 61.604,-6.125 61.438,-11.333L61.188,-20.896C61.021,-24.672 60.51,-27.589 59.667,-29.646C58.833,-31.698 57.469,-33.146 55.583,-33.979C53.693,-34.823 51.068,-35.25 47.708,-35.25L27.958,-35.25L27.958,0L9.313,0L9.313,-90.521ZM49.854,-50.979C54.63,-50.979 58.13,-51.943 60.354,-53.875C62.589,-55.802 63.708,-58.823 63.708,-62.937C63.708,-70.922 59.38,-74.917 50.729,-74.917L27.958,-74.917L27.958,-50.979L49.854,-50.979Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1211.61,986.112)">
        <g>
          <path
            d="M9.313,-90.521L75.042,-90.521L75.042,-74.792L27.688,-74.792L27.688,-55.521L71,-55.521L71,-40.042L27.688,-40.042L27.688,-16.75L77.292,-16.75L77.292,0L9.313,0L9.313,-90.521Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1293.82,986.112)">
        <g>
          <path
            d="M9.313,-90.521L28.083,-90.521L28.083,-56.771L65.458,-56.771L65.458,-90.521L84.229,-90.521L84.229,0L65.458,0L65.458,-40.167L28.083,-40.167L28.083,0L9.313,0L9.313,-90.521Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1387.37,986.112)">
        <g>
          <path
            d="M49.604,2.521C40.615,2.521 32.813,0.568 26.188,-3.333C19.563,-7.234 14.443,-12.771 10.833,-19.937C7.219,-27.115 5.417,-35.531 5.417,-45.187C5.417,-54.839 7.219,-63.255 10.833,-70.437C14.443,-77.615 19.563,-83.156 26.188,-87.062C32.813,-90.964 40.615,-92.917 49.604,-92.917C58.589,-92.917 66.417,-90.964 73.083,-87.062C79.75,-83.156 84.885,-77.615 88.5,-70.437C92.109,-63.255 93.917,-54.839 93.917,-45.187C93.917,-35.531 92.109,-27.115 88.5,-19.937C84.885,-12.771 79.75,-7.234 73.083,-3.333C66.417,0.568 58.589,2.521 49.604,2.521ZM49.604,-13.729C57.656,-13.729 63.906,-16.469 68.354,-21.958C72.813,-27.458 75.042,-35.198 75.042,-45.187C75.042,-55.187 72.813,-62.927 68.354,-68.417C63.906,-73.917 57.656,-76.667 49.604,-76.667C41.63,-76.667 35.438,-73.917 31.021,-68.417C26.615,-62.927 24.417,-55.187 24.417,-45.187C24.417,-35.198 26.615,-27.458 31.021,-21.958C35.438,-16.469 41.63,-13.729 49.604,-13.729Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1486.7,986.112)">
        <g>
          <path
            d="M45.188,2.521C33.438,2.521 24.438,-0.437 18.188,-6.354C11.938,-12.271 8.813,-20.812 8.813,-31.979L8.813,-90.771L27.563,-90.771L27.563,-34C27.563,-27.026 28.964,-21.922 31.771,-18.687C34.589,-15.464 39.021,-13.854 45.063,-13.854C51.198,-13.854 55.672,-15.443 58.479,-18.625C61.281,-21.818 62.688,-26.943 62.688,-34L62.688,-90.771L81.333,-90.771L81.333,-31.979C81.333,-20.896 78.219,-12.375 72,-6.417C65.792,-0.458 56.854,2.521 45.188,2.521Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1576.97,986.112)">
        <g>
          <path
            d="M42.417,2.521C31.01,2.521 22.073,-0.125 15.604,-5.417C9.146,-10.708 5.667,-18.219 5.167,-27.958L23.542,-27.958C24.208,-23 26.219,-19.323 29.583,-16.937C32.943,-14.547 37.813,-13.354 44.188,-13.354C55.104,-13.354 60.563,-17.167 60.563,-24.792C60.563,-30.167 52.672,-34.698 36.896,-38.396L36.375,-38.521L34.375,-39.021C25.969,-40.865 19.969,-42.922 16.375,-45.187C13.177,-47.198 10.74,-49.948 9.063,-53.437C7.38,-56.922 6.542,-61.01 6.542,-65.708C6.542,-74.526 9.521,-81.281 15.479,-85.979C21.438,-90.687 30.083,-93.042 41.417,-93.042C52,-93.042 60.271,-90.542 66.229,-85.542C72.188,-80.552 75.333,-73.484 75.667,-64.333L57.792,-64.333C57.458,-68.693 55.755,-72.026 52.688,-74.333C49.615,-76.651 45.318,-77.812 39.792,-77.812C34.917,-77.812 31.151,-76.865 28.5,-74.979C25.859,-73.089 24.542,-70.38 24.542,-66.854C24.542,-62.062 29.667,-58.448 39.917,-56.021L46.458,-54.521C51.833,-53.172 56.531,-51.865 60.563,-50.604C62.99,-49.854 65.38,-48.802 67.729,-47.458C75.115,-43.427 78.813,-36.672 78.813,-27.187C78.813,-17.781 75.635,-10.479 69.292,-5.271C62.958,-0.073 54,2.521 42.417,2.521Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1660.94,986.112)">
        <g>
          <path
            d="M9.313,-90.521L75.042,-90.521L75.042,-74.792L27.688,-74.792L27.688,-55.521L71,-55.521L71,-40.042L27.688,-40.042L27.688,-16.75L77.292,-16.75L77.292,0L9.313,0L9.313,-90.521Z"
            style="fill: rgb(44, 75, 124); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1763.68,986.115)">
        <g>
          <path
            d="M49.438,2.521C40.688,2.521 32.948,0.568 26.229,-3.333C19.505,-7.25 14.313,-12.776 10.646,-19.917C6.99,-27.068 5.167,-35.312 5.167,-44.646C5.167,-54.312 7.031,-62.797 10.771,-70.104C14.521,-77.422 19.839,-83.052 26.729,-87C33.63,-90.958 41.656,-92.937 50.813,-92.937C61.823,-92.937 70.797,-90.031 77.729,-84.229C84.672,-78.437 88.651,-70.531 89.667,-60.521L67.854,-60.521C65.656,-69.271 59.729,-73.646 50.063,-73.646C43.589,-73.646 38.458,-71.073 34.667,-65.937C30.885,-60.812 29,-53.797 29,-44.896C29,-35.979 30.781,-29.083 34.354,-24.208C37.938,-19.333 42.964,-16.896 49.438,-16.896C54.646,-16.896 58.823,-18.151 61.979,-20.667C65.13,-23.193 67.089,-26.896 67.854,-31.771L89.417,-31.771C87.818,-20.937 83.526,-12.51 76.542,-6.5C69.568,-0.484 60.531,2.521 49.438,2.521Z"
            style="fill: rgb(248, 152, 56); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1857.63,986.115)">
        <g>
          <path
            d="M50.563,2.521C41.313,2.521 33.281,0.589 26.479,-3.271C19.672,-7.146 14.417,-12.672 10.708,-19.854C7.01,-27.047 5.167,-35.521 5.167,-45.271C5.167,-55.021 7.01,-63.484 10.708,-70.667C14.417,-77.859 19.672,-83.385 26.479,-87.25C33.281,-91.125 41.313,-93.062 50.563,-93.062C59.813,-93.062 67.859,-91.125 74.708,-87.25C81.568,-83.385 86.865,-77.839 90.604,-70.604C94.339,-63.38 96.208,-54.937 96.208,-45.271C96.208,-35.604 94.339,-27.151 90.604,-19.917C86.865,-12.693 81.568,-7.146 74.708,-3.271C67.859,0.589 59.813,2.521 50.563,2.521ZM50.563,-16.646C57.63,-16.646 63.052,-19.104 66.833,-24.021C70.609,-28.937 72.5,-36.021 72.5,-45.271C72.5,-54.437 70.609,-61.5 66.833,-66.458C63.052,-71.417 57.63,-73.896 50.563,-73.896C43.505,-73.896 38.104,-71.437 34.354,-66.521C30.615,-61.604 28.75,-54.521 28.75,-45.271C28.75,-36.021 30.615,-28.937 34.354,-24.021C38.104,-19.104 43.505,-16.646 50.563,-16.646Z"
            style="fill: rgb(248, 152, 56); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,1958.89,986.115)">
        <g>
          <path
            d="M8.958,-90.667L40.854,-90.667L52.833,-46.542C54.594,-40.151 56.318,-32.792 58,-24.458C59.344,-30.927 61.365,-39.13 64.063,-49.062L75.292,-90.667L107.188,-90.667L107.188,0L86,0L86,-40.229C86,-44.255 86.297,-54.13 86.896,-69.854L69.479,0L46.417,0L29.375,-69.854C29.969,-51.021 30.271,-38.75 30.271,-33.042L30.271,0L8.958,0L8.958,-90.667Z"
            style="fill: rgb(248, 152, 56); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,2075.16,986.115)">
        <g>
          <path
            d="M8.958,-90.667L50.438,-90.667C60.948,-90.667 68.958,-88.25 74.458,-83.417C79.969,-78.583 82.729,-71.542 82.729,-62.292C82.729,-52.625 80.01,-45.229 74.583,-40.104C69.167,-34.979 61.375,-32.417 51.208,-32.417L32.292,-32.417L32.292,0L8.958,0L8.958,-90.667ZM47.667,-50.687C51.875,-50.687 54.964,-51.568 56.938,-53.333C58.906,-55.109 59.896,-57.885 59.896,-61.667C59.896,-68.802 55.734,-72.375 47.417,-72.375L32.292,-72.375L32.292,-50.687L47.667,-50.687Z"
            style="fill: rgb(248, 152, 56); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,2162.05,986.115)">
        <g>
          <path
            d="M9.458,-90.667L32.792,-90.667L32.792,-19.667L76.667,-19.667L76.667,0L9.458,0L9.458,-90.667Z"
            style="fill: rgb(248, 152, 56); fill-rule: nonzero"
          />
        </g>
      </g>
      <g transform="matrix(1,0,0,1,2239.35,986.115)">
        <g>
          <path
            d="M30.146,-34.167L-1.271,-90.667L24.833,-90.667L41.479,-54.104L58.125,-90.667L84.354,-90.667L52.708,-34.167L52.708,0L30.146,0L30.146,-34.167Z"
            style="fill: rgb(248, 152, 56); fill-rule: nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
