<template>
  <div
    v-if="!active"
    class="w-full p-2 pl-5 bg-inherit rounded-lg mt-2 relative"
    :class="{
      'brightness-95 text-gray-400': !isMyComment,
      'brightness-95': isMyComment
    }"
  >
    <div
      class="absolute top-0 left-0 w-1 h-full rounded-l"
      :style="`background-color: ${replyToColor}; ${
        isMyComment ? 'filter: brightness(1.1) !important;' : ''
      }`"
    ></div>
    <div class="flex w-full justify-between items-center gap-2 mb-1">
      <p
        :style="`color: ${replyToColor}; filter: brightness(${
          !isMyComment ? '1.1' : '0.98'
        }) !important;`"
        class="text-xs font-bold"
      >
        {{
          replyTo.attributes.creator.data.id === user.id ? 'You' : replyToUser
        }}
      </p>
      <div>
        <IconPack type="ArrowUturnLeft" class="w-3.5 h-3.w-3.5" />
        <ContactCard
          icononly
          @bg-color="setBackgroundColor"
          :contact="replyTo.attributes.creator.data.attributes"
          class="w-0 h-0 invisible"
        />
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <div v-if="replyToAttachmentCount > 0">
        <div class="text-xs flex items-center gap-1">
          <IconPack
            solid
            :type="
              replyToAttachmentType === 'pdf' ||
              replyToAttachmentType === 'template_document'
                ? 'Document'
                : 'Camera'
            "
            class="w-4 h-4"
          />
          <p>
            {{
              replyToAttachmentType === 'pdf' ||
              replyToAttachmentType === 'template_document'
                ? 'Document'
                : 'Photo'
            }}
          </p>
          <p v-if="replyToAttachmentCount > 1">x{{ replyToAttachmentCount }}</p>
        </div>
      </div>
      <p class="text-xs">{{ replyTo.attributes.comment_text }}</p>
    </div>
  </div>

  <transition name="slide-up" mode="out-in" appear>
    <div v-if="active" class="flex items-center gap-2 mb-2">
      <div
        class="w-full p-2 pl-5 bg-inherit rounded-lg mt-2 relative bg-white brightness-95 text-gray-400"
      >
        <div
          class="absolute top-0 left-0 w-1 h-full rounded-l"
          :style="`background-color: ${replyToColor};`"
        ></div>
        <div
          class="flex w-full justify-between brightness-90 items-center gap-2 mb-1"
        >
          <p :style="`color: ${replyToColor};`" class="text-xs font-bold">
            {{
              replyTo.attributes.creator.data.id === user.id
                ? 'You'
                : replyToUser
            }}
          </p>
          <div>
            <IconPack type="ArrowUturnLeft" class="w-3.5 h-3.w-3.5" />
            <ContactCard
              icononly
              @bg-color="setBackgroundColor"
              :contact="replyTo.attributes.creator.data.attributes"
              class="w-0 h-0 invisible"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div v-if="replyToAttachmentCount > 0">
            <div class="text-xs flex items-center gap-1">
              <IconPack
                solid
                :type="
                  replyToAttachmentType === 'pdf' ||
                  replyToAttachmentType === 'template_document'
                    ? 'Document'
                    : 'Camera'
                "
                class="w-4 h-4"
              />
              <p>
                {{
                  replyToAttachmentType === 'pdf' ||
                  replyToAttachmentType === 'template_document'
                    ? 'Document'
                    : 'Photo'
                }}
              </p>
              <p v-if="replyToAttachmentCount > 1">
                x{{ replyToAttachmentCount }}
              </p>
            </div>
          </div>
          <p class="text-xs">{{ replyTo.attributes.comment_text }}</p>
        </div>
      </div>

      <div
        @click="removeReplyTo"
        class="w-6 h-6 p-1 rounded-full flex items-center cursor-pointer justify-center border-2 border-gray-200 hover:bg-gray-200 transition-all duration-300"
      >
        <IconPack type="XMark" class="text-gray-400" />
      </div>
    </div>
  </transition>
</template>

<script>
import ContactCard from '@/components/Contacts/ContactCard.vue';

export default {
  name: 'ReplyCard',
  components: {
    ContactCard
  },
  emits: ['remove-reply-to'],
  props: {
    replyTo: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      replyToColor: ''
    };
  },
  computed: {
    replyToUser() {
      return this.replyTo.attributes.creator.data.attributes.first_name;
    },
    isMyComment() {
      return this.replyTo.attributes.creator.data.id === this.user.id;
    },
    replyToAttachmentCount() {
      return this.replyTo.attributes.job_comment_attachments.data.length;
    },
    replyToAttachmentType() {
      return this.replyTo.attributes.job_comment_attachments.data[0].attributes
        .file_type;
    }
  },
  methods: {
    setBackgroundColor(color) {
      this.replyToColor = color;
    },
    removeReplyTo() {
      this.$emit('remove-reply-to');
    }
  }
};
</script>

<style lang="scss" scoped></style>
