import { createRouter, createWebHistory } from 'vue-router';
import AdminRoutes from '@/router/Admin/';
import AppRoutes from '@/router/App/';
import ConnectRoutes from '@/router/Connect'
import store from '@/store';
import axios from 'axios';

let subdomain = window.location.host.split('.')[0];

let routes;

if (subdomain != 'www' && subdomain != 'localhost:3000') {
  if (subdomain === 'admin') {
    routes = AdminRoutes;
  } else {
    routes = AppRoutes.concat(ConnectRoutes)
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {

  if (to.query.jwt) {
    localStorage.setItem('token', to.query.jwt);
    console.log('jwt', to.query.jwt);
    await axios
      .get(
        import.meta.env.VITE_API_URL + '/users/me?populate=role',
        {
          headers: {
            Authorization: `Bearer ${to.query.jwt}`
          }
        }
      )
      .then((response) => {
        console.log('from router', response.data);
        //set store state for token
        store.commit('Authentication/SET_USER', response.data);
        store.commit('Authentication/AUTH_SUCCESS', { jwt: to.query.jwt, user: response.data, refresh: '' });
      });


  }

  document.title = to.meta.documentTitle || to.name + ' | Energy Warehouse';
  window.scrollTo(0, 0);
  // if route requires auth, check that user is logged in
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // console.log('requires auth');
    if (store.getters['Authentication/isLoggedIn']) {
      // load some things

    } else {
      to.fullPath == '/dashboard'
        ? next('login')
        : next(`/login?return_to=${to.fullPath}`);
    }
  }

  if (to.name != 'Inbox') {
    const user = JSON.parse(localStorage.getItem('user'));
    const lastUpdate = localStorage.getItem('lastInboxUpdate');
    if (user && lastUpdate) {
      debounce(() => {
        // store.dispatch('InboxItems/getInboxItems', { id: user?.id, lastUpdate: lastUpdate });
      }, 500);
    }
  }

  // print to page meta
  if (to.meta.requiresDesktopPlan) {
    let company = await store.getters['Company/company'];
    if (!company) {
      await store.dispatch('Company/getCompany');
    }

    if (company?.attributes.plan_code === 'BASIC') {
      next('/company');
    }
  }




  next();
});


let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};
export default router;
