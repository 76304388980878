<template>
  <AppCard
    titleType="base"
    v-if="question"
    class="border-none !bg-transparent flex justify-start !w-fit !shadow-none cursor-auto break-inside-avoid"
  >
    <div
      class="w-full flex flex-wrap break-after-avoid"
      :class="{ 'justify-center': question.type == 'image' }"
    >
      <p
        class="font-bold text-primary-600 break-after-avoid"
        :class="{
          'text-xl break-before-auto': question.type === 'heading',
          'mb-2': question.type === 'heading' && question.description,
          'w-80 text-center': question.type === 'image',
          '!text-sm': question.multi_input,
          'bg-primary-500 break-after-avoid break-before-auto w-full font-normal py-0.5 px-2  text-white rounded-md':
            question.type === 'image' && question.title
        }"
      >
        {{ question.title }}
      </p>
      <div
        v-if="question.show_na && isNA"
        @click="toggleNA"
        class="ml-2 px-2 py-1 text-xs font-semibold rounded-full"
        :class="{
          'bg-gray-200 text-gray-700': !isNA,
          'bg-secondary-500 text-white': isNA
        }"
      >
        N/A
      </div>
    </div>

    <p class="text-sm text-gray-500 break-after-avoid">
      <span
        v-if="question.type === 'heading'"
        v-html="question?.description?.replace(/(?:\r\n|\r|\n)/g, '<br>')"
      ></span>
      <span
        v-else-if="question.type != 'image' && question.type != 'credential'"
        >{{ question.description }}</span
      >
    </p>

    <hr
      class="mt-2 w-screen break-after-avoid max-w-[180mm]"
      v-if="question.type === 'heading'"
    />

    <transition name="scale-quick" mode="out-in" appear>
      <div class="w-full flex" v-if="!isNA">
        <!-- Select type -->
        <div v-if="question.type === 'select'">
          <div class="flex flex-wrap items-center w-full gap-5 mt-2">
            <div
              v-for="option in question.options"
              :key="option.key"
              @click="if (!disabled) localAnswer = option.key;"
              class="flex items-center p-1.5 pr-2.5 rounded-md border"
              :class="{ 'cursor-pointer': !disabled }"
            >
              <div class="border rounded-md w-6 h-6">
                <IconPack
                  v-if="localAnswer == option.key"
                  type="XMark"
                  class="w-4 h-5 m-auto stroke-[3]"
                />
              </div>

              <label
                :for="question.key + option.key"
                class="ml-1.5 flex-nowrap text-sm"
                :class="{ 'cursor-pointer': !disabled }"
              >
                {{ option.title }}
              </label>
            </div>

            <div v-if="question.other">
              <div
                @click="if (!disabled) localAnswer = 'other';"
                class="flex items-center border rounded-md p-2"
              >
                <div class="border rounded-md w-6 h-6">
                  <IconPack
                    v-if="localAnswer == 'other'"
                    type="XMark"
                    class="w-5 h-5 m-auto stroke-[3]"
                  />
                </div>
                <label
                  :for="question.key + 'other'"
                  class="ml-1.5 text-sm"
                  :class="{ 'cursor-pointer': !disabled }"
                >
                  Other
                </label>
              </div>
            </div>
          </div>
          <transition name="scale-quick" mode="out-in" appear>
            <TextInput
              v-if="localAnswer == 'other'"
              v-model="otherValue"
              placeholder="Enter other value"
              :disabled="disabled"
            />
          </transition>
        </div>

        <!-- Text type -->
        <div v-else-if="question.type === 'text' && !question.multi_input">
          <p
            class="px-4 py-2 rounded-md"
            :class="{ border: !question.multi_line && localAnswer }"
          >
            {{ localAnswer }}
          </p>
        </div>

        <!-- Number type -->
        <div v-else-if="question.type === 'number'">
          <div class="!w-fit flex items-center">
            <TextInput
              class="!w-fit"
              v-model="localAnswer"
              :icon="icon"
              type="number"
              :min="question.validation.min"
              :max="question.validation.max"
              :step="question.validation.step"
              :disabled="disabled"
            >
              <template v-if="question.unit" #iconRight>
                <span class="text-gray-500">{{ question.unit }}</span>
              </template>
            </TextInput>
          </div>
        </div>

        <!-- Date type -->
        <div v-else-if="question.type === 'date'" class="!z-50">
          <p class="border py-2 px-3 rounded-md">
            {{ moment(localAnswer).format('DD MMMM YYYY') }}
          </p>
        </div>

        <!-- Credential type -->
        <div v-else-if="question.type === 'credential'">
          <div class="flex flex-col mt-2 w-full gap-4">
            <!-- <SelectCredential v-model="localAnswer" :disabled="disabled" /> -->

            <div v-if="selectedCredential" class="w-full">
              <CredentialCard v-model="selectedCredential" />
            </div>
          </div>
        </div>

        <!-- Certificate number type -->
        <div v-else-if="question.type === 'certificate_number'">
          <p class="border py-2 px-3 rounded-md">{{ localAnswer }}</p>
        </div>

        <!-- image type -->
        <div class="mt-1 mx-auto flex" v-else-if="question.type === 'image'">
          <div
            class="border rounded-lg relative max-w-[50mm]"
            :class="{ 'break-before-avoid': questionNumber < 2 }"
          >
            <AdditionalDocCard
              size="large"
              v-if="localAnswer"
              @open-image-carousel="$emit('openImageCarousel', $event)"
              :document="{ file_type: 'image', url: localAnswer }"
            />
            <p
              class="absolute hidden bottom-2 right-2 w-fit mx-auto px-3 py-1 bg-green-500 rounded-full text-xs font-semibold text-white"
            >
              {{
                moment(new Date(answer?.attributes?.updatedAt)).format(
                  'DD MMM HH:mm'
                )
              }}
            </p>
          </div>
        </div>

        <!-- Product type -->
        <div v-else-if="question.type === 'product'">
          <div
            v-if="localAnswer && localAnswer.length > 0"
            class="flex items-center mt-4"
          >
            <img
              :src="productImage"
              class="object-scale-down"
              :class="{
                'w-28 h-36': question.product_category == 2,
                'w-36 h-28': question.product_category != 2
              }"
            />
            <div class="flex flex-wrap items-center gap-2">
              <p
                v-for="spec in selectedProduct?.attributes
                  .product_specifications?.data"
                :key="spec.id"
                class="text-sm text-gray-500 my-auto flex flex-col ml-8"
              >
                <span class="font-bold">
                  {{
                    spec?.attributes?.product_specification_type?.data
                      ?.attributes?.name
                  }}
                </span>
                <span>
                  {{ spec?.attributes?.value }}
                </span>
              </p>
            </div>
          </div>
          <div class="mt-1 text-sm text-gray-500" v-else>
            No product selected
          </div>
        </div>

        <!-- Signature type -->
        <div v-else-if="question.type === 'signature'">
          <div class="flex items-end mt-2 gap-4">
            <p class="ml-2 text-sm text-gray-500">Signature:</p>
            <img
              :src="localAnswer"
              class="w-[50mm] px-10 border-b-2 border-black"
            />
          </div>
        </div>
      </div>
    </transition>
  </AppCard>
</template>

<script>
import CredentialCard from '../../Credentials/CredentialCard.vue';
import AdditionalDocCard from '../AdditionalDocCard.vue';

export default {
  name: 'ReadOnlyQuestionCard',
  components: {
    CredentialCard,
    AdditionalDocCard
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    answer: {
      type: [String, Number, Array, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    questionNumber: {
      type: Number,
      default: 0
    }
  },
  emits: ['openImageCarousel'],
  async mounted() {
    if (this.question.type === 'date') {
      this.dateRange.startDate = new Date(this.answer?.attributes?.value?.data);
    }
    if (this.question.type === 'select') {
      if (this.question.other) {
        if (
          !this.question.options.find(
            (option) => option.key === this.answer?.attributes?.value?.data
          ) &&
          !!this.answer?.attributes?.value?.data
        ) {
          this.localAnswer = 'other';
          this.otherValue = this.answer?.attributes?.value.data;
        }
      }
    }
    if (this.localAnswer === 'N/A') this.isNA = true;
  },
  data() {
    return {
      localAnswer: this.answer?.attributes?.value?.data,
      isNA: false,
      otherValue: '',
      dateRange: {
        startDate: null,
        endDate: null
      },
      overwriteCertificate: false
    };
  },
  computed: {
    icon() {
      switch (this.question.type) {
        case 'select':
          return 'Check';
        case 'text':
          return 'DocumentText';
        case 'number':
          return 'Calculator';
        case 'date':
          return 'Calendar';
        case 'credential':
          return 'Identification';
        // Add more cases for other question types
        default:
          return 'QuestionMarkCircle';
      }
    },
    products() {
      return this.$store.getters['Products/products'];
    },
    categories() {
      return this.$store.getters['Products/productCategories'];
    },
    productImage() {
      return this.selectedProduct?.attributes?.main_image
        ? this.selectedProduct?.attributes?.main_image
        : this.selectedProduct?.attributes?.brand_image
        ? this.selectedProduct?.attributes?.brand_image
        : null;
    },
    credentials() {
      return this.$store.getters['Credentials/credentials'];
    },
    selectedCredential() {
      if (this.localAnswer && this.credentials)
        return this.credentials.find(
          (credential) => credential.id === this.localAnswer[0]?.id
        );
      return null;
    },
    selectedProduct() {
      if (this.answer && this.products)
        return this.products.find(
          (product) => product.id === this.answer[0]?.id
        );
      return null;
    }
  },
  methods: {
    toggleNA() {
      if (this.disabled) return;
      this.isNA = !this.isNA;
      if (this.isNA) {
        this.localAnswer = null;
      }
    },
    clearDates() {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
    },
    createImages(images) {
      this.localAnswer = images;
    }
  },
  watch: {
    answer: {
      handler(val) {
        if (
          this.question.type === 'credential' ||
          this.question.type === 'product' ||
          this.question.multi_input
        ) {
          this.localAnswer = val;
          this.isNA = val === 'N/A';
          return;
        }
        this.localAnswer = val?.attributes?.value?.data;
        this.isNA = val?.attributes?.value?.data === 'N/A';
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
DateRangePicker,
SearchableDropdown {
  z-index: 9999 !important;
}
</style>
