import ContactList from '@/views/App/Contacts/ContactList.vue';

export default [
    {
        meta: { layout: 'dashboard', requiresAuth: true, requiresDesktopPlan: true },
        path: '/contacts',
        name: 'Contacts',
        component: ContactList,
        title: 'Contacts',
    }
]