<template>
  <transition name="scale-quick" mode="out-in" appear>
    <div
      v-show="iosImageLoaded && androidImageLoaded"
      class="flex flex-row justify-center flex-wrap"
    >
      <a href="https://apps.apple.com/app/energy-warehouse/id6443489832">
        <img
          @load="iosImageLoaded = true"
          class="h-12 m-1 hover:-translate-y-1 hover:shadow-md transition-all duration-300 ease-in-out cursor-pointer"
          src="https://energy-warehouse.s3.af-south-1.amazonaws.com/website/apple-store.svg"
      /></a>
      <a
        href="https://play.google.com/store/apps/details?id=com.energy.warehouse.app"
      >
        <img
          @load="androidImageLoaded = true"
          class="h-12 m-1 hover:-translate-y-1 hover:shadow-md transition-all duration-300 ease-in-out cursor-pointer"
          src="https://energy-warehouse.s3.af-south-1.amazonaws.com/website/playstore.svg"
      /></a></div
  ></transition>
</template>
<script>
export default {
  data() {
    return {
      iosImageLoaded: false,
      androidImageLoaded: false
    };
  }
};
</script>
