import axios from 'axios';

export default {
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      delete axios.defaults.headers.common['Authorization'];
      axios
        .post(import.meta.env.VITE_API_URL + '/auth/local/', payload, {
          headers: {}
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            axios.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${response.data.jwt}`;
            commit('AUTH_SUCCESS', response.data);

            axios
              .get(
                import.meta.env.VITE_API_URL + '/users/me?populate=role',
                payload,
                {
                  headers: {
                    Authorization: `Bearer ${response.data.jwt}`
                  }
                }
              )
              .then((response) => {
                console.log(response.data);
                commit('SET_USER', response.data);
                resolve(response);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  register({ commit }, payload) {
    return new Promise((resolve, reject) => {
      delete axios.defaults.headers.common['Authorization'];
      axios
        .post(import.meta.env.VITE_API_URL + '/auth/local/register', payload, {
          headers: {}
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            commit('AUTH_SUCCESS', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  forgotPassword({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      delete axios.defaults.headers.common['Authorization'];
      axios
        .post(import.meta.env.VITE_API_URL + '/auth/forgot-password', payload, {
          headers: {}
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            // axios.defaults.headers.common[
            //   'Authorization'
            // ] = `Bearer ${response.data.jwt}`;
            // commit('AUTH_SUCCESS', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  resetPassword({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      delete axios.defaults.headers.common['Authorization'];
      axios
        .post(import.meta.env.VITE_API_URL + '/auth/reset-password/', payload, {
          headers: {}
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            // axios.defaults.headers.common[
            //   'Authorization'
            // ] = `Bearer ${response.data.jwt}`;
            // commit('AUTH_SUCCESS', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  getUserProfile({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          import.meta.env.VITE_API_URL +
          '/users/me?populate=pending_join_requests,role',
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['Authentication/token']
            }
          }
        )
        .then((response) => {
          console.log('getUserProfile', response);
          if (response.status === 200) {
            commit('SET_USER', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          // if 401, logout

          if (error.response.status === 401) {
            commit('LOG_OUT');
          }

          reject(error);
        });
    });
  },
  updateUserProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(import.meta.env.VITE_API_URL + '/users/' + payload.id, payload)
        .then((response) => {
          console.log('updateUserProfile', response);
          if (response.status === 200) {
            commit('SET_USER', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log('updateUserProfile', error.response);
          reject(error);
        });
    });
  },



  deleteAccount({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(import.meta.env.VITE_API_URL + '/auth/delete-account/', {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {


          console.log(error);
          reject(error);
        });
    });
  },
};
