<template>
  <div class="w-full flex flex-col">
    <div
      class="flex w-screen max-w-[210mm] bg-white -mt-5 z-[60] flex-col gap-8 mb-8"
    >
      <img
        :src="company?.attributes?.logo"
        :alt="company?.attributes?.name"
        class="w-60 h-fit mx-auto"
      />
      <div class="flex justify-center">
        <div class="w-[40%]">
          <LocationCard
            open
            hide-actions
            v-if="job?.location"
            :location="job?.location?.attributes"
            :title="job?.title"
            class="!shadow-none !bg-white shrink-0 !border-none"
          >
            <template v-if="job?.reference_number" #reference_number>
              {{ job?.reference_number }}
            </template>
          </LocationCard>
        </div>

        <div class="mt-5">
          <ContactCard
            open
            hide-actions
            class="!shadow-none !h-fit !w-fit"
            :contact="job?.client?.attributes"
          />
        </div>
      </div>
    </div>
    <div class="w-full flex justify-center">
      <AppTitle type="large" class="!w-fit mb-4">{{
        documentType?.attributes?.name
      }}</AppTitle>
    </div>
    <div class="w-full flex flex-col">
      <div v-for="item in documentStructure" :key="item.key">
        <AppCard
          id="section"
          titleType="medium"
          class="w-full max-w-screen-lg mx-auto border-none !bg-transparent !shadow-none"
        >
          <div class="w-full">
            <AppTitle
              ref="title"
              type="medium"
              class="!text-primary-500 break-after-avoid bg-gray-100 mt-6 border-gray-200 border text-center rounded-md"
            >
              {{ item.title }}
            </AppTitle>
          </div>

          <div class="grid grid-cols-3 items-start gap-2">
            <div
              id="question"
              ref="question"
              class="items-center my-1"
              :class="{
                'col-span-3':
                  (question.type !== 'image' &&
                    question.type !== 'credential' &&
                    question.type !== 'date' &&
                    question.type !== 'certificate_number') ||
                  question.multi_input,
                'flex justify-center': question.type === 'image'
              }"
              v-for="(question, j) in item.questions"
              :key="question.key"
            >
              <div v-if="question.multi_input" class="w-full">
                <AppTitle
                  v-if="
                    getQuestionValues(question)?.attributes?.value?.data.some(
                      (value) => value !== 'N/A'
                    )
                  "
                  type="base"
                  class="text-white bg-secondary-400 w-[89%] mt-4 border border-secondary-500 mx-auto text-center rounded-md"
                >
                  {{ question.title }}
                </AppTitle>
                <div class="flex flex-wrap justify-center">
                  <div
                    class="w-1/3 flex justify-center"
                    :class="{
                      'max-w-md': question.type === 'image'
                    }"
                    v-for="(questionValue, i) in getQuestionValues(question)
                      ?.attributes?.value.data"
                    :key="question.key + '-' + i"
                  >
                    <A4QuestionCard
                      :question-number="j"
                      @open-image-carousel="
                        () => {
                          $refs.carousel.index = i;
                          images = getQuestionValues(
                            question
                          )?.attributes?.value?.data?.map((value) => ({
                            url: value,
                            label: question.title
                          }));
                          showImageCarousel = true;
                        }
                      "
                      :answer="
                        getQuestionValues(question)?.attributes?.value?.data[i]
                      "
                      :question="{
                        ...question,
                        title: question.multi_input_label[i]
                      }"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <A4QuestionCard
                :question-number="j"
                v-else
                @open-image-carousel="
                  (showImageCarousel = true),
                    (images = [{ url: $event.url, label: question.title }]),
                    ($refs.carousel.index = 0)
                "
                :answer="getQuestionValues(question)"
                :question="question"
              />
            </div>
          </div>

          <!-- <p v-else type="base" class="text-center p-4">No data available</p> -->
        </AppCard>
      </div>
    </div>
  </div>

  <div class="footer">
    <p class="text-xs">
      {{ company?.attributes?.name }} | {{ company?.attributes?.email }} |
      {{ company?.attributes?.contact_number }}
    </p>
  </div>

  <ImageCarouselModal
    readonly
    ref="carousel"
    v-model="showImageCarousel"
    :images="images"
  />
</template>

<script>
import ContactCard from '../../../components/Contacts/ContactCard.vue';
import A4QuestionCard from '../../../components/Documents/Templates/A4QuestionCard.vue';
import LocationCard from '../../../components/Location/LocationCard.vue';
import ImageCarouselModal from '../../../components/Modals/ImageCarouselModal.vue';

export default {
  name: 'DocumentDetails',
  components: {
    A4QuestionCard,
    ImageCarouselModal,
    LocationCard,
    ContactCard
  },
  props: {
    uid: {
      type: String,
      required: true
    }
  },
  async beforeMount() {
    const removeHelpKit = () => {
      const helpKit = document.getElementById('helpkit-widget');
      if (helpKit) helpKit.remove();
      const helpKitButton = document.getElementById(
        'helpkit-launcherButton--ew'
      );
      if (helpKitButton) helpKitButton.remove();
    };

    removeHelpKit();

    // If the script is being re-injected by some process, keep checking and removing
    const intervalId = setInterval(() => {
      if (!document.getElementById('helpkit-widget')) {
        clearInterval(intervalId);
      } else {
        removeHelpKit();
      }
    }, 500);
  },

  async mounted() {
    const helpKit = document.getElementById('helpkit-widget');
    if (helpKit) await helpKit.remove();
    const helpKitButton = document.getElementById('helpkit-launcherButton--ew');
    if (helpKitButton) await helpKitButton.remove();
    try {
      if (this.uid) {
        await this.$store.dispatch('Company/getCompany');
        await this.$store.dispatch('Documents/getDocumentById', this.uid);
        await this.$store.dispatch('Products/getProducts');
        await this.$store.dispatch('Products/getProductCategories');
        await this.$store.dispatch('Credentials/getCredentials');
        await this.$store.dispatch(
          'Jobs/getJobById',
          this.document?.attributes?.job?.data?.attributes?.uid
        );
        this.contentHeight = document.body.scrollHeight;
      }
    } catch (error) {
      console.error('Failed to load data:', error);
    }
  },
  data() {
    return {
      showImageCarousel: false,
      images: [],
      type: '',
      contentHeight: 0,
      currentPage: 1
    };
  },
  computed: {
    job() {
      return this.$store.getters['Jobs/jobById'];
    },
    document() {
      return this.$store.getters['Documents/currentDocument'];
    },
    windowHeight() {
      return window.innerHeight;
    },
    documentType() {
      return this.document?.attributes?.document_type?.data;
    },
    documentStructure() {
      return this.documentType?.attributes?.structure;
    },
    documentValues() {
      return this.document?.attributes?.document_values?.data;
    },
    products() {
      return this.$store.getters['Products/products'];
    },
    credentials() {
      return this.$store.getters['Credentials/credentials'];
    },
    dpi() {
      return window.devicePixelRatio * 96;
    },
    pageCount() {
      const page = Math.ceil(this.pxToMm(this.contentHeight) / 297);
      return this.pxToMm(this.contentHeight) % 297 > 0 ? page + 1 : page;
    }
  },
  methods: {
    pxToMm(px) {
      return px * 0.2645833333;
    },
    getComponentName(type) {
      const components = {
        select: 'select',
        text: 'TextInput',
        date: 'input',
        credential: 'input'
      };
      return components[type] || null;
    },
    getSectionValues(section) {
      return this.documentValues?.filter(
        (value) => value.attributes.section === section
      );
    },
    getQuestionValues(question) {
      const value = this.documentValues?.filter(
        (value) => value.attributes.key === question.key
      );

      if (question.type === 'product') {
        return this.mapProductValue(value);
      }
      if (question.type === 'credential') {
        return this.mapCredentialValue(value);
      }

      return value[0];
    },
    mapProductValue(value) {
      if (this.products) {
        const product = this.products.find(
          (product) => product.id === value[0]?.attributes.value.data
        );

        return !product
          ? value[0]?.attributes.value.data
          : [
              {
                id: product.id,
                name: product.attributes.name,
                main_image: product.attributes.main_image,
                brand_image: product.attributes.brand_image
              }
            ];
      }
    },
    mapCredentialValue(value) {
      if (this.credentials) {
        const credential = this.credentials.find(
          (credential) => credential.id === value[0]?.attributes.value.data
        );
        return !credential
          ? value[0]?.attributes.value.data
          : [
              {
                id: credential.id,
                name:
                  credential.attributes.first_name +
                  ' ' +
                  credential.attributes.last_name,
                description:
                  credential.attributes.registration_number +
                  ' - ' +
                  credential.attributes.registration_type
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
              }
            ];
      }
    }
  }
};
</script>
