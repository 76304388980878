export default {
  contacts: (state) => {
    return state.contacts;
  },
  contactNames: (state) => {
    return state.contactNames;
  },
  contactById: (state) => (id) => {
    return state.contacts?.find((contact) => contact.uid === id);
  },
  fetching: (state) => {
    return state.fetching;
  },
};
