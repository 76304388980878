<template>
  <transition name="scale-quick" mode="out-in" appear>
    <div key="spinner" class="self-center scrollbar-hide h-screen flex" v-if="showSkeleton">
      <AppSpinner class="h-20 text-primary-500 self-center" />
    </div>

    <div v-else key="subscriptionPlans" class="flex flex-col w-full">
      <div class="w-full">
        <AppTitle type="large" class="text-center mb-0">Cancel Subscription</AppTitle>
        <div class="w-full text-center mb-4 max-w-lg m-auto">
          Are you sure you want to cancel your subscription? You will no longer
          have access to the Energy Warehouse platform.
        </div>

        <CancellationReasonSelector @reason-selected="(event) => (cancellationReason = event)" />
      </div>

      <div class="flex flex-row max-w-md flex-wrap w-full mx-auto my-5 px-8">
        <router-link to="/subscription" class="w-full lg:w-1/2 p-1">
          <AppButton>
            <IconPack type="ArrowLeft" class="w-5 h-5 mr-2" /> Back
          </AppButton>
        </router-link>

        <div class="w-full lg:w-1/2 p-1">
          <AppButton @click="cancelSubscription()" :pulse="submitting" color="red">Confirm Cancellation</AppButton>
        </div>
      </div>
      <div class="w-full text-center mb-4 max-w-lg m-auto opacity-50 text-sm">
        You may be charged any outstanding amounts on your account.
      </div>
    </div>
  </transition>
</template>
<script>
import CancellationReasonSelector from '@/components/Subscriptions/CancellationReasonSelector.vue';

export default {
  components: {
    CancellationReasonSelector
  },
  data() {
    return {
      submitting: false,
      cancellationReason: ''
    };
  },
  computed: {
    showSkeleton() {
      return !this.company;
    },
    company() {
      return this.$store.getters['Company/company'];
    }
  },
  watch: {
    company: function (val) {
      if (!val?.id) return;

      if (val.attributes?.subscription_status === 'cancelled') {
        this.$router.push('/subscription');
      }
    }
  },
  mounted() {
    this.$store.dispatch('Company/getCompany');
  },
  methods: {
    cancelSubscription() {
      this.submitting = true;
      this.$store
        .dispatch('Company/updateCompany', {
          id: this.company.id,
          subscription_status: 'cancelled',
          cancellation_reason: this.cancellationReason
        })
        .then(() => {
          this.submitting = false;
          this.$toast.success('Subscription cancelled');
        })
        .catch(() => {
          this.submitting = false;
          this.$toast.error('Error cancelling subscription, contact support');
        });
    }
  }
};
</script>
