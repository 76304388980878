<template>
  <div class="max-w-[300px] h-screen w-full border-r p-7 bg-white">
    <div v-if="hasBack" class="text-primary-600">
      <AppButton @click="goBack" outline class="w-max mb-4"
        ><IconPack type="ArrowLeft" class="w-4 h-4 mr-2" />Back</AppButton
      >
    </div>
    <div class="h-3/4 overflow-y-scroll scrollbar-hide">
      <div
        v-if="!hasSlot"
        class="flex flex-wrap items-start space-y-4 text-gray-700 font-medium"
      >
        <SideBarItem to="/" selected icon="Home">Dashboard</SideBarItem>
        <SideBarItem to="/documents/templates" icon="Clipboard"
          >Document Templates</SideBarItem
        >
        <SideBarItem to="/settings" icon="Cog">Settings</SideBarItem>
      </div>
      <div v-else>
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import SideBarItem from './SidebarItem.vue';
export default {
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
    hasBack() {
      return this.$route.name !== 'Dashboard';
    }
  },
  components: {
    SideBarItem
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
};
</script>
