<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="flex w-full">
    <div
      class="w-full xl:px-16 max-w-screen-2xl relative"
      :class="{ 'xl:px-2': showComments }"
      :style="showComments ? 'width: calc(100% - 520px) !important' : ''"
    >
      <transition name="scale-quick" mode="out-in" appear>
        <div v-if="!loading" class="w-full flex justify-between">
          <div
            v-if="job"
            @click="$router.back()"
            class="flex gap-2 cursor-pointer items-center hover:-translate-y-[1px] transition-transform duration-300"
          >
            <IconPack
              type="ArrowLeft"
              class="cursor-pointer h-6 w-6 text-primary-500"
            />
            <p>Jobs</p>
          </div>
          <div class="flex items-center gap-4">
            <!-- Comments Section -->
            <AppButton
              @click="toggleComments"
              color="primary"
              outline
              class="relative group"
            >
              <div class="flex gap-2 items-center">
                <div class="relative group">
                  <div class="flex relative w-5 h-5">
                    <IconPack
                      type="ChatBubbleLeft"
                      class="h-5 w-5 absolute text-primary-500 group-hover:text-white cursor-pointer transition-all duration-300"
                    />
                    <IconPack
                      type="ChatBubbleLeftEllipsis"
                      :class="
                        showComments
                          ? 'opacity-100 group-hover:opacity-0'
                          : 'opacity-0 group-hover:opacity-100'
                      "
                      class="h-5 w-5 transition-all duration-200 absolute text-primary-500 group-hover:text-white cursor-pointer"
                    />
                  </div>
                </div>
                <transition name="scale-quick" mode="out-in" appear>
                  <div
                    v-if="pulse"
                    class="absolute -top-1.5 -right-1.5 w-5 h-5"
                  >
                    <div
                      class="animate-ping ease-in absolute top-0.5 inline-flex h-5 w-5 rounded-full bg-secondary-300 opacity-75"
                    />
                    <div
                      class="relative inline-flex animate-spin rounded-full h-5 w-5 bg-secondary-400"
                    >
                      <IconPack
                        type="ArrowPath"
                        class="w-3 h-3 m-auto text-white"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="totalComments > 0 || commentTotal > 0"
                    class="w-6 h-6 rounded-full bg-secondary-500 absolute flex justify-center items-center text-xs font-bold text-white -top-3 -right-3"
                  >
                    {{ totalComments > 0 ? totalComments : commentTotal }}
                  </div>
                </transition>
                <p
                  class="text-primary-500 group-hover:text-white transition-all duration-300"
                >
                  Comments
                </p>
              </div>
            </AppButton>
          </div>
        </div>
      </transition>
      <transition name="scale-quick" mode="out-in" appear>
        <div
          v-if="!job || loading"
          class="flex justify-center gap-4 items-center w-full h-full"
        >
          <IconPack
            type="ArrowPath"
            class="animate-spin w-5 h-5 text-primary-500"
          />
          <p>Loading ...</p>
        </div>
        <div v-else class="w-full mt-10 flex flex-col gap-10">
          <JobDetailCard :job="job" />
          <div class="flex gap-12 flex-col w-full">
            <div
              class="flex gap-2"
              :class="{
                'flex-col lg:flex-row': !showComments,
                'flex-col': showComments
              }"
            >
              <div class="lg:w-1/2" :class="{ 'lg:w-full': showComments }">
                <ContactCard
                  :style="showComments ? 'height: 230px !important;' : ''"
                  open
                  collapsable
                  :contact="job.client?.attributes"
                />
              </div>
              <div class="lg:w-1/2" :class="{ 'lg:w-full': showComments }">
                <LocationCard
                  open
                  collapsable
                  :location="job.location?.attributes"
                />
              </div>
            </div>
            <AppCard
              v-if="!loading"
              :open="job.documents.length > 0"
              @toggle-collapse="toggleTemplateDocs"
            >
              <!-- Template Documents -->
              <template #title>
                <div class="flex gap-2 items-center">
                  <IconPack type="Document" class="w-6 h-6 text-primary-500" />
                  <p class="text-primary-500 font-medium text-lg">
                    Template Documents
                  </p>
                </div>
                <p
                  v-if="templateDocsCollapsed || job.documents.length <= 0"
                  class="text-sm ml-7 mt-1 text-gray-400"
                >
                  {{ job.documents.length }} Documents
                </p>
                <p
                  v-else
                  class="text-sm ml-7 font-normal mt-1 text-gray-500 mb-4"
                >
                  Official documents that are required to be completed for this
                  job. Examples include: COC, SSEG Report, etc.
                </p>
              </template>
              <template #topRight>
                <div
                  v-tippy="{
                    content:
                      company?.attributes?.subscription_status ==
                      'payment_failed'
                        ? 'Please update your payment method.'
                        : ''
                  }"
                >
                  <AppButton
                    outline
                    color="secondary"
                    class="group"
                    :disabled="
                      company?.attributes?.subscription_status ==
                      'payment_failed'
                    "
                  >
                    <p
                      @click="$refs.addDocModal.showModal(title)"
                      class="text-secondary-500 whitespace-nowrap group-hover:text-white transition-all duration-300"
                    >
                      Create New
                    </p>
                  </AppButton>
                </div>
              </template>
              <div
                class="grid gap-10 mt-5"
                :class="{
                  'lg:grid-cols-1': showComments,
                  'lg:grid-cols-2': !showComments
                }"
              >
                <div v-for="document in sortedTemplateDocs" :key="document.id">
                  <TemplateDoc
                    @refresh="refresh"
                    :document="{
                      ...document.attributes,
                      id: document.id,
                      jobUid: jobId,
                      jobId: job.id
                    }"
                    :id="document.id"
                  />
                </div>
              </div>
            </AppCard>
            <AppCard open @toggle-collapse="toggleAdditionalDocs">
              <!-- Additional Documents -->
              <template #title>
                <div class="flex gap-2 items-center">
                  <IconPack
                    type="DocumentPlus"
                    class="w-6 h-6 text-primary-500"
                  />
                  <p class="text-primary-500 font-medium text-lg">
                    Additional Documents
                  </p>
                </div>
                <p
                  v-if="additionalDocsCollapsed"
                  class="text-sm ml-7 mt-1 text-gray-400"
                >
                  0 Documents
                </p>
                <p v-else class="text-sm ml-7 font-normal mt-1 text-gray-500">
                  Any additional documents that you need to keep record of.
                  Examples include: photos of pre-existing damage, the initial
                  COC document supplied by the client, etc.
                </p>
              </template>
              <FileUploadModal
                collapsable
                labeled
                ref="fileUpload"
                title="Upload Files"
                @create-documents="createDocuments"
                class="mt-4"
              />
              <div class="w-full my-10 flex flex-wrap gap-10 justify-center">
                <AdditionalDocCard
                  @delete-doc="deleteDocument"
                  @openImageCarousel="showImageModal(i)"
                  v-for="(doc, i) in sortedStaticDocs"
                  :key="i"
                  :document="{ ...doc.attributes, id: doc.id }"
                  @refresh="refresh"
                />
              </div>
            </AppCard>
          </div>
        </div>
      </transition>
    </div>
  </div>

  <JobComments
    v-if="!loading"
    v-model="showComments"
    :location="job.location"
    :client="job.client"
    :job="job"
    @updateTotal="updateTotalComments"
  />

  <ImageCarouselModal
    deletable
    @delete-image="deleteDocument"
    v-if="job && images"
    ref="carousel"
    v-model="showImageCarousel"
    :images="images"
    @refresh="refresh"
  />

  <ErrorModal ref="errorModal" />
  <AddDocument @create="createTemplateDoc" v-if="job" ref="addDocModal" />
</template>

<script>
import JobDetailCard from '@/components/Jobs/JobDetailsCard.vue';
import ContactCard from '@/components/Contacts/ContactCard.vue';
import LocationCard from '@/components/Location/LocationCard.vue';
import TemplateDoc from '@/components/Documents/TemplateDocCard.vue';
import AdditionalDocCard from '@/components/Documents/AdditionalDocCard.vue';
import ImageCarouselModal from '@/components/Modals/ImageCarouselModal.vue';
import FileUploadModal from '@/components/Modals/FileUploadModal.vue';
import ErrorModal from '@/components/Modals/ErrorModal.vue';
import AddDocument from '@/views/App/AddDocument.vue';
import JobComments from '@/components/Jobs/Comments/JobComments.vue';

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    ContactCard,
    JobDetailCard,
    LocationCard,
    TemplateDoc,
    AdditionalDocCard,
    ImageCarouselModal,
    FileUploadModal,
    ErrorModal,
    AddDocument,
    JobComments
  },
  data() {
    return {
      jobId: this.id,
      templateDocsCollapsed: false,
      additionalDocsCollapsed: false,
      showImageCarousel: false,
      showFileUpload: false,
      loading: true,
      pulse: false,
      routeChangeCount: 0,
      showComments: false,
      totalComments: 0
    };
  },
  async mounted() {
    console.log('mounted');
    this.loading = true;

    let job = this.$store.getters['Jobs/jobById'];

    if (job && job.uid === this.jobId && !this.$route.query.comment) {
      this.loading = false;
      return;
    }

    this.$store.dispatch('Jobs/clearJob');
    await this.$store.dispatch('Jobs/getJobById', this.jobId).then((res) => {
      job = res?.data?.data[0];
      if (!this.$store.getters['Company/company']) {
        this.$store.dispatch('Company/getCompany').then(() => {
          this.loading = false;
        });
      }
      this.loading = false;
      this.pulse = true;
    });
    await this.$store
      .dispatch('JobComments/getJobComments', {
        location: job.attributes.location?.data.id,
        client: job.attributes.client?.data.id
      })
      .then((res) => {
        this.totalComments = res?.data.meta.pagination.total;
        this.totalComments -= res?.data?.data.filter(
          (comment) => comment.attributes.is_deleted
        ).length;
        this.$store.commit(
          'JobComments/SET_TOTAL_COMMENTS',
          this.totalComments
        );
        this.pulse = false;
      });

    if (this.$route.query.comment) {
      this.showComments = true;
    }
  },
  computed: {
    job() {
      return this.$store.getters['Jobs/jobById'];
    },
    commentTotal() {
      return this.$store.getters['JobComments/totalComments'];
    },
    title() {
      return this.job?.location?.attributes.address_line;
    },
    sortedTemplateDocs() {
      return this.job.documents
        .filter((doc) => !doc.attributes.is_deleted)
        .slice()
        .sort((a, b) => {
          const statusA = this.getStatus(a.attributes);
          const statusB = this.getStatus(b.attributes);

          const statusOrder = ['Open', 'Pending', 'Approved', 'Locked'];

          return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
        });
    },
    sortedStaticDocs() {
      return this.job.static_documents.slice().sort((a, b) => {
        const typeA = a.attributes.file_type;
        const typeB = b.attributes.file_type;

        const statusOrder = ['image', 'pdf'];

        return statusOrder.indexOf(typeA) - statusOrder.indexOf(typeB);
      });
    },
    images() {
      let imageList = this.job?.static_documents?.filter(
        (doc) => doc.attributes.file_type === 'image'
      );
      return imageList?.map((image) => {
        return {
          url: image.attributes.url,
          title: image.attributes.title,
          uid: image.attributes.uid,
          id: image.id
        };
      });
    }
  },
  methods: {
    createTemplateDoc(payload) {
      if (payload.template) {
        this.$store
          .dispatch('Documents/cloneDocument', {
            documentId: payload.template.id,
            jobId: this.job.id,
            documentName: payload.label
          })
          .then(() => {
            this.$store.dispatch('Jobs/getJobById', this.jobId).then(() => {
              this.$toast.success('Document cloned successfully');
            });
          })
          .catch(() => {
            this.$toast.error('Failed to create document');
          });
      } else if (payload.type) {
        const document = {
          uid: '',
          name: payload.label,
          document_type: payload.type.id,
          job: this.job.id
        };
        this.$store
          .dispatch('Documents/createDocument', document)
          .then(() => {
            this.$store.dispatch('Jobs/getJobById', this.jobId).then(() => {
              this.$toast.success('Document created successfully');
            });
          })
          .catch(() => {
            this.$toast.error('Failed to create document');
          });
      }
    },
    toggleTemplateDocs(collapsed) {
      this.templateDocsCollapsed = collapsed;
    },
    toggleAdditionalDocs(collapsed) {
      this.additionalDocsCollapsed = collapsed;
    },
    getStatus(document) {
      return document.is_locked
        ? 'Locked'
        : document.is_approved && !document.is_locked
        ? 'Approved'
        : document.user_approvals?.data.length <
            document.approvals_required?.data.length &&
          document.user_approvals?.data.length > 0 &&
          document.user_approvals?.data.some(
            (approval) => approval.attributes.confirmed === true
          )
        ? 'Pending'
        : 'Open';
    },
    showImageModal(index) {
      if (this.$refs.carousel) {
        if (typeof index === 'string')
          index = this.images.findIndex((image) => image.uid === index);
        this.showImageCarousel = true;
        this.$refs.carousel.index = index;
      } else {
        setTimeout(() => {
          this.showImageModal(index);
        }, 500);
      }
    },

    showFileModal() {
      this.showFileUpload = true;
    },

    deleteDocument(file) {
      this.job.static_documents = this.job.static_documents.filter(
        (doc) => doc.id !== file.id
      );
    },

    refresh(message) {
      this.$store.dispatch('Jobs/getJobById', this.jobId).then(() => {
        if (message) this.$toast.success(message);
      });
    },

    toggleComments() {
      this.showComments = !this.showComments;
    },

    updateTotalComments(total) {
      this.totalComments = total;
    },

    createDocuments(files) {
      this.$refs.fileUpload.pulse = true;
      const data = files.map((file) => {
        return {
          uid: '',
          title: file.type.includes('pdf')
            ? file.label
              ? file.label
              : file.name.split('.')[0]
            : file.label,
          file: file?.data,
          type: 'private',
          job: this.job.id,
          fileMimeType: file.type
        };
      });

      data.forEach((doc, index) => {
        this.$store
          .dispatch('Documents/createStaticDocument', doc)
          .then((res) => {
            this.job.static_documents.push({
              id: res?.data.id,
              attributes: {
                title: res?.data.title,
                file_type: res?.data.file_type,
                url: res?.data.url,
                type: res?.data.type,
                uid: res?.data.uid,
                createdAt: res?.data.createdAt,
                updatedAt: res?.data.updatedAt
              }
            });

            this.$refs.fileUpload.removeFile(index);
            if (index === data.length - 1) {
              this.$refs.fileUpload.removeAllFiles();
              this.$refs.fileUpload.pulse = false;
              this.showFileUpload = false;
              this.$toast.success('Documents uploaded successfully');
            }
          })
          .catch((error) => {
            this.$refs.fileUpload.pulse = false;
            this.$toast.error('Failed to upload documents', {
              position: 'bottom',
              onClick: () => {
                this.$refs.errorModal.show(error);
              }
            });
          });
      });
    }
  }
};
</script>
