<template>
    <transition name="scale-quick" mode="out-in" appear>
        <div class="rounded-lg shadow">
            <div class="flex rounded-t-lg flex-col gap-2 items-center sticky top-0 z-20 bg-white p-4 shadow-sm">
                <div class="flex w-full justify-between ">
                    <IconPack @click="closePopup" type="XMark"
                        class="w-7 h-7 text-white rounded-full bg-primary-500 p-0.5" />
                    <h1 v-if="hasTitle" class="text-lg font-semibold ">
                        <slot name="title" />
                    </h1>
                    <div class="w-6 h-6"></div>
                </div>
                <p v-if="hasSubtitle" class="text-sm text-gray-400">
                    <slot name="subtitle" />
                </p>
            </div>

            <div class="flex flex-col gap-4 py-2 px-6">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'PopUp',
    emits: ['close'],
    computed: {
        hasSubtitle() {
            return this.$slots.subtitle !== undefined;
        },
        hasTitle() {
            return this.$slots.title !== undefined;
        }
    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
    },
}
</script>

<style lang="scss" scoped></style>