export default {
  SET_JOBS(state, payload) {
    state.jobs = payload;
  },

  SET_TOTAL(state, payload) {
    state.total = payload;
  },

  UPDATE_JOB(state, payload) {
    // console.log('UPDATE_JOB', payload);
    if (state.jobs) {
      const index = state.jobs.findIndex(job => job.id === payload.id);

      if (index !== -1) {
        state.jobs[index] = payload;
      } else {
        state.jobs.push(payload);
      }
    }
    else {
      state.jobs = [payload];
    }
  },

  SET_FETCHING(state, payload) {
    state.fetching = payload;
  },

  SET_JOB_BY_ID(state, payload) {
    state.jobById = payload;
  },

  SET_JOB_TYPES(state, payload) {
    state.jobTypes = payload;

    localStorage.setItem('jobTypes', JSON.stringify(payload));
  },

  DELETE_JOB(state, payload) {
    state.jobs = state.jobs.filter(job => job.id !== payload);
  },
};
