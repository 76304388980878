import store from '..';

export default {
    async getJobComments({ commit, rootGetters }, { location, client }) {
        try {
            const response = await store.$api.get(`${import.meta.env.VITE_API_URL}/job-comments`, {
                headers: {
                    Authorization: 'Bearer ' + rootGetters['Authentication/token'],
                },
                params: {
                    populate: 'job.location,job.client,job_comment_attachments,job_comment_attachments.creator,job_comment_attachments.document,reply_to,reply_to.job_comment_attachments,mentions,creator,job.job_type,reply_to.creator,job_comment_attachments.document.user_approvals,job_comment_attachments.document.approvals_required,job_comment_attachments.document.is_approved,job_comment_attachments.document.is_locked',
                    'filters[job][location][id][$eq]': location,
                    'filters[job][client][id][$eq]': client,
                    sort: 'createdAt:asc',
                }
            });

            if (response.status === 200) {
                console.log('job_comments', response.data.data);
                commit('SET_JOB_COMMENTS', response.data.data);
                return response;
            }
        } finally {
            commit('SET_FETCHING', false);
        }
    },

    async createComment({ commit, rootGetters }, { jobId, comment, attachments, mentions, replyToId }) {
        commit('SET_FETCHING', true);
        return new Promise((resolve, reject) => {
            store.$api
                .post(
                    import.meta.env.VITE_API_URL + '/job-comments',
                    {
                        data: {
                            uid: '',
                            job: jobId,
                            comment_text: comment,
                            mentions: { connect: mentions?.map((mention) => ({ id: mention.id })) },
                            attachments,
                            reply_to: replyToId
                        }
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + rootGetters['Authentication/token']
                        }
                    }
                )
                .then((response) => {
                    console.log('createComment', response);
                    if (response.status === 200) {
                        // commit('ADD_JOB_COMMENT', response.data.data);
                        resolve(response);
                    }
                })
                .catch((error) => {
                    console.error('createComment', error.response);
                    reject(error);
                }).finally(() => {
                    commit('SET_FETCHING', false);
                });
        });
    },

    deleteComment({ commit, rootGetters }, commentId) {
        commit('SET_FETCHING', true);
        return new Promise((resolve, reject) => {
            store.$api
                .delete(import.meta.env.VITE_API_URL + '/job-comments/' + commentId, {
                    headers: {
                        Authorization: 'Bearer ' + rootGetters['Authentication/token']
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        commit('DELETE_JOB_COMMENT', commentId);
                        resolve(response);
                    }
                })
                .catch((error) => {
                    console.error('deleteComment', error.response);
                    reject(error);
                }).finally(() => {
                    commit('SET_FETCHING', false);
                });
        });
    }

};
