<template>
  <div class="flex h-full justify-center items-center px-2">
    <div
      class="flex flex-col w-full max-w-md px-4 py-8 sm:px-6 md:px-8 lg:px-10"
    >
      <IconLogo
        class="justify-self-start self-start mb-10 m-auto h-48 w-48 p-4 rounded-3xl shadow bg-gradient-to-br from-primary-400 to-primary-600"
      />
      <p class="mb-6">
        Download the <strong>Energy Warehouse</strong> app and log in to get
        started.
      </p>

      <AppStores />

      <p class="mt-6">
        Company billing profile should be setup on
        <a class="font-bold" href="/company">app.ew.energy/company</a>
      </p>
    </div>
  </div>
</template>
<script>
import IconLogo from '@/components/EW/IconLogo.vue';
export default {
  components: {
    IconLogo
  },
  mounted() {
    //fetch company
    this.$store.dispatch('Company/getCompany');
  }
};
</script>
