<template>
  <div><AppTitle type="page">Dashboard</AppTitle></div>
</template>
<script>
export default {
  mounted() {
    // if (this.$store.getters['Authentication/userRole'] != 'ew_admin') {
    //   window.location = 'https://app.ew.energy';
    // }
  }
};
</script>
