<template>
  <div class="w-full flex flex-col justify-center items-center h-screen">
    <div class="w-full">
      <EWMainLogo class="h-32 m-auto" />
    </div>
    <div class="w-full">
      <AppSpinner class="text-primary-500 h-20 w-20 m-auto mt-10" />
      <div class="text-center m-auto mt-4 opacity-50">
        Opening in EW, ensure that you have the app installed.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.location.href = 'energywarehouse://';
  }
};
</script>
