export const strapi = {
  toastErrors(strapiResponse) {
    let duration = 5000;
    
    if (strapiResponse.response.data.error.details.errors) {
      strapiResponse.response.data.error.details.errors.forEach((error) => {
        this.$toast.error(error.message, {duration});
      });
    } else if (typeof strapiResponse.response.data.error.details === 'string')
      this.$toast.error(strapiResponse.response.data.error.details, {duration});
    else this.$toast.error(strapiResponse.response.data.error.message, {duration});
  }
};

export default {
  install: (app, options) => {
    app.config.globalProperties.toastApiErrors = strapi.toastErrors;
  }
};
