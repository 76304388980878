export default {
  SET_CONTACTS(state, payload) {
    state.contacts = payload;
  },
  SET_CONTACT_NAMES(state, payload) {
    state.contactNames = payload;
  },

  ADD_OR_UPDATE_CONTACT(state, payload) {
    if (state.contacts) {
      const index = state.contacts.findIndex((contact) => contact.uid === payload.uid);
      if (index !== -1) {
        state.contacts[index] = payload;
      } else {
        state.contacts.push(payload);
      }
    } else {
      state.contacts = [payload];
    }
  },

  DELETE_CONTACT(state, payload) {
    state.contacts = state.contacts.filter((contact) => contact.uid !== payload);
  },
  SET_FETCHING(state, payload) {
    state.fetching = payload;
  }

};
