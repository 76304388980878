<template>
  <transition name="scale-quick" mode="out-in" appear>
    <div key="spinner" class="self-center scrollbar-hide h-screen flex" v-if="showSkeleton">
      <AppSpinner class="h-20 text-primary-500 self-center" />
    </div>

    <div v-else key="subscriptionPlans" class="flex flex-col w-full">
      <div class="w-full">
        <AppTitle type="large" class="text-center mb-0">Delete Account</AppTitle>
        <div class="w-full text-center mb-4 max-w-lg m-auto">
          Are you sure you want to delete your account? You will no longer have
          access to the Energy Warehouse platform.
        </div>

        <AppCard class="w-full text-center mb-4 max-w-lg m-auto">
          <span class="mr-2 text-2xl">⚠️</span> All of your data will be deleted
          and cannot be recovered.
        </AppCard>
      </div>

      <div class="flex flex-row max-w-md flex-wrap w-full mx-auto my-5 px-8">
        <router-link to="/subscription" class="w-full lg:w-1/2 p-1">
          <AppButton>
            <IconPack type="ArrowLeft" class="w-5 h-5 mr-2" /> Back
          </AppButton>
        </router-link>

        <div class="w-full lg:w-1/2 p-1">
          <AppButton @click="deleteAccount()" :pulse="submitting" color="red">Confirm Deletion</AppButton>
        </div>
      </div>
      <div class="w-full text-center mb-4 max-w-lg m-auto opacity-50 text-sm">
        If you have an active subscription and are the only user on the account,
        you will need to cancel your subscription before deleting your account.
      </div>
    </div>
  </transition>
</template>
<script>
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';

export default {
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    showSkeleton() {
      return !this.company;
    },
    company() {
      return this.$store.getters['Company/company'];
    },
    userProfile() {
      return this.$store.getters['Authentication/user'];
    }
  },
  watch: {
    company: function (val) {
      if (!val?.id) return;

      if (val.attributes?.subscription_status === 'cancelled') {
        this.$router.push('/subscription');
      }
    }
  },
  mounted() {
    this.$store.dispatch('Company/getCompany');
  },
  methods: {
    deleteAccount() {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        title: '⚠️ Delete Account',
        message:
          'Are you sure you want to delete your account? This action is irreversible.'
      });

      reveal();

      onConfirm(() => {
        this.submitting = true;
        this.$store
          .dispatch('Authentication/deleteAccount', {
            id: this.userProfile.id
          })
          .then((res) => {
            this.submitting = false;
            this.$toast.success('Account deleted successfully');

            //logout
            this.$store.commit('Authentication/LOG_OUT');
            this.$router.push('/login');
          })
          .catch((err) => {
            this.submitting = false;

            this.toastApiErrors(err);
          });
      });
    }
  }
};
</script>
