<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <Jobs />
</template>

<script>
import Jobs from '@/views/App/Jobs.vue';

export default {
    components: {
        Jobs
    }
};
</script>
