<template>
  <div tabindex="0">
    <div v-if="label" class="font-bold h-5">
      {{ label }}
    </div>
    <div class="my-2 mr-1 flex relative">
      <span
        v-if="hasIcon"
        class="rounded-l inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 transition shadow-sm text-sm"
      >
        <slot name="icon" />
      </span>
      <component
        :is="multiline ? 'textarea' : 'input'"
        :name="name"
        ref="input"
        :disabled="disabled"
        :value="localValue"
        :max="max"
        :min="min"
        :type="type === 'phone' ? 'text' : type"
        class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-300 shadow-sm text-base focus:outline-none transition focus:ring-2 focus:ring-primary-600 focus:border-transparent"
        :class="{
          'rounded-r': hasIcon,
          'rounded-l': hasIconRight,
          'rounded-md': !hasIcon && !hasIconRight
        }"
        :step="step"
        :placeholder="placeholder"
        @blur="blur"
        @input="onInput"
        @keydown="onKeyDown"
        @focus="this.isInputFocused = true"
      ></component>
      <transition name="fade">
        <span v-if="pulse" class="absolute z-20 -top-3 -right-1 h-3 w-3">
          <span
            class="animate-ping ease-in absolute -right-2 top-0.5 inline-flex h-5 w-5 rounded-full bg-secondary-300 opacity-75"
          />
          <span
            class="relative inline-flex animate-spin rounded-full h-5 w-5 bg-secondary-400"
          >
            <IconPack type="ArrowPath" class="w-3 h-3 m-auto text-white" />
          </span>
        </span>
      </transition>
      <span
        v-if="hasIconRight"
        class="rounded-r inline-flex items-center px-3 border-t bg-white border-r border-b border-gray-300 text-gray-500 transition shadow-sm text-sm"
      >
        <slot name="iconRight" />
      </span>
      <transition name="scale" mode="out-in" appear>
        <AppChip
          v-if="errorMessage"
          class="text-xs bg-red-500 z-10 shadow-md absolute -top-5 -right-5"
        >
          {{ errorMessage }}
        </AppChip>
      </transition>
      <transition name="scale" mode="out-in" appear>
        <AppChip
          v-if="isInputFocused && hasChip"
          size="xs"
          class="absolute z-40 -top-4 -right-4 bg-primary"
        >
          <slot name="chip" />
        </AppChip>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    },
    modelValue: {
      type: [String, Number]
    },

    placeholder: {
      type: String
    },
    label: {
      type: String
    },
    max: { type: Number, default: undefined },
    min: { type: Number, default: undefined },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    multiline: { type: Boolean, default: false },
    pulse: { type: Boolean, default: false },
    step: { type: Number, default: 1 }
  },
  emits: [
    'blur',
    'update:modelValue',
    'keyup',
    'keydown',
    'keypress',
    'focus',
    'change',
    'input'
  ],
  data() {
    return {
      isInputFocused: false,
      errorMessage: null
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit(
          'update:modelValue',
          this.type === 'number' ? Number(value) : value
        );
      }
    },
    hasIconRight() {
      return !!this.$slots.iconRight;
    },
    hasIcon() {
      return !!this.$slots.icon;
    },
    hasChip() {
      return !!this.$slots.chip;
    }
  },
  mounted() {
    this.adjustTextareaHeight();
    // pass events on to parent
    ['keyup', 'keydown', 'keypress', 'focus', 'change', 'input'].map(
      (event) => {
        this.$refs.input.addEventListener(event, ($event) =>
          this.$emit(event, $event)
        );
      }
    );
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    updateValue: function (value) {
      this.$emit('update:modelValue', value);
    },

    errorFocus() {
      this.$refs.input.focus();
      this.$refs.input.classList.add(
        'focus:ring-red-500',
        'bg-red-100',
        'bg-opacity-50',
        'placeholder-red-300',
        'text-red-400'
      );
    },

    blur(event) {
      this.$emit('blur', event);
      this.isInputFocused = false;
      this.$refs.input.classList.remove(
        'focus:ring-red-500',
        'bg-red-100',
        'bg-opacity-50',
        'placeholder-red-300',
        'text-red-400'
      );
    },

    onInput(event) {
      this.$refs.input.classList.remove(
        'focus:ring-red-500',
        'bg-red-100',
        'bg-opacity-50',
        'placeholder-red-300',
        'text-red-400'
      );
      if (this.type === 'phone' && this.max) {
        // const truncatedValue = event.target.value.substring(0, this.max);
        // this.$emit('update:modelValue', truncatedValue);
        this.$emit('update:modelValue', event.target.value);
      } else {
        this.$emit('update:modelValue', event.target.value);
      }
    },
    adjustTextareaHeight() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.multiline && this.$refs.input) {
            const textarea = this.$refs.input;
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
          }
        }, 100);
      });
    },
    onKeyDown(event) {
      // If the type is "phone"

      if (this.type === 'phone') {
        if (
          event.key === 'Backspace' ||
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight' ||
          event.key === 'Delete' ||
          event.key === 'Home' ||
          event.key === 'End'
        ) {
          return;
        }
      }
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.localValue = newValue; // Ensuring localValue is updated whenever modelValue changes externally

        if (this.multiline) {
          this.adjustTextareaHeight();
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped>
textarea {
  resize: none;
  min-height: 100px;
}
</style>
