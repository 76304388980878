<template>
  <AppCard @click="$emit('update:active', active ? active : !active)" class="p-8 duration-200 transform"
    :class="{ 'hover:-translate-y-0.5  cursor-pointer': !active }">
    <div class="text-center flex items-center select-none justify-center transition-all duration-200 font-bold"
      :class="{ 'text-yellow-600': !active, 'mb-4': active }">
      <IconPack type="Plus" v-if="!active" class="w-5 h-5 mr-1" />

      <slot name="title"></slot>
    </div>

    <slot v-if="active" name="content"></slot>

    <div v-if="active && controls" class="flex justify-center mt-4">
      <AppButton @click="$emit('update:active', false)" color="gray" class="text-sm opacity-30 px-10 w-max mx-4">Cancel
      </AppButton>

      <AppButton :pulse="pulse" @click="$emit('saveClicked', $event)" class="w-max px-10 mx-4">Save</AppButton>
    </div>
  </AppCard>
</template>
<script>
export default {
  name: 'AddCard',
  emits: ['saveClicked', 'update:active'],
  props: {
    pulse: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: true
    }
  },
};
</script>
