export default {
  SET_CREDENTIALS(state, credentials) {
    state.credentials = credentials;
  },
  ADD_OR_UPDATE_CREDENTIAL(state, credential) {
    const index = state.credentials.findIndex((c) => c.id === credential.id);
    if (index === -1) {
      state.credentials.push(credential);
    } else {
      state.credentials[index] = credential;
    }
  },
  DELETE_CREDENTIAL(state, id) {
    const index = state.credentials.findIndex((c) => c.id === id);
    state.credentials.splice(index, 1);
  },

};
