<template>
  <div>
    <SearchableDropdown
      :disabled="disabled"
      :options="productOptions"
      v-model="selectedProduct"
      placeholder="Select Product"
      searchable
    >
      <template #iconRight>
        <IconPack type="ChevronDown" class="w-5 h-5" />
      </template>
    </SearchableDropdown>
  </div>
</template>

<script>
export default {
  name: 'SelectProduct',
  components: {},
  props: {
    type: {
      type: Number,
      required: true
    },
    modelValue: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedProduct: this.modelValue
    };
  },
  computed: {
    products() {
      return this.$store.getters['Products/products'];
    },
    productOptions() {
      //use reduce to remove products that are not INverters
      return this.products.reduce((acc, product) => {
        if (
          product?.attributes?.categories?.data[0].attributes?.name ===
          this.category?.attributes?.name
        ) {
          acc.push({
            name: product.attributes.name,
            img: product.attributes.main_image,
            id: product.id
          });
        }
        return acc;
      }, []);
    },
    categories() {
      return this.$store.getters['Products/productCategories'];
    },
    category() {
      return this.categories.find((category) => category.id === this.type);
    }
  },
  methods: {},
  watch: {
    selectedProduct: {
      handler(val) {
        this.$emit('update:modelValue', val);
      },
      deep: true
    }
  }
};
</script>
