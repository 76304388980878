class tailwind {
  getColor(color) {
    return window
      .getComputedStyle(document.body)
      .getPropertyValue('--color-' + color);
  }
}

export default {
  install: (app, options) => {
    app.config.globalProperties.tailwind = new tailwind();
  }
};
