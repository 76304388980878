import GlobalRoutes from '../Global';
import DashboardRoutes from '@/router/App/Dashboard';
import InviteRoutes from '@/router/App/Invite';
import CompanyRoutes from '@/router/App/Company';
import AccountRoutes from '@/router/App/Account';
import JobsRoutes from '@/router/App/Jobs';
import ContactRoutes from '@/router/App/Contacts';
import DocumentRoutes from '@/router/App/Documents';

const baseRoutes = [
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    redirect: '/app'
  }
];

const routes = baseRoutes
  .concat(GlobalRoutes)
  .concat(DashboardRoutes)
  .concat(InviteRoutes)
  .concat(CompanyRoutes)
  .concat(AccountRoutes)
  .concat(JobsRoutes)
  .concat(ContactRoutes)
  .concat(DocumentRoutes);

export default routes;
