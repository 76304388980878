<template>
  <div
    class="text-primary-600 text-left w-full font-bold"
    :class="{
      'text-sm py-1': type === 'small',
      'text-base py-1': type === 'base',
      'text-lg py-1': type === 'medium',
      'text-xl py-2': type === 'large',
      'text-2xl  border-b py-4 mb-3': type === 'page',
      'border-b pb-4 ': border
    }"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    type: { type: String, default: undefined },
    border: { type: Boolean, default: false }
  }
};
</script>
