<template>
  <button
    class="justify-center select-none flex items-center text-white transition ease-in font-bold duration-200 text-center shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded border-2 relative w-full"
    :class="{
      'text-xs py-1 px-2': size == 'xs',
      'text-sm py-2 px-4': size == 'sm',
      'text-md py-2 px-4': size == 'md',
      'text-lg py-2 px-4': size == 'lg',

      'border-primary-500 hover:border-primary-400 hover:bg-primary-400 focus:ring-primary-400 focus:ring-offset-primary-200':
        color == 'primary' && !customColor,
      'bg-primary-500':
        color == 'primary' && !(outline || link) && !customColor,
      'text-primary-600 outline-primary-50':
        color == 'primary' && outline && !link && !customColor,
      'hover:!text-white !text-primary-500': outline,

      'border-secondary-500 hover:border-secondary-400 hover:bg-secondary-400 focus:ring-secondary-400 focus:ring-offset-secondary-200':
        color == 'secondary' && !customColor,
      'bg-secondary-500':
        color == 'secondary' && !(outline || link) && !customColor,
      'text-secondary-600 outline-secondary-50':
        color == 'secondary' && outline && !link && !customColor,

      ' border-blue-500 hover:border-blue-400 hover:bg-blue-400 focus:ring-blue-400 focus:ring-offset-blue-200':
        color == 'blue',
      'bg-blue-500': color == 'blue' && !(outline || link),
      'text-blue-500 outline-blue-50': color == 'blue' && outline && !link,

      'border-red-500 hover:border-red-400 hover:bg-red-400 focus:ring-red-400 focus:ring-offset-red-200':
        color == 'red',
      'bg-red-500': color == 'red' && !(outline || link),
      'text-red-500 outline-red-50': color == 'red' && outline && !link,

      'border-green-500 hover:border-green-400 hover:bg-green-400 focus:ring-green-400 focus:ring-offset-green-200':
        color == 'green',
      'bg-green-500': color == 'green' && !(outline || link),
      'text-green-500 outline-green-50': color == 'green' && outline && !link,

      'border-gray-400 hover:border-gray-600 hover:bg-gray-600 focus:ring-gray-400 focus:ring-offset-gray-200':
        color == 'gray',
      'bg-gray-400 text-white': color == 'gray' && !(outline || link),
      'text-gray-600 outline-gray-50': color == 'gray' && outline && !link,

      'text-primary-600 border-white focus:ring-white-400 focus:ring-offset-white-200':
        color == 'white',
      'bg-white hover:bg-gray-100': color == 'white' && !(outline || link),
      'text-primary outline-primary': color == 'white' && outline && !link,

      'hover:text-white': outline,
      'bg-none outline-none': link,
      '!opacity-60 pointer-events-none': disabled,
      'bg-white': !transparent,

      customColor: customColor && !link && !outline
    }"
    :style="'background-color:' + customColor + '; border-color:' + customColor"
    @click="clicked"
  >
    <transition name="fade">
      <span v-if="pulse" class="absolute -top-3 -right-1 h-3 w-3">
        <span
          class="animate-ping ease-in absolute -right-2 -top-03 inline-flex h-5 w-5 rounded-full bg-secondary-300 opacity-75"
        />
        <span
          class="relative inline-flex animate-spin rounded-full h-5 w-5 bg-secondary-400"
        >
          <IconPack type="ArrowPath" class="w-3 h-3 m-auto text-white" />
        </span>
      </span>
    </transition>

    <transition name="scale" mode="out-in" appear>
      <AppSpinner v-if="spinner" class="w-5 h-5 mr-2 inline" />
    </transition>
    <div v-if="hasIcon && !spinner" class="mr-2 inline">
      <slot name="icon" />
    </div>
    <slot v-if="!spinner" />
  </button>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'sm'
    },
    customColor: String,
    outline: Boolean,
    spinner: Boolean,
    pulse: Boolean,
    disabled: Boolean,
    link: Boolean,
    transparent: { type: Boolean, default: true }
  },
  emits: ['clicked'],
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    }
  },
  methods: {
    clicked() {
      this.$emit('clicked');
    }
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.customColor {
  transition: all 0.3s;
}

.customColor:hover {
  opacity: 0.8;
}
</style>
