<template>
  <AppCard :collapsable="collapsable" class=" ">
    <template v-if="title" #title>
      <p class="text-primary-500">{{ title }}</p>
    </template>
    <div
      class="flex gap-4 justify-between w-full"
      :class="{
        'mt-5': title
      }"
    >
      <div class="flex gap-4">
        <div>
          <input
            type="file"
            ref="fileInput"
            style="display: none"
            :accept="
              type == 'image'
                ? 'image/*'
                : type == 'pdf'
                ? 'application/pdf'
                : 'image/*, application/pdf'
            "
            @change="handleFileInput"
            :multiple="multiple"
          />
          <AppButton
            class="whitespace-nowrap"
            @click="openFileInput"
            :disabled="!multiple && files.length >= 1"
          >
            <template #icon>
              <IconPack type="PlusCircle" class="w-5 h-5 text-white" />
            </template>
            <p>
              Select
              {{ type === 'image' ? 'Image' : type === 'pdf' ? 'PDF' : 'File' }}
            </p>
          </AppButton>
        </div>
        <transition name="scale-quick" mode="out-in" appear>
          <div v-if="files.length > 0">
            <AppButton
              :pulse="pulse"
              @click="createDocuments"
              class="group"
              outline
            >
              <template #icon>
                <IconPack
                  type="ArrowUpTray"
                  class="w-5 h-5 text-primary-500 group-hover:text-white"
                />
              </template>
              <p class="text-primary-500 group-hover:text-white">Upload</p>
            </AppButton>
          </div>
        </transition>
      </div>
      <transition name="scale" mode="out-in" appear>
        <div
          @click="removeAllFiles"
          v-if="files.length > 1"
          class="flex gap-1 whitespace-nowrap items-center rounded bg-white p-2 border border-gray-300 shadow-sm text-gray-500 hover:bg-gray-100 transition-all duration-300"
        >
          <IconPack type="XMark" class="w-5 h-5 text-gray-500" />
          <p>Remove All</p>
        </div>
      </transition>
    </div>
    <div
      class="flex border-2 border-dashed p-6 w-full rounded transition-all duration-300 mt-5"
      @dragover.prevent="handleDragover"
      @dragleave="handleDragLeave"
      @drop="handleDrop"
      :class="{
        'bg-primary-500 bg-opacity-10': dragging,
        'opacity-50 select-none': pulse
      }"
    >
      <div v-if="!files.length">
        <div class="flex items-center gap-4" v-if="!disableDragDrop">
          <IconPack
            :type="type === 'pdf' ? 'DocumentPlus' : 'Photo'"
            class="w-10 h-10 text-primary-500"
          />
          <p>
            Drag & Drop
            {{ type === 'image' ? 'image' : type === 'pdf' ? 'pdf' : 'file' }}
            here or use the button above
          </p>
        </div>
        <div v-else>
          <p class="text-gray-500">No files selected</p>
        </div>
      </div>
      <div class="w-full" v-else>
        <ul
          class="flex gap-2 w-full"
          :class="{
            'flex-row flex-wrap gap-4': grid,
            'flex-col': !grid
          }"
        >
          <li v-for="(file, index) in files" :key="index">
            <transition name="scale-quick" mode="out-in" appear>
              <div
                class="flex border w-full justify-between rounded-lg items-center relative"
                :class="{
                  'p-1': grid,
                  'px-6 py-2 ': !grid
                }"
                v-if="isImage(file.type)"
              >
                <div class="flex w-full gap-10 items-center">
                  <img
                    class="rounded object-cover object-center"
                    :class="{
                      'w-36 h-36 ': grid,
                      'w-20 h-20': !grid
                    }"
                    :src="getObjectUrl(file)"
                    alt="Preview"
                  />
                  <div v-if="!grid" class="flex max-w-xs w-full flex-col gap-2">
                    <p class="text-sm max-w-xs overflow-scroll font-medium">
                      {{ file.name }}
                    </p>
                    <p class="text-sm text-gray-500">
                      {{ file.size / 1000 }} KB
                    </p>
                  </div>
                  <TextInput
                    v-if="labeled"
                    v-model="file.label"
                    class="w-full max-w-xs"
                    placeholder="Document Label"
                  >
                    <template #chip>
                      <p class="text-sm">Optional</p>
                    </template>
                  </TextInput>
                </div>
                <button
                  :class="{
                    absolute: grid
                  }"
                  class="hover:shadow-md hover:-translate-y-0.5 transition-all duration-300 bg-red-400 p-1 w-fit h-fit rounded-full -top-2 -right-2"
                  @click="removeFile(index)"
                >
                  <IconPack type="XMark" class="w-6 h-6 text-white" />
                </button>
              </div>
              <div v-else-if="isPDF(file.type)">
                <div
                  class="flex border w-full justify-between px-6 py-2 rounded-lg items-center"
                >
                  <div class="flex w-full gap-10 items-center">
                    <div class="w-20 h-20 items-center justify-center flex">
                      <IconPack
                        type="Document"
                        class="w-10 h-10 text-primary-500"
                      />
                    </div>
                    <div class="flex max-w-xs w-full flex-col gap-2">
                      <p class="text-sm max-w-xs overflow-scroll font-medium">
                        {{ file.name }}
                      </p>
                      <p class="text-sm text-gray-500">
                        {{ file.size / 1000 }} KB
                      </p>
                    </div>
                    <TextInput
                      v-if="labeled"
                      v-model="file.label"
                      class="w-full max-w-xs"
                      placeholder="Document Label"
                    >
                      <template #chip>
                        <p class="text-sm">Optional</p>
                      </template>
                    </TextInput>
                  </div>
                  <button
                    class="hover:shadow-md hover:-translate-y-0.5 transition-all duration-300 bg-red-400 p-1 w-fit h-fit rounded-full -top-2 -right-2"
                    @click="removeFile(index)"
                  >
                    <IconPack type="XMark" class="w-6 h-6 text-white" />
                  </button>
                </div>
              </div>
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </AppCard>
</template>

<script>
export default {
  components: {},
  props: {
    type: {
      type: String,
      default: 'none'
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    labeled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    grid: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    disableDragDrop: {
      type: Boolean,
      default: false
    }
  },
  emits: ['create-documents'],
  data() {
    return {
      files: [],
      pulse: false,
      dragging: false
    };
  },
  methods: {
    handleDragover(event) {
      event.preventDefault();
      this.dragging = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.dragging = false;
    },
    hideModal() {
      this.openModal = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.dragging = false;

      let newFiles = [...event.dataTransfer.files];
      newFiles = newFiles.filter((file) => {
        return file.type.includes('image/') || file.type === 'application/pdf';
      });

      if (!this.multiple) {
        if (newFiles.length > 1) {
          this.$toast.error('Only one file is allowed');
        }
        this.files = [newFiles[0]]; // Replace the existing files with the new file
      } else {
        if (newFiles.length > 0) {
          this.addFiles(newFiles);
        }
      }
    },

    handleFileInput() {
      const newFiles = [...this.$refs.fileInput.files];
      if (!this.multiple && this.files.length + newFiles.length > 1) {
        // If multiple is false and trying to add more than one file
        this.$refs.fileInput.value = null; // reset file input
        return;
      }
      this.addFiles(newFiles);
    },
    addFiles(newFiles) {
      this.files = this.multiple ? [...this.files, ...newFiles] : [newFiles[0]]; // Only add the first selected file if multiple is false
      // Clear the input value to allow selecting the same file again or a different one if needed
      this.$refs.fileInput.value = null;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    isImage(fileType) {
      return fileType.startsWith('image/');
    },
    isPDF(fileType) {
      return fileType === 'application/pdf';
    },
    getObjectUrl(file) {
      return URL.createObjectURL(file);
    },
    openFileInput() {
      if (this.multiple || (!this.multiple && this.files.length === 0)) {
        this.$refs.fileInput.click();
      }
    },
    removeAllFiles() {
      this.files = [];
    },
    createDocuments() {
      this.pulse = true;
      // Convert files to base64
      const promises = this.files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              label: file.label || '',
              type: file.type,
              data: reader.result.split('base64,')[1],
              name: file.name
            });
          };
          reader.onerror = () => {
            reject(reader.error);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((documents) => {
          this.$emit('create-documents', documents);
          this.removeAllFiles(); // Clear files after uploading
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.pulse = false;
        });
    }
  }
};
</script>

<style scoped>
/* add styling on dragover */
.dropbox {
  transition: all 0.3s;
}

.dropbox.dragover {
  border-color: #2563eb;
  background-color: #f0f4ff;
}
</style>
