<template>
  <div>
    <div
      @click="debounce(toggleActions, 100)"
      ref="actions"
      class="w-5 h-5 bg-gray-100 hover:bg-gray-200 relative hover:-translate-y-0.5 hover:shadow-lg z-40 transition-all duration-300 rounded-md flex items-center justify-center"
    >
      <IconPack type="ChevronDown" class="w-4 h-4" />
      <transition name="scale-quick" mode="out-in" appear>
        <div
          v-if="isActive"
          ref="dropdown"
          class="w-fit absolute bg-white border p-1 flex flex-col text-primary-500 shadow-lg rounded-lg -ml-1 top-0 right-full -translate-x-1 z-50"
        >
          <div
            v-for="option in options"
            :key="option.name"
            @click="handleAction(option.name, $event)"
            class="flex items-center text-sm gap-2 p-1 px-4 rounded-lg justify-start hover:bg-primary-500 hover:bg-opacity-10 cursor-pointer"
          >
            <IconPack :type="option.icon" class="w-4 h-4 shrink-0" />
            <p>{{ option.name }}</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { dropdownStore } from '../../utils/DropdownStore';

export default {
  setup() {
    return { debounce };
  },
  props: {
    uid: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isActive() {
      return dropdownStore.activeDropdown === this.uid;
    }
  },
  methods: {
    toggleActions() {
      if (this.isActive) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },
    openDropdown() {
      dropdownStore.activeDropdown = this.uid;
      document.addEventListener('click', this.handleClickOutside);
    },
    closeDropdown() {
      dropdownStore.activeDropdown = null;
      document.removeEventListener('click', this.handleClickOutside);
    },
    handleAction(action, event) {
      event.stopPropagation(); // Prevent the event from bubbling up
      this.$emit(action.toLowerCase());
      this.closeDropdown(); // Close the dropdown after action
    },
    handleClickOutside(event) {
      const dropdown = this.$refs.dropdown;
      const actions = this.$refs.actions;

      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        !actions.contains(event.target)
      ) {
        this.closeDropdown();
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};

let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};
</script>
