
export default {
  SET_PLANS(state, payload) {
    state.plans = payload;
  },
  SET_BILLABLES(state, payload) {
    state.billables = payload;
  }
  
};
