<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <transition name="scale-quick" mode="out-in" appear>
    <div class="w-full h-full px-2 md:px-10 max-w-screen-2xl">
      <!-- <EWMainLogo class="w-64 h-full m-auto" /> -->
      <div class="flex justify-between items-center w-full mb-5">
        <AppTitle type="large">Jobs</AppTitle>
        <div
          class="w-full max-w-xs whitespace-nowrap"
          v-tippy="{
            content:
              company?.attributes?.subscription_status == 'payment_failed'
                ? 'Please update your payment method.'
                : ''
          }"
        >
          <AppButton
            :disabled="
              company?.attributes?.subscription_status == 'payment_failed'
            "
            @click="this.$router.push({ name: 'Add-Site' })"
            outline
            class="w-full group max-w-sm my-4"
          >
            <template #icon>
              <IconPack
                type="MapPin"
                class="cursor-pointer h-6 w-6 text-primary-500 transition-all duration-300 group-hover:text-white"
              />
            </template>
            <p
              class="text-primary-500 transition-all duration-300 group-hover:text-white"
            >
              Add Site
            </p>
          </AppButton>
        </div>
      </div>
      <!-- Filter Section -->
      <p class="text-gray-500 hidden md:block text-base">
        Search for jobs by reference, address, stand or client name
      </p>
      <div
        class="flex w-full flex-wrap-reverse md:flex-nowrap gap-2 md:gap-0 justify-between items-start"
      >
        <div class="w-full flex gap-2 flex-wrap items-center">
          <!-- Text filter -->
          <p class="text-gray-500 text-sm md:hidden">
            Search for jobs by reference, address, stand or client name
          </p>
          <TextInput
            class="w-full max-w-6xl"
            v-model="filter"
            placeholder="Search jobs ..."
          >
            <template #icon>
              <IconPack type="MagnifyingGlass" class="w-5 h-5 text-gray-500" />
            </template>
          </TextInput>

          <!-- JobType Filter -->
          <SearchableDropdown
            v-if="jobTypes && jobTypes.length > 0"
            dashed
            v-model="jobTypeFilter"
            :options="
              jobTypes?.map((type) => {
                return { name: type.name, color: type.color };
              })
            "
            placeholder="Job Type"
          >
            <template #iconLeft>
              <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
            </template>
          </SearchableDropdown>
          <!-- Date range Picker -->
          <DateRangePicker
            :ranges="false"
            v-model="dateRange"
            :dateRange="dateRange"
            auto-apply
            controlContainerClass="rounded cursor-pointer hover:bg-gray-100 transition-all duration-300 bg-white py-2 px-4 border border-gray-300 shadow-sm border-dashed w-full text-gray-500"
          >
            <template #input>
              <div class="flex gap-2 items-center">
                <IconPack type="Calendar" class="w-5 h-5 text-gray-500" />
                <p class="text-gray-500">Date</p>
                <div
                  class="flex items-center gap-1"
                  v-if="
                    moment(dateRange.startDate).format('Do MMM YYYY') !==
                    'Invalid date'
                  "
                >
                  <span class="text-gray-400">|</span>
                  <p
                    class="text-gray-400 text-sm font-semibold flex items-center gap-1"
                  >
                    <span
                      class="bg-gray-100 py-1 px-2 rounded whitespace-nowrap"
                      >{{
                        moment(dateRange.startDate).format('Do MMM YY')
                      }}</span
                    >
                    <span
                      class="flex items-center gap-1 whitespace-nowrap"
                      v-if="
                        moment(dateRange.startDate).format('Do MMM YY') !==
                        moment(dateRange.endDate).format('Do MMM YY')
                      "
                    >
                      <span>-</span>
                      <span class="bg-gray-100 py-1 px-2 rounded">{{
                        moment(dateRange.endDate).format('Do MMM YY')
                      }}</span>
                    </span>
                  </p>
                </div>
              </div>
            </template>
            <template #footer>
              <!-- Clear Date filters -->
              <template
                v-if="
                  dateRange.startDate !== null && dateRange.endDate !== null
                "
              >
                <div
                  @click="clearDates"
                  class="p-1 border-t mt-1 flex justify-center"
                >
                  <p
                    class="hover:bg-gray-100 w-full h-full p-2 text-center text-sm rounded"
                  >
                    Clear Filters
                  </p>
                </div>
              </template>
            </template>
          </DateRangePicker>
        </div>
        <div
          class="flex w-full md:w-1/2 flex-wrap justify-between md:justify-end gap-2 md:gap-5 mt-2 items-center"
        >
          <AppSwitch
            v-if="!filter"
            color="green"
            class="whitespace-nowrap"
            v-model="showCompleted"
            rightLabel="Show Completed"
          />

          <div class="flex gap-2">
            <div
              @click="resetFilters"
              v-if="
                filter ||
                jobTypeFilter ||
                dateRange.startDate ||
                dateRange.endDate ||
                locationFilter ||
                clientFilter
              "
              class="flex gap-1 items-center rounded bg-white p-2 border border-gray-300 shadow-sm text-gray-500 hover:bg-gray-100 transition-all duration-300 cursor-pointer"
            >
              <IconPack type="XMark" class="w-5 h-5 text-gray-500" />
              <p>Reset</p>
            </div>
            <!-- total -->
            <div
              class="flex gap-1 items-center rounded bg-white p-2 border border-gray-300 shadow-sm text-gray-500"
            >
              <p class="text-gray-500">Total:</p>
              <transition name="scale-quick" mode="out-in" appear>
                <p v-if="!fetching" class="text-primary-500 font-bold">
                  {{ totalJobs }}
                </p>
                <div
                  v-else
                  class="w-6 h-4 text-transparent rounded bg-gray-200 animate-pulse"
                >
                  {{ totalJobs }}
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div
        class="my-2 w-full items-center flex gap-2 transition-all duration-300"
        :class="{
          'opacity-0': !locationFilter && !clientFilter,
          'opacity-100': locationFilter || clientFilter
        }"
      >
        <!-- location filter -->
        <transition name="scale-quick" mode="out-in">
          <div
            @click="locationFilter = ''"
            v-if="locationFilter && jobList"
            class="flex cursor-pointer select-none gap-2 items-center group"
          >
            <p
              class="bg-alt w-fit flex gap-1 pl-3 group-hover:pr-2 py-1 transition-all duration-300 rounded-full text-xs font-bold text-white"
            >
              <span>Location:</span>
              <span>
                {{
                  jobList.find(
                    (job) => job.location.attributes.uid === locationFilter
                  )?.location.attributes.address_line
                }}
              </span>
              <IconPack
                type="XMark"
                class="w-3 h-4 group-hover:w-4 text-white cursor-pointer opacity-0 group-hover:opacity-100 transition-all duration-300 hover:shadow-sm rounded-full"
              />
            </p>
            <p class="text-xs text-text font-bold"></p>
          </div>
        </transition>
        <!-- client filter -->
        <transition name="scale-quick" mode="out-in">
          <div
            @click="clientFilter = ''"
            v-if="clientFilter && jobList"
            class="flex cursor-pointer select-none gap-2 items-center group"
          >
            <p
              class="bg-primary-300 w-fit flex pl-3 group-hover:pr-2 py-1 transition-all duration-300 rounded-full text-xs font-bold text-white"
            >
              Client: {{ getClientName() }}
              <IconPack
                type="XMark"
                class="w-3 h-4 group-hover:w-4 text-white cursor-pointer opacity-0 group-hover:opacity-100 transition-all duration-300 hover:shadow-sm rounded-full"
              />
            </p>
            <p class="text-xs text-gray-500 font-bold"></p>
          </div>
        </transition>
      </div>
      <div
        v-if="jobList && filteredList && !loading"
        class="flex flex-col mt-5 gap-10 md:gap-8"
      >
        <div v-for="job in filteredList" :key="job.id">
          <transition name="scale" mode="out-in" appear>
            <JobCard
              v-if="job.location || job.client"
              :job="job"
              :client="job.client"
              :locaiton="job.location"
            />
            <JobCardLoader v-else />
          </transition>
        </div>

        <div v-if="fetching" class="w-full flex items-center justify-center">
          <AppSpinner class="w-8" />
        </div>

        <div v-else-if="filteredList.length <= 0" class="flex flex-col gap-4">
          <transition name="scale" mode="out-in" appear>
            <p>No jobs found matching the current filters.</p>
          </transition>
        </div>

        <AppPagination
          v-if="pageCount > 1 && !fetching"
          :currentPage="parseInt(currentPage)"
          :pageCount="pageCount"
          @previous="loadPreviousPage"
          @next="loadNextPage"
          @goto="gotoPage"
        />
      </div>
      <transition v-else name="scale-quick" mode="out-in">
        <div class="flex flex-col gap-4">
          <div v-for="i in 5" :key="i">
            <JobCardLoader />
          </div>
        </div>
      </transition>
    </div>
  </transition>
  <ErrorModal ref="errorModal" />
</template>

<script>
import JobCard from '@/components/Jobs/JobCard.vue';
import JobCardLoader from '@/components/Jobs/Skeletons/JobCardLoader.vue';
import DateRangePicker from 'vue3-daterange-picker';
import AppSwitch from '@/components/EW/AppSwitch.vue';
import ErrorModal from '@/components/Modals/ErrorModal.vue';
import AppPagination from '@/components/EW/AppPagination.vue';

export default {
  components: {
    JobCard,
    JobCardLoader,
    DateRangePicker,
    AppSwitch,
    ErrorModal,
    AppPagination
  },
  async mounted() {
    if (!this.jobTypes) await this.$store.dispatch('Jobs/getJobTypes');
    this.showCompletedFilter =
      (await localStorage.getItem('showCompleted')) === 'true';
    this.currentPage =
      parseInt(this.$route.query?.page?.toString()) ||
      localStorage.getItem('currentJobPage')
        ? parseInt(localStorage.getItem('currentJobPage'))
        : 1;
  },
  beforeUnmount() {
    window.removeEventListener('storage', this.handleLocalStorageChange);
  },

  data() {
    return {
      perPage: 25,
      pageCount: 0,
      loading: false,
      filtered: false,
      showCompletedFilter: false
    };
  },
  computed: {
    jobList() {
      let jobs = this.$store.getters['Jobs/jobs'];
      return jobs;
    },

    totalJobs() {
      return this.$store.getters['Jobs/total'];
    },

    fetching() {
      return this.$store.getters['Jobs/isFetching'];
    },
    // ********************************************************************************************************************************************************************************************************************************************************************
    filteredList() {
      let filterList = this.jobList;
      if (this.shouldApplyFilters) filterList = this.applyFilters();

      return filterList;
    },

    shouldApplyFilters() {
      return (
        !!this.filter ||
        !!this.jobTypeFilter ||
        !!this.dateRange.startDate ||
        !!this.dateRange.endDate ||
        !!this.locationFilter ||
        !!this.clientFilter ||
        !this.showCompletedFilter
      );
    },

    // ********************************************************************************************************************************************************************************************************************************************************************
    currentPage: {
      get() {
        const value =
          parseInt(this.$route.query?.page?.toString()) ||
          localStorage.getItem('currentJobPage') ||
          1;
        return value == 'NaN' ? 1 : value;
      },
      set(value) {
        if (value == 'NaN') value = 1;

        this.$router
          .replace({ query: { ...this.$route.query, page: value } })
          .then(() => {
            let oldValue = parseInt(localStorage.getItem('currentJobPage'));

            if (oldValue === value && !this.shouldApplyFilters) {
              this.loadJobs();
            } else if (!this.shouldApplyFilters) {
              this.loadJobs(true);
            } else {
              // this.filtered = false;
              this.loadJobs();
            }

            localStorage.setItem('currentJobPage', value.toString());
          });
      }
    },

    filter: {
      get() {
        return this.$route.query?.query?.toString() || '';
      },
      set(value) {
        const query =
          value !== ''
            ? { ...this.$route.query, query: value }
            : { ...this.$route.query, query: undefined };
        this.$router.replace({ query: query });
      }
    },

    jobTypeFilter: {
      get() {
        if (this.jobTypes && this.jobTypes.length > 0) {
          let type = this.jobTypes?.find(
            (type) =>
              type.name.toLowerCase() ===
              this.$route.query?.jobType?.toString()?.toLowerCase()
          );
          return type
            ? { name: type.name, color: type.color }
            : this.$route.query?.jobType?.toString() || '';
        }
        return '';
      },
      set(value) {
        this.filtered = false;
        if (Array.isArray(value)) value = value[0];
        const query =
          value !== ''
            ? {
                ...this.$route.query,
                jobType: typeof value == 'object' ? value.name : value
              }
            : { ...this.$route.query, jobType: undefined };
        this.$router.replace({ query: query });
        return value;
      }
    },

    dateRange: {
      get() {
        return {
          startDate: this.$route.query?.startDate?.toString()
            ? new Date(this.$route.query?.startDate?.toString())
            : null,
          endDate: this.$route.query?.endDate?.toString()
            ? new Date(this.$route.query?.endDate?.toString())
            : null
        };
      },
      set(value) {
        value.startDate?.setHours(0, 0, 0, 0);
        value.endDate?.setHours(23, 59, 59, 999);

        const query =
          value.startDate !== null && value.endDate !== null
            ? {
                ...this.$route.query,
                startDate: value.startDate.toISOString(),
                endDate: value.endDate.toISOString()
              }
            : { ...this.$route.query, startDate: null, endDate: null };
        this.$router.replace({ query: query });
      }
    },

    showCompleted: {
      get() {
        return this.showCompletedFilter;
      },
      set(value) {
        this.filtered = false;
        this.showCompletedFilter = value;
        localStorage.setItem('showCompleted', value.toString());
        this.loadJobs();
      }
    },

    locationFilter: {
      get() {
        return this.$route.query?.location?.toString() || '';
      },
      set(value) {
        const query =
          value !== ''
            ? { ...this.$route.query, location: value }
            : { ...this.$route.query, location: undefined };

        this.$router.replace({ query: query });
      }
    },

    clientFilter: {
      get() {
        return this.$route.query?.client?.toString() || '';
      },
      set(value) {
        const query =
          value !== ''
            ? { ...this.$route.query, client: value }
            : { ...this.$route.query, client: undefined };
        this.$router.replace({ query: query });
      }
    }
  },

  methods: {
    loadJobs(load) {
      const start = (this.currentPage - 1) * this.perPage;
      const limit = this.perPage;

      const showCompleted = this.showCompletedFilter;

      if (load) this.loading = true;

      if (this.shouldApplyFilters && this.filtered) {
        this.queryStore();
      } else {
        debounce(() => {
          this.$store
            .dispatch('Jobs/getJobs', { start, limit, showCompleted })
            .then((res) => {
              this.pageCount = Math.ceil(
                res?.data.meta.pagination.total / this.perPage
              );
            })
            .finally(() => {
              if (load) this.loading = false;
            })
            .catch((error) => {
              this.$toast.error('Error loading jobs', {
                duration: 5000,
                position: 'bottom',
                onClick: () => {
                  this.$refs.errorModal.show(error);
                }
              });
            });
        }, 200);
      }
    },
    // ********************************************************************************************************************************************************************************************************************************************************************
    applyFilters() {
      let filterList = this.jobList;
      if (this.jobTypeFilter)
        filterList = filterList.filter(
          (job) =>
            job.job_type?.attributes.name?.toLowerCase() ==
            this.jobTypeFilter.name?.toLowerCase()
        );
      if (this.filter)
        filterList = filterList.filter((job) => {
          if (
            job.client?.attributes?.first_name
              ?.toLowerCase()
              .includes(this.filter.toLowerCase())
          )
            return job;
          if (
            job.client?.attributes?.last_name
              ?.toLowerCase()
              .includes(this.filter.toLowerCase())
          )
            return job;
          if (
            job.location?.attributes?.address_line
              ?.toLowerCase()
              .includes(this.filter.toLowerCase())
          )
            return job;
          if (
            job.location?.attributes?.stand_number
              ?.toLowerCase()
              .includes(this.filter.toLowerCase())
          )
            return job;
          if (
            job.location?.attributes?.suburb
              ?.toLowerCase()
              .includes(this.filter.toLowerCase())
          )
            return job;
          if (
            job.location?.attributes?.city
              ?.toLowerCase()
              .includes(this.filter.toLowerCase())
          )
            return job;
          if (
            job.reference_number
              ?.toLowerCase()
              .includes(this.filter.toLowerCase())
          )
            return job;
          if (job.title?.toLowerCase().includes(this.filter.toLowerCase()))
            return job;
          if (
            job.location?.attributes?.building_name
              ?.toLowerCase()
              .includes(this.filter.toLowerCase())
          )
            return job;
        });
      if (!this.showCompletedFilter && !this.filter)
        filterList = filterList.filter(
          (job) => job.current_status?.attributes.status !== 'completed'
        );
      if (this.dateRange.startDate && this.dateRange.endDate)
        filterList = filterList.filter((job) => this.isJobWithinDateRange(job));
      if (this.clientFilter)
        filterList = filterList.filter(
          (job) => job.client?.attributes.uid === this.clientFilter
        );
      if (this.locationFilter)
        filterList = filterList.filter(
          (job) => job.location?.attributes.uid === this.locationFilter
        );

      if (!this.filtered) {
        this.queryStore();
        this.filtered = true;
      }
      return filterList;
    },

    queryStore() {
      const start = (this.currentPage - 1) * this.perPage;
      const limit = this.perPage;

      let query = {
        filters: {
          $and: [
            {
              $or: []
            }
          ]
        }
      };

      if (this.jobTypeFilter) {
        query.filters.$and[0].$or.push({
          job_type: {
            name: {
              $eq: this.jobTypeFilter.name
            }
          }
        });
      }

      if (this.filter) {
        query.filters.$or = [
          ...query.filters.$and[0].$or,
          {
            reference_number: {
              $containsi: this.filter
            }
          },
          {
            location: {
              $or: [
                {
                  address_line: {
                    $containsi: this.filter
                  }
                },
                {
                  stand_number: {
                    $containsi: this.filter
                  }
                },
                {
                  suburb: {
                    $containsi: this.filter
                  }
                },
                {
                  city: {
                    $containsi: this.filter
                  }
                },
                {
                  building_name: {
                    $containsi: this.filter
                  }
                }
              ]
            }
          },
          {
            client: {
              $or: [
                {
                  first_name: {
                    $containsi: this.filter
                  }
                },
                {
                  last_name: {
                    $containsi: this.filter
                  }
                }
              ]
            }
          },
          {
            title: {
              $containsi: this.filter
            }
          }
        ];
      }

      if (this.dateRange.startDate && this.dateRange.endDate) {
        query.filters.$and[0].$or.push({
          start_date: {
            $gte: this.dateRange.startDate.toISOString(),
            $lte: this.dateRange.endDate.toISOString()
          }
        });
      }

      if (this.clientFilter) {
        query.filters.$and.push({
          client: {
            uid: {
              $eq: this.clientFilter
            }
          }
        });
      }

      if (this.locationFilter) {
        query.filters.$and.push({
          location: {
            uid: {
              $eq: this.locationFilter
            }
          }
        });
      }

      const showCompleted = this.showCompletedFilter;

      if (!this.filtered) {
        debounce(() => {
          this.$store
            .dispatch('Jobs/getJobs', {
              start: start,
              limit: limit,
              filters: query,
              showCompleted
            })
            .then(({ data }) => {
              this.pageCount = Math.ceil(
                data.meta.pagination.total / this.perPage
              );
              if (this.currentPage > this.pageCount) {
                this.currentPage = 1;
              }
            })
            .finally(() => {
              this.filtered = true;
              this.loading = false;
            })
            .catch((error) => {
              this.$toast
                .error('Error loading jobs', {
                  duration: 5000,
                  position: 'bottom',
                  onClick: () => {
                    this.$refs.errorModal.show(error);
                  }
                })
                .finally(() => {
                  this.filtered = true;
                  this.loading = false;
                })
                .catch((error) => {
                  this.$toast.error('Error loading jobs', {
                    duration: 5000,
                    position: 'bottom',
                    onClick: () => {
                      this.$refs.errorModal.error = error;
                      this.showError = true;
                    }
                  });
                });
            });
        }, 200);
      }
    },
    // ********************************************************************************************************************************************************************************************************************************************************************

    isJobWithinDateRange(job) {
      const jobStartDate = new Date(job.start_date);
      return (
        this.dateRange.startDate <= jobStartDate &&
        this.dateRange.endDate >= jobStartDate
      );
    },

    loadPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    loadNextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },

    gotoPage(page) {
      if (page === '...') {
        return;
      }
      this.currentPage = page;
    },

    clearDates() {
      this.dateRange = {
        startDate: null,
        endDate: null
      };
    },
    resetFilters() {
      this.clearDates();
      this.jobTypeFilter = '';
      this.locationFilter = '';
      this.clientFilter = '';
      this.filter = '';
      this.$router.replace({ query: {} });
    },

    getClientName() {
      const client = this.jobList.find(
        (job) => job.client?.attributes.uid === this.clientFilter
      )?.client.attributes;
      const first_name = client?.first_name;
      const last_name = client?.last_name;
      return first_name
        ? last_name
          ? first_name + ' ' + last_name
          : first_name
        : '';
    }
  },
  watch: {
    filter(newFilter) {
      this.filtered = false;
      if (newFilter === '' && !this.shouldApplyFilters) {
        this.loadJobs();
        this.showCompleted = false;
      } else {
        this.showCompleted = true;
      }
    },
    jobTypeFilter(newFilter) {
      this.filtered = false;
      if (newFilter == '' && !this.shouldApplyFilters) {
        this.loadJobs();
      }
    },
    locationFilter(newFilter) {
      this.filtered = false;
      if (newFilter === '' && !this.shouldApplyFilters) {
        this.loadJobs();
      }
    },
    clientFilter(newFilter) {
      this.filtered = false;
      if (newFilter === '' && !this.shouldApplyFilters) {
        this.loadJobs();
      }
    },
    dateRange: {
      handler(newFilter) {
        this.filtered = false;
        if (
          !newFilter.startDate &&
          !newFilter.endDate &&
          !this.shouldApplyFilters
        ) {
          this.loadJobs();
        }
      },
      deep: true
    }
  }
};

let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};
</script>

<style>
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #2c4b7c !important;
}

.daterangepicker {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.ranges li:hover {
  background-color: #375d9a !important;
  color: #fff !important;
}

.ranges li.active {
  background-color: #375d9a !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.vue-daterange-picker .ranges {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}
</style>
