<template>
    <AppModal style="backdrop-filter: blur(3px);" name="showError" v-model="showError">
        <div class="w-full h-screen flex justify-center items-center max-w-lg m-auto">
            <div class="p-5 flex flex-col gap-5 bg-white w-fit m-auto rounded-lg border">
                <div class="flex gap-2 items-center">
                    <IconPack type="XCircle" class="text-red-500 w-8 h-8 shrink-0" />
                    <h1 class="text-red-500 text-lg font-semibold">{{ error }}</h1>
                </div>
                <div class="flex gap-1 flex-col ">
                    <p class="text-zinc-700 font-semibold">
                        Code: <span class="font-bold ml-2 text-red-400">{{ error.response?.status }}</span>
                    </p>
                    <p class="text-red-400  text-sm font-normal">{{ error.code }}</p>
                </div>
                <div class="flex gap-1 flex-col ">
                    <p class="text-zinc-700 font-semibold">
                        Message:
                    </p>
                    <p class="text-red-400  text-sm font-normal">{{ error.response?.data.error?.message }}</p>
                </div>
                <!-- close button -->
                <!-- {{ console.log(error.toJSON()) }} -->
                <div class="w-fit mt-5">
                    <AppButton @click="showError = false" color="red">Dismiss</AppButton>
                </div>
            </div>
        </div>
    </AppModal>
</template>

<script>
export default {
    name: "ErrorModal",
    emits: ["update:modelValue"],
    data() {
        return {
            error: {
                type: Object,
                default: null
            },
            showError: false
        }
    },
    methods: {
        show(error) {
            this.error = error;
            this.showError = true;
        }
    }
}
</script>