export default {
    SET_INBOX_ITEMS(state, inboxItems) {
        state.inboxItems = inboxItems;
    },
    SET_FETCHING(state, fetching) {
        state.fetching = fetching;
    },
    ADD_OR_UPDATE_INBOX_ITEM(state, inboxItem) {
        const index = state.inboxItems.findIndex(item => item.id === inboxItem.id)

        if (index === -1) {
            state.inboxItems.push(inboxItem);
        } else {
            state.inboxItems[index] = inboxItem;
        }
    },
    MARK_ALL_READ(state) {
        state.inboxItems.forEach(item => item.attributes.is_read = true);
    },
    UPDATE_INBOX(state, inboxItem) {
        if (!state.inboxItems.some(item => item.id === inboxItem.id)) {
            state.inboxItems.push(inboxItem);
        }

        state.inboxItems = state.inboxItems.filter(item => item.id);
    }

}