<template>
  <svg
    stroke-miterlimit="10"
    style="
      fill-rule: nonzero;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
    "
    version="1.1"
    viewBox="0 0 206.59 206.59"
    width="206.59pt"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs />
    <clipPath id="ArtboardFrame">
      <rect height="206.59" width="206.59" x="0" y="0" />
    </clipPath>
    <g clip-path="url(#ArtboardFrame)" id="Layer-1">
      <path
        d="M3.66211e-06 0L206.59 0L206.59 206.59L3.66211e-06 206.59L3.66211e-06 0Z"
        fill="#2c4b7c"
        fill-rule="nonzero"
        opacity="0"
        stroke="none"
      />
      <g opacity="1">
        <path
          d="M105.281 29.4688C104.943 29.4688 104.619 29.5268 104.281 29.5312C145.44 30.0738 178.781 63.6795 178.781 104.969C178.781 125.211 170.874 144.226 156.531 158.5L156.312 158.719C154.575 160.3 139.236 173.838 125.406 174.438C125.767 174.451 126.103 174.531 126.469 174.531C140.532 174.531 156.536 160.336 158.312 158.719L158.531 158.5C172.874 144.226 180.781 125.211 180.781 104.969C180.781 63.3423 146.904 29.4687 105.281 29.4688ZM103.312 44.0312C69.7078 44.0313 42.3438 71.3603 42.3438 104.969C42.3437 121.194 48.651 136.469 60.0938 147.969C66.8724 154.783 75.2621 159.937 84.3438 162.906C88.1588 164.151 90.2477 168.275 89 172.094C88.11 174.816 85.7476 176.557 83.0938 176.938C83.4248 176.984 83.7659 177.094 84.0938 177.094C87.1528 177.094 89.9969 175.162 91 172.094C92.2477 168.275 90.1588 164.151 86.3438 162.906C77.2621 159.937 68.8724 154.783 62.0938 147.969C50.651 136.469 44.3438 121.194 44.3438 104.969C44.3438 71.6966 71.1702 44.6333 104.312 44.0938C103.976 44.0882 103.65 44.0312 103.312 44.0312ZM91.375 58.9375C91.0167 58.9368 90.7087 59.0777 90.375 59.1562C92.3905 59.6288 93.9417 61.377 93.9375 63.5312L93.9062 83.9062L95.9062 83.9062L95.9375 63.5312C95.9423 61.0246 93.8807 58.9423 91.375 58.9375ZM120 59C119.648 58.9993 119.329 59.1427 119 59.2188C121.016 59.6913 122.535 61.4083 122.531 63.5625L122.5 83.9688L124.5 83.9688L124.531 63.5625C124.536 61.0559 122.506 59.0048 120 59ZM134.562 84L134.5 103.812C134.471 118.671 124.13 131.081 110.25 134.281C110.519 142.062 111.97 151.609 116.5 156.656C118.553 158.94 121.089 160 124.469 160C124.831 160 125.238 159.89 125.625 159.844C122.704 159.662 120.36 158.725 118.5 156.656C113.97 151.609 112.519 142.062 112.25 134.281C126.13 131.081 136.471 118.671 136.5 103.812L136.562 84L134.562 84Z"
          fill="#fcb515"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
        <path
          d="M103.293 29.4858C61.6707 29.4858 27.8115 63.3526 27.8114 104.979C27.8114 125.075 35.6149 143.986 49.7885 158.231C58.1809 166.665 68.5706 173.069 79.8273 176.745C80.5775 176.992 81.3382 177.11 82.0856 177.11C85.1447 177.11 87.9936 175.16 88.9967 172.092C90.2443 168.274 88.159 164.171 84.3439 162.926C75.2623 159.957 66.8755 154.787 60.0968 147.973C48.6541 136.474 42.3527 121.205 42.3527 104.979C42.3527 71.3706 69.6922 44.0309 103.297 44.0309C136.902 44.0309 164.241 71.3706 164.241 104.979C164.241 121.265 157.908 136.558 146.404 148.066C141.99 152.033 131.032 160.003 124.454 160.003C121.074 160.003 118.546 158.94 116.493 156.657C111.291 150.861 110.15 139.12 110.196 130.948C110.223 130.947 110.248 130.942 110.274 130.94L95.6473 130.94C95.5741 140.685 96.9445 156.63 105.652 166.347C110.456 171.713 116.96 174.545 124.454 174.545C138.517 174.545 154.523 160.338 156.3 158.721L156.537 158.499C170.88 144.225 178.779 125.221 178.779 104.979C178.779 63.3526 144.915 29.4858 103.293 29.4858Z"
          fill="#f89838"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
        <path
          d="M89.3852 58.957C86.8795 58.9522 84.8638 60.9998 84.859 63.5064L84.8197 83.896L71.8778 83.871L71.8396 103.691C71.8062 121.003 85.804 135.055 103.116 135.088C120.429 135.122 134.481 121.124 134.514 103.812L134.552 83.9919L122.506 83.9686L122.545 63.5791C122.55 61.0725 120.501 59.017 117.996 59.0122C115.49 59.0073 113.474 61.055 113.469 63.5616L113.43 83.9511L93.8953 83.9135L93.9346 63.5239C93.9394 61.0173 91.891 58.9618 89.3852 58.957Z"
          fill="#f89838"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
      </g>
    </g>
    <g clip-path="url(#ArtboardFrame)" id="Layer-1-copy" visibility="hidden">
      <path
        d="M3.66211e-06 0L206.59 0L206.59 206.59L3.66211e-06 206.59L3.66211e-06 0Z"
        fill="#2c4b7c"
        fill-rule="nonzero"
        opacity="0"
        stroke="none"
      />
      <g opacity="0">
        <path
          d="M149.125 180.154L146.704 166.855C141.116 172.805 134.195 177.342 126.511 180.093C117.645 183.152 108.31 184.625 98.9326 184.444C76.9109 184.444 59.5468 177.213 46.8405 162.749C34.1341 148.286 27.7911 128.46 27.8115 103.272C27.8115 77.5736 34.8899 57.6151 49.0467 43.3971C63.2035 29.179 83.0394 22.0903 108.554 22.1312C128.615 22.1312 144.804 27.1157 157.123 37.0847C169.441 47.0537 176.744 61.0982 179.032 79.2181L132.18 79.2181C130.682 73.6918 127.462 68.7866 122.987 65.2145C118.463 61.9069 112.96 60.2129 107.359 60.4036C98.4287 59.8646 89.9138 64.2336 85.1435 71.8026C80.1999 79.4019 77.7382 90.7294 77.7587 105.785C77.7587 119.738 80.2305 130.166 85.1741 137.071C90.1178 143.976 97.6558 147.428 107.788 147.428C114.289 147.622 120.697 145.85 126.174 142.341C130.968 139.238 134.288 134.313 135.366 128.706L113.089 128.706L113.089 95.765L180.779 95.765L180.779 180.154L149.125 180.154Z"
          fill="#000000"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
      </g>
      <g opacity="1">
        <path
          d="M26.7643 104.259C26.8034 61.1113 61.9429 26.0432 105.095 26.0822C125.927 26.1011 145.523 34.2082 160.277 48.9146C169.012 57.6223 175.641 68.3988 179.441 80.0713C179.697 80.8493 179.818 81.638 179.818 82.4128C179.815 85.5839 177.791 88.5354 174.609 89.5724C170.65 90.8622 166.399 88.6966 165.112 84.7405C162.043 75.3234 156.691 66.6243 149.634 59.591C137.724 47.7182 121.901 41.1715 105.081 41.1563C70.2411 41.1248 41.874 69.4404 41.8424 104.276C41.8109 139.113 70.1267 167.479 104.967 167.511C121.85 167.526 137.709 160.975 149.65 149.06C153.766 144.488 162.038 133.136 162.045 126.317C162.048 122.814 160.948 120.192 158.583 118.062C153.355 113.361 143.457 111.851 135.391 111.566C132.06 125.951 119.189 136.677 103.786 136.693L83.2396 136.715L83.2268 124.227L62.0884 124.249C59.4899 124.251 57.3607 122.127 57.3581 119.53C57.3554 116.932 59.4823 114.842 62.0808 114.84L83.2151 114.819L83.1972 94.5667L62.0588 94.5879C59.4603 94.5905 57.3312 92.4667 57.3285 89.8692C57.3258 87.2716 59.4487 85.1858 62.0472 85.1832L83.1856 85.158L83.1735 71.744L103.72 71.7223C119.012 71.7067 131.832 82.2535 135.304 96.4755C145.43 96.7524 159.613 98.7305 168.638 106.833C174.196 111.818 177.126 118.562 177.119 126.331C177.105 140.91 162.363 157.489 160.685 159.329L160.455 159.575C145.645 174.429 125.937 182.6 104.953 182.581C61.8013 182.542 26.7253 147.406 26.7643 104.259Z"
          fill="#fcb515"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
        <path
          d="M26.7723 102.186C26.8113 59.0381 61.9508 23.9699 105.103 24.009C125.935 24.0278 145.531 32.135 160.285 46.8413C169.02 55.549 175.649 66.3255 179.449 77.998C179.704 78.776 179.826 79.5647 179.826 80.3395C179.823 83.5106 177.799 86.4621 174.617 87.4991C170.658 88.7889 166.407 86.6233 165.12 82.6673C162.051 73.2501 156.699 64.5511 149.642 57.5177C137.732 45.6449 121.909 39.0983 105.089 39.083C70.249 39.0515 41.8819 67.3671 41.8504 102.203C41.8188 137.04 70.1347 165.406 104.975 165.438C121.858 165.453 137.717 158.902 149.657 146.987C153.774 142.415 162.046 131.063 162.052 124.244C162.056 120.741 160.956 118.119 158.591 115.989C152.587 110.59 140.418 109.396 131.946 109.437C131.944 109.464 131.939 109.49 131.938 109.518L131.951 94.3548C142.053 94.2881 158.581 95.7236 168.646 104.76C174.204 109.745 177.134 116.489 177.127 124.258C177.113 138.836 162.371 155.416 160.693 157.256L160.463 157.502C145.653 172.356 125.945 180.527 104.961 180.508C61.8092 180.469 26.7332 145.333 26.7723 102.186Z"
          fill="#f89838"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
        <path
          d="M57.3364 87.7957C57.3338 85.1982 59.4583 83.1106 62.0568 83.1079L83.1935 83.0863L83.1797 69.6702L103.726 69.6492C121.673 69.6308 136.226 84.1547 136.245 102.101C136.263 120.048 121.739 134.602 103.792 134.62L83.2462 134.641L83.2335 122.153L62.0968 122.175C59.4983 122.178 57.3694 120.052 57.3668 117.455C57.3641 114.857 59.4887 112.769 62.0871 112.767L83.2238 112.745L83.2031 92.4944L62.0664 92.5161C59.468 92.5187 57.3391 90.3933 57.3364 87.7957Z"
          fill="#f89838"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
      </g>
    </g>
    <g id="Layer-2" visibility="hidden">
      <g opacity="0">
        <path
          d="M3.66211e-06 0L206.59 0L206.59 0L206.59 206.59L206.59 206.59L3.66211e-06 206.59L3.66211e-06 206.59L3.66211e-06 0L3.66211e-06 0Z"
          fill="#2c4b7c"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
        <path
          d="M102.32 88.69L128.57 132.92L128.57 29.23L102.32 88.69Z"
          fill="#f89838"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
        <path
          d="M104.27 117.9L78.02 73.67L78.02 177.36L104.27 117.9Z"
          fill="#f89838"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
        <path
          d="M78.02 73.67L102.32 88.69L128.57 132.92L104.27 117.9L78.02 73.67Z"
          fill="#fcb515"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
      </g>
    </g>
  </svg>
</template>
