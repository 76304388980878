import axios from 'axios';

const http = axios.create();
let maintenanceModeTimer;



/* Response Interceptors */
const interceptResErrors = (err) => {
  // maintenance mode
  if (
    err.response &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.error.message === 'Server is in maintenance mode'
  ) {
    showMaintenanceMessage();
  }

  return Promise.reject(err);
};

const interceptResponse = (res) => {
  return Promise.resolve(res);
};

http.interceptors.response.use(interceptResponse, interceptResErrors);

/* Request Interceptors */
const interceptReqErrors = (err) => Promise.reject(err);
const interceptRequest = (config) => {
  return config;
};
http.interceptors.request.use(interceptRequest, interceptReqErrors);


const showMaintenanceMessage = debounce(() => {
  alert('Server is in maintenance mode, please try again later');
}, 1000);

function debounce(func, delay) {
  return function(...args) {
    clearTimeout(maintenanceModeTimer);
    maintenanceModeTimer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  }
}

export default http;
