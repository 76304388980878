export default {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },

  SET_PRODUCT_CATEGORIES(state, productCategories) {
    state.productCategories = productCategories;
  }

};
