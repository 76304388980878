<template>
  <div
    v-tippy="{
      content: document.url
        ? `${creatorName ? creatorName + ' -' : ''} ${moment(createdAt).format(
            'DD MMM YYYY'
          )}`
        : 'No preview available',
      delay: comment ? 3000 : 300
    }"
    v-if="document?.file_type == 'image'"
    class="flex flex-col items-center justify-start gap-2"
  >
    <div
      @click="openImageCarousel"
      class="relative border group overflow-hidden items-center flex rounded-lg"
      :class="{
        'w-40 h-40': !readonly,
        'w-28 h-28': readonly,
        '!w-full !h-full ': size == 'large',
        '!border-none p-12': !!document?.url == false
      }"
    >
      <img
        v-if="document?.url"
        :src="document?.url"
        class="object-cover w-full h-full"
        :class="{
          'object-contain': size == 'large'
        }"
      />
      <div
        v-else
        class="flex flex-col gap-2 justify-center items-center w-full"
      >
        <IconPack type="BoltSlash" class="w-6 h-6 text-gray-400 m-auto" />
        <p class="text-sm text-gray-500">No Image</p>
      </div>
      <div
        class="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-black opacity-0 group-hover:opacity-50 flex items-center justify-center"
      >
        <IconPack solid type="MagnifyingGlassPlus" class="w-6 h-6 text-white" />
      </div>
    </div>
    <div
      v-if="document?.title"
      :class="{
        'w-40': !readonly,
        'w-28': readonly,
        '!w-full !max-w-sm': size == 'large'
      }"
    >
      <p
        v-tippy="{ content: document?.title, delay: comment ? 3000 : 300 }"
        class="w-full text-gray-400 text-center text-sm"
      >
        {{ shortenedTitle }}
      </p>
    </div>
  </div>
  <div
    v-tippy="{
      content: document.url
        ? `${creatorName ? creatorName + ' -' : ''} ${moment(createdAt).format(
            'DD MMM YYYY'
          )}`
        : 'No preview available',
      delay: comment ? 300 : 3000
    }"
    @click="openPDF"
    v-if="document?.file_type == 'pdf'"
  >
    <div
      class="w-40 h-40 relative p-2 bg-white border shadow-sm hover:-translate-y-[2px] transition-all duration-300 hover:shadow-md border-gray-200 items-center justify-center flex flex-col gap-5 rounded-lg"
      :class="{ '!border-none': !document?.url }"
    >
      <p
        class="rounded-lg text-center text-primary-600 text-sm font-black z-10"
      >
        {{ shortenedTitle }}
      </p>
      <div
        class="rounded-full bg-secondary px-4 py-2 w-fit flex items-center justify-center z-10"
      >
        <IconPack type="Document" class="w-4 h-4 text-white mr-1" />
        <p class="text-white font-bold text-xs">PDF</p>
      </div>
    </div>
  </div>

  <AppModal name="EditLabelModal" v-model="showEditLabel">
    <div class="w-full h-screen flex" style="backdrop-filter: blur(3px)">
      <div class="w-full max-w-lg bg-white rounded-md m-auto p-4">
        <TextInput ref="labelEditor" v-model="label" multiline label="Label" />
        <div class="flex justify-end gap-4 mt-4">
          <div>
            <AppButton @click="updateLabel('cancel')" color="gray"
              >Cancel</AppButton
            >
          </div>
          <div>
            <AppButton @click="updateLabel('save')" color="primary"
              >Save</AppButton
            >
          </div>
        </div>
      </div>
    </div>
  </AppModal>

  <PDFModal
    :editable="!readonly"
    :deletable="!readonly"
    v-model="showPDF"
    :url="pdfURL"
    @delete="deleteDocument"
    @edit-label="editLabel"
  />
</template>

<script>
import PDFModal from '@/components/Modals/PDFModal.vue';

export default {
  components: {
    PDFModal
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    comment: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  emits: ['openImageCarousel', 'delete-doc', 'refresh'],
  data() {
    return {
      showPDF: false,
      pdfURL: '',
      showEditLabel: false,
      label: ''
    };
  },
  computed: {
    isLandscape() {
      //get width and height of the image
      const img = new Image();
      img.src = this.document?.url;
      const width = img.width;
      const height = img.height;
      return width > height;
    },
    shortenedTitle() {
      if (!this.document?.title) return '';
      const words = this.document?.title.split(' ');
      if (words.length > 7) {
        return words.slice(0, 7).join(' ') + '...';
      }
      return this.document?.title;
    },
    creatorName() {
      if (
        this.document?.creator?.data?.attributes.first_name &&
        this.document?.creator?.data?.attributes.last_name
      )
        return `${this.document?.creator?.data?.attributes.first_name} ${this.document?.creator?.data?.attributes.last_name}`;

      return false;
    },
    createdAt() {
      return this.document?.createdAt;
    }
  },
  methods: {
    openImageCarousel() {
      this.$emit('openImageCarousel', this.document);
    },
    openPDF() {
      this.pdfURL = this.document?.url;
      this.showPDF = true;
    },
    deleteDocument() {
      this.$store
        .dispatch('Documents/deleteStaticDocument', this.document?.id)
        .then(() => {
          this.showPDF = false;
          this.$emit('delete-doc', this.document);
          this.$toast.success('Document deleted successfully');
        });
    },
    editLabel() {
      this.showEditLabel = true;
      this.label = this.document?.title;
    },
    updateLabel(action) {
      if (action === 'save') {
        if (!this.label) {
          this.$refs.labelEditor.errorFocus();
          this.$refs.labelEditor.errorMessage = "Label is required for PDF's";
        } else {
          this.$store
            .dispatch('Documents/upadteStaticDocument', {
              id: this.document?.id,
              attributes: {
                title: this.label
              }
            })
            .then(() => {
              this.$emit('refresh', 'Label updated successfully');
            });

          this.showEditLabel = false;
        }
      }
      if (action === 'cancel') {
        this.showEditLabel = false;
      }
    }
  },
  watch: {
    label() {
      this.$refs.labelEditor.errorMessage = '';
    }
  }
};
</script>
