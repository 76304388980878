<template>
  <div class="flex h-full justify-center items-center flex-col h-screen">
    <div
      class="flex flex-col w-full max-w-md px-4 py-8 sm:px-6 md:px-8 lg:px-10"
    >
      <EWMainLogo class="justify-self-start self-start mb-10 m-auto w-48" />

      <div class="m-auto w-full">
        <AppTitle class="self-start text-xl font-bold sm:text-2xl"
          >Reset password</AppTitle
        >
        <div>
          <div class="flex flex-col mb-6">
            <TextInput v-model="email" type="email" placeholder="Email address"
              ><template #icon
                ><IconPack type="LockOpen" class="w-5 h-5" /></template
            ></TextInput>
          </div>

          <div class="flex w-full">
            <AppButton
              color="primary"
              class="justify-center"
              :pulse="loading"
              @click="resetPassword()"
              >Reset</AppButton
            >
          </div>
        </div>
      </div>
    </div>
    <router-link
      to="/login"
      class="text-gray-500 font-medium hover:text-gray-700 transition-colors duration-300"
      ><div class="flex flex-row items-center">
        <IconPack type="ArrowLeft" class="w-5 h-5 mr-2" /> Back to login
      </div></router-link
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: '',
      loading: false
    };
  },
  methods: {
    resetPassword() {
      this.loading = true;
      this.$store
        .dispatch('Authentication/forgotPassword', {
          email: this.email
        })
        .then(() => {
          this.$toast.success(
            'You should see a reset link in your email inbox'
          );
          this.$router.push('/login');
          this.loading = false;
        })
        .catch((error) => {
          this.toastApiErrors(error);
          this.loading = false;
        });
    }
  }
};
</script>
