<template>
  <div
    class="static hidden lg:block z-40 lg:max-w-[90px] 2xl:max-w-[250px] 3xl:max-w-[300px] shadow rounded-b-lg top-20 left-0 w-full border-r p-7 bg-white h-fit transition-all duration-400 ease-in-out transform"
  ></div>
  <div
    class="fixed z-50 lg:max-w-[90px] 2xl:max-w-[250px] 3xl:max-w-[300px] shadow rounded-b-lg left-0 w-full border-r p-7 bg-white lg:min-h-screen transition-all duration-400 ease-in-out transform"
    :class="{
      '-top-full': !showSidebar && windowWidth < 1024,
      'top-20': showSidebar
    }"
  >
    <div v-if="hasBack" class="text-primary-600">
      <AppButton @click="goBack" color="primary" outline class="w-max mb-4">
        <IconPack type="ArrowLeft" class="w-4 h-4 mr-2" />Back
      </AppButton>
    </div>
    <div
      class="h-3/4 w-full lg:w-fit 2xl:w-full overflow-y-scroll scrollbar-hide"
    >
      <div
        v-if="!hasSlot"
        class="flex flex-wrap items-start space-y-4 text-gray-700 font-medium"
      >
        <SideBarItem v-if="hasCompany && company?.attributes.plan_code != 'BASIC'" to="/jobs" icon="Briefcase"
          >Jobs</SideBarItem
        >
        <SideBarItem v-if="hasCompany && company?.attributes.plan_code != 'BASIC'" to="/documents" icon="DocumentText"
          >Documents</SideBarItem
        >
        <SideBarItem v-if="hasCompany && company?.attributes.plan_code != 'BASIC'" to="/contacts" icon="UserCircle"
          >Contacts</SideBarItem
        >
        <SideBarItem
          v-if="hasCompany"
          to="/company"
          selected
          icon="BuildingOffice2"
          >Company</SideBarItem
        >

        <SideBarItem
          v-if="hasConnectAccount && hasCompany"
          to="/connect/plants"
          icon="HomeModern"
          >Manage Plants
        </SideBarItem>
        <SideBarItem to="/subscription" selected icon="CreditCard"
          >Subscription</SideBarItem
        >
        <SideBarItem to="/invite/get-the-app" icon="DevicePhoneMobile"
          >Get the App</SideBarItem
        >
      </div>
      <div v-else>
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import SideBarItem from './SidebarItem.vue';
export default {
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
    hasBack() {
      return false;
    },
    showSidebar() {
      return this.$store.getters['App/showSidebar'];
    }
  },
  components: {
    SideBarItem
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      if (this.windowWidth > window.innerWidth && window.innerWidth < 1024)
        this.$store.commit('App/SET_SIDEBAR', false);

      this.windowWidth = window.innerWidth;
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>
