<template>
    <router-link class="w-full" :to="to">
        <div class="
        flex
        items-center
        p-2
        w-full
        lg:w-fit
        2xl:w-full
        rounded-md
        cursor-pointer
        hover:bg-gray-100
        transition-color
        duration-200
      " :class="{
          'bg-gray-200  text-black hover:bg-gray-200': route == to
      }">
            <tippy class="hidden lg:block 2xl:hidden" placement="left">
                <IconPack :type="icon" class="w-6 h-6 3xl:mr-2" />
                <template #content>
                    <slot />
                </template>
            </tippy>

            <IconPack :type="icon" class="w-6 h-6 mr-2 lg:hidden 2xl:block" />
            <div class="lg:hidden 2xl:block">
                <slot />
            </div>
        </div>
    </router-link>
</template>
<script>
import Tippy from 'vue-tippy';

export default {
    components: [Tippy],
    props: {
        icon: {
            type: String,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        },
        to: {
            type: String,
            default: null
        }
    },
    computed: {
        route() {
            return this.$route.fullPath;
        }
    }
};
</script>