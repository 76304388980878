<template>
  <div
    class="z-[60] h-20 shadow-md flex items-center justify-between bg-white sticky top-0"
  >
    <div class="topbar flex items-center w-2/3 pl-3">
      <button
        class="lg:hidden border border-gray-300 rounded-md text-primary-600 w-10 h-10 relative focus:outline-none bg-white"
        @click="setSidebar(!showSidebar)"
      >
        <span class="sr-only">Open main menu</span>
        <div
          class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          <span
            aria-hidden="true"
            class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
            :class="{
              'rotate-45': showSidebar,
              ' -translate-y-1.5': !showSidebar
            }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
            :class="{ 'opacity-0': showSidebar }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
            :class="{
              '-rotate-45': showSidebar,
              ' translate-y-1.5': !showSidebar
            }"
          ></span>
        </div>
      </button>
      <EWMainLogo class="h-24 p-4 ml-6" />
    </div>

    <div class="flex items-center gap-6">
      <div
        @click="$router.push({ name: 'Inbox' })"
        class="relative cursor-pointer"
        v-tippy="{ content: 'Inbox' }"
      >
        <IconPack
          type="Inbox"
          class="w-7 h-7 text-secondary-400 hover:-translate-y-0.5 transition-all duration-300"
        />
        <div
          v-if="unreadItems.length > 0"
          class="absolute -top-1.5 -right-1.5 rounded-full bg-red-500 w-5 h-5 p-1 flex items-center justify-center"
        >
          <p v-if="inboxItems" class="text-xs text-white">
            {{ unreadItems?.length }}
          </p>
          <AppSpinner v-else class="w-4 h-4 text-white" />
        </div>
      </div>
      <ProfileDropdown v-if="loggedIn" class="mr-6" />
    </div>
  </div>
</template>
<script>
import ProfileDropdown from './ProfileDropdown.vue';
export default {
  components: {
    ProfileDropdown
  },
  computed: {
    showSidebar() {
      return this.$store.getters['App/showSidebar'];
    },
    inboxItems() {
      return this.$store.getters['InboxItems/inboxItems'];
    },
    unreadItems() {
      return this.inboxItems?.filter((item) => !item?.attributes?.is_read);
    }
  },
  methods: {
    setSidebar(value) {
      this.$store.commit('App/SET_SIDEBAR', value);
    }
  }
};
</script>
