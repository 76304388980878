<template>
  <div class="w-full">
    <AppCard
      @click="navigateToJob(job.uid)"
      clickable
      class="w-full relative"
      :class="{ 'md:rounded-l-none': jobType }"
    >
      <div
        v-if="jobType"
        class="flex items-center justify-center absolute -top-2 z-10 md:top-0 left-0 md:-left-8 shadow-sm rounded-t-md md:rounded-none md:rounded-l-md w-full md:w-8 md:h-full"
        :style="`background-color: ${jobType.color}`"
      >
        <p class="md:-rotate-90 whitespace-nowrap font-semibold text-white">
          {{ jobType.name }}
        </p>
      </div>
      <div class="w-full justify-between mt-3 md:mt-0 flex items-center">
        <tippy>
          <LocationCard
            v-if="job.location"
            @click.stop="applyLocationFilter(job.location.attributes)"
            :location="job.location.attributes"
            :title="job.title"
            class="shadow-none hover:shadow hover:-translate-y-1 border-none transition-all duration-300"
          >
            <template v-if="job.reference_number" #reference_number>
              {{ job.reference_number }}
            </template>
          </LocationCard>
          <template #content>
            <p class="font-medium">
              Click to view all jobs for
              {{ job.location?.attributes.address_line }}
            </p>
          </template>
        </tippy>
      </div>

      <template #topRight>
        <div
          class="hidden md:flex gap-2 z-10 absolute group top-3 right-3"
          :class="{
            'translate-y-9 md:translate-y-6': completed
          }"
        >
          <SearchableDropdown
            @selected="handleSelection"
            ref="actions"
            noborder
            action
            @click.stop
            class="rounded-md"
            :options="options"
          >
            <template #iconLeft>
              <IconPack
                type="EllipsisHorizontalCircle"
                class="w-8 h-8 group-hover:text-primary-500 bg-white p-1 rounded-md hover:bg-gray-100 transition-all duration-300 text-gray-500 cursor-pointer"
              />
            </template>
          </SearchableDropdown>
        </div>
      </template>

      <template #chip>
        <div
          v-if="completed"
          class="px-2 py-1.5 translate-y-7 md:translate-y-0 gap-2 inline-flex items-center font-bold rounded-full shadow-md text-center w-max text-white bg-green-500"
        >
          <IconPack solid type="CheckCircle" class="w-5 h-5 text-white" />
          <p class="text-sm">Completed</p>
        </div>
      </template>

      <template #bottom>
        <div
          class="flex justify-between flex-wrap gap-2 items-center border-t mt-2 pt-2 w-full"
        >
          <!-- Schedule -->
          <div class="flex flex-wrap mt-2 md:mt-0 gap-2 md:gap-5">
            <AppChip class="bg-secondary-500 h-8 font-medium">
              <template #icon>
                <IconPack type="Clock" class="w-4 h-4 text-white" />
              </template>
              <p class="">{{ getTime }}</p>
            </AppChip>
            <AppChip class="bg-primary-500 h-8 font-medium">
              <template #icon>
                <IconPack type="Calendar" class="w-4 h-4 text-white" />
              </template>
              <p class="flex gap-1.5">
                <span v-for="(date, i) in dateCreated" :key="i">
                  {{ date }}
                </span>
              </p>
            </AppChip>
          </div>
          <!-- Contact -->
          <div v-if="job.client" class="flex justify-center items-center">
            <tippy>
              <ContactCard
                @click.stop="applyClientFilter(job.client.attributes)"
                condensed
                :contact="job.client.attributes"
                class="hover:-translate-y-1 hover:shadow shadow-none border-none transition-all duration-300"
              />
              <template #content>
                <p class="font-medium">
                  Click to view all jobs for
                  {{ job.client.attributes.first_name }}
                  {{ job.client.attributes.last_name }}
                </p>
              </template>
            </tippy>
          </div>
          <!-- Document Status -->
          <div
            class="group w-full md:w-1/3 hidden lg:flex justify-end relative"
          >
            <div class="flex w-full h-5 rounded-full overflow-hidden">
              <div
                :style="{ width: calculateGraphSegmentWidth(openCount) }"
                class="h-full bg-gray-300"
              ></div>
              <div
                :style="{ width: calculateGraphSegmentWidth(pendingCount) }"
                class="h-full bg-secondary-500"
              ></div>
              <div
                :style="{ width: calculateGraphSegmentWidth(approvedCount) }"
                class="h-full bg-green-500"
              ></div>
              <div
                :style="{ width: calculateGraphSegmentWidth(lockedCount) }"
                class="h-full bg-primary-500"
              ></div>
            </div>
            <div
              class="w-[160%] h-fit p-4 z-20 rounded-lg border border-gray-300 bg-white invisible opacity-0 absolute bottom-6 right-0 group-hover:visible group-hover:opacity-100 transition-all duration-300"
            >
              <!-- Group and display documents by status -->
              <div class="w-full grid grid-cols-2 gap-y-2">
                <!-- Locked Documents -->
                <div
                  v-if="lockedDocuments?.length > 0"
                  class="w-full p-2 flex flex-col gap-1"
                >
                  <h3 class="font-semibold text-primary-500">Locked:</h3>
                  <div
                    v-for="document in lockedDocuments"
                    :key="document.id"
                    class="flex items-center gap-2"
                  >
                    <div class="w-1 h-3 rounded-full bg-primary-500"></div>
                    <p class="text-sm whitespace-nowrap">
                      {{ document.attributes.name.split('-')[1] }}
                    </p>
                  </div>
                </div>
                <!-- Approved Documents -->
                <div
                  v-if="approvedDocuments?.length > 0"
                  class="w-full p-2 flex flex-col gap-1"
                >
                  <h3 class="font-semibold text-green-500">Approved:</h3>
                  <div
                    v-for="document in approvedDocuments"
                    :key="document.id"
                    class="flex items-center gap-2"
                  >
                    <div class="w-1 h-3 rounded-full bg-green-500"></div>
                    <p class="text-sm whitespace-nowrap">
                      {{ document.attributes.name.split('-')[1] }}
                    </p>
                  </div>
                </div>
                <!-- Open Documents -->
                <div
                  v-if="openDocuments?.length > 0"
                  class="w-full p-2 flex flex-col gap-1"
                >
                  <h3 class="font-semibold text-gray-400">Open:</h3>
                  <div
                    v-for="document in openDocuments"
                    :key="document.id"
                    class="flex items-center gap-2"
                  >
                    <div class="w-1 h-3 rounded-full bg-gray-400"></div>
                    <p class="text-sm whitespace-nowrap">
                      {{ document.attributes.name.split('-')[1] }}
                    </p>
                  </div>
                </div>
                <!-- Pending Documents -->
                <div
                  v-if="pendingDocuments?.length > 0"
                  class="w-full p-2 flex flex-col gap-1"
                >
                  <h3 class="font-semibold text-secondary-500">Pending:</h3>
                  <div
                    v-for="document in pendingDocuments"
                    :key="document.id"
                    class="flex items-center gap-2"
                  >
                    <div class="w-1 h-3 rounded-full bg-secondary-500"></div>
                    <p class="text-sm whitespace-nowrap">
                      {{ document.attributes.name.split('-')[1] }}
                    </p>
                  </div>
                </div>
              </div>
              <p class="mt-2">
                <span class="font-semibold text-primary-500">Total: </span>
                <span class="text-sm">{{
                  openCount + pendingCount + approvedCount + lockedCount
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </template>
    </AppCard>
    <ErrorModal ref="errorModal" />
  </div>
</template>

<script>
import ContactCard from '@/components/Contacts/ContactCard.vue';
import LocationCard from '@/components/Location/LocationCard.vue';
import moment from 'moment';
import { Tippy } from 'vue-tippy';
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import ErrorModal from '@/components/Modals/ErrorModal.vue';

export default {
  components: {
    ContactCard,
    LocationCard,
    Tippy,
    ErrorModal
  },
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  computed: {
    options() {
      if (this.company?.attributes?.subscription_status === 'payment_failed') {
        return [];
      }
      if (this.isAdmin) {
        return ['Edit Job', 'Delete Job'];
      } else {
        return ['Edit Job'];
      }
    },
    completed() {
      if (this.job.current_status) {
        return this.job.current_status.attributes.status === 'completed';
      } else {
        return false;
      }
    },
    dateCreated() {
      return moment(this.job.start_date).format('Do MMM YY').split(' ');
    },
    getTime() {
      return moment(this.job.start_date).format('HH:mm');
    },
    openCount() {
      return this.getStatusCount('Open');
    },
    pendingCount() {
      return this.getStatusCount('Pending');
    },
    approvedCount() {
      return this.getStatusCount('Approved');
    },
    lockedCount() {
      return this.getStatusCount('Locked');
    },
    lockedDocuments() {
      return this.job.documents?.filter(
        (document) =>
          this.status(document.attributes) === 'Locked' &&
          !document.attributes.is_deleted
      );
    },
    approvedDocuments() {
      return this.job.documents?.filter(
        (document) =>
          this.status(document.attributes) === 'Approved' &&
          !document.attributes.is_deleted
      );
    },
    pendingDocuments() {
      return this.job.documents?.filter(
        (document) =>
          this.status(document.attributes) === 'Pending' &&
          !document.attributes.is_deleted
      );
    },
    openDocuments() {
      return this.job.documents?.filter(
        (document) =>
          this.status(document.attributes) === 'Open' &&
          !document.attributes.is_deleted
      );
    },
    jobType() {
      return this.job.job_type ? this.job.job_type.attributes : null;
    }
  },
  methods: {
    getStatusCount(status) {
      return this.job.documents?.filter(
        (document) =>
          this.status(document.attributes) === status &&
          !document.attributes.is_deleted
      ).length;
    },

    status(document) {
      return document.is_locked
        ? 'Locked'
        : document.is_approved && !document.is_locked
        ? 'Approved'
        : document.user_approvals?.data.length <
            document.approvals_required?.data.length &&
          document.user_approvals?.data.length > 0 &&
          document.user_approvals?.data.some(
            (approval) => approval.attributes.confirmed === true
          )
        ? 'Pending'
        : 'Open';
    },

    calculateGraphSegmentWidth(count) {
      const totalSegments =
        this.openCount +
        this.pendingCount +
        this.approvedCount +
        this.lockedCount;
      const percentageWidth = (count / totalSegments) * 100;

      return `${percentageWidth}%`;
    },

    applyClientFilter(client) {
      let query = { ...this.$route.query, client: client.uid };
      this.$router.replace({ query });
    },

    applyLocationFilter(location) {
      this.$router.push({ query: { location: location.uid } });
    },

    navigateToJob(jobId) {
      this.$router.push(`/job/${jobId}`);
    },

    editJob(jobId) {
      this.$router.push(`/jobs/edit-site/${jobId}`);
    },
    deleteJob() {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        title: 'Delete Job',
        icon: 'Trash',
        message: 'Are you sure you want to delete this job?',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      });

      debounce(() => {
        reveal();
      }, 100);

      onConfirm(() => {
        this.$store
          .dispatch('Jobs/deleteJob', this.job.id)
          .then(() => {
            this.$toast.success('Job deleted successfully');
          })
          .catch((error) => {
            this.$toast.error('Failed to delete job', {
              position: 'bottom',
              onClick: () => {
                this.$refs.errorModal.show(error);
              }
            });
          });
      });
    },
    handleSelection(option) {
      if (option === 'Edit Job') {
        this.editJob(this.job.uid);
      } else if (option === 'Delete Job') {
        this.deleteJob();
      }
    }
  }
};

let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(func, delay);
};
</script>
