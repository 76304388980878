import '@/assets/base.css';
import StrapiUtils from '@/utils/StrapiUtils';
import TailwindUtils from '@/utils/TailwindUtils';
import Toaster from '@meforma/vue-toaster';
import '@vueform/multiselect/themes/default.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VACL from 'vacl';
import VueGtag from 'vue-gtag';
import moment from 'moment';
import * as ConfirmDialog from 'vuejs-confirm-dialog'
import * as Sentry from "@sentry/vue";


import copyText from '@meforma/vue-copy-to-clipboard';

import { vfmPlugin } from 'vue-final-modal';

const app = createApp(App);

import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import VueTippy from 'vue-tippy';

app.use(VueTippy, {
  defaultProps: {
    placement: 'top',
    animation: 'scale-subtle',
    arrow: false,
    distance: 5,
    inertia: true
  }
});

// import components
import HeroIcons from '@/components/Dashboard/HeroIcons.vue';
import AppButton from '@/components/EW/AppButton.vue';
import AppChip from '@/components/EW/AppChip.vue';
import AppSpinner from '@/components/EW/AppSpinner.vue';
import EWMainLogo from '@/components/EW/MainLogo.vue';
import TextInput from '@/components/EW/TextInput.vue';
import AppStores from '@/components/EW/AppStores.vue';
import SearchableDropdown from '@/components/EW/SearchableDropdown.vue';
import AppCard from '@/components/Dashboard/AppCard.vue';
import AppTitle from '@/components/Dashboard/AppTitle.vue';
import AdminSideBar from '@/components/Dashboard/AdminSideBar.vue';
import TopBar from '@/components/Dashboard/TopBar.vue';


app.component('EWMainLogo', EWMainLogo);
app.component('TextInput', TextInput);
app.component('AppButton', AppButton);
app.component('AppChip', AppChip);
app.component('AppSpinner', AppSpinner);
app.component('IconPack', HeroIcons);
app.component('AppStores', AppStores);
app.component('SearchableDropdown', SearchableDropdown);
app.component('AppTitle', AppTitle);
app.component('TopBar', TopBar);
app.component('AdminSideBar', AdminSideBar);
app.component('AppCard', AppCard);

import Multiselect from '@vueform/multiselect';
app.component('Multiselect', Multiselect);

// import page layouts
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import FullPageLayout from '@/layouts/FullPageLayout.vue';
import PrintPageLayout from '@/layouts/PrintPageLayout.vue';

// register page layouts
app.component('FullPageLayout', FullPageLayout);
app.component('DashboardLayout', DashboardLayout);
app.component('PrintPageLayout', PrintPageLayout);

// import tailwind to JS
// import colors from '../colors.js';
// app.config.globalProperties.colors = colors;

// Sentry.init({
//   dsn: "https://ca78653b0fef5b02aab841076154f4fd@o4504054890496000.ingest.us.sentry.io/4506915210723328",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/api.ew.energy/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


app
  .use(ConfirmDialog)
  .use(store)
  .use(router)
  .use(Toaster, {
    position: 'bottom-right',
    duration: 3000,
    queue: true,
    maxToasts: 12
  })
  .use(StrapiUtils)
  .use(TailwindUtils)
  .use(copyText)
  .use(VACL)
  .use(
    vfmPlugin({
      key: '$modal',
      componentName: 'AppModal',
      dynamicContainerName: 'ModalsContainer'
    })
  )
  .use(VueGtag, {
    config: {
      id: 'G-2RHWNLKS8X',
      appName: 'Energy Warehouse Dashboard',
      bootstrap: true,
      enabled: true,
      pageTrackerScreenviewEnabled: true
    }
  });

import axios from '././utils/Axios';

app.config.globalProperties.$api = axios;

app.config.globalProperties.moment = moment;

store.$api = axios;

app.mount('#app');

app.mixin({
  computed: {
    hasCompany() {
      if (this.company?.id) {
        localStorage.setItem('company_id', this.company?.id);
      }

      return localStorage.getItem('company_id') || this.company?.id;
    },
    hasConnectAccount() {
      if (this.company?.attributes?.connect_account?.data?.id) {
        localStorage.setItem('has_connect_account', this.company?.id);
      }

      return localStorage.getItem('has_connect_account') === this.company?.id || this.company?.attributes?.connect_account?.data?.id
    },
    isBetaUser() {
      return this.user?.beta_user;
    },
    loggedIn() {
      return this.$store.getters['Authentication/isLoggedIn'];
    },
    company() {
      return this.$store.getters['Company/company'];
    },
    isTrial() {
      if (!this.company?.attributes) return false;

      if (this.company?.attributes?.subscription_status !== 'trial')
        return false;

      return true;
    },
    isActiveTrial() {
      if (!this.isTrial) return false;

      if (moment(this.company.attributes.expiry) < moment()) return false;

      return true;
    },
    user() {
      return this.$store.getters['Authentication/user'];
    },
    isAdmin() {
      return !this.company?.attributes
        ? false
        : this.company?.attributes?.admins?.data?.find(
          (admin) => admin.id === this.user?.id
        );
    },
    jobTypes() {
      return this.$store.getters['Jobs/jobTypes'];
    },
  },

  methods: {
    validateCellphone(cellphone) {
      if (!cellphone) return false;
      const cellphoneRegex = /^(\+27|0)[6-8][0-9]{8}$/;
      return cellphoneRegex.test(cellphone);
    },
    validateEmail(email) {
      if (!email) return false;
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    },
    validateForm(event) {
      if (!this.name) {
        this.$toast.error('Please enter a company name');
        event.preventDefault();
        return false;
      }
      if (!this.validateEmail(this.email)) {
        this.$toast.error('Please enter a valid email address');
        event.preventDefault();
        return false;
      }
      if (!this.validateCellphone(this.contact_number)) {
        this.$toast.error('Please enter a valid contact number');
        event.preventDefault();
        return false;
      }
      this.redirecting = true;
      return true;
    },
    formatPrice(price, addVat = false, symbol = 'R') {
      let value = price;
      if (addVat) {
        value = Math.round(price * 1.15);
      }

      if (!price) {
        return symbol + ' 0';
      }

      return symbol + ' ' + this.formatLargeNumber(value, 2);
    },
    formatLargeNumber(number, decimalPlaces = 0) {
      if (Number.isNaN(number / 1)) {
        return number;
      }
      if (!number) {
        return '0';
      }

      let output;
      if (number < 100000) {
        output = number
          .toLocaleString(undefined, {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces
          })
          .replace(/\.00$/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      } else if (number < 1000000) {
        output =
          (number / 1000)
            .toFixed(0)
            .replace(/\.00$/, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + 'K';
      } else {
        output =
          (number / 1000000)
            .toFixed(decimalPlaces)
            .replace(/\.00$/, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + 'M';
      }

      return output;
    }
  }
});
