<template>
  <div class="p-4 flex items-center flex-col w-full">
    <label for="cancellationReason" class="block font-bold mb-2"
      >Why are you cancelling?</label
    >

    <select
      v-model="selectedReason"
      @change="handleReasonChange"
      class="border rounded p-2"
    >
      <option value="" disabled>Select a reason</option>
      <option v-for="reason in reasons" :key="reason" :value="reason">
        {{ reason }}
      </option>
      <option value="other">Other</option>
    </select>
    <div v-if="selectedReason === 'other'" class="mt-2 w-full">
      <label for="otherReason" class="block font-bold mb-2"
        >Specify other reason:</label
      >
      <textarea
        v-model="otherReason"
        class="w-full p-2 border rounded"
        rows="3"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedReason: '',
      otherReason: '',
      reasons: [
        'Too expensive',
        'Not using it enough',
        'Too complicated',
        'Found an alternative solution',
        'Technical issues'
      ]
    };
  },
  watch: {
    selectedReason: 'handleReasonChange',
    otherReason: 'handleReasonChange'
  },
  methods: {
    handleReasonChange() {
      if (this.selectedReason !== 'other') {
        this.otherReason = '';
      }
      const reasonToSend =
        this.selectedReason === 'other'
          ? this.otherReason
          : this.selectedReason;
      this.$emit('reason-selected', reasonToSend);
    }
  }
};
</script>
