import PlantsRoutes from './Plants';

const baseRoutes = [
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    redirect: '/invite/get-the-app'
  }
];

const routes = baseRoutes
  .concat(PlantsRoutes)


export default routes;
