export default {
  types: (state) => state.types,
  getTypeByUID: (state) => (uid) => {
    return state.types ? state.types.find((i) => i.attributes.uid === uid) : null;
  },
  generatedPDF: (state) => state.generatedPDF,
  currentDocument: (state) => state.currentDocument,
  documents: (state) => state.documents,
  categories: (state) => state.categories,
};
