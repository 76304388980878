<template>
    <transition name="fade" mode="out-in" appear>
        <div class="flex justify-between mt-4 transition-opacity duration-200">
            <button class="group disabled:opacity-0 transition-opacity duration-300 select-none cursor-pointer flex gap-2"
                @click="$emit('previous')" :disabled="currentPage <= 1">
                <IconPack type="ChevronLeft"
                    class="w-6 h-6 transition-all duration-300  text-primary-500 group-hover:text-primary-100" />
                <p class="text-primary-500 transition-all duration-300 group-hover:text-primary-100">Previous
                    Page
                </p>
            </button>
            <div class="flex gap-4">
                <div v-for="page in condensedPageNumbers" :key="page">
                    <div class="group cursor-pointer" @click="$emit('goto', page)" :class="{
                        'opacity-40': this.currentPage != page,
                    }">
                        <p class="text-primary-500 transition-colors duration-300 group-hover:text-primary-100">
                            {{
                                page
                            }}</p>
                    </div>
                </div>
            </div>
            <button class="group disabled:opacity-0 transition-opacity duration-300 select-none cursor-pointer flex gap-2"
                @click="$emit('next')" :disabled="currentPage == pageCount">
                <p class="text-primary-500 transition-all duration-300 group-hover:text-primary-100">Next Page
                </p>
                <IconPack type="ChevronRight"
                    class="w-6 h-6 transition-all duration-300 ml-1 text-primary-500 group-hover:text-primary-100" />
            </button>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        pageCount: {
            type: Number,
            required: true
        },
    },
    emits: ['previous', 'next', 'goto'],
    computed: {
        condensedPageNumbers() {
            const visiblePages = 5;
            const totalPageCount = this.pageCount;
            const currentPage = this.currentPage;

            if (totalPageCount <= visiblePages) {
                return Array.from({ length: totalPageCount }, (_, i) => i + 1);
            }

            let firstPage;
            let lastPage;

            if (currentPage <= Math.floor(visiblePages / 2) + 1) {
                firstPage = 1;
                lastPage = visiblePages;
            } else if (currentPage >= totalPageCount - Math.floor(visiblePages / 2)) {
                firstPage = totalPageCount - visiblePages + 1;
                lastPage = totalPageCount;
            } else {
                firstPage = currentPage - Math.floor(visiblePages / 2);
                lastPage = firstPage + visiblePages - 1;
            }

            const condensedPages = [];

            if (firstPage > 1) {
                condensedPages.push(1);
                if (firstPage > 2) {
                    condensedPages.push('...');
                }
            }

            for (let i = firstPage; i <= lastPage; i++) {
                condensedPages.push(i);
            }

            if (lastPage < totalPageCount - 1) {
                condensedPages.push('...');
            }

            if (lastPage < totalPageCount) {
                condensedPages.push(totalPageCount);
            }

            return condensedPages;
        },

    },
}
</script>