<template>
  <div>
    <div v-if="label" class="font-bold h-5 mb-2">{{ label }}</div>
    <div class="flex relative">
      <div class="relative group">
        <div
          @click="toggleOpen"
          class="z-10 flex gap-2 justify-start items-center transition-all duration-300 rounded appearance-none border border-gray-300 bg-transparent text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent"
          :class="{
            'border-dashed shadow-sm py-2 px-4': dashed,
            'border-none shadow-none': noborder,
            'border shadow-sm py-2 px-4': !noborder && !dashed,
            'bg-white hover:bg-gray-100 cursor-pointer': !action && !disabled,
            'p-0': action
          }"
        >
          <span v-if="hasIconLeft">
            <slot name="iconLeft"></slot>
          </span>
          <p
            v-if="placeholder"
            class="text-md text-gray-500 whitespace-nowrap select-none"
          >
            {{ placeholder }}
          </p>
          <span v-if="selectedOptions.length > 0" class="ml-2 flex gap-2">
            <span class="text-gray-400 m-auto">|</span>
            <!-- selected chips -->
            <span
              v-for="(selected, index) in selectedDisplay"
              :key="index"
              class="cursor-pointer whitespace-nowrap px-2 items-center rounded"
              :class="`${getTailwindColor(selected.color)} ${
                !isObject(selected) || !selected.color ? 'bg-gray-100' : ''
              }`"
              :style="
                isObject(selected) && getTailwindColor(selected.color) == ''
                  ? { backgroundColor: selected.color }
                  : {}
              "
            >
              <p
                class="text-sm font-semibold"
                :class="
                  isObject(selected) && selected.color
                    ? 'text-white'
                    : 'text-gray-400'
                "
              >
                {{ isObject(selected) ? selected.name : selected }}
              </p>
            </span>
          </span>
          <span v-if="hasIconRight">
            <slot name="iconRight"></slot>
          </span>
        </div>
        <div
          v-show="isOpen"
          class="dropdown-options absolute z-50 bg-white border border-gray-300 mt-1 rounded-md shadow-lg"
          :class="{
            '-left-[220%] md:-left-[200%] z-50 top-8 md:top-7 shadow': action
          }"
        >
          <div
            v-if="searchable"
            class="flex gap-1 px-4 py-2.5 border items-center"
          >
            <IconPack type="MagnifyingGlass" class="w-4 h-4 text-gray-500" />
            <input
              v-model="searchTerm"
              @input="filterOptions"
              :placeholder="placeholder"
              :disabled="disabled"
              ref="search"
              class="focus:ring-0 focus:outline-none placeholder:text-base placeholder:font-light"
            />
          </div>
          <ul
            v-if="isOpen && options && options.length > 0"
            class="flex flex-col max-h-60 scrollbar-hide overflow-scroll"
            :class="{ 'p-1': !multiple }"
          >
            <li
              v-show="filteredOptions.length > 0"
              @click="selectOption(option)"
              v-for="(option, index) in filteredOptions"
              :key="index"
            >
              <div
                class="flex gap-2 hover:bg-gray-100 w-full px-4 py-1 items-center rounded"
              >
                <div v-if="multiple" class="inline-flex items-center">
                  <label
                    class="relative flex items-center p-2 rounded-full cursor-pointer"
                    :for="getName(option)"
                  >
                    <input
                      v-model="selectedOptions"
                      :value="getName(option)"
                      :id="getName(option)"
                      type="checkbox"
                      class="peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all checked:border-primary-500 checked:bg-white"
                    />
                    <span
                      class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
                    >
                      <IconPack
                        type="CheckCircle"
                        solid
                        class="w-6 h-6 text-primary-500"
                      />
                    </span>
                  </label>
                </div>
                <label
                  @click="onClick"
                  :for="getName(option)"
                  class="whitespace-nowrap flex gap-2 items-center select-none text-base"
                >
                  <img
                    v-if="isObject(option) && option.img"
                    :src="option.img"
                    class="w-10 h-10 my-2 object-scale-down"
                  />
                  <p class="flex gap-2.5 items-center">
                    <span
                      v-if="isObject(option) && option.color"
                      :class="`w-3 h-3 rounded-full ${getTailwindColor(
                        option.color
                      )}`"
                      :style="
                        isObject(option) && getTailwindColor(option.color) == ''
                          ? { backgroundColor: option.color }
                          : {}
                      "
                    >
                    </span>
                    <span
                      v-if="typeof option === 'object' && option.icon"
                      class="w-4 h-4"
                    >
                      <IconPack :type="option.icon" />
                    </span>
                    <span>{{ getName(option) }}</span>
                  </p>
                  <p class="text-xs text-gray-400">
                    {{ option?.description }}
                  </p>
                </label>
              </div>
            </li>
            <li
              v-show="isOpen && filteredOptions.length <= 0"
              class="absolute z-20 py-2 px-4 whitespace-nowrap bg-white rounded-b-md shadow-lg"
            >
              No options found.
            </li>
          </ul>
          <!-- v-else -->
          <div
            v-else
            class="absolute z-20 py-2 px-4 whitespace-nowrap bg-white rounded-b-md shadow-lg"
            :class="{
              '-right-full shadow border rounded-md border-gray-300': action
            }"
          >
            No options found.
          </div>
          <div>
            <div
              @click="clearSelected"
              v-if="selectedOptions.length > 0"
              class="p-1 border-t mt-1 flex justify-center"
            >
              <p
                class="hover:bg-gray-100 w-full h-full p-2 text-center text-sm rounded"
              >
                Clear Selection
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    modelValue: [Array, String, Object],
    placeholder: String,
    label: String,
    errorMessage: String,
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    multiple: Boolean,
    searchable: Boolean,
    dashed: {
      type: Boolean,
      default: false
    },
    noborder: {
      type: Boolean,
      default: false
    },
    action: {
      // do not store value, only emit it e.g. for Action dropdown
      type: Boolean,
      default: false
    }
  },
  emits: [
    'blur',
    'update:modelValue',
    'keyup',
    'keydown',
    'keypress',
    'focus',
    'change',
    'input',
    'selected',
    'search'
  ],
  data() {
    return {
      searchTerm: '',
      isOpen: false,
      filteredOptions:
        Array.isArray(this.options) && this.options.length > 0
          ? this.options
          : [],
      selectedOptions: Array.isArray(this.modelValue)
        ? this.modelValue
        : [this.modelValue] || []
    };
  },
  computed: {
    hasIconLeft() {
      return !!this.$slots.iconLeft;
    },
    hasIconRight() {
      return !!this.$slots.iconRight;
    },
    selectedDisplay() {
      this.filter();
      return this.selectedOptions.length >= 3
        ? [`${this.selectedOptions.length} selected`]
        : this.selectedOptions;
    }
  },
  methods: {
    getTailwindColor(color) {
      return color?.includes('-') ? 'bg-' + color : '';
    },
    getName(option) {
      return this.isObject(option) && option ? option.name : option;
    },

    isObject(option) {
      return typeof option === 'object' && option;
    },

    filter() {
      //remove null elements
      this.selectedOptions = this.selectedOptions.filter((option) => option);
    },

    filterOptions() {
      this.filteredOptions = this.options.filter((option) =>
        this.getName(option)
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      );
      this.isOpen = true;
    },
    handleClickOutside(event) {
      const dropdown = this.$el;
      if (!dropdown.contains(event.target)) {
        this.isOpen = false;
        dropdown
          .querySelector('.dropdown-options')
          .classList.remove('dropdown-reverse');
      }
    },

    toggleOpen() {
      if (this.disabled) return;
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$nextTick(() => {
          const dropdown = this.$el.querySelector('.dropdown-options');
          if (dropdown) {
            const dropdownRect = dropdown.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            if (dropdownRect.bottom > viewportHeight) {
              dropdown.classList.add('dropdown-reverse');
            } else {
              dropdown.classList.remove('dropdown-reverse');
            }
          }

          if (this.searchable) {
            this.$refs.search.focus();
          }
        });
      }
    },

    removeSelected(index) {
      this.selectedOptions.splice(index, 1);
      this.$emit('input', this.selectedOptions);
    },
    selectOption(option) {
      if (this.action) {
        this.$emit('selected', option);
        this.isOpen = false;
        this.clearSelected();
        return;
      }

      if (this.multiple) {
        if (this.isObject(option) && option) {
          option = option.name;
        }
        this.selectedOptions.includes(option)
          ? (this.selectedOptions = this.selectedOptions.filter(
              (selectedOption) => selectedOption !== option
            ))
          : this.selectedOptions.push(option);
      } else {
        this.selectedOptions = [option];
        this.isOpen = false;
      }

      this.$emit('update:modelValue', this.selectedOptions);
    },
    clearSelected() {
      this.selectedOptions = [];
      this.searchTerm = '';
      this.$emit('update:modelValue', this.selectedOptions);
    },
    onClick(e) {
      if (this.multiple) {
        e.stopPropagation();
      }
    }
  },
  watch: {
    modelValue() {
      this.selectedOptions = Array.isArray(this.modelValue)
        ? this.modelValue
        : [this.modelValue] || [];
    },
    options() {
      this.filteredOptions = Array.isArray(this.options) ? this.options : [];
    },
    searchTerm(value) {
      this.$emit('search', value);
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
.dropdown-reverse {
  top: auto;
  bottom: 100%;
  transform: translateY(4px);
}
.dropdown-options {
  z-index: 9999 !important;
}
.dropdown-options:hover {
  z-index: 9999 !important;
}
</style>
