<template>
  <AppCard @click="handleClick" clickable>
    <template #title>
      <p :class="{ 'opacity-50': isRead }" class="text-lg font-bold">
        {{ item?.attributes?.from_user?.data.attributes.first_name }}
        {{ item?.attributes?.from_user?.data.attributes.last_name }}
      </p>
    </template>
    <template #topRight>
      <div class="flex items-center gap-2">
        <SearchableDropdown
          @click.stop
          action
          noborder
          :options="[isRead ? 'Mark as unread' : 'Mark as read']"
          @selected="handleAction"
          class="-translate-y-2 z-20"
        >
          <template #iconLeft>
            <div
              class="border rounded-md p-0.5 hover:shadow transition-all duration-300"
            >
              <IconPack type="ChevronDown" class="w-3.5 h-3.5" />
            </div>
          </template>
        </SearchableDropdown>
      </div>
    </template>
    <div :class="{ 'opacity-50': isRead }" class="flex items-center gap-4">
      <IconPack
        :type="isRead ? 'EnvelopeOpen' : 'Envelope'"
        class="w-6 h-6 shrink-0"
        :class="{ 'text-secondary-500': !isRead }"
      />
      <p class="text-sm mt-2 text-gray-500">{{ item?.attributes?.body }}</p>
      <div class="flex ml-auto items-center gap-2">
        <IconPack type="Clock" class="w-4 h-4" />
        <p class="text-xs text-gray-500 whitespace-nowrap">
          {{ moment(item?.attributes?.createdAt).fromNow() }}
        </p>
      </div>
    </div>
  </AppCard>
</template>

<script>
export default {
  name: 'InboxItemCard',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRead() {
      return this.item?.attributes?.is_read;
    }
  },
  methods: {
    toggleRead() {
      if (!this.isRead) {
        this.$store.dispatch('InboxItems/markAsRead', {
          id: this.item.id,
          read: true
        });
      } else {
        this.$store.dispatch('InboxItems/markAsRead', {
          id: this.item.id,
          read: false
        });
      }
    },
    markAsRead() {
      this.$store.dispatch('InboxItems/markAsRead', {
        id: this.item.id,
        read: true
      });
    },
    handleAction(action) {
      if (action === 'Mark as read' || action === 'Mark as unread') {
        this.toggleRead();
      }
    },
    handleClick() {
      if (!this.isRead) {
        this.markAsRead();
      }
      const jobId = this.item.attributes.link.split('/').slice(-2)[0];
      this.$store
        .dispatch('Jobs/getJobs', {
          filters: { filters: { id: { $eq: jobId } } }
        })
        .then((res) => {
          if (this.item.attributes.type === 'job_comment_mention')
            this.$router.push({
              name: 'Job-Details',
              params: { id: res.data.data[0].attributes.uid },
              query: {
                comment:
                  this.item?.attributes?.job_comment?.data?.attributes?.uid
              }
            });
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
