<template>
  <div
    v-if="!comment.attributes.is_deleted"
    class="p-2.5 max-w-sm w-fit rounded-xl flex flex-col relative"
    :class="{
      'bg-primary-400 text-white ml-auto': isMyComment,
      'bg-white border': !isMyComment
    }"
  >
    <!-- app spinner -->
    <transition name="scale-quick" mode="out-in" appear>
      <div
        v-if="working"
        class="absolute z-30 top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center rounded-xl"
      >
        <div class="absolute -top-1.5 -right-1.5 w-5 h-5">
          <div
            class="animate-ping ease-in absolute top-0.5 inline-flex h-5 w-5 rounded-full bg-secondary-300 opacity-75"
          />
          <div
            class="relative inline-flex animate-spin rounded-full h-5 w-5 bg-secondary-400"
          >
            <IconPack type="ArrowPath" class="w-3 h-3 m-auto text-white" />
          </div>
        </div>
      </div>
    </transition>

    <!-- Time and Job Type -->
    <div
      class="absolute px-2.5 flex justify-between items-center gap-1 w-full bottom-2 left-0 text-xs"
    >
      <div class="flex items-center gap-1">
        <div
          class="w-2 h-2 rounded-full"
          :style="`background-color: ${jobType?.color} !important;`"
        ></div>
        <p>{{ jobType?.name }}</p>
      </div>
      <p>{{ time }}</p>
    </div>

    <!-- Comment Author-->
    <div class="flex w-full items-center gap-4 justify-between">
      <div class="flex gap-2 w-fit items-center">
        <ContactCard
          :contact="comment.attributes.creator.data.attributes"
          icononly
          class="scale-90"
        />
        <p class="font-bold">{{ from }}</p>
      </div>
      <!-- Actions -->
      <div
        @click="showActions = !showActions"
        ref="actions"
        class="w-5 h-5 relative hover:-translate-y-0.5 hover:shadow-lg z-30 transition-all duration-300 rounded-md flex items-center justify-center"
        :class="{
          'bg-primary-500 hover:bg-primary-300': isMyComment,
          'bg-gray-100 hover:bg-gray-200': !isMyComment
        }"
      >
        <IconPack type="ChevronDown" class="w-4 h-4" />
        <transition name="scale-quick" mode="out-in" appear>
          <div
            v-if="showActions"
            class="w-32 absolute bg-white border p-1 flex flex-col text-primary-500 shadow-lg rounded-lg -left-32 -ml-1 top-0 z-40"
          >
            <div
              v-for="option in options"
              :key="option.name"
              @click="
                option.name === 'Delete' ? deleteComment() : replyToComment()
              "
              class="flex items-center gap-2 p-1 px-4 rounded-lg justify-center hover:bg-primary-500 hover:bg-opacity-10 cursor-pointer"
            >
              <IconPack :type="option.icon" class="w-4 h-4" />
              <p>{{ option.name }}</p>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <!-- Reply To -->
    <ReplyCard v-if="replyTo" :replyTo="replyTo" />

    <!-- Comment Text -->
    <div
      class="my-2 flex items-center flex-wrap gap-1"
      v-if="jobCommentAttachments.length > 0"
    >
      <div
        :class="{ 'z-20': attachment.attributes.file_type === 'image' }"
        v-for="(attachment, i) in jobCommentAttachments"
        :key="attachment.id"
      >
        <!-- max 5 and then show +n more -->
        <div v-if="i < 6" class="relative">
          <AdditionalDocCard
            @openImageCarousel="showImageModal(i)"
            readonly
            comment
            v-if="attachment.attributes.file_type === 'image'"
            :document="{
              url: attachment.attributes.url,
              title: attachment.attributes.name,
              file_type: 'image',
              createdAt: attachment.attributes.createdAt,
              creator: attachment.attributes.creator
            }"
          />
          <div
            v-if="i == 5 && jobCommentAttachments.length > 6"
            @click="showImageModal(i)"
            class="absolute w-full h-full top-0 left-0 flex items-center justify-center rounded-lg bg-black bg-opacity-60"
          >
            <p class="text-white text-lg font-bold p-1 rounded-lg">
              +{{ jobCommentAttachments.length - 5 }}
            </p>
          </div>
        </div>

        <div v-if="attachment.attributes.file_type === 'template_document'">
          <TemplateDocCard
            condensed
            :document="{
              ...attachment.attributes.document.data.attributes,
              id: attachment.attributes.document.data.id,
              jobUid: comment.attributes.job.data.attributes.uid,
              jobId: comment.attributes.job.data.id
            }"
            :id="attachment.attributes.document.data.id"
          />
        </div>

        <div v-if="attachment.attributes.file_type === 'pdf'">
          <AdditionalDocCard
            readonly
            :document="{
              url: attachment.attributes.url,
              title: attachment.attributes.name,
              file_type: 'pdf'
            }"
          />
        </div>
      </div>
    </div>

    <transition name="fade-delay" mode="out-in" appear>
      <p
        class="mt-2 mb-6"
        v-html="
          text?.replace(
            //replace \n with <br> for new lines
            /\n/g,
            '<br>'
          )
        "
      />
    </transition>
  </div>
  <div
    v-else
    class="p-2.5 opacity-30 max-w-sm w-fit rounded-xl flex flex-col relative"
    :class="{
      'bg-primary-400 text-white ml-auto': isMyComment,
      'bg-white border': !isMyComment
    }"
  >
    <div class="flex w-full items-center gap-2 justify-between">
      <div class="flex gap-2 w-fit items-center">
        <ContactCard
          :contact="comment.attributes.creator.data.attributes"
          icononly
          class="scale-90"
        />
        <p class="font-bold">{{ from }}</p>
      </div>
      <p class="text-xs">{{ time }}</p>
    </div>
    <p class="mt-2 flex items-center">
      <span class="text-sm">This comment has been deleted</span>
    </p>
  </div>
  <ImageCarouselModal
    readonly
    ref="carousel"
    v-model="showImageCarousel"
    :images="images"
  />
</template>

<script>
import ContactCard from '@/components/Contacts/ContactCard.vue';
import moment from 'moment';
import TemplateDocCard from '@/components/Documents/TemplateDocCard.vue';
import AdditionalDocCard from '@/components/Documents/AdditionalDocCard.vue';
import ReplyCard from './ReplyCard.vue';
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import ImageCarouselModal from '@/components/Modals/ImageCarouselModal.vue';

export default {
  name: 'CommentCard',

  components: {
    ContactCard,
    TemplateDocCard,
    AdditionalDocCard,
    ReplyCard,
    ImageCarouselModal
  },
  props: {
    comment: {
      type: Object,
      default: null
    }
  },
  emits: ['replyToComment'],
  mounted() {
    window.addEventListener('click', (e) => {
      if (this.$refs.actions && !this.$refs.actions.contains(e.target)) {
        this.showActions = false;
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('click', () => {});
  },
  data() {
    return {
      replyToColor: '',
      showActions: false,
      working: false,
      showImageCarousel: false
    };
  },
  computed: {
    options() {
      return this.isMyComment
        ? [
            { name: 'Reply', icon: 'ArrowUturnLeft' },
            { name: 'Delete', icon: 'Trash' }
          ]
        : [{ name: 'Reply', icon: 'ArrowUturnLeft' }];
    },
    isMyComment() {
      return this.comment?.attributes?.creator.data.id === this.user.id;
    },
    images() {
      return this.jobCommentAttachments
        .filter((attachment) => attachment.attributes.file_type === 'image')
        .map((attachment) => {
          return {
            uid: attachment.id,
            url: attachment.attributes.url
          };
        });
    },
    time() {
      return moment(this.comment?.attributes?.createdAt).format('HH:mm');
    },
    from() {
      return (
        this.comment?.attributes?.creator?.data?.attributes?.first_name +
        ' ' +
        this.comment.attributes.creator.data.attributes.last_name
      );
    },
    mentions() {
      return this.comment?.attributes?.mentions?.data?.map(
        (mention) =>
          mention.attributes.first_name + ' ' + mention.attributes.last_name
      );
    },
    jobType() {
      return this.comment?.attributes?.job?.data?.attributes?.job_type?.data
        ?.attributes;
    },
    replyTo() {
      return this.comment?.attributes?.reply_to?.data;
    },
    replyToUser() {
      return (
        this.replyTo?.attributes?.creator?.data?.attributes?.first_name +
        ' ' +
        this.replyTo.attributes.creator.data.attributes.last_name
      );
    },
    replyToTime() {
      return moment(this.replyTo?.attributes?.created_at).format('HH:mm');
    },
    jobCommentAttachments() {
      return this.comment?.attributes?.job_comment_attachments?.data;
    },
    text() {
      let comment = this.comment?.attributes?.comment_text;
      this.mentions.forEach((mention) => {
        mention.split(' ').forEach((word, i) => {
          comment = comment?.replace(
            word,
            `<span class="text-primary-50"><span class="text-xs">${
              i == 0 ? '@' : ''
            }</span>${word}</span>`
          );
        });
      });
      return comment;
    },
    templateDoc() {
      return this.comment?.attributes?.job_comment_attachments?.data?.find(
        (attachment) => attachment.attributes.file_type === 'template_document'
      );
    },
    location() {
      return this.comment?.attributes?.job?.data?.attributes?.location?.data;
    },
    client() {
      return this.comment?.attributes?.job?.data?.attributes?.client?.data;
    }
  },
  methods: {
    setBackgroundColor(color) {
      this.replyToColor = color;
    },
    deleteComment() {
      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        title: 'Delete Comment',
        message: 'Are you sure you want to delete this comment?',
        icon: 'Trash',
        confirmText: 'Delete'
      });

      reveal();

      onConfirm(() => {
        this.working = true;
        this.$store
          .dispatch('JobComments/deleteComment', this.comment.id)
          .then(async () => {
            await this.$store
              .dispatch('JobComments/getJobComments', {
                location: this.location.id,
                client: this.client.id
              })
              .then(() => {
                this.$toast.success('Comment deleted successfully');
              })
              .finally(() => {
                this.working = false;
              });
          });
      });
    },
    replyToComment() {
      this.$emit('replyToComment', this.comment);
    },
    showImageModal(index) {
      if (this.$refs.carousel) {
        if (typeof index === 'string')
          index = this.images.findIndex((image) => image.uid === index);
        this.showImageCarousel = true;
        this.$refs.carousel.index = index;
      } else {
        setTimeout(() => {
          this.showImageModal(index);
        }, 500);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
