import GlobalRoutes from '../Global';
import DashboardRoutes from '@/router/Admin/Dashboard';
import DocumentsRoutes from '@/router/Admin/Documents';

const baseRoutes = [
  // {
  //   path: '/:catchAll(.*)',
  //   name: 'NotFound',
  //   redirect: '/app',
  // }, 
];

const routes = baseRoutes.concat(GlobalRoutes).concat(DashboardRoutes).concat(DocumentsRoutes);


export default routes;
