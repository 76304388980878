<template>
  <AppModal
    style="backdrop-filter: blur(3px)"
    name="newContact"
    v-model="openModal"
  >
    <div class="w-full h-screen flex relative">
      <div
        @click="hideModal"
        class="w-full opacity-0 h-full z-10 absolute top-0 left-0"
      ></div>
      <div class="w-full max-w-5xl px-20 h-fit m-auto py-5 relative">
        <div class="w-full z-20 h-full rounded-xl relative">
          <AppCard class="w-full h-fit px-10 pt-6 pb-8 rounded-xl">
            <template #title> {{ title }} </template>
            <template #topRight>
              <div>
                <AppButton @click="addContact" type="primary"
                  >Save Contact</AppButton
                >
              </div>
            </template>
            <div class="flex items-center gap-2 w-full p-1 mb-5">
              <div
                class="w-20 h-20 rounded-full transition-all duration-1000 flex items-center justify-center relative"
                :style="`background-color: ${this.backgroundColor}`"
              >
                <div class="flex">
                  <p class="font-bold text-2xl text-white">
                    {{ firstName[0] }}
                  </p>
                  <p class="font-bold text-2xl text-white">{{ lastName[0] }}</p>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="flex gap-1 5">
                  <p class="font-semibold text-primary-500">{{ firstName }}</p>
                  <p class="font-semibold text-primary-500">{{ lastName }}</p>
                </div>
                <p class="text-sm text-gray-500">{{ email }}</p>
                <p class="text-sm text-gray-500">{{ phoneNumber }}</p>
              </div>
            </div>

            <div class="flex gap-4">
              <div class="w-1/2">
                <TextInput
                  ref="firstNameInput"
                  v-model="firstName"
                  label="First Name"
                  placeholder="John"
                >
                  <template #icon>
                    <IconPack type="UserCircle" class="h-5 w-5 text-gray-500" />
                  </template>
                </TextInput>
              </div>
              <div class="w-1/2">
                <TextInput
                  ref="lastNameInput"
                  v-model="lastName"
                  label="Last Name"
                  placeholder="Doe"
                />
              </div>
            </div>
            <div class="w-full">
              <TextInput
                ref="emailInput"
                type="email"
                v-model="email"
                label="Email"
                placeholder="Email"
              >
                <template #icon>
                  <IconPack type="Envelope" class="h-5 w-5 text-gray-500" />
                </template>
              </TextInput>
            </div>
            <div class="w-1/2">
              <TextInput
                :errorMessage="errorMsg"
                type="phone"
                ref="phoneInput"
                :max="10"
                v-model="phone"
                label="Phone"
                placeholder="Phone"
              >
                <template #icon>
                  <IconPack type="Phone" class="h-5 w-5 text-gray-500" />
                </template>
              </TextInput>
            </div>
          </AppCard>

          <div
            @click="hideModal"
            class="w-10 h-10 hover:shadow-sm hover:shadow-white hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full absolute top-0 -right-14 flex items-center justify-center"
          >
            <IconPack type="XMark" class="w-5 h-5 text-primary-500" />
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'newContact'],
  data() {
    return {
      openModal: this.modelValue,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      errorMsg: '',
      title: 'New Contact'
    };
  },
  computed: {
    backgroundColor() {
      return stringToHslColor(`${this.firstName}${this.lastName}`, 60, 70);
    },
    phoneNumber: {
      get() {
        // get current value and remove spaces and dashes and remove all non digits
        return this.phone.replace(/[\s-]/g, '').replace(/[^0-9]/g, '');
      },
      set(value) {
        // set new value and remove all spaces and dashes
        this.phone = value.replace(/[\s-]/g, '');
      }
    }
  },
  methods: {
    hideModal() {
      this.openModal = false;
      this.$emit('update:modelValue', this.openModal);
    },
    addContact() {
      // validate input
      if (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.phone &&
        this.phoneNumber.length == 10
      ) {
        // create new contact object
        const newContact = {
          uid: '',
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          cellphone: this.phone
        };
        // emit new contact
        this.$store
          .dispatch('Contacts/createContact', newContact)
          .then((res) => {
            this.$toast.success('Contact saved successfully');
            this.$emit('newContact', res.data.data.attributes);
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error('Error saving contact');
          });
        // close modal
        this.hideModal();
      } else {
        // show error
        if (!this.firstName) {
          this.$toast.error('Please fill out all fields');
          this.$refs.firstNameInput.errorFocus();
        } else if (!this.lastName) {
          this.$toast.error('Please fill out all fields');
          this.$refs.lastNameInput.errorFocus();
        } else if (!this.email) {
          this.$toast.error('Please fill out all fields');
          this.$refs.emailInput.errorFocus();
        } else if (!this.phoneNumber || this.phoneNumber.length !== 10) {
          this.$toast.error('Invalid phone number');
          this.$refs.phoneInput.errorFocus('Invalid phone number');
          this.errorMsg = 'Invalid phone number';
        }
      }
    },
    editContact(contact) {
      if (contact) {
        this.title = 'Edit Contact';
        contact.first_name
          ? (this.firstName = contact.first_name)
          : (this.firstName = '');
        contact.last_name
          ? (this.lastName = contact.last_name)
          : (this.lastName = '');
        contact.email ? (this.email = contact.email) : (this.email = '');
        contact.cellphone
          ? (this.phone = contact.cellphone)
          : (this.phone = '');
      }
    },

    clearForm() {
      this.title = 'New Contact';
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = '';
    },

    closeModalOnEscape(event) {
      if (event.key === 'Escape' && this.openModal) {
        this.hideModal();
      }
    }
  },
  watch: {
    modelValue() {
      this.openModal = this.modelValue;
    },
    phoneNumber() {
      this.errorMsg = '';
    }
  },
  mounted() {
    window.addEventListener('keydown', this.closeModalOnEscape);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.closeModalOnEscape);
  }
};

const stringToHslColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};
</script>
