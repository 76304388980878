<template>
    <AppCard>
        <div class="flex gap-4 items-center justify-start" :class="{
            'flex-row': !small,
            'flex-col': small
        }">
            <label v-if="selectable" class="relative flex items-center animate-pulse p-2 rounded-full cursor-pointer">
                <input disabled type="checkbox"
                    class="peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all checked:border-primary-500 checked:bg-white" />
            </label>

            <div class="flex flex-row items-center animate-pulse gap-2">
                <transition name="scale-quick" mode="out-in" appear>
                    <div class="font-bold text-sm  text-transparent bg-gray-200  rounded-full">
                        Online
                    </div>
                </transition>

                <AppChip size="xs" class="text-xs bg-gray-300 text-transparent">
                    <IconPack v-if="plant.attributes.soc > 60" solid type="Battery100" class="w-4 h-4 mr-2" />
                    <IconPack v-else-if="plant.attributes.soc > 30" solid type="Battery50" class="w-4 h-4 mr-2" />
                    <IconPack v-else type="Battery0" class="w-4 h-4 mr-2" />
                    {{ plant.attributes.soc }}%
                </AppChip>

                <IconPack v-if="!small" type="DevicePhoneMobile" class="w-5 h-5 text-gray-300" />
            </div>

            <div class="flex-1 flex flex-col">
                <transition name="scale-quick" mode="out-in" appear>
                    <div class="bg-gray-200 w-fit rounded-full text-transparent animate-pulse">
                        {{ plant.attributes.name }}
                    </div>
                </transition>
            </div>

            <div v-if="!small" class="flex flex-row items-center gap-4 justify-end">
                <transition name="scale-quick" mode="out-in" appear>
                    <div
                        class="uppercase ml-auto text-right bg-gray-300 text-transparent rounded-full animate-pulse text-xs font-bold">
                        No Reload
                    </div>
                </transition>

                <transition name="scale-quick" mode="out-in" appear>
                    <div class="font-bold text-xs text-transparent bg-gray-400 rounded-full animate-pulse text-right">
                        8 kW
                    </div>
                </transition>

                <AppButton size="xs" customColor="rgb(200, 200, 200)" class="w-max animate-pulse text-transparent">Sunsynk
                    <IconPack type="ArrowTopRightOnSquare" class="w-4 h-4 ml-2" />
                </AppButton>
            </div>
        </div>
    </AppCard>
</template>
<script>
export default {
    components: {},
    props: {
        small: {
            type: Boolean,
            default: false
        },

        selectable: {
            type: Boolean,
            default: false
        },

        plant: {
            type: Object,
            default: () => ({})
        },

    }
}
</script>
  