<template>
  <AppCard class="p-0 text-white">
    <div
      class="bg-secondary-400 rounded-md flex items-center justify-center flex-col gap-4 p-4"
    >
      <IconPack
        v-if="type == 'Warning'"
        type="ExclamationCircle"
        class="w-10 h-10"
      />
      <IconPack
        v-else-if="type === 'Info'"
        type="InformationCircle"
        class="w-10 h-10"
      />
      <div class="text-2xl font-bold">{{ type }}</div>
      <div class="text-center text-lg md:px-8">
        <slot />
      </div>
    </div>
  </AppCard>
</template>
<script>
export default {
  name: 'WarnCard',
  props: {
    type: {
      type: String,
      default: 'Warning'
    }
  }
};
</script>
