<template>
  <AppCard
    titleType="base"
    v-if="question"
    class="border-none !bg-transparent !shadow-none cursor-auto"
  >
    <template #title>
      <div class="flex items-center">
        <p
          :class="{
            'text-xl': question.type === 'heading',
            'mb-2': question.type === 'heading' && question.description
          }"
        >
          {{ question.title }}
        </p>
        <div
          v-if="question.show_na"
          @click="toggleNA"
          class="ml-2 px-2 py-1 text-xs font-semibold rounded-full"
          :class="{
            'bg-gray-200 text-gray-700': !isNA,
            'bg-secondary-500 text-white': isNA
          }"
        >
          N/A
        </div>
      </div>
    </template>

    <template #text>
      <p class="text-sm text-gray-500">
        <span
          v-if="question.type === 'heading'"
          v-html="question?.description?.replace(/(?:\r\n|\r|\n)/g, '<br>')"
        ></span>
        <span v-else>{{ question.description }}</span>
      </p>
    </template>

    <hr class="mt-5" v-if="question.type === 'heading'" />

    <transition name="scale-quick" mode="out-in" appear>
      <div v-if="!isNA">
        <!-- Select type -->
        <div v-if="question.type === 'select'">
          <div class="flex flex-wrap items-center w-full gap-5 mt-2">
            <div
              v-for="option in question.options"
              :key="option.key"
              @click="if (!disabled) localAnswer = option.key;"
              class="flex items-center p-2 rounded-md border"
              :class="{ 'cursor-pointer': !disabled }"
            >
              <input
                :disabled="disabled"
                type="radio"
                :id="question.key + option.key"
                :value="option.key"
                v-model="localAnswer"
                class="form-radio h-4 w-4 accent-primary-400"
              />
              <label
                :for="question.key + option.key"
                class="ml-1.5 flex-nowrap text-sm"
                :class="{ 'cursor-pointer': !disabled }"
              >
                {{ option.title }}
              </label>
            </div>

            <div v-if="question.other">
              <div
                @click="if (!disabled) localAnswer = 'other';"
                class="flex items-center border rounded-md p-2"
              >
                <input
                  :disabled="disabled"
                  type="radio"
                  :id="question.key + 'other'"
                  value="other"
                  v-model="localAnswer"
                  class="form-radio h-4 w-4 accent-primary-400"
                  :class="{ 'cursor-pointer': !disabled }"
                />
                <label
                  :for="question.key + 'other'"
                  class="ml-1.5 text-sm"
                  :class="{ 'cursor-pointer': !disabled }"
                >
                  Other
                </label>
              </div>
            </div>
          </div>
          <transition name="scale-quick" mode="out-in" appear>
            <TextInput
              v-if="localAnswer == 'other'"
              v-model="otherValue"
              placeholder="Enter other value"
              class="max-w-xl"
              :disabled="disabled"
            />
          </transition>
        </div>

        <!-- Text type -->
        <div v-else-if="question.type === 'text'">
          <TextInput
            v-model="localAnswer"
            :placeholder="question.title"
            :icon="icon"
            :disabled="disabled"
            :multiline="question.multi_line"
            class="max-w-xl"
          />
        </div>

        <!-- Number type -->
        <div v-else-if="question.type === 'number'">
          <div class="flex items-center">
            <TextInput
              v-model="localAnswer"
              :icon="icon"
              type="number"
              :min="question.validation.min"
              :max="question.validation.max"
              :step="question.validation.step"
              :disabled="disabled"
            >
              <template v-if="question.unit" #iconRight>
                <span class="text-gray-500">{{ question.unit }}</span>
              </template>
            </TextInput>
          </div>
        </div>

        <!-- Date type -->
        <div v-else-if="question.type === 'date'" class="!z-50">
          <DateRangePicker
            :disabled="disabled"
            :ranges="false"
            v-model="dateRange"
            :dateRange="dateRange"
            auto-apply
            showDropdowns
            singleDatePicker="single"
            controlContainerClass="rounded mt-2 cursor-pointer hover:bg-gray-100 transition-all duration-300 bg-white py-1.5 px-4 border border-gray-300 shadow-sm w-full text-gray-500"
          >
            <template #input>
              <div class="flex gap-2 items-center">
                <IconPack type="Calendar" class="w-5 h-5 text-gray-500" />
                <p class="text-gray-500">Select Date</p>
                <div
                  class="flex items-center gap-1"
                  v-if="
                    moment(dateRange.startDate).format('Do MMM YYYY') !==
                    'Invalid date'
                  "
                >
                  <span class="text-gray-400">|</span>
                  <p
                    class="text-gray-400 text-sm font-semibold flex items-center gap-1"
                  >
                    <span
                      class="bg-gray-100 py-1 px-2 rounded whitespace-nowrap"
                      >{{ moment(dateRange.startDate).format('Do MMM YY') }}
                    </span>
                  </p>
                </div>
              </div>
            </template>
            <template #footer>
              <!-- Clear Date filters -->
              <template
                v-if="
                  dateRange.startDate !== null && dateRange.endDate !== null
                "
              >
                <div
                  @click="clearDates"
                  class="p-1 border-t mt-1 flex justify-center"
                >
                  <p
                    class="hover:bg-gray-100 w-full h-full p-2 text-center text-sm rounded"
                  >
                    Clear Date
                  </p>
                </div>
              </template>
            </template>
          </DateRangePicker>
        </div>

        <!-- Credential type -->
        <div v-else-if="question.type === 'credential'">
          <div class="flex flex-col mt-2 gap-4">
            <SelectCredential v-model="localAnswer" :disabled="disabled" />

            <div v-if="selectedCredential" class="">
              <CredentialCard v-model="selectedCredential" />
            </div>
          </div>
        </div>

        <!-- Certificate number type -->
        <div v-else-if="question.type === 'certificate_number'">
          <p class="text-sm text-gray-500 mb-4">
            Reference the certificate number from another document (only locked
            documents show)
          </p>
          <SearchableDropdown
            :disabled="disabled"
            :options="question.options"
            placeholder="Select document to link"
          >
            <template #iconRight>
              <IconPack type="ChevronDown" class="w-5 h-5" />
            </template>
          </SearchableDropdown>

          <p
            v-if="!overwriteCertificate"
            @click="
              if (!disabled) overwriteCertificate = !overwriteCertificate;
            "
            class="text-sm text-gray-400 hover:text-primary-400 transition-all duration-300 cursor-pointer mt-2 font-black"
          >
            Click to manually enter certificate number
          </p>

          <TextInput
            :disabled="disabled"
            v-else
            v-model="localAnswer"
            :placeholder="question.title"
            :icon="icon"
            label="Overwrite certificate number"
            class="max-w-lg mt-3"
          />
        </div>

        <!-- image type -->
        <div class="mt-1 w-full flex" v-else-if="question.type === 'image'">
          <FileUploadModal
            v-if="!answer"
            grid
            type="image"
            class="border-none !shadow-none w-fit !p-0 !bg-transparent"
            v-model="localAnswer"
            :accept="['image/*']"
            :disabled="disabled"
            @create-documents="createImages"
            disable-drag-drop
            :multiple="!question.validation.min_answers"
          />

          <div v-else class="border rounded-lg relative">
            <AdditionalDocCard
              @open-image-carousel="$emit('openImageCarousel', $event)"
              :document="{ file_type: 'image', url: localAnswer }"
            />
            <p
              class="absolute bottom-2 right-2 w-fit mx-auto px-3 py-1 bg-green-500 rounded-full text-xs font-semibold text-white"
            >
              {{
                moment(new Date(answer.attributes.updatedAt)).format(
                  'DD MMM HH:mm'
                )
              }}
            </p>
          </div>
        </div>

        <!-- Product type -->
        <div v-else-if="question.type === 'product'">
          <SelectProduct
            :disabled="disabled"
            :type="question.product_category"
            v-model="localAnswer"
          />

          <div
            v-if="localAnswer && localAnswer.length > 0"
            class="flex items-center mt-4"
          >
            <img
              :src="productImage"
              class="object-scale-down"
              :class="{
                'w-28 h-36': question.product_category == 2,
                'w-36 h-28': question.product_category != 2
              }"
            />
            <div class="flex flex-wrap items-center gap-2">
              <p
                v-for="spec in selectedProduct?.attributes
                  .product_specifications?.data"
                :key="spec.id"
                class="text-sm text-gray-500 my-auto flex flex-col ml-8"
              >
                <span class="font-bold">
                  {{
                    spec.attributes?.product_specification_type?.data
                      ?.attributes.name
                  }}
                </span>
                <span>
                  {{ spec.attributes.value }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </AppCard>
</template>

<script>
import DateRangePicker from 'vue3-daterange-picker';
import SelectCredential from '../../Credentials/SelectCredential.vue';
import FileUploadModal from '../../Modals/FileUploadModal.vue';
import SelectProduct from '../../Products/SelectProduct.vue';
import CredentialCard from '../../Credentials/CredentialCard.vue';
import AdditionalDocCard from '../AdditionalDocCard.vue';

export default {
  name: 'ReadOnlyQuestionCard',
  components: {
    DateRangePicker,
    SelectCredential,
    FileUploadModal,
    SelectProduct,
    CredentialCard,
    AdditionalDocCard
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    answer: {
      type: [String, Number, Array, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openImageCarousel'],
  async mounted() {
    if (this.question.type === 'date') {
      this.dateRange.startDate = new Date(this.answer?.attributes?.value?.data);
    }
    if (this.question.type === 'select') {
      if (this.question.other) {
        if (
          !this.question.options.find(
            (option) => option.key === this.answer?.attributes?.value?.data
          ) &&
          !!this.answer?.attributes?.value?.data
        ) {
          this.localAnswer = 'other';
          this.otherValue = this.answer?.attributes?.value.data;
        }
      }
    }
    if (this.answer?.attributes?.value?.data === 'N/A') this.isNA = true;
  },
  data() {
    return {
      localAnswer:
        this.question.type === 'product'
          ? this.answer
          : this.answer?.attributes?.value?.data,
      isNA: false,
      otherValue: '',
      dateRange: {
        startDate: null,
        endDate: null
      },
      overwriteCertificate: false
    };
  },
  computed: {
    icon() {
      switch (this.question.type) {
        case 'select':
          return 'Check';
        case 'text':
          return 'DocumentText';
        case 'number':
          return 'Calculator';
        case 'date':
          return 'Calendar';
        case 'credential':
          return 'Identification';
        // Add more cases for other question types
        default:
          return 'QuestionMarkCircle';
      }
    },
    products() {
      return this.$store.getters['Products/products'];
    },
    categories() {
      return this.$store.getters['Products/productCategories'];
    },
    productImage() {
      return this.selectedProduct?.attributes.main_image
        ? this.selectedProduct.attributes.main_image
        : this.selectedProduct?.attributes.brand_image
        ? this.selectedProduct.attributes.brand_image
        : null;
    },
    credentials() {
      return this.$store.getters['Credentials/credentials'];
    },
    selectedCredential() {
      if (this.localAnswer && this.credentials)
        return this.credentials.find(
          (credential) => credential.id === this.localAnswer[0]?.id
        );
      return null;
    },
    selectedProduct() {
      if (this.localAnswer && this.products)
        return this.products.find(
          (product) => product.id === this.localAnswer[0]?.id
        );
      return null;
    }
  },
  methods: {
    toggleNA() {
      if (this.disabled) return;
      this.isNA = !this.isNA;
      if (this.isNA) {
        this.localAnswer = null;
      }
    },
    clearDates() {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
    },
    createImages(images) {
      this.localAnswer = images;
    }
  },
  watch: {
    answer: {
      handler(val) {
        this.localAnswer = val;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
DateRangePicker,
SearchableDropdown {
  z-index: 9999 !important;
}
</style>
