import store from '..';
import qs from 'qs';

export default {
  getContacts({ commit, rootGetters }, { start, limit, search }) {
    commit('SET_FETCHING', true);
    return new Promise((resolve, reject) => {
      const apiParams = {
        'pagination[start]': start,
        'pagination[limit]': limit,
        'sort': 'first_name:asc',
      };

      store.$api
        .get(`${import.meta.env.VITE_API_URL}/contacts?${qs.stringify(search)}`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token'],
          },
          params: apiParams,
        })
        .then((response) => {
          if (response.status === 200) {
            const contactList = response.data.data.map((contact) => {
              return {
                id: contact.id,
                uid: contact.attributes.uid,
                createdAt: contact.attributes.createdAt,
                updatedAt: contact.attributes.updatedAt,
                cellphone: contact.attributes.cellphone,
                email: contact.attributes.email,
                tel: contact.attributes.tel,
                first_name: contact.attributes.first_name,
                last_name: contact.attributes.last_name,
                company: contact.attributes.company,
              };
            });
            // console.log(contactList);
            commit('SET_CONTACTS', contactList);
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) commit('SET_CONTACTS', {});
          console.log(error);
          reject(error);
        }).finally(() => {
          commit('SET_FETCHING', false);
        });
    });
  },

  getContactById({ commit, rootGetters }, id) {
    const filters = 'filters[uid][$eq]=' + id;
    commit('SET_FETCHING', true);
    return new Promise((resolve, reject) => {
      store.$api
        .get(`${import.meta.env.VITE_API_URL}/contacts/?${filters}`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token'],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit('ADD_OR_UPDATE_CONTACT', response.data.data[0].attributes);
            resolve(response.data.data[0]);
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) commit('SET_CONTACT_BY_ID', {});
          console.log(error);
          reject(error);
        }).finally(() => {
          commit('SET_FETCHING', false);
        });
    });
  },


  getContactNames({ commit, rootGetters }, { start, limit }) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(import.meta.env.VITE_API_URL + '/contacts', {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          },
          params: {
            'pagination[start]': start,
            'pagination[limit]': limit,
            'sort': 'createdAt:desc'
          }
        })
        .then((response) => {
          console.log('getContactNames', response);
          if (response.status === 200) {
            const contactList = response.data.data.map((contact) => {
              return contact.attributes.first_name ? contact.attributes.last_name ? contact.attributes.first_name + ' ' + contact.attributes.last_name : contact.attributes.first_name : contact.attributes.last_name ? contact.attributes.last_name : 'n/a';
            });

            const uniqueContactList = [...new Set(contactList)];

            commit('SET_CONTACT_NAMES', uniqueContactList);
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) commit('SET_CONTACT_NAMES', {});
          console.log(error);
          reject(error);
        });
    });
  },
  createContact({ commit, rootGetters }, contact) {
    console.log('saveContact', contact);
    return new Promise((resolve, reject) => {
      store.$api
        .post(import.meta.env.VITE_API_URL + '/contacts', {
          data: contact
        }, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token'],
          },
        })
        .then((response) => {
          console.log('createContact', response);
          if (response.status === 200) {
            commit('ADD_OR_UPDATE_CONTACT', contact);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  deleteContact({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      store.$api
        .delete(import.meta.env.VITE_API_URL + '/contacts/' + id, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token'],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit('DELETE_CONTACT', id);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
};
