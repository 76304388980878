<template>
  <div class="flex items-center">
    <small v-if="leftLabel" class=" opacity-50 text-sm" :class="{
      'opacity-100': !localModalValue || !rightLabel,
      'font-bold': !localModalValue && rightLabel,
      //'hidden': modelValue && rightLabel,
    }">{{ leftLabel }}</small>
    <div class="relative inline-block w-10 mr-2 ml-2 align-middle select-none">
      <input :id="id" v-model="localModalValue" type="checkbox" class="
          outline-none
          focus:outline-none
          right-[17px]
          checked:right-0.5
          duration-200
          ease-in
          absolute
          block
          top-0.5
          w-5
          h-5
          rounded-full
          bg-white  
          appearance-none
          cursor-pointer
        " />
      <label :for="id" class="block overflow-hidden h-6 rounded-full cursor-pointer" :class="{
        [colorClass]: localModalValue || (leftLabel && rightLabel),
        'bg-gray-300': !localModalValue,
      }">
      </label>
    </div>
    <small v-if="rightLabel" class=" opacity-50 text-sm" :class="{
      'opacity-100': localModalValue || !leftLabel,
      'font-bold': localModalValue && leftLabel,
      //'hidden': !localModalValue && leftLabel,
    }">{{ rightLabel
}}</small>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  props: {
    modelValue: Boolean,
    leftLabel: String,
    rightLabel: String,
    color: {
      type: String,
      default: 'primary',
    },
  },

  emits: ['update:modelValue'],
  data() {
    return {
      id: null,
    };
  },
  computed: {
    localModalValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
    colorClass() {
      return `bg-${this.color}-500`;
    },
  },
  mounted() {
    this.id = uuidv4();
  },
};
</script>
