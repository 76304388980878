import ManagePlants from '@/views/Connect/Plants.vue';

export default [
  {
    meta: { layout: 'dashboard', requiresAuth: true, requiresDesktopPlan: true },
    path: '/connect/plants',
    name: 'Manage Plants',
    component: ManagePlants,
    title: 'Manage Plants',

  },
];
