<template>
  <DashboardLayout v-if="documentType">
    <template #customSidebar>
      <div>
        <AppTitle border class="mb-4">Structure Elements</AppTitle>
        <draggable class="list-group" tag="div" :component-data="{
          type: 'transition-group',
          name: !drag ? 'flip-list' : null
        }" :group="{ name: 'sections', pull: 'clone', put: false }" :clone="onClone" :list="sectionsPallet"
          v-bind="dragOptions" @start="drag = true" @end="drag = false" item-key="key">
          <div v-for="element in sectionsPallet" :key="element.key" class="flex flex-wrap w-full">
            <AppCard titleType="small" :icon="element.icon" class="my-1 w-full cursor-move"><template #title>{{
              element.name }}</template></AppCard>
          </div>
        </draggable>

        <AppTitle border class="my-4">Question Elements</AppTitle>

        <draggable :component-data="{
          type: 'transition-group',
          name: !drag ? 'flip-list' : null
        }" :group="{ name: 'questions', pull: 'clone', put: false }" :list="questionsPallet" v-bind="dragOptions"
          :clone="onClone" @start="drag = true" @end="drag = false" item-key="key">
          <AppCard v-for="element in questionsPallet" :key="element.key" titleType="small" :icon="element.icon"
            class="w-full cursor-move my-2"><template #title><span class="capitalize">{{
              element.type.replace('_', ' ')
                }}</span></template></AppCard>
        </draggable>
      </div>

      <AppSwitch class="my-4 text-sm bottom-0 absolute" v-model="showKeys" leftLabel="Show PDF Mapping" />
    </template>

    <AppTitle v-if="documentType" type="page">Edit Document Template: {{ documentType.attributes.name }}</AppTitle>
    <AppTitle border class="my-8">Basic Info</AppTitle>

    <div v-if="documentType?.attributes" class="mb-4 grid grid-cols-2 w-full items-center">
      <TextInput class="mr-4" label="Template Name" v-model="documentType.attributes.name" />

      <div class="ml-4">
        <div class="font-bold h-5 text-primary-600">Template Type</div>
        <Multiselect v-model="documentType.attributes.type" :options="['Global', 'Company']" />
      </div>
    </div>

    <AppTitle border class="my-8">Document Sections</AppTitle>

    <draggable class="list-group" tag="div" :component-data="{
      type: 'transition-group',
      name: !drag ? 'flip-list' : null
    }" handle=".drag-handle" group="sections" :list="structure" :clone="onClone" v-bind="dragOptions"
      @start="drag = true" @end="drag = false" item-key="key">
      <div v-for="(element, index) in structure" :key="element.key || index">
        <SectionCard :ref="'section_' + element.key" :showKeys="showKeys" :key="element.key || index"
          :section="generateKey(element)" @updateSection="updateSection" @removeSection="removeSection"
          @cloneSection="cloneSection" />
      </div>

      <AppCard v-if="!structure || structure.length == 0" missing class="my-4 mx-4"><template #title>Drop sections
          here</template>
      </AppCard>
    </draggable>

    <AppButton class="w-32 ml-auto" @click="saveTemplate()">Save</AppButton>
  </DashboardLayout>
</template>

<script>
import AppTitle from '../../../components/Dashboard/AppTitle.vue';

import SectionCard from '../../../components/Documents/Templates/SectionCard.vue';

import { VueDraggableNext } from 'vue-draggable-next';

import DashboardLayout from '../../../layouts/DashboardLayout.vue';

import _ from 'lodash';
import AppSwitch from '../../../components/EW/AppSwitch.vue';
import { v4 as uuid } from 'uuid';

export default {
  components: {
    draggable: VueDraggableNext,
    AppTitle,
    SectionCard,
    DashboardLayout,
    AppSwitch
  },
  data() {
    return {
      showKeys: false,
      drag: false,
      sectionsPallet: [
        {
          title: 'Section name',
          name: 'Section',
          icon: 'Bars4',
          questions: []
        }
      ],
      questionsPallet: [
        {
          title: '',
          type: 'heading',
          icon: 'ChatBubbleLeft',
          required: false,
          allow_templating: false,
          validation: {}
        },
        {
          title: '',
          type: 'select',
          icon: 'Check',
          required: true,
          allow_templating: true,
          validation: {}
        },
        {
          title: '',
          type: 'text',
          icon: 'DocumentText',
          required: true,
          allow_templating: true,
          validation: {}
        },
        {
          title: '',
          type: 'date',
          icon: 'Calendar',
          required: true,
          allow_templating: true,
          validation: {}
        },
        {
          title: '',
          type: 'number',
          icon: 'Calculator',
          required: true,
          allow_templating: true,
          validation: {}
        },
        {
          title: '',
          type: 'formula',
          icon: 'Beaker',
          hidden: true,
          required: true,
          allow_templating: true,
          validation: {}
        },
        {
          title: '',
          type: 'image',
          icon: 'Photo',
          required: true,
          validation: {}
        },
        {
          title: '',
          type: 'product',
          icon: 'ArchiveBox',
          required: true,
          validation: {}
        },
        {
          title: '',
          type: 'signature',
          icon: 'Pencil',
          required: true,
          validation: {}
        },
        {
          title: 'Certificate number',
          type: 'certificate_number',
          icon: 'DocumentText',
          allow_templating: true,
          required: true,
          validation: {}
        },
        {
          title: '',
          type: 'credential',
          icon: 'Identification',
          allow_templating: true,
          required: true,
          validation: {}
        }
        //   {
        //   title: '',
        //   type: 'location',
        //   icon: 'Map',
        //   required: true
        // },
      ]
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    documentTypeUid() {
      return this.$route.params.uid;
    },
    documentType() {
      return this.$store.getters['Documents/getTypeByUID'](
        this.documentTypeUid
      );
    },

    structure: {
      get() {
        if (!this.documentType?.attributes.structure) return [];
        return this.documentType?.attributes.structure;
      },
      set(val) {
        this.$store.commit('Documents/UPDATE_DOCUMENT_TYPE', {
          id: this.documentType.id,
          attributes: {
            uid: this.documentTypeUid,
            structure: val
          }
        });
      }
    },

    sectionCount() {
      return Object.keys(this.structure).length;
    },
    questionCount() {
      let count = 0;

      Object.keys(this.structure).forEach((key) => {
        count += this.structure[key].questions.length;
      });

      return count;
    }
  },

  mounted() {
    this.$store.dispatch('Documents/getDocumentTemplates');
  },
  methods: {
    updateSection(section) {
      this.structure = this.structure.map((i) => {
        if (i.key === section.key) {
          return section;
        }
        return i;
      });
    },
    saveTemplate() {
      this.$store
        .dispatch('Documents/updateDocumentTemplate', {
          ...this.documentType
        })
        .then(() => {
          this.$toast.success('Saved successfully');
          //this.$router.push('/documents/templates')
        })
        .catch((err) => {
          this.toastApiErrors(err);
        });
    },

    removeSection(sectionKey) {
      let index = this.structure.findIndex((i) => i.key == sectionKey);

      this.structure.splice(index, 1);
    },
    cloneSection(sectionKey) {
      let index = this.structure.findIndex((i) => i.key == sectionKey);

      let section = this.structure[index];

      let newSection = JSON.parse(JSON.stringify(section));

      newSection.key = uuid();

      this.structure.splice(index + 1, 0, newSection);
    },

    onClone(e) {
      return { ..._.cloneDeep(e), key: uuid() };
    },
    generateKey(e) {
      if (e.key) return e;
      e.key = uuid();

      return e;
    }
  }
};
</script>
