<script setup>
import { RouterView } from 'vue-router';
</script>

<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <component :is="Component" class="mx-auto" />
    </router-view>
  </component>
</template>

<script>
const defaultLayout = 'full-page';

export default {
  computed: {
    layout() {
      var specifiedLayout = this.$route.meta.layout || defaultLayout;

      return specifiedLayout + '-layout';
    }
  },
  data() {
    return {
      showError: false
    };
  },
  async mounted() {
    this.$store.dispatch('Company/getCompany');
    if (this.$store.getters['Authentication/isLoggedIn']) {
      this.$vacl.setRoles([this.$store.getters['Authentication/userRole']]);
      this.$store.dispatch('Jobs/getJobTypes');

      // const user = await this.$store.getters['Authentication/user'];
      // this.$store
      //   .dispatch('InboxItems/getInboxItems', { id: user.id })
      //   .then(() => {
      //     localStorage.setItem('lastInboxUpdate', new Date().toISOString());
      //   });

      // this.$store.dispatch('Plants/getAreas');
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  @apply text-primary-600;
}
</style>
