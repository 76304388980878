<template>
  <!-- Header -->
  <div class="print-page-layout relative">
    <div class="a4-container max-w-[210mm] mx-auto p-5 relative">
      <slot />
    </div>
    <!-- Footer -->
    <div
      class="fixed bottom-0 right-0 w-full flex px-5 justify-between items-center"
    >
      <img
        v-if="companyLogo"
        :src="companyLogo"
        class="p-2 rounded-lg bg-white z-50 w-full max-w-[30mm]"
      />
      <img
        src="@/assets/logos/PDFLogo.svg"
        class="h-12 max-w-[25mm] object-contain"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrintPageLayout',
  async beforeMount() {
    const helpKit = document.getElementById('helpkit-widget');
    if (helpKit) await helpKit.remove();
    const helpKitButton = document.getElementById('helpkit-launcherButton--ew');
    if (helpKitButton) await helpKitButton.remove();
  },
  async mounted() {
    const helpKit = document.getElementById('helpkit-widget');
    if (helpKit) await helpKit.remove();
    const helpKitButton = document.getElementById('helpkit-launcherButton--ew');
    if (helpKitButton) await helpKitButton.remove();
  },
  computed: {
    companyLogo() {
      return this.company?.attributes?.logo;
    }
  }
};
</script>
