<template>
  <div class="min-h-screen h-fit scrollbar-hide bg-gray-50">
    <TopBar />
    <div
      v-if="company?.attributes?.subscription_status == 'payment_failed'"
      class="w-full h-fit bg-red-500 flex text-center md:text-left flex-col md:flex-row sticky justify-between top-20 z-50 items-center gap-3 p-4"
    >
      <div class="flex items-center gap-4 flex-col md:flex-row">
        <IconPack
          type="ExclamationTriangle"
          class="text-white shrink-0 w-7 h-7"
        />
        <p class="text-white font-semibold text-lg">
          Payment method has failed. Please update your payment method on our
          website to continue using our services.
        </p>
      </div>
      <div>
        <AppButton
          @click="updateSubscription"
          color="white"
          class="!text-red-500"
          >Update Payment Method</AppButton
        >
      </div>
    </div>

    <div class="h-fit flex relative">
      <div class="dialogs fixed top-0 left-0">
        <DialogsWrapper />
      </div>
      <AppSideBar />
      <!-- <AppSideBar v-if="!$vacl.has('ew_admin')" /> -->
      <!-- <AdminSideBar v-else-if="!hasCustomSidebar" />
      <AdminSideBar v-else>
        <slot name="customSidebar" />
      </AdminSideBar> -->

      <main
        class="w-full px-4 lg:px-10 py-20 justify-center !overflow-y-visible scrollbar-hide relative h-fit"
        :class="{ flex: !$vacl.has('ew_admin') }"
      >
        <routerView v-if="!$vacl.has('ew_admin')" />
        <slot v-if="$vacl.has('ew_admin')" />
      </main>
    </div>
  </div>
</template>
<script>
import AppSideBar from '../components/Dashboard/AppSideBar.vue';
export default {
  components: {
    AppSideBar
  },
  mounted() {},
  computed: {
    hasCustomSidebar() {
      return !!this.$slots.customSidebar;
    }
  },
  methods: {
    updateSubscription() {
      const planCode = this.company?.attributes?.plan_code;

      this.$router.push(`/company/subscribe/${planCode}`);
    }
  }
};
</script>
