<template>
  <div class="flex h-screen justify-center items-center flex-col">
    <div class="flex flex-col w-full max-w-md px-6 py-8 md:px-8 lg:px-10">
      <EWMainLogo class="justify-self-start self-start mb-10 m-auto w-48" />

      <div class="m-auto w-full">
        <AppTitle class="self-start text-xl font-bold sm:text-2xl mb-4"
          >Sign in</AppTitle
        >
        <div>
          <div class="flex flex-col mb-2">
            <TextInput v-model="email" placeholder="Email address"
              ><template #icon
                ><IconPack type="Envelope" class="w-5 h-5" /></template
            ></TextInput>
          </div>

          <div class="flex flex-col mb-6">
            <TextInput v-model="password" type="password" placeholder="Password"
              ><template #icon
                ><IconPack type="LockOpen" class="w-5 h-5" /></template
            ></TextInput>
          </div>

          <div class="flex items-center mb-6 -mt-4">
            <div class="flex ml-auto">
              <router-link
                to="/forgot-password"
                class="inline-flex text-xs font-medium text-gray-400 sm:text-sm hover:text-gray-500 transition-colors duration-300"
              >
                Forgot your password?
              </router-link>
            </div>
          </div>
          <div class="flex w-full">
            <AppButton
              color="primary"
              class="justify-center"
              :pulse="loading"
              @click="login()"
              >Login</AppButton
            >
          </div>
        </div>
      </div>
    </div>
    <router-link
      to="/register"
      class="text-gray-500 font-medium hover:text-gray-700 transition-colors duration-300"
      ><div class="flex flex-row items-center">
        <IconPack type="Pencil" class="w-5 h-5 mr-2" /> New? Sign up here
      </div></router-link
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false
    };
  },
  computed: {
    company() {
      return this.$store.getters['Company/company'];
    },
    returnTo() {
      return this.$route.query?.return_to;
    },
    nextPath() {
      return this.returnTo ? this.returnTo : null;
    }
  },
  mounted() {
    if (this.$route.query.verified)
      this.$toast.success('Your email has been verified. You can now login.');
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch('Authentication/login', {
          identifier: this.email?.trim(),
          password: this.password?.trim()
        })
        .then((profile) => {
          this.$store
            .dispatch('Company/getCompany')
            .then(() => {
              if (profile?.data?.role?.type === 'ew_admin') {
                this.$router.push('/documents/templates');
              } else if (this.nextPath) {
                this.$router.push(this.nextPath);
              } else if (
                this.company?.attributes?.plan_code &&
                this.company?.attributes?.subscription_status === 'active'
              ) {
                this.$router.push('/');
              } else {
                this.$router.push('/invite/get-the-app');
              }
              this.loading = false;
            })
            .catch((error) => {
              this.$router.push('/subscription');
              this.loading = false;
            });
        })
        .catch((error) => {
          this.toastApiErrors(error);
          this.loading = false;
        });
    }
  }
};
</script>
