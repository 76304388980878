export default {
  SET_DOCUMENT_TYPES(state, payload) {
    state.types = payload;
  },

  ADD_DOCUMENT_TYPE(state, payload) {
    state.types.push(payload);
  },

  UPDATE_DOCUMENT_TYPE(state, payload) {
    state.types = state.types.map((type) => {
      if (type.attributes.uid === payload.attributes.uid) {
        return payload
      } else return type;
    });
  },
  SET_GENERATED_PDF(state, payload) {
    state.generatedPDF.push(payload);
  },

  UPDATE_STATIC_DOCS(state, payload) {
    console.log('UPDATE_STATIC_DOCS', payload, state);
  },

  SET_CURRENT_DOCUMENT(state, payload) {
    state.currentDocument = payload;
  },
  SET_DOCUMENTS(state, payload) {
    state.documents = payload;
  },

  SET_DOCUMENT_CATEGORIES(state, payload) {
    state.categories = payload;
  }
};
