<template>
  <AppCard class="w-full relative">
    <div class="flex flex-wrap gap-4 items-center justify-between">
      <div class="flex flex-col w-3/4 gap-4">
        <div class="flex gap-2 items-center">
          <IconPack type="Briefcase" class="w-6 h-6 text-primary-500" />
          <h1 class="text-lg text-primary-600 font-extrabold">Job Details</h1>

          <div
            v-if="job.reference_number"
            class="py-1 px-2 w-fit flex flex-nowrap ml-4 flex-shrink-0 bg-gray-100 border rounded"
          >
            <p class="text-xs font-bold text-gray-500">
              REF: {{ job.reference_number }}
            </p>
          </div>
        </div>
        <div v-if="job.title || job.description" class="flex flex-col gap-1">
          <p v-if="job.title" class="text-base font-bold text-primary-500">
            {{ job.title }}
          </p>
          <p v-if="job.description" class="text-sm text-gray-500">
            {{ job.description }}
          </p>
        </div>
      </div>
      <!-- edit -->
      <div
        @click="editJob"
        class="flex gap-2 items-center cursor-pointer rounded bg-white p-2 border border-gray-300 shadow-sm text-gray-500 hover:bg-gray-100 min-w-fit transition-all duration-300"
      >
        <IconPack type="PencilSquare" class="w-5 h-5" />
        <p class="font-medium">Edit Job</p>
      </div>
      <!-- job type -->
      <div
        v-if="job && job.job_type"
        class="w-fit py-2 px-4 rounded-full"
        :style="'background-color:' + job.job_type.attributes.color"
      >
        <p class="font-bold text-white">{{ job.job_type.attributes.name }}</p>
      </div>
    </div>
  </AppCard>
  <AppCard class="-mt-8">
    <div class="flex flex-wrap gap-4 items-center justify-between">
      <div class="flex flex-col gap-3">
        <h1 class="text-lg text-primary-600 font-extrabold">Scheduled for</h1>
        <div class="flex gap-3">
          <AppChip class="bg-secondary">
            <template #icon>
              <IconPack type="Clock" class="w-5 h-5 text-white" />
            </template>
            <p class="font-medium text-base">{{ getTime }}</p>
          </AppChip>
          <AppChip class="bg-primary">
            <template #icon>
              <IconPack type="Calendar" class="w-5 h-5 text-white" />
            </template>
            <p class="font-medium text-base flex gap-1.5">
              <span v-for="(date, i) in getDateCreated" :key="i">
                {{ date }}
              </span>
            </p>
          </AppChip>
        </div>
      </div>

      <div class="flex flex-col gap-3">
        <h1 class="text-lg text-primary-600 font-extrabold">Status</h1>
        <div
          class="flex gap-1 items-center"
          :class="{
            'text-green-500': status.status == 'completed',
            'text-secondary-500': status.status !== 'completed'
          }"
        >
          <IconPack :type="getIcon" class="w-5 h-5" />
          <p class="font-semibold text-base">{{ getStatus }}</p>
        </div>
      </div>
      <div
        class="w-fit"
        v-tippy="{
          content:
            company?.attributes?.subscription_status == 'payment_failed'
              ? 'Please update your payment method.'
              : ''
        }"
      >
        <AppButton
          ref="statusChange"
          :pulse="pulse"
          class=""
          :disabled="
            pulse ||
            company?.attributes?.subscription_status == 'payment_failed'
          "
          @click="jobStatusChange"
        >
          <template #icon>
            <IconPack
              :type="
                status.status === 'completed' ? 'ArrowUturnLeft' : 'CheckCircle'
              "
              class="w-5 h-5 text-white"
            />
          </template>
          <p>{{ status.status === 'completed' ? 'Reset' : 'Complete' }}</p>
        </AppButton>
      </div>
    </div>
  </AppCard>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      getTime: moment(this.job.start_date).format('HH:mm'),
      getDateCreated: moment(this.job.start_date)
        .format('Do MMM YYYY')
        .split(' '),
      pulse: false
    };
  },
  computed: {
    status() {
      if (!this.job.status.length) {
        return {
          status: 'in_progress',
          attributes: {
            created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            updated_at: moment().format('YYYY-MM-DD HH:mm:ss')
          }
        };
      }
      return this.job.status[this.job.status.length - 1].attributes;
    },

    getStatus() {
      switch (this.status.status) {
        case 'completed':
          return 'Completed';
        default:
          return 'In Progress';
      }
    },
    getIcon() {
      switch (this.status.status) {
        case 'completed':
          return 'CheckCircle';
        default:
          return 'Clock';
      }
    }
  },
  methods: {
    async jobStatusChange() {
      this.pulse = true;

      const newStatus =
        this.status.status === 'completed' ? 'in_progress' : 'completed';

      try {
        await this.$store.dispatch('Jobs/changeJobStatus', {
          id: this.job.id,
          status: newStatus
        });

        this.status.status = newStatus;
        this.$toast.success('Job status changed successfully');
      } catch (error) {
        this.$toast.error('Job status change failed');
      } finally {
        this.pulse = false;
      }
    },

    editJob() {
      this.$router.push({
        name: 'Edit-Site',
        params: {
          id: this.job.uid
        }
      });
    }
  }
};
</script>
