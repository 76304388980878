<template>
    <svg  viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.0002 23.0001H26.2697C25.9102 23.617 25.9102 24.3826 26.2697 25.0002H25.0002V23.0001ZM1.73031 23.0001H2.99979V25.0002H1.73031C2.08979 24.3826 2.08979 23.617 1.73031 23.0001ZM17.5117 15.0821C18.5704 17.0231 17.8551 19.4491 15.9177 20.5115C15.629 20.6682 15.3242 20.7893 15.0077 20.8709L15.5896 22.863L12.5628 20.4101C12.4104 20.2854 12.3864 20.0586 12.5118 19.9062C12.6095 19.7889 12.7735 19.7422 12.9179 19.8005C14.4645 20.3948 16.2028 19.6292 16.7971 18.0819C17.1172 17.2616 17.0545 16.3399 16.633 15.5626L17.5117 15.0821ZM12.4104 11.1366L15.4372 13.5895C15.5896 13.7149 15.6129 13.9417 15.4882 14.0941C15.3905 14.2151 15.2265 14.2581 15.0821 14.2034C13.5348 13.6019 11.8008 14.3711 11.2029 15.9177C10.8828 16.738 10.9455 17.6604 11.367 18.4377L10.4883 18.9182C9.42958 16.9801 10.1442 14.5469 12.0823 13.4881C12.371 13.3321 12.6758 13.211 12.9923 13.1286L12.4104 11.1366ZM25.0002 2.99979H26.2697C25.9102 3.6174 25.9102 4.38302 26.2697 4.9999H25.0002V2.99979ZM6.00031 2.99979H21.9997V7.99969H6.00031V2.99979ZM1.73031 2.99979H2.99979V4.9999H1.73031C2.08979 4.38302 2.08979 3.6174 1.73031 2.99979ZM4.9999 0C3.89448 0 2.99979 0.894688 2.99979 2.0001H0V2.99979C0.550521 2.99979 0.999687 3.44896 0.999687 4.00021C0.999687 4.55073 0.550521 4.9999 0 4.9999V6.00031H2.99979V21.9997H0V23.0001C0.550521 23.0001 0.999687 23.4493 0.999687 23.9998C0.999687 24.551 0.550521 25.0002 0 25.0002V25.9999H2.99979C2.99979 27.1053 3.89448 28 4.9999 28H23.0001C24.1055 28 25.0002 27.1053 25.0002 25.9999H28V25.0002C27.4495 25.0002 27.0003 24.551 27.0003 23.9998C27.0003 23.4493 27.4495 23.0001 28 23.0001V21.9997H25.0002V6.00031H28V4.9999C27.4495 4.9999 27.0003 4.55073 27.0003 4.00021C27.0003 3.44896 27.4495 2.99979 28 2.99979V2.0001H25.0002C25.0002 0.894688 24.1055 0 23.0001 0H4.9999Z"
            fill="" />
        <circle cx="14.5" cy="17.5" r="7.5" fill="" />
        <path d="M11 18.327L14.8182 14L14 17.1731H17L13.1818 21.5001L14 18.327H11Z" fill="#fff" stroke="#fff"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {

}
</script>