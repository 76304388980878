<template>
  <AppCard class="relative">
    <div class="flex items-center gap-4">
      <div class="w-14 h-14 bg-gray-200 rounded-full"></div>
      <div class="flex w-full flex-col gap-2 mt-3">
        <div
          class="w-fit text-lg font-bold text-transparent h-4 bg-gray-200 rounded"
        >
          Account DemoUSer
        </div>
        <div class="w-fit text-transparent h-4 bg-gray-200 rounded">
          demouseraccount@demo.comz
        </div>
        <div
          class="w-fit text-lg font-bold text-transparent h-4 bg-gray-200 rounded"
        >
          Account DemoUSer
        </div>
      </div>
    </div>
  </AppCard>
</template>

<script>
export default {
  name: 'ContactCardSkeleton',
  props: {
    icononly: { type: Boolean, default: false },
    condensed: { type: Boolean, default: false }
  }
};
</script>
