<template>
  <AppModal ref="ConnectInviteModal" style="backdrop-filter: blur(3px);" name="ConnectInviteModal" v-model="openModal"
    v-slot:default="{ params }">
    <div v-if="params?.id" class="w-full h-screen flex relative">
      <div @click="hideModal" class="w-full opacity-0 h-full z-10 absolute top-0 left-0"></div>
      <div class="w-full max-w-screen-md px-20 h-fit m-auto py-5 relative">
        <div class="w-full z-20 h-full rounded-xl relative">
          <AppCard class="w-full h-fit px-10 pt-6 pb-8 rounded-xl">
            <div class="flex flex-row items-center justify-center gap-2 w-full">
              <ConnectIconLogo
                class="h-15 w-16 p-1 rounded-xl shadow bg-gradient-to-br from-primary-400 to-primary-600" />
              <AppTitle class="text-2xl">Invite to Connect</AppTitle>
            </div>

            <PlantCard small class="my-4" :plant="params" />

            <p class="my-4">
              This will allow the customer to set up their account and manage
              their plant on the Connect mobile app.
            </p>

            <div class="my-4 w-full border-t" />
            <p>
              <b>Reload AI</b> the customer will be able to opt-in to Reload AI
              to optimise their system for Load Shedding.
            </p>

            <div class="my-4">
              <AddClientView v-model="client" />
            </div>

            <AppButton @click="sendInvite(params.id)" :pulse="submitting" type="primary" class="mt-4">Send Invite
              <IconPack type="PaperAirplane" class="ml-2 mb-1 -rotate-45 w-4 h-4" />
            </AppButton>
          </AppCard>
          <div @click="hideModal"
            class="w-10 h-10 hover:shadow-sm hover:shadow-white hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full absolute top-0 -right-14 flex items-center justify-center">
            <IconPack type="XMark" class="w-5 h-5 text-primary-500" />
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>
<script>
import ConnectIconLogo from '@/components/EW/ConnectIconLogo.vue';
import PlantCard from '@/components/Connect/PlantCard.vue';
import AddClientView from '@/components/Contacts/AddClientView.vue';
import ConfirmDialog from '@/components/EW/ConfirmDialog.vue';
import { createConfirmDialog } from 'vuejs-confirm-dialog';

export default {
  components: {
    ConnectIconLogo,
    PlantCard,
    AddClientView
  },
  data() {
    return {
      openModal: false,
      submitting: false,
      client: null
    };
  },
  computed: {
    plants() {
      return this.$store.getters['Plants/plants'];
    },
  },
  methods: {
    hideModal() {
      this.openModal = false;
      this.client = null;
    },
    sendInvite(plantId) {
      const selectedPlant = this.plants.find((p) => p.id === plantId);

      this.submitting = true;

      if (!this.client) {
        this.$toast.error('Please select a client');
        this.submitting = false;
        return;
      }

      const pastInvites = selectedPlant.attributes.invites.data.find((i) => i.attributes.email === this.client.email);

      const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
        icon: pastInvites ? 'ExclamationCircle' : 'PaperAirplane',
        title: pastInvites ? 'Resend Invite' : 'Send Invite',
        message: pastInvites ? 'This client has already been invited. Do you want to resend the invite?' : `Are you sure you want to send an invite to ${this.client.first_name} ${this.client.last_name}?`,
        color: 'primary',
      });

      reveal();

      onConfirm(() => {
        this.$store
          .dispatch('Plants/sendInvite', {
            plantId,
            client: this.client
          })
          .then(() => {
            this.$toast.success('Invite sent');
            this.$store.dispatch('Plants/getPlantById', plantId).then(() => {
              this.hideModal();
              this.submitting = false;
            });
          })
          .catch(() => {
            this.$toast.error('Failed to send invite');
          })
      });

    }
  },
  watch: {
    openModal() {
      const invites = this.$refs.ConnectInviteModal.params?.attributes.invites?.data;
      const contactUid = invites[0]?.attributes?.comply_contact_uid;

      if (contactUid) {
        this.client = this.$store.getters['Contacts/contacts'].find((c) => c.uid === contactUid);
      }
    }
  }
};
</script>
