export default {
    SET_JOB_COMMENTS: (state, jobComments) => state.jobComments = jobComments,
    SET_FETCHING: (state, fetching) => state.fetching = fetching,
    ADD_JOB_COMMENT: (state, jobComment) => state.jobComments.push(jobComment),
    DELETE_JOB_COMMENT: (state, jobCommentId) => {
        state.jobComments = state.jobComments.filter(jobComment => jobComment.id !== jobCommentId);
    },
    UPDATE_JOB_COMMENT: (state, jobComment) => {
        const index = state.jobComments.findIndex(jc => jc.id === jobComment.id);
        if (index !== -1) {
            state.jobComments[index] = jobComment;
        }
    },
    SET_TOTAL_COMMENTS: (state, totalComments) => state.totalComments = totalComments,
}