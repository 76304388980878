import store from '..';
import moment from 'moment';


export default {
  getDocumentTemplates({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(import.meta.env.VITE_API_URL + '/document-types', {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          },
          params: {
            populate: 'category'
          }
        })
        .then((response) => {
          console.log('getDocumentTemplates', response);

          const data = response.data.data.map((item) => {
            return {
              id: item.id,
              attributes: {
                ...item.attributes,
                category: item.attributes.category.data
              }
            };
          });
          if (response.status === 200) {
            commit('SET_DOCUMENT_TYPES', data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  createDocumentTemplate({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      store.$api.post(import.meta.env.VITE_API_URL + '/document-types', { data: payload }, {
        headers: {
          Authorization: 'Bearer ' + rootGetters['Authentication/token']
        }
      })
        .then((response) => {
          console.log('createDocumentTemplate', response);
          if (response.status === 200) {
            commit('ADD_DOCUMENT_TYPE', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log('createDocumentTemplate', error.response);
          reject(error);
        }
        );
    });
  },

  updateDocumentTemplate({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      store.$api
        .put(
          import.meta.env.VITE_API_URL + '/document-types/' + payload.id,
          { data: payload.attributes },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['Authentication/token']
            },
            params: {
              populate: 'category'
            }
          }
        )
        .then((response) => {
          console.log('updateDocumentTemplate', response);

          // map reponse.data.data
          const data = {
            id: response.data.data.id,
            attributes: {
              ...response.data.data.attributes,
              category: response.data.data.attributes.category.data
            }
          };

          console.log('data', data);

          if (response.status === 200) {
            commit('UPDATE_DOCUMENT_TYPE', data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log('updateDocumentTemplate', error.response);
          reject(error);
        });
    });
  },

  deleteDocumentTemplate({ commit, rootGetters }, documentTypeId) {
    return new Promise((resolve, reject) => {
      store.$api
        .delete(import.meta.env.VITE_API_URL + '/document-types/' + documentTypeId, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })

        .then((response) => {
          console.log('deleteDocumentTemplate', response);
          if (response.status === 200) {
            commit('DELETE_DOCUMENT_TYPE', documentTypeId);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log('deleteDocumentTemplate', error.response);
          reject(error);
        });
    });
  },

  getLastMonthDocumentUsage({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(import.meta.env.VITE_API_URL + '/documents', {
          params: {
            'pagination[limit]': 1,
            'filters[createdAt][$gte]': moment()
              .subtract(30, 'days')
              .format('YYYY-MM-DD'),

            ...(payload?.name && {
              'filters[document_type][name][$contains]': payload.name
            })
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  generatePDF({ rootGetters }, document_id) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(
          import.meta.env.VITE_API_URL + `/documents/generate-pdf/${document_id}`,
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['Authentication/token']
            }
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // commit('SET_GENERATED_PDF', {id: document_id, data:response.data});
            console.log('generatePDF', response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log('generatePDF', error.response);
          reject(error);
        });
    });
  },

  async lockDocument({ rootGetters }, document_id) {

    return new Promise((resolve, reject) => {
      store.$api
        .post(
          import.meta.env.VITE_API_URL + `/documents/lock-document/${document_id}`, {},
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['Authentication/token']
            }
          }
        )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log('lockDocument error:', error.response);
          reject(error);
        });
    }).catch((error) => {
      console.log('lockDocument error:', error);
      throw error;
    });
  },

  async createStaticDocument({ rootGetters }, payload) {
    try {
      const response = await store.$api.post(
        import.meta.env.VITE_API_URL + '/static-documents',
        { data: payload },
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        }
      );
      if (response.status === 200) {
        return response;
      }
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  },

  async upadteStaticDocument({ rootGetters }, payload) {
    try {
      const response = await store.$api.put(
        import.meta.env.VITE_API_URL + '/static-documents/' + payload.id,
        { data: payload.attributes },
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        }
      );
      if (response.status === 200) {
        return response;
      }
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteStaticDocument({ rootGetters }, document_id) {

    return new Promise((resolve, reject) => {
      store.$api
        .delete(import.meta.env.VITE_API_URL + `/static-documents/${document_id}`, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log('deleteStaticDocument', error.response);

          reject(error);
        });
    });
  },

  requestDocumentApprovalToggle({ rootGetters }, { documentId, approvalUserId, action }) {
    return async function requestDocumentApprovalToggle() {
      return new Promise((resolve, reject) => {
        store.$api
          .get(import.meta.env.VITE_API_URL + `/documents/${documentId}/request-approval/${approvalUserId}/${action}`, {
            headers: { Authorization: 'Bearer ' + rootGetters['Authentication/token'] },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  },


  toggleApproval({ rootGetters }, { documentId, userId, approved, latitude, longitude }) {
    return async function approveDocument() {
      return new Promise((resolve, reject) => {
        return store.$api
          .post(
            import.meta.env.VITE_API_URL + '/documents/approval/' + documentId,
            {
              userId, approved, latitude, longitude
            },
            {
              headers: { Authorization: 'Bearer ' + rootGetters['Authentication/token'] }
            }
          )
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((error) => {
            reject(error);
            console.log(error);
          });
      });
    };
  },

  getDocumentByType({ rootGetters }, { type }) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(import.meta.env.VITE_API_URL + '/documents', {
          params: {
            populate: 'document_type',
            'filters[document_type][name][$containsi]': type,
            'filters[is_template][$eq]': 'true',
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  createDocument({ rootGetters }, document) {
    return new Promise((resolve, reject) => {
      store.$api
        .post(import.meta.env.VITE_API_URL + '/documents', { data: document }, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    }
    );
  },

  cloneDocument({ rootGetters }, { documentId, jobId, documentName }) {
    return new Promise((resolve, reject) => {
      store.$api
        .post(import.meta.env.VITE_API_URL + '/documents/clone/' + documentId, { job: jobId, name: documentName }, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    }
    );
  },

  deleteDocument({ rootGetters }, documentId) {
    return new Promise((resolve, reject) => {
      store.$api
        .delete(import.meta.env.VITE_API_URL + '/documents/' + documentId, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },


  async getDocumentById({ rootGetters, commit }, documentUid) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(import.meta.env.VITE_API_URL + '/documents', {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          },
          params: {
            'filters[uid][$eq]': documentUid,
            populate: 'document_type,document_values,job'
          }
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
            if (response.data.data.length > 0) {
              commit('SET_CURRENT_DOCUMENT', response.data.data[0]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  getDocuments({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(import.meta.env.VITE_API_URL + '/documents', {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          },
          params: {
            populate: 'user_approvals, approvals_required, creator, updator, is_approved, is_locked, last_value_update, document_type',
            ...payload,
          }
        })
        .then((response) => {
          if (response.status === 200) {
            commit('SET_DOCUMENTS', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },


  getDocumentCategories({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(import.meta.env.VITE_API_URL + '/document-type-categories', {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          },
          params: {
            filters: {
              allow_custom: {
                $ne: false
              }
            }
          }
        })
        .then((response) => {
          console.log('getDocumentCategories', response);
          if (response.status === 200) {
            commit('SET_DOCUMENT_CATEGORIES', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  toggleArchiveDocumentType({ commit, rootGetters }, { id, archived }) {
    console.log('toggleArchiveDocumentType', id, archived);
    return new Promise((resolve, reject) => {
      store.$api
        .put(import.meta.env.VITE_API_URL + '/document-types/' + id, { data: { archived } }, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_DOCUMENT_TYPE', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  cloneDocumentType({ commit, rootGetters }, { id, name }) {
    return new Promise((resolve, reject) => {
      store.$api
        .post(import.meta.env.VITE_API_URL + '/document-types/clone/' + id, { name }, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          if (response.status === 200) {
            commit('ADD_DOCUMENT_TYPE', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }


};
