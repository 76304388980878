<template>
    <AppCard class="mt-4 relative">
        <!-- skeleton loader -->
        <div class="flex animate-pulse justify-between">
            <div class="h-full w-10 absolute top-0 left-0  bg-gray-300 rounde-l-lg"></div>
        </div>
        <div class="animate-pulse ml-14 flex flex-col gap-4">
            <div class="flex gap-10 items-center">
                <div class="w-1/4 h-32 bg-gray-300 rounded"></div>
                <div class="w-1/2 flex flex-col gap-2">
                    <div class="w-1/3 h-4 bg-gray-300 rounded"></div>
                    <div class="w-1/4 h-3 bg-gray-300 rounded-full"></div>
                    <div class="w-24 h-6 bg-gray-300 rounded"></div>
                </div>
            </div>
            <hr>
            <div class="flex p-1 justify-between items-center">
                <div class="flex gap-6 w-2/3 items-center justify-between">
                    <div class="flex w-1/2 gap-2">
                        <div class="w-24 h-7 bg-gray-300 rounded-full"></div>
                        <div class="w-24 h-7 bg-gray-300 rounded-full"></div>
                    </div>
                    <div class="flex w-1/2 items-center">
                        <svg class="w-10 h-10 me-3 text-gray-200 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                        </svg>
                        <div class="w-1/3 h-3 bg-gray-300 rounded-full"></div>
                    </div>
                </div>
                <div class="w-1/3 h-5 bg-gray-300 rounded-full"></div>
            </div>  
        </div>
    </AppCard>
</template>
