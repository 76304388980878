<template>
  <component :is="icon"></component>
</template>
<script>
import * as OutlineIcons from '@heroicons/vue/24/outline';
import * as SolidIcons from '@heroicons/vue/24/solid';
import InverterIcon from '@/assets/icons/InverterIcon.vue';
import BatteryIcon from '@/assets/icons/BatteryIcon.vue';

export default {
  components: {
    SolidIcons,
    OutlineIcons,
    InverterIcon,
    BatteryIcon,
  },
  props: {
    solid: { type: Boolean, default: false },
    type: { type: String, default: '' },
  },
  computed: {
    icon() {
      let IconPack = this.solid ? SolidIcons : OutlineIcons;
      if (this.type === 'Inverter') return 'InverterIcon';
      if (this.type === 'Battery') return 'BatteryIcon';
      return IconPack[this.type + 'Icon'];
    }
  }
};
</script>
