<template>
  <AppCard
    v-if="contact"
    :open="open"
    @toggle-collapse="toggleCollapse"
    :clickable="clickable && !icononly"
    :condensed="this.condensed || this.icononly"
    class="relative"
    :style="
      icononly
        ? 'padding: 0px !important; background-color: transparent !important;'
        : ''
    "
    :class="{
      'w-fit': condensed && !icononly,
      'w-full h-full': !condensed && !icononly,
      'w-fit border-none': icononly
    }"
  >
    <template v-if="collapsable" #title>
      <div class="flex gap-2 items-center">
        <IconPack type="UserCircle" class="w-6 h-6 text-primary-500" />
        <p class="text-primary-500 font-medium text-lg">Contact</p>
      </div>
      <p v-if="collapsed" class="text-sm ml-7 mt-1 text-gray-400">
        {{ contact.first_name }} {{ contact.last_name }}
      </p>
    </template>

    <div
      class="flex flex-col justify-between w-full h-full items-start"
      :class="{
        'mt-4 w-full': collapsable && !icononly,
        'w-fit': !collapsable
      }"
    >
      <div
        :class="{ 'gap-2': condensed && !icononly, 'gap-5': !condensed }"
        class="flex items-center w-full"
      >
        <tippy>
          <div
            :style="`background-color: ${color ? color : backgroundColor};`"
            :class="{
              'w-9 h-9': condensed,
              'w-7 h-7': icononly,
              'hover:shadow hover:-translate-y-0.5 transition-all duration-300 cursor-pointer':
                clickable && icononly
            }"
            class="w-14 h-14 items-center justify-center flex rounded-full"
          >
            <p
              :class="{
                'text-sm': condensed,
                'text-xl': !condensed,
                'text-xs': icononly
              }"
              class="font-black tracking-wider text-white"
            >
              {{ initials }}
            </p>
          </div>
        </tippy>
        <div v-if="!icononly">
          <h2
            :class="{ 'text-md': condensed, 'text-lg': !condensed }"
            class="text-primary-600 text-left whitespace-nowrap font-bold"
          >
            {{ contact.first_name }} {{ contact.last_name }}
          </h2>
          <div class="flex flex-col gap-2 mt-2" v-if="!condensed">
            <p class="flex gap-2">
              <span class="text-sm font-semibold text-gray-500">Email: </span>
              <span class="text-sm font-semibold text-gray-500">{{
                contact.email
              }}</span>
            </p>
            <p class="flex gap-2">
              <span class="text-sm font-semibold text-gray-500">Phone: </span>
              <span class="text-sm font-semibold text-gray-500">{{
                contact.cellphone
              }}</span>
            </p>
          </div>
        </div>
        <div v-if="!icononly" class="flex items-center gap-5 ml-auto">
          <div
            v-if="editable"
            v-tippy="{ content: 'Edit', delay: 500 }"
            class="bg-gray-100 hover:shadow hover:-translate-y-0.5 transition-all duration-300 hover:bg-primary-300 group rounded-full p-2"
          >
            <IconPack
              type="PencilSquare"
              class="w-4 h-4 text-gray-400 group-hover:text-white transition-all duration-300"
              @click="toggleCollapse(!collapsed)"
            />
          </div>
          <AppButton
            color="gray"
            v-if="deletable"
            v-tippy="{ content: 'Delete', delay: 500 }"
            @click.stop="deleteContact(contact)"
            class="!bg-gray-100 !border-none hover:shadow hover:-translate-y-0.5 transition-all duration-300 hover:!bg-red-300 group rounded-full !p-2"
            :pulse="pulse"
          >
            <IconPack
              type="Trash"
              class="w-4 h-4 text-gray-400 group-hover:text-white transition-all duration-300"
              @click="toggleCollapse(!collapsed)"
            />
          </AppButton>
        </div>
      </div>

      <div
        v-if="collapsable && !hideActions"
        class="flex flex-wrap h-full lg:h-10 w-full px-5 mt-5 lg:absolute lg:bottom-4 lg:left-0 justify-center lg:justify-end gap-3"
      >
        <div>
          <AppButton outline class="group">
            <template #icon>
              <IconPack
                type="Phone"
                class="w-5 h-5 text-primary-500 group-hover:text-white"
              />
            </template>
            <p
              class="text-primary-500 font-medium group-hover:text-white whitespace-nowrap"
            >
              Call {{ contact.first_name }}
            </p>
          </AppButton>
        </div>
        <div>
          <AppButton outline class="group">
            <template #icon>
              <IconPack
                type="Envelope"
                class="w-5 h-5 text-primary-500 group-hover:text-white"
              />
            </template>
            <p class="text-primary-500 font-medium group-hover:text-white">
              Email
            </p>
          </AppButton>
        </div>
        <div>
          <AppButton @click="applyFilter" color="secondary">
            <template #icon>
              <IconPack type="Briefcase" class="w-5 h-5 text-white" />
            </template>
            <p class="text-white font-medium">Jobs</p>
          </AppButton>
        </div>
      </div>
    </div>
    <template v-if="!collapsed" #bottom>
      <div class="flex justify-end"></div>
    </template>
  </AppCard>
  <AppCard v-else class="h-full">
    <template #title>
      <div class="flex gap-2 items-center">
        <IconPack type="UserCircle" class="w-6 h-6 text-primary-500" />
        <p class="text-primary-500 font-medium text-lg">Contact</p>
      </div>
    </template>
    <p class="text-sm ml-7 mt-1 text-gray-400">No client selected</p>
  </AppCard>
</template>

<script>
import { Tippy } from 'vue-tippy';

export default {
  components: {
    Tippy
  },
  emits: ['bg-color', 'delete'],
  props: {
    contact: { type: Object, required: false },
    collapsable: { type: Boolean, default: false },
    condensed: { type: Boolean, default: false },
    icononly: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
    clickable: { type: Boolean, default: false },
    color: { type: String, default: undefined },
    editable: { type: Boolean, default: false },
    deletable: { type: Boolean, default: false },
    hideActions: { type: Boolean, default: false }
  },
  data() {
    return {
      texts: [this.contact?.first_name, this.contact?.last_name],
      collapsed: !this.open,
      pulse: false
    };
  },
  computed: {
    backgroundColor() {
      const color = stringToHslColor(this.texts.join(''), 60, 70);
      this.$emit('bg-color', color);
      return color;
    },
    initials() {
      if (this.contact.first_name && this.contact.last_name) {
        return (
          this.contact.first_name?.charAt(0) + this.contact.last_name.charAt(0)
        );
      } else {
        return this.contact.first_name?.charAt(0);
      }
    }
  },
  methods: {
    toggleCollapse(collapsed) {
      this.collapsed = collapsed;
    },
    applyFilter() {
      this.$router.push({ path: '/jobs', query: { client: this.contact.uid } });
    },
    async deleteContact(contact) {
      const filter = {
        filters: {
          client: {
            id: {
              $eq: contact.id
            }
          }
        }
      };
      this.pulse = true;
      await this.$store
        .dispatch('Jobs/getJobs', { filters: filter })
        .then(() => {
          this.pulse = false;
        });
      this.$emit('delete', contact);
    }
  },
  watch: {
    contact: {
      handler() {
        this.texts = [this.contact.first_name, this.contact.last_name];
      },
      deep: true
    }
  }
};

const stringToHslColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};
</script>
