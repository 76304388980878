<template>
    <svg viewBox="0 0 34 18" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="9" height="12" rx="2" stroke="none" fill-opacity="0.9"/>
        <path d="M13.2502 9.07695L17.0685 4.75L16.2503 7.9231H19.2503L15.4321 12.2501L16.2503 9.07695H13.2502Z" 
            stroke-linecap="round" stroke-linejoin="round" />
        <path fill-rule="evenodd" clip-rule="evenodd" stroke="none" fill-opacity="0.6"
            d="M0 4.5C0 2.01472 2.02964 0 4.53333 0H27.2C29.7037 0 31.7333 2.01472 31.7333 4.5V4.55626C33.0266 4.81685 34 5.95184 34 7.3125V10.6875C34 12.0482 33.0266 13.1832 31.7333 13.4437V13.5C31.7333 15.9853 29.7037 18 27.2 18H4.53333C2.02964 18 0 15.9853 0 13.5V4.5ZM29.4667 4.5C29.4667 3.25736 28.4518 2.25 27.2 2.25H4.53333C3.28149 2.25 2.26667 3.25736 2.26667 4.5V13.5C2.26667 14.7426 3.28149 15.75 4.53333 15.75H27.2C28.4518 15.75 29.4667 14.7426 29.4667 13.5V4.5Z" />
    </svg>
</template>

<script>
export default {

}
</script>