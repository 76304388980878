import store from '..';

export default {
  getPlans({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      store.$api
        .post(
          import.meta.env.VITE_API_URL + '/billing/plans',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['Authentication/token']
            }
          }
        )
        .then((response) => {
          console.log('getPlans', response);
          if (response.status === 200) {
            commit('SET_PLANS', response.data.plans);
            commit('SET_BILLABLES', response.data.billables)

            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            commit('SET_PLANS', {});
            commit('SET_BILLABLES', {});
          }
          
          console.log(error);
          reject(error);
        });
    });
  }
};
